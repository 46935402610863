import axios from 'axios';
import { base_URL } from '../config/config';

const API_BASE_URL = `${base_URL}/api`;

export const registerVerify = async (emailSignup, password) => {
    try {
        let data = { email:emailSignup };


        console.log('Email Signup:', emailSignup);
        console.log('Data:', data);

        const response = await axios.post(`${API_BASE_URL}/vendor/check/verify`, data);
        const { accessToken, refreshToken } = response.data;
        // Save tokens to local storage
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
        }
        if (refreshToken) {
            localStorage.setItem('refreshToken', refreshToken);
        }
        return response.data;
    } catch (error) {
        console.error('Error checking email verification:', error.response ? error.response.data : error.message);
        throw error;
    }
};

