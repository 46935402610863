/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { base_URL } from '../../config/config';
import { VenueContext } from '../../context/VenueContext';
import { saveResData } from '../../api/restaurant-api';
import Cookies from 'js-cookie';
import { Carousel } from 'react-responsive-carousel';

const HeroSection = ({ data = [], data2 }) => {
	// Provide a default value for data
	const { formData, setFormData, resetForm } =
		useContext(VenueContext);
	const navigate = useNavigate();
	const [isExpanded, setIsExpanded] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

	const vendorId = localStorage.getItem('vendorId');
	const path = useLocation();
	const errors = localStorage.getItem('Errors');

	const handleSubmit = async () => {
		if (errors) {
			toast.error(errors.trim());
		} else {
			const updatedItemStatus =
				formData?.itemStatus === 'Approved'
					? 'Edit Pending'
					: formData?.itemStatus === 'Rejected'
					? 'ReSubmitting'
					: 'Pending';

			// Manually construct the updated form data
			const updatedFormData = {
				...formData,
				itemStatus: updatedItemStatus,
				vendorId: vendorId,
				status: 'completed',
			};

			// Log the updated form data for verification
			console.log('Form Data before submit:', updatedFormData);

			if (path.pathname?.includes('venue-preview')) {
				const response = await axios.post(
					`${base_URL}/api/venueform/updateVenueForm`,
					updatedFormData
					// { ...formData, vendorId: vendorId, status: "completed" }
				);

				if (response.data.success) {
					// Assuming `success` indicates a successful registration
					toast.success('Venue created successfully!');
					localStorage.removeItem('Errors');
					Cookies.remove('uploadedFiles');
					resetForm();
					setTimeout(() => {
						navigate('/dashboard?venueRegister=true'); // Redirect to the dashboard
					}, 1000); // Delay to allow toast message to display
				} else {
					// Handle unsuccessful venue creation
					toast.error('Failed to create the venue.');
				}
			} else {
				const updatedItemStatus =
					formData?.itemStatus === 'Approved'
						? 'Edit Pending'
						: formData?.itemStatus === 'Rejected'
						? 'ReSubmitting'
						: 'pending';

				// Manually construct the updated form data
				const updatedFormData = {
					...formData,
					itemStatus: updatedItemStatus,
					vendorId: vendorId,
					status: 'completed',
				};
				const response = await axios.post(
					`${base_URL}/api/restaurantform/updateResForm`,
					updatedFormData
					// { ...formData, vendorId: vendorId, status: "completed" }
				);

				if (response.data.success) {
					toast.success('Restaurant created successfully!');
					Cookies.remove('uploadedFiles');
					resetForm();
					setTimeout(() => {
						navigate('/dashboard?restaurantRegister=true');
					}, 1000); // Delay to allow toast message to display
				} else {
					console.log(response);
					// Handle unsuccessful restaurant creation
					toast.error('Failed to create the restaurant.');
				}
			}
		}
	};
	const handleExpandClick = () => {
		setIsExpanded(!isExpanded);
	};
	return (
		<div className='p-6'>
			<div className='flex flex-wrap lg:px-20 justify-between gap-5 items-center w-full mb-6'>
				<Link
					to={
						path.pathname?.includes('/restaurant-preview')
							? '/venu-restaurant-Form?lastStep=lastStep'
							: '/venu-Listing-Form?lastStep=lastStep'
					}
					className='flex items-center text-gray-600 cursor-pointer'
				>
					<FaArrowLeft className='mr-2' />
					<span>Back</span>
				</Link>
				<div className='flex gap-3 items-center'>
					<div className='bg-black px-2 md:px-0 md:w-[200px] h-[40px] gap-x-2 flex items-center justify-center rounded-lg'>
						<img src='/tick.svg' alt='' />
						<button className=' text-white  ' onClick={handleSubmit}>
							Submit for review
						</button>
					</div>
					<Link
						to={
							path.pathname?.includes('/restaurant-preview')
								? '/venu-restaurant-Form'
								: '/venu-Listing-Form'
						}
						className='bg-red-500 text-white px-2 md:px-0 md:w-[200px] h-[40px] justify-center rounded-lg flex items-center'
					>
						<AiOutlineEdit className='mr-2' /> Edit
					</Link>
				</div>
			</div>
			{/* venue name and previewlisting card button */}
			<div className='flex justify-between lg:px-20 mb-5'>
				<div>
					<h2 className='text-[32px] font-bold flex gap-4 items-center'>
						Venue Detail Page
					</h2>
				</div>
				{/* preview button */}
				<Link
					to={
						path.pathname?.includes('/restaurant-preview')
							? '/venu-restaurant-Form?lastStep=lastStep'
							: '/venu-Listing-Form?lastStep=lastStep'
					}
				>
					<div className='active:opacity-30 mt-1 flex items-center justify-center text-[#222222] font-[500px] px-2 border border-[#222222] rounded-lg md:px-0 md:w-[250px] h-[40px] gap-x-3 cursor-pointer'>
						<img src='/eye.svg' alt='' />
						<button>Preview Listing Card</button>
					</div>
				</Link>
				{/* slider */}
			</div>
			<div className='flex justify-between flex-wrap gap-1 lg:gap-0 lg:px-16 px-0 mb-4'>
				{/* main image */}
				{data[0] && (
					<div className='relative lg:w-1/2 w-full'>
						<img
							onClick={handleExpandClick}
							src={data[0]}
							alt='Main Image'
							className='rounded-lg w-full h-[585px] object-cover cursor-pointer'
						/>
					</div>
				)}
				{/* right side images */}
				<div className='flex flex-col justify-between lg:w-[49.5%] w-full'>
					{data[1] && (
						<img
							src={data[1]}
							alt='Image 2'
							className='rounded-lg mb-2 h-[351px] object-cover cursor-pointer active:opacity-65'
						/>
					)}
					<div className='flex justify-between gap-1  flex-wrap'>
						{data[2] && (
							<img
								src={data[2]}
								alt='Image 3'
								className='rounded-lg mb-4 h-[228px] w-full sm:w-[50%] object-cover cursor-pointer active:opacity-65'
							/>
						)}
						{data.length > 3 && (
							<div className='  h-[228px] w-full sm:w-[49%] relative active:opacity-65'>
								<img
									src={data[3]}
									alt='Image 3'
									className='rounded-lg mb-4 w-full h-full object-cover cursor-pointer'
								/>
								<div className='absolute top-0 h-full rounded-lg bg-opacity-80 w-full bg-black text-white flex items-center justify-center cursor-pointer gap-x-3 active:opacity-65'>
									<img src='/puzzle.svg' alt='' />
									Show all photos
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroSection;
