import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import OutlinedLabelledTextField from "../../components/OutlinedLabelledTextfield";
import backgroundIcon from "../../assets/images/background_icon.svg";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MessageCard from "../onboarding/components/MessageCard";
import EmailSentBadgeIcon from "../../assets/icons/EmailSentBadgeIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { register } from "../../api/signup.request";
import EmailVerification from "./EmailVerification";
import { toast } from "react-toastify";
import { LoaderIcon } from "react-hot-toast";

function Register() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [workMail, setWorkMail] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const [loading, setLoading] = useState(false);

  let verifyPassword;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset errors
    setEmailError(false);
    setPasswordError(false);
    setPasswordMatchError(false);
    if (!isValidEmail(workMail)) {
      setEmailError(true);
      toast.error("Invalid email format");
      return;
    }

    if (!isValidPassword(createPassword)) {
      setPasswordError(true);
      toast.error(
        "Password must be at least 8 characters long."
      );
      return;
    }

    if (!doPasswordsMatch(createPassword, confirmPassword)) {
      setPasswordMatchError(true);
      toast.error("Passwords do not match");
      return;
    }

    setLoading(true);

    try {
      const signupData = {
        email: workMail,
        name: fullName,
        password: createPassword,
      };

      const response = await register(signupData);
      console.log('response', response);

      // Store the tokens and vendor ID in localStorage
      // localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("vendorId", response?.newVendor?._id);

      // Check if vendorId is stored correctly
      const storedVendorId = localStorage.getItem("vendorId");
      if (!storedVendorId) {
        throw new Error("Failed to store vendor ID. Please try again.");
      }

      // Proceed only if vendorId is stored successfully
      setApiResponse(response);
      setIsSubmitted(true);
      setLoading(false);
      toast.success("Please verify your email address.");
    } catch (error) {
      setLoading(false);
      console.error("Error registering venue:", error);
      toast.error(error.message || "An error occurred during registration.");
    }
  };




  const isValidPassword = (password) => {
    // const passwordRegex = /^(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    // return passwordRegex.test(password);
    return password.length >= 8;
  };

  const handleRetryRegistration = async () => {
    try {
      const signupData = {
        email: workMail,
        name: fullName,
        password: createPassword,
      };

      const response = await register(signupData);
      setApiResponse(response);
      setIsSubmitted(true);
      toast.success(response.message);
      console.log(response);
    } catch (error) {
      console.error("Error registering venue:", error);
    }
  };

  const doPasswordsMatch = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const isValidEmail = (email) => {
    const freeEmailDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
    const emailParts = email.split('@');
    if (emailParts.length !== 2) return false;

    const domain = emailParts[1];
    return !freeEmailDomains.includes(domain) && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const isFormValid = () => {
    return (
      fullName.trim() !== "" &&
      workMail.trim() !== "" &&
      createPassword.trim() !== "" &&
      confirmPassword.trim() !== "" &&
      checked
    );
  };

  return (
    <>
      <ResponsiveAppBar />
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "30%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "65vh",
        }}
      >
        {isSubmitted ? (
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mt: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "85%",
              }}
            >
              <EmailVerification
                email={workMail}
                password={(verifyPassword = "email")}
              />
              <MessageCard
                loaderComponent={
                  <CheckCircleIcon
                    sx={{
                      fontSize: 60,
                      mt: 4,
                      color: theme.palette.green.text,
                    }}
                  />
                }
                primaryText={"Verify your email"}
                secondaryText={`We've sent an email to ${workMail} to verify your email address and activate your account`}
                richText={"If you haven't received an email after a while,"}
                richLinkText={"click here to try again"}
                onRichLinkClick={handleRetryRegistration}
              />
            </Box>
          </Container>
        ) : (
          <>
            <Container>
              <Box
                sx={{
                  mt: 6,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    mt: { xs: 7, md: 10 },
                    fontWeight: "600",
                    fontSize: { xs: "1.6rem", md: "32px" },
                  }}
                >
                  Please fill in the details below to{" "}
                  <span style={{ color: theme.palette.primary.main }}>
                    Register
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ mb: { md: 4 }, color: "grey.dark" }}
                >
                  Ready to list your venue?
                </Typography>
              </Box>
            </Container>
            <Container
              maxWidth="sm"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box sx={{ mt: 7, width: { md: "80%" } }}>
                <form onSubmit={handleSubmit}>
                  <OutlinedLabelledTextField
                    id="fullName"
                    label="Full Name"
                    placeholder="Enter Full Name"
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />

                  <OutlinedLabelledTextField
                    id="workMail"
                    label="Work Mail"
                    placeholder="user@workapp.com"
                    type="email"
                    value={workMail}
                    onChange={(e) => setWorkMail(e.target.value)}
                    error={emailError}
                    helperText={
                      emailError ? "Please enter a valid email address" : ""
                    }
                  />

                  <OutlinedLabelledTextField
                    id="createPassword"
                    label="Create Password"
                    placeholder="Create a password"
                    type={showPassword ? 'text' : 'password'}
                    value={createPassword}
                    onChange={(e) => setCreatePassword(e.target.value)}
                    error={passwordError || passwordMatchError}
                    helperText={
                      passwordError
                        ? "Password must be at least 8 characters long."
                        : passwordMatchError
                        ? "Passwords do not match"
                        : "Password should be at least 8 characters long."
                    }
                    eye={true}
                    showPassword={showPassword}
                    togglefunction={togglePasswordVisibility}
                  />

                  <OutlinedLabelledTextField
                    id="confirmPassword"
                    label="Confirm Password"
                    placeholder="Re-enter your password"
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={passwordMatchError}
                    helperText={passwordMatchError ? "Passwords do not match" : ""}
                    eye={true}
                    showPassword={showPassword}
                    togglefunction={togglePasswordVisibility}
                  />

                  <Box sx={{ mb: { xs: 2, md: 7 } }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(e) => setChecked(e.target.checked)}
                          sx={{
                            transform: "scale(1.5)", // Adjust the size of the checkbox
                            marginRight: "10px", // Add some space between the checkbox and label
                            color: "grey.border",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body1" // Change this to match the text field size
                          sx={{
                            color: "grey.text",
                            fontSize: "1rem",
                            textAlign: { xs: "left" },
                          }}
                        >
                          I agree to the{" "}
                          <Link
                            href="/terms&condition"
                            color="primary"
                            target="_blank"
                            sx={{ textDecoration: "none" }}
                          >
                            Terms & Conditions
                          </Link>{" "}
                          and{" "}
                          <Link
                            href="/privacy-policy"
                            color="primary"
                            target="_blank"
                            sx={{ textDecoration: "none" }}
                          >
                            Privacy Policy
                          </Link>
                        </Typography>
                      }
                    />
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    sx={{
                      mb: { xs: 1, md: 3 },
                      borderRadius: "8px",
                      mt: 2,
                      backgroundColor: isFormValid()
                        ? "primary.main"
                        : "grey.border",
                      color: isFormValid() ? "white" : "text",
                    }}
                    disabled={!isFormValid()}
                  >
                    {loading ? <LoaderIcon /> : "Register"}
                  </Button>
                </form>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    color: "grey.text",
                  }}
                >
                  Already have an account?{" "}
                  <Link
                    href="/login"
                    color="primary"
                    sx={{ textDecoration: "none" }}
                  >
                    Login.
                  </Link>
                </Typography>
              </Box>
            </Container>
          </>
        )}
      </div>
    </>
  );
}

export default Register;
