'use client'
import React, { useState } from 'react'
import logo from '../../assets/pics/whiteLogo.svg'
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { Link } from 'react-router-dom';


const Navbar = () => {

    const [openExplore, setOpenExplore] = useState(false)

    const handleExplore = () => {
        setOpenExplore((prev) => !prev)
    }



    return (
        <div className='bg-primary flex justify-center w-full py-3 z-30'>
            <div className='w-[90%] flex justify-between items-center ' >

                {/* logo */}
                <div>
                    <img alt='' src={logo} />
                </div>

                {/* profile and explore  */}
                <div className='flex items-center gap-8 relative z-50 '>
                    {/* <div onClick={handleExplore} className='sm:flex hidden items-center gap-2 cursor-pointer'>
                        <h1 className='text-white'>Explore</h1>
                        {
                            openExplore ? <IoMdArrowDropup className='text-white text-lg' /> :
                                <IoMdArrowDropdown className='text-white text-lg' />
                        }
                        {
                            openExplore &&
                            <div className='bg-white rounded-lg z-50 absolute top-9 left-0 shadow-xl py-2 px-2' >
                                <li className='py-1 px-2 rounded-md hover:bg-[#9696964a]'>item 1</li>
                                <li className='py-1 px-2 rounded-md hover:bg-[#9696964a]'>item 2</li>
                                <li className='py-1 px-2 rounded-md hover:bg-[#9696964a]'>item 3</li>
                            </div>
                        }
                    </div> */}
                    <Link to={'/list-venues'} className='text-white sm:flex hidden'>List your venue</Link>

                    <Link to={'/login'} className='text-white'>login</Link>

                    <Link to={'/register'} className='bg-white py-1 px-2 rounded-sm text-[#222222]'>Signup</Link>

                </div>
            </div>
        </div>
    )
}

export default Navbar
