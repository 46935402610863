import React from 'react'
import img1 from '../../assets/pics/blog1.png'
import img2 from '../../assets/pics/blog2.png'
import img3 from '../../assets/pics/blog3.png'



const Blogs = () => {

    const data = [
        { img: img1, name: '7 Ideas for A company party on a budget', des: "Knowing how much you have to spend is the first step to planning " },
        { img: img2, name: 'Casual meet up ideas for the perfect meetings', des: "Knowing how much you have to spend is the first step to planning " },
        { img: img3, name: 'Casual meet up ideas for the perfect meetings', des: "Knowing how much you have to spend is the first step to planning " },
    ]


    return (
        <div className='flex justify-center pt-20' >
            <div className='w-[90%] flex flex-col items-center'>
                <h1 className='sm:text-4xl text-2xl text-darkGray font-semibold text-center my-4'>Recent blog articles</h1>
                <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4'>
                    {
                        data.map((e, i) => (
                            <div key={i} className=' bg-transparent' >
                                <img src={e.img} className='w-full shadow-2xl rounded-lg' alt="" />
                                <div className='flex justify-center -mt-6 bg-transparent'>
                                    <div className='bg-white rounded-lg w-[90%] p-3' >
                                        <h1 className='text-xl text-darkGray font-semibold' >{e.name}</h1>
                                        <p className='text-[#4B4B4B] mt-2'>{e.des}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='flex justify-center w-full sm:my-10 my-6'>
                    <button className='bg-primary text-white rounded-lg w-[130px] py-2'>View All Spaces</button>
                </div>
            </div>
        </div>
    )
}

export default Blogs
