import React from 'react'
import Img from '../../assets/pics/listVenuImg.png'


const ListVenu = () => {
    return (
        <div className='flex justify-center pt-20'>
            <div className='w-[90%] md:flex-row flex-col-reverse flex justify-between items-center gap-12' >
                <div className='md:w-1/2 w-full flex flex-col md:items-start items-center xl:pr-16 pr-8 gap-4' >
                    <h1 className='xl:text-5xl lg:text-4xl text-3xl md:text-start text-center font-semibold text-darkGray'>List your venue and expand your reach</h1>
                    <p className='text-secondary md:text-start text-center text-lg mt-2 font-medium'>We are the fastest growing venue search platform in the UAE, providing you direct access to your target customers. Make your venue visible to event planners and venue seekers globally.</p>

                    <div className='flex md:justify-start justify-center w-full sm:my-10 my-6'>
                        <button className='bg-primary text-white rounded-lg w-[120px] py-2'>Get Started</button>
                    </div>
                </div>
                <div className='md:w-1/2 w-full flex justify-center' >
                    <img src={Img} alt="" className='' />
                </div>
            </div>
        </div>
    )
}

export default ListVenu
