import React, { useState, useEffect, useContext } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { Tooltip } from "@mui/material";
import { VenueContext } from "../../../context/VenueContext";

const PricingForm = ({ id, type, removeForm }) => {
  const { formData, setFormData } = useContext(VenueContext);
  const [formDetails, setFormDetails] = useState({
    minSpend: "",
    currency: "AED",
    duration: "",
    from: "",
    to: "",
    selectedDays: [],
  });

  const [error, setError] = useState({
    minSpend: "",
    duration: "",
    from: "",
    to: "",
    selectedDays: [],
  });

  useEffect(() => {
    const forms = formData.pricing && formData.pricing[type];
    const form = forms ? forms.find((item) => item.id === id) : null;
    if (form) {
      setFormDetails(form);
      validateForm(form);
    }
  }, [id, type, formData.pricing]);

  const validateForm = (form) => {
    const errors = { ...error };

    if (!form.minSpend) {
      errors.minSpend = "Minimum spend is required.";
    } else {
      errors.minSpend = "";
    }

    if (!form.duration) {
      errors.duration = "Duration is required.";
    } else {
      errors.duration = "";
    }

    if (!form.from) {
      errors.from = "please set the start time";
    } else {
      errors.from = "";
    }

    if (!form.to) {
      errors.to = "please set the end time.";
    } else {
      errors.to = "";
    }

    if (form?.selectedDays.length < 1) {
      errors.selectedDays = "Select the Day .";
    } else {
      errors.selectedDays = "";
    }
    setError(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => {
      const updatedForm = { ...prev, [name]: value };
      updateFormData(updatedForm);
      return updatedForm;
    });
  };

  const updateFormData = (updatedForm) => {
    // Avoid unnecessary updates
    setFormData((prevData) => {
      const updatedPricing = {
        ...prevData.pricing,
        [type]: (prevData.pricing[type] || []).map((item) =>
          item.id === id ? { ...item, ...updatedForm } : item
        ),
      };
      // Only update if formData actually changes
      if (JSON.stringify(prevData.pricing) !== JSON.stringify(updatedPricing)) {
        return { ...prevData, pricing: updatedPricing };
      }
      return prevData;
    });
  };

  // Dont allow user to select same days on diff categories
  const handleDaySelection = (day) => {
    // Collect all selected days from other categories
    const allSelectedDays = Object.keys(formData.pricing).reduce(
      (days, categoryKey) => {
        if (categoryKey !== type) {
          const categoryItems = formData.pricing[categoryKey];
          if (Array.isArray(categoryItems)) {
            categoryItems.forEach((item) => {
              if (item.selectedDays) {
                days.push(...item.selectedDays);
              }
            });
          }
        }
        return days;
      },
      []
    );

    // Check if the day is already selected in another category
    const dayAlreadySelected = allSelectedDays.includes(day);

    if (dayAlreadySelected) {
      setError((prev) => ({
        ...prev,
        selectedDays: `${day} is already selected in another category.`,
      }));
      return;
    }

    // Update the selected days for the current form
    setFormDetails((prev) => {
      const updatedDays = prev.selectedDays.includes(day)
        ? prev.selectedDays.filter((selectedDay) => selectedDay !== day)
        : [...prev.selectedDays, day];
      const updatedForm = { ...prev, selectedDays: updatedDays };
      updateFormData(updatedForm);
      return updatedForm;
    });
  };

  const days = [
    { value: "Sun", label: "S" },
    { value: "Mon", label: "M" },
    { value: "Tue", label: "T" },
    { value: "Wed", label: "W" },
    { value: "Thu", label: "T" },
    { value: "Fri", label: "F" },
    { value: "Sat", label: "S" },
  ];
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col mb-2 gap-y-1">
          <h3 className="text-lg font-medium text-[#1D1F2C]">{type}</h3>
          {type == "Minimum Spend Pricing" && (
            <p className="text-[#B0B0B0] text-[14px]">
              The minimum spend per venue refers to the minimum amount required
              by the client to be spent on the entire event space
            </p>
          )}
          {type == "Venue Rental" && (
            <p className="text-[#B0B0B0] text-[14px]">
              Add minimum spend when no hire fee is required but the customer
              must reach a minimum spend at the bar.
            </p>
          )}
          {type == "Min spend per person" && (
            <p className="text-[#B0B0B0] text-[14px]">
              Add minimum spend when no hire fee is required but the customer
              must reach a minimum spend at the bar.
            </p>
          )}
        </div>
        <div className="border rounded-lg p-4 mb-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col text-[14px]  text-[#5E5E5E]">
              <label className="block mb-2">Minimum Spend</label>
              <div className="py-2 flex border mt-1 rounded-lg overflow-hidden h-[38px]">
                <select
                  name="currency"
                  value={formDetails.currency}
                  onChange={handleChange}
                  className="w-[25%]  pl-2 focus:outline-none outline-none"
                >
                  <option value="AED">AED</option>
                </select>
                <input
                  type="number"
                  name="minSpend"
                  value={formDetails.minSpend || ""}
                  onChange={handleChange}
                  min="0" // Allow decimal values if needed
                  onInput={(e) => {
                    // Optional: Prevent non-numeric characters from being input
                    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                  }}
                  placeholder="Enter pricing"
                  className="border-l  pl-2 w-full focus:outline-none placeholder:text-[#B0B0B0] outline-none"
                />
              </div>
              {error.minSpend && (
                <span className="text-red-500 text-sm mt-1">
                  {error.minSpend}
                </span>
              )}
            </div>
            <div>
              <label className="text-[14px]  text-[#5E5E5E] mb-2 flex items-center gap-2">
                Duration
                <Tooltip
                  title="You have the option to establish various pricing structures depending on different sessions, including, morning, evening, or night, as well as weekends and weekdays. Alternatively, you can choose a pricing format for the entire day sessions that aligns best with your requirements."
                  arrow
                >
                  <img src="/Info.svg" width={24} height={24} />
                </Tooltip>
              </label>
              <div className="select-container">
                <select
                  name="duration"
                  value={formDetails.duration || ""}
                  onChange={handleChange}
                  className="border rounded-lg p-2 w-full"
                >
                  <option value="">Select duration</option>
                  <option value="per session">per session</option>
                  <option value="permorning">per morning</option>
                  <option value="perafternoon">per afternoon</option>
                  <option value="perevening">per evening</option>
                  <option value="perday">per day</option>
                </select>
                <div className="custom-arrow">
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.174019 0.688203L0.17402 0.688202C0.281236 0.571237 0.42254 0.5125 0.599191 0.5125H9.40081C9.57742 0.5125 9.71871 0.571783 9.82594 0.689882C9.93375 0.808622 9.9875 0.946799 9.9875 1.10496C9.9875 1.11275 9.9843 1.12731 9.97655 1.14984C9.96895 1.17191 9.95748 1.20032 9.94204 1.23517C9.91131 1.3045 9.8652 1.39859 9.80364 1.51753L5.62731 5.69385C5.53216 5.78901 5.43416 5.85811 5.33338 5.90156C5.23256 5.94502 5.12149 5.96684 5 5.96684C4.87851 5.96684 4.76744 5.94502 4.66662 5.90156C4.56584 5.85811 4.46785 5.789 4.37269 5.69382L0.194725 1.51589C0.138084 1.45925 0.0935546 1.39609 0.0610483 1.3264C0.0287014 1.25702 0.0125 1.18268 0.0125 1.1032C0.0125 0.94387 0.0662612 0.805735 0.174019 0.688203Z"
                      fill="#222222"
                      stroke="#222222"
                      stroke-width="0.025"
                    />
                  </svg>
                </div>
              </div>
              {error.duration && (
                <span className="text-red-500 text-sm mt-1">
                  {error.duration}
                </span>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4 justify-between">
              <div>
                <label className="block mb-2 text-[14px]  text-[#5E5E5E]">
                  From
                </label>
                <div className="select-container">
                  <select
                    name="from"
                    value={formDetails.from || ""}
                    onChange={handleChange}
                    className="border rounded-lg p-2 w-full"
                  >
                    {Array.from({ length: 24 }, (_, i) => (
                      <>
                        <option
                          key={`${i}:00`}
                          value={`${String(i).padStart(2, "0")}:00`}
                        >
                          {`${String(i).padStart(2, "0")}:00`}
                        </option>
                        <option
                          key={`${i}:30`}
                          value={`${String(i).padStart(2, "0")}:30`}
                        >
                          {`${String(i).padStart(2, "0")}:30`}
                        </option>
                      </>
                    ))}
                  </select>
                  <div className="custom-arrow">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.174019 0.688203L0.17402 0.688202C0.281236 0.571237 0.42254 0.5125 0.599191 0.5125H9.40081C9.57742 0.5125 9.71871 0.571783 9.82594 0.689882C9.93375 0.808622 9.9875 0.946799 9.9875 1.10496C9.9875 1.11275 9.9843 1.12731 9.97655 1.14984C9.96895 1.17191 9.95748 1.20032 9.94204 1.23517C9.91131 1.3045 9.8652 1.39859 9.80364 1.51753L5.62731 5.69385C5.53216 5.78901 5.43416 5.85811 5.33338 5.90156C5.23256 5.94502 5.12149 5.96684 5 5.96684C4.87851 5.96684 4.76744 5.94502 4.66662 5.90156C4.56584 5.85811 4.46785 5.789 4.37269 5.69382L0.194725 1.51589C0.138084 1.45925 0.0935546 1.39609 0.0610483 1.3264C0.0287014 1.25702 0.0125 1.18268 0.0125 1.1032C0.0125 0.94387 0.0662612 0.805735 0.174019 0.688203Z"
                        fill="#222222"
                        stroke="#222222"
                        stroke-width="0.025"
                      />
                    </svg>
                  </div>
                </div>
                {error.from && (
                  <span className="text-red-500 text-sm mt-1">
                    {error.from}
                  </span>
                )}
              </div>
              <div>
                <label className="block mb-2 text-[14px]  text-[#5E5E5E]">
                  To
                </label>
                <div className="select-container">
                  <select
                    name="to"
                    value={formDetails.to || ""}
                    onChange={handleChange}
                    className="border rounded-lg p-2 w-full"
                  >
                    {Array.from({ length: 24 }, (_, i) => (
                      <>
                        <option
                          key={`${i}:00`}
                          value={`${String(i).padStart(2, "0")}:00`}
                        >
                          {`${String(i).padStart(2, "0")}:00`}
                        </option>
                        <option
                          key={`${i}:30`}
                          value={`${String(i).padStart(2, "0")}:30`}
                        >
                          {`${String(i).padStart(2, "0")}:30`}
                        </option>
                      </>
                    ))}
                  </select>
                  <div className="custom-arrow">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.174019 0.688203L0.17402 0.688202C0.281236 0.571237 0.42254 0.5125 0.599191 0.5125H9.40081C9.57742 0.5125 9.71871 0.571783 9.82594 0.689882C9.93375 0.808622 9.9875 0.946799 9.9875 1.10496C9.9875 1.11275 9.9843 1.12731 9.97655 1.14984C9.96895 1.17191 9.95748 1.20032 9.94204 1.23517C9.91131 1.3045 9.8652 1.39859 9.80364 1.51753L5.62731 5.69385C5.53216 5.78901 5.43416 5.85811 5.33338 5.90156C5.23256 5.94502 5.12149 5.96684 5 5.96684C4.87851 5.96684 4.76744 5.94502 4.66662 5.90156C4.56584 5.85811 4.46785 5.789 4.37269 5.69382L0.194725 1.51589C0.138084 1.45925 0.0935546 1.39609 0.0610483 1.3264C0.0287014 1.25702 0.0125 1.18268 0.0125 1.1032C0.0125 0.94387 0.0662612 0.805735 0.174019 0.688203Z"
                        fill="#222222"
                        stroke="#222222"
                        stroke-width="0.025"
                      />
                    </svg>
                  </div>
                </div>
                {error.to && (
                  <span className="text-red-500 text-sm mt-1">{error.to}</span>
                )}
              </div>
            </div>
            <div className="mt-2">
              <label className="block mb-2 text-[14px]  text-[#5E5E5E]">
                Choose the days for which the pricing applies
              </label>
              <div className="flex space-x-4">
                {days.map((day) => (
                  <button
                    key={day.value}
                    type="button"
                    className={`w-8 h-8 rounded-full flex items-center border justify-center ${
                      formDetails.selectedDays?.includes(day.value)
                        ? "border-green1 text-green1"
                        : "border-secondary text-secondary"
                    }`}
                    onClick={() => handleDaySelection(day.value)}
                  >
                    {day.label}
                  </button>
                ))}
              </div>
              {error.selectedDays && (
                <span className="text-red-500 text-sm mt-1">
                  {error.selectedDays}
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-end mt-3">
            <button
              onClick={removeForm}
              className="text-secondary flex items-center gap-4 font-medium"
            >
              <IoTrashOutline size={20} />
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingForm;
