import React from 'react'
import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoTwitter } from "react-icons/io";
import img from '../../assets/pics/whyImg.png'


const WhyUs = () => {

    const data = [
        { head: 'Comprehensive Platform', des: 'We are your one-stop solution for venue needs, providing diverse options like hotels, unconventional venues, and kid-friendly spaces to meet any event requirement.' },
        { head: 'Diverse Venue Options', des: 'Our amazing team is always seeking to list fresh and unique venues. From hidden gems to unconventional spaces, we offer venue choices that guarantee unforgettable events.' },
        { head: 'Absolutely Free', des: 'No Hidden fees or charges for venue seekers to browse, evaluate and connect, irrespective of your event.' },
        { head: 'Seamless Process', des: 'Our intuitive and user-friendly platform streamlines the search process, enabling you to discover your perfect venue with ease.' },
        { head: 'Advance filtering technology', des: 'Refine your search using our filters to discover venues that best suit your preferences.' },
        { head: 'Accessible Anywhere', des: 'No matter where you are, Find my Venue allows you to effortlessly  search and connect with venue operators, in just a few clicks.' },
    ]

    return (
        <div className='flex justify-center pt-20' >
            <div className='w-[90%]'>
                <h1 className='text-[#222222] sm:text-4xl text-3xl font-semibold'>Why Find My Venue</h1>
                <div className='flex items-center mt-10'>
                    <div className='lg:w-[60%] w-full h-full'>
                        <div className=' grid grid-cols-2 gap-6'>
                            {
                                data.map((e, i) => (
                                    <div key={i} className='text-[#B0B0B0]' >
                                        <h1 className='sm:text-xl text-lg font-semibold  ' >{e.head}</h1>
                                        <p className='mt-2 sm:text-base text-sm'>{e.des}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='text-[#B0B0B0] w-full flex sm:flex-col lg:mt-0 md:mt-3 mt-6 sm:gap-2 gap-4 sm:justify-normal justify-center flex-row'>
                            <h1 className='text-lg'>Follow us on</h1>
                            <div className='flex gap-3  items-center'>
                                <FaSquareFacebook className='text-xl' />
                                <FaWhatsapp className='text-xl' />
                                <IoLogoLinkedin className='text-xl' />
                                <IoLogoTwitter className='text-xl' />
                            </div>
                        </div>
                    </div>
                    <div className='w-[40%] lg:flex hidden justify-center'>
                        <img src={img} className='' alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs
