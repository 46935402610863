// src/data/formData.js
export const hotelTypes = [
    'Luxury Hotel', 'Boutique Hotel', 'Resort Hotel', 'Budget Hotel',
    'Business Hotel', 'Airport Hotel', 'Eco-friendly Hotel', 'All-Inclusive Resort',
    'Historic Hotel', 'Casino Hotel', 'Glamping Hotel', 'Floating Hotel',
    'Safari Hotel', 'Golf Resorts', 'Wellness Hotel', 'Heritage Hotel',
    'Beach Hotel', 'Others'
];

export const hotelViews = [
    'Creek', 'River', 'Lake', 'Meadow/Field', 'Desert', 'Ocean',
    'Mountain', 'Forest', 'Vineyard', 'Valley', 'Cliffside', 'Island',
    'Sunset/Sunrise', 'Cityscape', 'Skyline', 'Garden', 'Golf Course',
    'Landscaped Grounds', 'Park', 'Courtyard', 'Countryside', 'Trails',
    'Harbor', 'Waterfall', 'Waterfront', 'Ravine', 'Glacier', 'Tundra',
    'Wetlands', 'Marsh', 'Pond', 'Canyon', 'Marine', 'Boardwalk',
    'Promenade', 'Rooftop', 'Stadium', 'Amusement Park', 'Plaza',
    'Historical District', 'Pedestrian Street', 'Waterfront Park'
];

export const hotelStyles = {
    traditional: [
        'Colonial', 'Spanish', 'Victorian', 'Gothic Revival', 'Tudor',
        'French Country', 'Mediterranean', 'Farmhouse', 'Georgian', 'Federal',
        'Cape Cod', 'Bungalow', 'Arts & Crafts', 'Arts Nouveau'
    ],
    modern: [
        'Minimalist', 'Contemporary', 'Modern', 'Mid-Century Modern', 'Scandinavian',
        'Industrial', 'Brutalism', 'Postmodernism', 'Deconstructivism', 'High-tech', 'Parametricism'
    ],
    eclectic: [
        'Eclectic', 'Bohemian', 'Art Deco', 'Shabby Chic', 'Retro',
        'Fusion', 'Global'
    ],
    cultural: [
        'Asian Fusion', 'Chinese Traditional', 'Japanese Traditional', 'Balinese',
        'Thai', 'Korean Hanok', 'Indian Traditional', 'Tibetan', 'Southeast Asian',
        'Filipino Bahay Kubo', 'Vietnamese Colonial', 'Malaysian Malay',
        'Singaporean Peranakan', 'Indonesian Javanese', 'Mongolian Ger', 'Moroccan',
        'Persian/Iranian', 'African Tribal', 'Maori', 'Mexican'
    ]
};


export const VenueStyles = {
    traditionalStyle: [
        'Colonial', 'Spanish', 'Victorian', 'Gothic Revival', 'Tudor',
        'French Country', 'Mediterranean', 'Farmhouse', 'Georgian', 'Federal',
        'Cape Cod', 'Bungalow', 'Arts & Crafts', 'Arts Nouveau'
    ],
    modernContemporaryStyle: [
        'Minimalist', 'Contemporary', 'Modern', 'Mid-Century Modern', 'Scandinavian',
        'Industrial', 'Brutalism', 'Postmodernism', 'Deconstructivism', 'High-tech', 'Parametricism'
    ],
    eclecticStyle: [
        'Eclectic', 'Bohemian', 'Art Deco', 'Shabby Chic', 'Retro',
        'Fusion', 'Global'
    ],
    culturalRegionalStyle: [
        'Asian Fusion', 'Chinese Traditional', 'Japanese Traditional', 'Balinese',
        'Thai', 'Korean Hanok', 'Indian Traditional', 'Tibetan', 'Southeast Asian',
        'Filipino Bahay Kubo', 'Vietnamese Colonial', 'Malaysian Malay',
        'Singaporean Peranakan', 'Indonesian Javanese', 'Mongolian Ger', 'Moroccan',
        'Persian/Iranian', 'African Tribal', 'Maori', 'Mexican'
    ]
};


export const hotelChains = [
    'Accor',
    'Aman Resorts',
    'APA Group',
    'Archipelago International',
    'Ascott Limited (The)',
    'Avari Hotels',
    'Banyan Tree Holdings',
    'Barrière',
    'Belmond Limited',
    'Best Western Hotels',
    'Blackstone Group (The)',
    'BTG Homeinns',
    'Centara Hotels & Resorts',
    'China Lodging Group',
    'Choice Hotels International',
    'Cinnamon Hotels & Resorts',
    'Compass Hospitality',
    'Dalata Hotel Group',
    'Disney Experiences',
    'Dorchester Collection',
    'Drury Hotels',
    'Dusit International',
    'Elite Hotels of Sweden',
    'Eurobuilding Hotels',
    'Extended Stay America',
    'Fab Hotels',
    'First Hotels',
    'Four Seasons Hotels and Resorts',
    'General Hotel Management (GHM)',
    'GreenTree Inns',
    'Hilton Worldwide',
    'Huazhu Hotels Group',
    'Hyatt Hotels Corporation',
    'IHG Hotels & Resorts (IHG)',
    'Indian Hotels Company Limited (IHCL)',
    'Interstate Hotels & Resorts',
    'InTown Suites',
    'Jetwing Hotels Ltd',
    'Jinjiang International',
    'Jumeirah',
    'Kempinski',
    'Langham Hospitality Group',
    'Lifestyle Holidays Group',
    'Loews Hotels',
    'Lotte Hotels & Resorts',
    'Magnuson Hotels',
    'Mandarin Oriental Hotel Group',
    'Marriott International',
    'Meliá Hotels International',
    'MGM Resorts International',
    'Millennium & Copthorne Hotels',
    'Minor Hotels',
    'NH Hotel Group',
    'Nordic Choice Hotels',
    'Oberoi Group (The)',
    'Okura Nikko Hotel Management',
    'Omni Hotels & Resorts',
    'ONYX Hospitality Group',
    'OYO',
    'Pan Pacific Hotels and Resorts',
    'Prince Hotels',
    'Radisson Hotel Group',
    'Red Lion Hotels Corporation',
    'Red Planet Hotels',
    'Red Roof Inn',
    'RIU Hotels & Resorts',
    'Rocco Forte Hotels',
    'Rosewood Hotel Group',
    'S Hotels & Resorts',
    'Scandic Hotels',
    'Shahpura Hotels',
    'Shangri-La Hotels and Resorts',
    'Shilo Inns',
    'Soneva',
    'Tokyu Hotels',
    'Toyoko Inn',
    'Travelodge',
    'Treebo',
    'Warwick Hotels and Resorts',
    'Westgate Resorts',
    'Whitbread',
    'Wyndham Hotels & Resorts'
];



export const hotelBrands = [
    "Tokyu",
    "Excel",
    "REI",
    "Premier Inn",
    "hub by Premier Inn",
    "Wyndham Grand",
    "Dolce",
    "Wyndham",
    "TRYP",
    "Esplendor",
    "Dazzler",
    "Trademark",
    "La Quinta",
    "Wingate",
    "Wyndham Garden",
    "AmericInn",
    "Ramada",
    "Baymont",
    "Microtel",
    "Days Inn",
    "Super 8",
    "Howard Johnson's",
    "Travelodge",
    "Hawthorn Suites",
    "Eurobuilding Express",
    "Eurobuilding Hotel & Suites",
    "EB Hotel"
];
