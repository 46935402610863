import React, { useState } from 'react';

const AboutThisHotel = ({ data }) => {
	const [showMore, setShowMore] = useState(false);
	const view = data?.additionalDetails?.venueView?.join(', ');
	const style = [
		...data?.additionalDetails?.venueStyle.traditionalStyle,
		...data?.additionalDetails?.venueStyle.culturalRegionalStyle,
		...data?.additionalDetails?.venueStyle.eclecticStyle,
		...data?.additionalDetails?.venueStyle.modernContemporaryStyle,
	].join(', ');

	const handleViewBrochure = () => {
		if (data?.UploadDocuments?.brochure?.length > 0) {
			window.open(data.UploadDocuments.brochure[0], '_blank');
		}
	};

	return (
		<div className='py-5 lg:w-3/4 w-full  px-1 mx-auto'>
			<div className='bg-white p-6 rounded-lg mb-8'>
				<h2 className='text-2xl font-bold mb-4'>About This Venue</h2>
				{/* Conditionally render the "View Brochure" button */}
				{data?.UploadDocuments?.brochure?.length > 0 && (
					<button
						onClick={handleViewBrochure}
						className='text-primary font-medium mb-4'
					>
						View Brochure
					</button>
				)}
				<p
					className={`text-[#222222] text-xl font-normal mb-4 ${
						showMore ? '' : 'line-clamp-3'
					}`}
				>
					{data?.basicDetails?.venueDescription}
				</p>
				<button
					onClick={() => setShowMore(!showMore)}
					className='text-primary font-medium'
				>
					{showMore ? 'Show Less' : 'Show More'}
				</button>

				<div className='grid grid-cols-2 gap-4 p-8 w-full rounded-xl bg-[#F7F7F7] mt-6'>
					<div className='col-span-1 border-r border-gray-400 pr-4'>
						<h3 className='text-xl font-medium mb-2'>Style</h3>
						<span className='text-[#222222] text-sm'>{style}</span>
					</div>
					<div className='col-span-1 pl-4'>
						<h3 className='text-xl font-medium mb-2'>View</h3>
						<span className='text-[#222222] text-sm'>{view}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutThisHotel;
