import React, { useState, useRef, useEffect } from 'react';
import Navbar from '../../components/webComps/Navbar';
import Hero from './Hero';
import HowWorks from './HowWorks';
import Spaces from './Spaces';
import WhyUs from './WhyUs';
import ExploreVenues from './ExploreVenues';
import ExploreHotel from './ExploreHotel';
import ListVenu from './ListVenu';
import UniqueSpace from './UniqueSpace';
import Blogs from './Blogs';
import Feedback from './Feedback';
import Footer from '../../components/webComps/Footer';
import { useQuery } from '@tanstack/react-query';
import { getAllVenues } from '../../api/venue-api';

const Home = () => {
  const [hotelFilter, setHotelFilter] = useState([]);
  const [venueFilter, setVenueFilter] = useState([]);

  const heroAfterRef = useRef(null);

  const scrollToHeroAfter = () => {
    if (heroAfterRef.current) {
      heroAfterRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const currentPage = 1;
  const itemsPerPage = 1;

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['venueData', currentPage, itemsPerPage],
    queryFn: async () => {
      return await getAllVenues(currentPage, itemsPerPage);
    },
    refetchOnWindowFocus: false,
  });

  // Use useEffect to set filters once data is available
  useEffect(() => {
    if (data) {
      setHotelFilter(data.filter(item => item.category === "Hotel"));
      setVenueFilter(data.filter(item => item.category === "Venue"));
    }
  }, [data]); // This will run only when `data` changes

  console.log(venueFilter);
  console.log(hotelFilter);

  return (
    <div className='overflow-hidden'>
      <Navbar />
      <div ref={heroAfterRef}>
        <Hero />
      </div>
      <HowWorks />
      <Spaces />
      <WhyUs />
      <ExploreVenues venues={venueFilter} />
      <ExploreHotel hotels={hotelFilter} />
      <ListVenu />
      <UniqueSpace />
      <Blogs />
      <Feedback />
      <Footer onScrollDownClick={scrollToHeroAfter} />
    </div>
  );
}

export default Home;
