import React, { useContext, useEffect, useState } from 'react';
import DetailComponent from './DetailComponent';
import { GoPencil } from 'react-icons/go';
import Modal from '../../components/shared/custom-modal/custom-modal';
import { IoAddCircleOutline } from 'react-icons/io5';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import AddNewVenue from './AddNewVenue';
import VenuModel from '../../components/shared/VenuModal';
import ListonVenue from './ListonVenue';
import ArenaHall from './ArenaHall';
import { RxCross1 } from 'react-icons/rx';
import { VenueContext } from '../../context/VenueContext';
import { saveDataForVenue, saveVenueData } from '../../api/venue-api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createResForm, saveResData } from '../../api/restaurant-api';

const VenueCard = ({
	title,
	type,
	data,
	multiVenue,
	category,
	combineData,
}) => {
	const checkAdmin = localStorage.getItem('checkAdmin');

	const [isCardOpen, setIsCardOpen] = useState(true);
	const [activePopup, setActivePopup] = useState(null);
	const [modalStep, setModalStep] = useState(0);
	const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
	const [newTitle, setNewTitle] = useState(title);
	const [filteredData, setFilteredData] = useState([]);
	const { formData, setFormData } = useContext(VenueContext);
	const vendorId = localStorage?.getItem('vendorId');
	const navigate = useNavigate();

	useEffect(() => {
		if (title) {
			setNewTitle(title);
		}
	}, [title]);

	const showCards = () => {
		setIsCardOpen(!isCardOpen);
	};

	const handleTogglePopup = (index) => {
		setActivePopup(activePopup === index ? null : index);
	};

	const handleOpenModal = () => {
		setModalStep(1);
	};

	const handleCloseModal = () => {
		setModalStep(0);
	};
	const handleNextModalStep = () => {
		setModalStep(modalStep + 1);
	};

	const handleRenameClick = () => {
		setIsRenameModalOpen(true);
	};

	const handleRenameClose = () => {
		setIsRenameModalOpen(false);
	};

	const handleRenameSave = () => {
		// Here you can add the logic to save the new title
		setIsRenameModalOpen(false);
	};

	const handleAddSubVenue = () => {
		setModalStep(2); // Open the ListonVenue modal
	};

	const handleSelectVenue = (filteredData) => {
		setFilteredData(filteredData);
		setModalStep(3); // Open the ArenaHall modal
	};

	const [loading, setLoading] = useState(false); // Add loading state
	const handleSaveData = async () => {
		setLoading(true); // Set loading to true when starting the API call
		try {
			let updatedData;

			if (category === 'Venue' && combineData === 'Restaurant') {
				const firstItem = data[0];
				updatedData = {
					...formData,
					vendorId,
					basicDetails: {
						...formData?.basicDetails,
						hotelName: firstItem?.basicDetails?.isPrimaryVenue
							? firstItem?.basicDetails?.venueName
							: firstItem?.basicDetails?.hotelName,
						isPrimaryVenue: false,
					},
					location: firstItem?.location,
					status: 'pending',
				};

				const response = await createResForm(updatedData);
				if (response.success && response?.venueForm?._id) {
					console.log(response);

					navigate(
						`/create-Listing/Restaurant/${response.venueForm?._id}`
					); // Use navigate from react-router
				}
			} else if (category === 'Venue') {
				updatedData = {
					...formData,
					vendorId: vendorId, // Make sure to define or obtain vendorId
					status: 'pending',
				};
				const response = await saveDataForVenue(updatedData); // Make sure saveVenueData is defined
				if (response.success && response?.venueForm?._id) {
					console.log(response);
					navigate(
						`/create-Listing/${category}/${response.venueForm?._id}`
					); // Use navigate from react-router
				}
			}
			//  else {
			//   const response = await saveResData(updatedData); // Make sure saveVenueData is defined
			//   console.log(response);

			//   if (response.success && response?.restaurantForm?._id) {
			//     navigate(`/venu-restaurant-Form/${category}/${response.restaurantForm?._id}`); // Use navigate from react-router
			//   }
			// }
		} catch (error) {
			toast.error(
				error.response?.data?.error ||
					'An error occurred. Please try again.'
			);
			console.error('Error:', error);
		} finally {
			setLoading(false); // Set loading to false when API call completes
		}
	};

	return (
		<>
			<div className='flex justify-between items-center p-4 bg-white rounded-lg box-shadow mt-6'>
				<div>
					<div className='flex flex-row items-center'>
						<div className='font-medium text-2xl capitalize pe-3'>
							{newTitle}
						</div>
						{combineData !== 'hotel' && (
							<GoPencil
								className='text-lg cursor-pointer mt-2'
								onClick={handleRenameClick}
							/>
						)}
					</div>
					<div className='text-sm text-gray-500'>{type}</div>
				</div>
				<div className='flex items-center space-x-4'>
					{checkAdmin == 'true' && (
						<button
							disabled={!multiVenue}
							onClick={handleOpenModal}
							className={`flex gap-3 items-center ${
								multiVenue
									? 'text-gray-500'
									: 'cursor-not-allowed text-gray-200'
							}`}
						>
							<IoAddCircleOutline className='text-3xl cursor-pointer' />
							<p className='text-lg'>Add venue</p>
						</button>
					)}
					{!isCardOpen ? (
						<IoIosArrowDown
							className='text-4xl text-red-500 cursor-pointer'
							onClick={showCards}
						/>
					) : (
						<IoIosArrowUp
							className='text-4xl text-red-500 cursor-pointer'
							onClick={showCards}
						/>
					)}
				</div>
			</div>
			{isCardOpen && (
				<div className='flex justify-center '>
					<DetailComponent
						data={data}
						category={category}
						combineData={combineData}
					/>
				</div>
			)}

			{modalStep === 1 && (
				<Modal isOpen={true} onClose={handleCloseModal}>
					<AddNewVenue
						category={category}
						data={data}
						combineData={combineData}
						title={title}
						onContinue={handleNextModalStep}
						onClose={handleCloseModal}
						onAddSubVenue={handleAddSubVenue}
					/>
				</Modal>
			)}

			{isRenameModalOpen && (
				<Modal isOpen={true} onClose={handleRenameClose}>
					<div className='p-6 bg-white rounded-lg'>
						<button
							onClick={handleRenameClose}
							className='absolute top-4 right-4 text-gray-600 hover:text-gray-900'
						>
							<RxCross1 className='h-6 w-6' />
						</button>
						<h2 className='text-3xl text-center font-semibold mb-4'>
							Rename Venue
						</h2>
						<label className='block mb-2'>Name of the venue</label>
						<input
							type='text'
							value={newTitle}
							onChange={(e) => setNewTitle(e.target.value)}
							className='border rounded w-full py-3 px-3 mb-4'
						/>
						<button
							onClick={handleRenameSave}
							className='bg-red-500 text-white py-2 px-4 rounded w-full'
						>
							Save
						</button>
					</div>
				</Modal>
			)}

			{modalStep === 2 && (
				<VenuModel isOpen={true} onClose={handleCloseModal}>
					<ListonVenue
						category={category}
						data={data}
						combineData={combineData}
						onContinue={handleNextModalStep}
						onClose={handleCloseModal}
						onSelectVenue={handleSelectVenue}
					/>
				</VenuModel>
			)}

			{modalStep === 3 && (
				<VenuModel isOpen={true} onClose={handleCloseModal}>
					<ArenaHall
						title={title}
						formData={formData}
						setFormData={setFormData}
						filteredData={filteredData}
						onContinue={handleSaveData}
						onClose={handleCloseModal}
					/>
				</VenuModel>
			)}
		</>
	);
};

export default VenueCard;
