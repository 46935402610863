import React, { useContext, useState, useEffect } from "react";
import ToggleSwitch from "../../shared/common/custom-toggle";
import AddFeatureModal from "./add-feature-form";
import {
  FaPhoneAlt,
  FaConciergeBell,
  FaTshirt,
  FaSuitcaseRolling,
  FaGlobe,
  FaUtensils,
  FaBed,
  FaSnowflake,
  FaTrash,
} from "react-icons/fa";
import { HotelContext } from "../../../context/HotelContext";
import Modal from "../../shared/custom-modal/custom-modal";
import * as FaIcons from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

const defaultFeatures = [
  {
    name: "Calls (Local)",
    icon: <img src="/room-features/call.svg" />,
    isDefault: true,
  },
  {
    name: "Calls (Toll-free)",
    icon: <img src="/room-features/toll-free.svg" />,
    isDefault: true,
  },
  {
    name: "Concierge Services",
    icon: <img src="/room-features/concierge.svg" />,
    isDefault: true,
  },
  {
    name: "Laundry Service",
    icon: <img src="/room-features/laundry.svg" />,
    isDefault: true,
  },
  {
    name: "Luggage Storage",
    icon: <img src="/room-features/luggage.svg" />,
    isDefault: true,
  },
  {
    name: "Internet Access",
    icon: <img src="/room-features/internet.svg" />,
    isDefault: true,
  },
  {
    name: "Room Service",
    icon: <img src="/room-features/food-tray.svg" />,
    isDefault: true,
  },
  {
    name: "Air conditioning",
    icon: <img src="/room-features/air-condition.svg" />,
    isDefault: true,
  },
  {
    name: "Kitchen",
    icon: <img src="/room-features/kitchen.svg" />,
    isDefault: true,
  },
];

const RoomFeatures = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [features, setFeatures] = useState(defaultFeatures);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureToEdit, setFeatureToEdit] = useState(null);

  const roomFeatures = formData?.amenities?.roomFeatures || {};
  const roomFeaturesIcons = formData?.amenities?.roomFeaturesIcons || {};

  useEffect(() => {
    const iconsMap = new Map(Object.entries(roomFeaturesIcons));

    const updatedFeatures = Object.keys(roomFeatures).map((feature) => ({
      name: feature,
      icon: iconsMap.get(feature) || "FaAccusoft",
    }));

    setFeatures((prevFeatures) => {
      const existingFeaturesMap = new Map(prevFeatures.map((item) => [item.name, item]));
      const combinedFeatures = [...prevFeatures];

      updatedFeatures.forEach((newFeature) => {
        if (!existingFeaturesMap.has(newFeature.name)) {
          combinedFeatures.push(newFeature);
        }
      });

      return combinedFeatures;
    });
  }, [roomFeatures, roomFeaturesIcons]);

  const handleToggle = (featureName) => {
    const updatedFeatures = {
      ...roomFeatures,
      [featureName]: !roomFeatures[featureName],
    };

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        roomFeatures: updatedFeatures,
      },
    }));
  };

  const handleUpdateFeature = (feature) => {
    const updatedFeatures = features.map((e, i) =>
      i === feature.index ? { name: feature.name, icon: feature.icon } : e
    );

    setFeatures(updatedFeatures);

    const icons = {};
    const names = {};

    updatedFeatures.forEach((facility, index) => {
      icons[facility.name] = facility.icon;
      names[facility.name] = index === feature.index ? true : roomFeatures[facility.name];
    });

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        roomFeatures: names,
        roomFeaturesIcons: icons,
      },
    }));
  };

  const handleAddFeature = (newFeature) => {
    if (featureToEdit) {
      const updatedFeatures = features.map((feature) =>
        feature.name === featureToEdit.name ? newFeature : feature
      );
      setFeatures(updatedFeatures);
      setFeatureToEdit(null);
    } else {
      setFeatures((prevFeatures) => [...prevFeatures, newFeature]);
    }

    setFormData((prevData) => {
      // Ensure previous data and amenities are defined
      const amenities = prevData?.amenities || {};
      const roomFeatures = amenities?.roomFeatures || {};
      const roomFeaturesIcons = amenities?.roomFeaturesIcons || {};

      return {
        ...prevData,
        amenities: {
          ...amenities,
          roomFeaturesIcons: {
            ...roomFeaturesIcons,
            [newFeature.name]: newFeature.icon,
          },
          roomFeatures: {
            ...roomFeatures,
            [newFeature.name]: true,
          },
        },
      };
    });
  };


  const handleEditFeature = (feature) => {
    setFeatureToEdit(feature);
    setIsModalOpen(true);
  };

  const handleDeleteFeature = (featureName) => {
    const updatedFeatures = features.filter((feature) => feature.name !== featureName);
    setFeatures(updatedFeatures);

    const updatedRoomFeatures = { ...roomFeatures };
    delete updatedRoomFeatures[featureName];

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        roomFeatures: updatedRoomFeatures,
      },
    }));
  };

  const renderIcon = (iconName) => {
    const IconComponent = FaIcons[iconName];
    return IconComponent ? <IconComponent size={32} /> : null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="flex justify-between items-center w-full pb-5">
          <h2 className="text-2xl font-semibold text-neutralBlack mb-4">
            Room Features
          </h2>
          <button
            onClick={() => {
              setFeatureToEdit(null);
              setIsModalOpen(true);
            }}
            className="text-primary font-medium text-base underline"
          >
            Add Custom Feature
          </button>
        </div>
        {features &&
          features.map((feature, index) => {
            const isDefaultFeature = defaultFeatures.find(
              (defaultFeature) => defaultFeature?.name === feature?.name
            );
            return (
              <div
                key={index}
                className="flex py-1 items-center justify-between w-full lg:w-[60%] mb-4"
              >
                <div className="flex items-center w-[80%] justify-between">
                  <div className="flex items-center space-x-4">
                    {isDefaultFeature ? (
                      feature.icon
                    ) : (
                      <span className="text-2xl mr-2">
                        {renderIcon(feature.icon)}
                      </span>
                    )}
                    <span className="text-base text-darkGray">
                      {feature.name}
                    </span>
                  </div>
                  <ToggleSwitch
                    isOn={roomFeatures[feature.name] || false}
                    handleToggle={() => handleToggle(feature.name)}
                  />
                </div>
                {!isDefaultFeature && (
                  <div className="flex items-center gap-1">
                    <button
                      className="text-sm text-[#1252B1] hover:text-red-600"
                      onClick={() => handleEditFeature({ ...feature, index })}

                    >
                      <img src="/edit.svg" />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-600"
                      onClick={() => handleDeleteFeature(feature.name)}
                    >
                      <img src="/trash.svg" />
                    </button>
                  </div>
                )}

              </div>
            );
          })}
        {formData?.amenities?.errors && (
          <p className="text-red-500 text-base pt-1">{formData?.amenities?.errors.roomFeatures}</p>
        )}
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <AddFeatureModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onSave={handleAddFeature}
          featureToEdit={featureToEdit}
          onUpdate={handleUpdateFeature}
        />
      </Modal>
    </div>
  );
};

export default RoomFeatures;