import React, { useContext, useState, useEffect } from "react";
import { VenueContext } from "../../context/VenueContext";
import {
  validateCateringAndDrinks,
  validateSpaceRules,
  validateOffersAndPackages,
  validatePricing,
  validateBasicDetails,
  validateAdditionalDetails,
  validateFacilitiesStepper,
  validateUploadDocumentstepper,
} from "./validation";

const steps = [
  {
    title: "Overview",
    subSteps: [
      "Basic Details",
      "Additional Details",
      "Venue Type",
      "Event Type",
      "Location",
    ],
  },
  { title: "Capacity", subSteps: [] },
  { title: "Pricing", subSteps: [] },
  { title: "Offers & Packages", subSteps: [] },
  { title: "Catering & Drinks", subSteps: [] },
  { title: "Space Rules", subSteps: [] },
  { title: "Facilities & Equipment", subSteps: ["Facilities", "Equipment"] },
  { title: "Audio", subSteps: [] },
  { title: "Upload Documents", subSteps: [] },
  { title: "Photos & Videos", subSteps: [] },
  { title: "Preview", subSteps: [] },
];

const Stepper = ({
  currentStep,
  currentSubStep,
  setCurrentStep,
  setCurrentSubStep,
}) => {
  const { formData, setFormData } = useContext(VenueContext);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const validateStep = (stepTitle) => {
      let isInvalid = false;
      if (stepTitle === "Catering & Drinks") {
        isInvalid = validateCateringAndDrinks(formData.cateringAndDrinks);
        if (formData.cateringmissing?.isError !== isInvalid) {
          setFormData((prevData) => ({
            ...prevData,
            cateringmissing: { isError: isInvalid },
          }));
        }
      } else if (stepTitle === "Space Rules") {
        isInvalid = validateSpaceRules(formData.spaceRules);
        if (formData.spacerulesmissing?.isError !== isInvalid) {
          setFormData((prevData) => ({
            ...prevData,
            spacerulesmissing: { isError: isInvalid },
          }));
        }
      } else if (
        stepTitle === "Facilities & Equipment" &&
        currentSubStep === 0
      ) {
        isInvalid = validateFacilitiesStepper(formData.facilities);
        if (formData.facilities?.isError !== isInvalid) {
          setFormData((prevData) => ({
            ...prevData,
            facilitiesdatamissing: { isError: isInvalid },
          }));
        }
      } else if (stepTitle === "Upload Documents") {
        isInvalid = validateUploadDocumentstepper(formData.UploadDocuments);
        if (formData.UploadDocuments?.isError !== isInvalid) {
          setFormData((prevData) => ({
            ...prevData,
            uploadDocumentsdatamissing: { isError: isInvalid },
          }));
        }
      } else if (stepTitle === "Pricing") {
        isInvalid = validatePricing(formData.pricing);
        if (formData.pricingmissing?.isError !== isInvalid) {
          setFormData((prevData) => ({
            ...prevData,
            pricingmissing: { isError: isInvalid },
          }));
        }
      } else if (stepTitle === "Overview" && currentSubStep === 0) {
        // Check for 'Basic Details'
        isInvalid = validateBasicDetails(formData.basicDetails);
        if (formData.overviewbasicdetailsmissing?.isError !== isInvalid) {
          setFormData((prevData) => ({
            ...prevData,
            overviewbasicdetailsmissing: { isError: isInvalid },
          }));
        }
      } else if (stepTitle === "Additional Details") {
        isInvalid = validateAdditionalDetails(formData.additionalDetails);
        if (formData.additionaldetailsmissing?.isError !== isInvalid) {
          setFormData((prevData) => ({
            ...prevData,
            additionaldetailsmissing: { isError: isInvalid },
          }));
        }
      }

      return isInvalid;
    };

    const newErrors = {};
    for (let i = 0; i < currentStep; i++) {
      const stepTitle = steps[i].title;
      const isInvalid = validateStep(stepTitle);
      newErrors[stepTitle] = isInvalid;
    }

    setErrors(newErrors);
  }, [currentStep, currentSubStep, formData]); // Ensure dependencies are correct

  const getStepIndicatorClass = (stepIndex) => {
    const stepTitle = steps[stepIndex].title;
    if (stepIndex < currentStep) {
      return errors[stepTitle] ? "bg-[#FFF6E6]" : "bg-green1"; // Completed steps with or without errors
    }
    if (stepIndex === currentStep) {
      return "bg-transparent"; // Current step being filled
    }
    return "bg-transparent"; // Default state for future steps
  };

  const showTickMark = (stepIndex) => {
    const stepTitle = steps[stepIndex].title;
    return stepIndex < currentStep && !errors[stepTitle]; // Only show tick if completed and no errors
  };

  const showExclamationMark = (stepIndex) => {
    const stepTitle = steps[stepIndex].title;
    return stepIndex < currentStep && errors[stepTitle]; // Only show exclamation if completed and has errors
  };

  useEffect(() => {
    if (currentStep === 0) {
      setErrors({});
    }
  }, [currentStep]);
  return (
    <div className="w-full bg-white p-4">
      <ul className="relative space-y-3">
        {steps.map((step, index) => (
          <div key={index}>
            <li
              className={`flex items-center cursor-pointer relative mb-10 ${
                currentStep === index || Math.floor(currentStep) === index
                  ? "text-green1"
                  : "text-lightGray"
              }`}
              onClick={() => setCurrentStep(index)}
            >
              <div className="flex items-center">
                <span
                  className={`w-7 h-7 flex items-center justify-center rounded-full border-2 ${
                    currentStep === index || Math.floor(currentStep) === index
                      ? "border-green1"
                      : "border-lightGray"
                  } ${getStepIndicatorClass(index)}`}
                >
                  {/* Conditionally render tick mark or exclamation mark */}
                  {showTickMark(index) && (
                    <svg
                      className="w-4 h-4 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  )}
                  {showExclamationMark(index) && (
                    <svg
                      className="w-4 h-4 text-yellow-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M4.93 4.93l14.14 14.14M4.93 19.07l14.14-14.14"
                      ></path>
                    </svg>
                  )}
                  {!showTickMark(index) && !showExclamationMark(index) && (
                    <span
                      className={`w-2 h-2 rounded-full ${
                        currentStep === index ||
                        Math.floor(currentStep) === index
                          ? "bg-green1"
                          : "bg-lightGray"
                      }`}
                    ></span>
                  )}
                </span>
                {index !== steps.length - 1 && (
                  <span
                    className={`min-h-full absolute left-o top-8 min-w-[2px] ${
                      currentStep > index || Math.floor(currentStep) === index
                        ? "bg-green1"
                        : "bg-lightGray"
                    } ml-3`}
                  ></span>
                )}
              </div>
              <span
                className={`${
                  currentStep >= index ? "text-black" : "border-lightGray "
                } ml-3`}
              >
                {step.title}
              </span>
            </li>
            {step.subSteps.length > 0 && Math.floor(currentStep) === index && (
              <ul className="ml-8">
                {step.subSteps.map((subStep, subIndex) => (
                  <li
                    key={subIndex}
                    className={`flex relative items-center cursor-pointer mb-2 ${
                      currentSubStep === subIndex
                        ? "text-green1"
                        : "text-lightGray"
                    }`}
                    onClick={() => setCurrentSubStep(subIndex)}
                  >
                    {subIndex !== step.subSteps.length && (
                      <span
                        className={`min-h-6 rounded-md min-w-[2px] ${
                          currentStep > index + subIndex / 10
                            ? "bg-green1"
                            : "bg-lightGray"
                        } ml-3`}
                      ></span>
                    )}
                    <span
                      className={`ml-3 ${
                        currentStep > index + subIndex / 10
                          ? "text-black"
                          : "border-lightGray"
                      }`}
                    >
                      {subStep}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Stepper;
