import React from 'react'
import img1 from '../../assets/pics/handle1.svg'
import img2 from '../../assets/pics/handle2.svg'
import img3 from '../../assets/pics/handle3.svg'


const Effortless = () => {

    const data = [
        { icon: img1, name: 'Instant Registration', des: 'Create your account in under 15 mins' },
        { icon: img2, name: 'Intuitive Dashboard', des: 'Manage leads, track enquiries, add/update listings and gain detailed venue performance insights and analytics' },
        { icon: img3, name: 'Enhanced Features', des: 'Optimize listings with group accommodation suggestions and automated responses to clients.' },
    ]

    return (
        <div className='w-full flex justify-center pt-20' >
            <div className='w-[90%] flex flex-col items-center' >
                <h1 className='text-[#222222] sm:text-4xl text-3xl font-semibold my-10'>Get Verified Inquiries Promptly</h1>
                <div className="items-center grid md:grid-cols-3 sm:grid-cols-2 items-center first-letter: grid-cols-1 gap-6">
                    {
                        data.map((e, i) => (
                            <div className='border border-[#BDBDBD] bg-white p-3 flex flex-col items-center gap-4 rounded-md' >
                                <img src={e.icon} alt="" />
                                <h1 className='text-lg font-medium'>{e.name}</h1>
                                <p className='text-secondary text-center'>{e.des}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Effortless
