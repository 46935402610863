/* eslint-disable no-unused-vars */
export const validateBasicForm = (formData, setFormData) => {
	const basicDetails = formData.basicDetails || {};
	const errors = {};

	// Basic Details Validation
	if (!basicDetails?.venueName) {
		errors.venueName = 'Venue name is required';
	}

	if (!basicDetails?.hotelName) {
		errors.hotelName = 'Hotel name is required';
	}
	const text = basicDetails?.venueDescription;
	const words = text.trim().split(/\s+/); // Regular expression to handle multiple spaces
	const wordCount = words.length;
	console.log('WORD COUNT', wordCount);

	// Check if the word count is within the valid range

	if (!basicDetails?.venueDescription || wordCount < 100) {
		errors.venueDescription = 'Write atleast 100 words';
	}
	if (wordCount > 600) {
		errors.venueDescription = 'Maximum word count exceeded';
	}

	console.log(wordCount);
	// if (wordCount < 100 || wordCount > 600) {
	//   errors.venueDescription = "Venue description must be between 100 and 600 words";
	// }
	// Update the formData with validation errors
	setFormData((prevData) => ({
		...prevData,
		basicDetails: {
			...prevData.basicDetails,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validateAdditionForm = (formData, setFormData) => {
	const additionalDetails = formData?.additionalDetails || {};
	const errors = {};

	// Validate Venue Style
	const venueView = additionalDetails.venueView || {};
	const totalVenue = Object.values(venueView).flat().length;

	if (totalVenue < 1) {
		errors.venueView = 'You must select at least 1 venue View .';
	}

	const venueStyle = additionalDetails.venueStyle || {};
	const totalVenueStyles = Object.values(venueStyle).flat().length;

	if (totalVenueStyles < 1) {
		errors.venueStyle = 'You must select at least 1 venue styles.';
	}
	// venue setting
	const venueSetting = additionalDetails.venueSetting || {};
	const totalVenueSetting = Object.values(venueSetting).flat().length;

	if (totalVenueSetting < 1) {
		errors.venueSetting = 'You must select at least 1 venue setting.';
	}
	// Validate Venue Hire
	const venueHire = additionalDetails.venueHire || '';
	if (!venueHire) {
		errors.venueHire = 'You must select a venue hire option.';
	}

	// Update the form data with errors
	setFormData((prevData) => ({
		...prevData,
		additionalDetails: {
			...prevData.additionalDetails,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const valiVenuedateTypeForm = (formData, setFormData) => {
	const additionalDetails = formData?.venueType || {};
	const errors = {};

	// Validate Venue Style
	const venueType1 = additionalDetails.venueType || {};
	const totalVenue = Object.values(venueType1).flat().length;

	if (totalVenue < 1) {
		errors.venueType = 'You must select at least 1 venue Type .';
	}

	// Update the form data with errors
	setFormData((prevData) => ({
		...prevData,
		venueType: {
			...prevData.venueType,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validateLocationForm = (formData, setFormData) => {
	const locationDetails = formData?.location || {};
	const errors = {};

	if (!locationDetails.addressLine1) {
		errors.addressLine1 = 'Address Line 1 is required';
	}
	if (!locationDetails.city) {
		errors.city = 'City is required';
	}
	if (!locationDetails.area) {
		errors.area = 'Area is required';
	}
	if (!locationDetails.mapLink) {
		errors.mapLink = 'Map Link is required';
	}

	setFormData((prevData) => ({
		...prevData,
		location: {
			...prevData.location,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validateCapacityTypeForm = (formData, setFormData) => {
	const capacity = formData?.capacity || {};
	const errors = {};

	// Check if required fields are present
	if (!capacity.venueSize) {
		errors.venueSize = 'Venue Size is required';
	}
	if (!capacity.sitting) {
		errors.sitting = 'Sitting capacity is required';
	}
	if (!capacity.standing) {
		errors.standing = 'Standing capacity is required';
	}

	// Validate additional layout fields
	const additionalLayout = capacity.additionalLayout || {};
	Object.keys(additionalLayout).forEach((layoutType) => {
		const layout = additionalLayout[layoutType];
		if (layout.enabled && !layout.value) {
			errors[layoutType] = `${
				layoutType.charAt(0).toUpperCase() + layoutType.slice(1)
			} layout value is required`;
		}
	});

	setFormData((prevData) => ({
		...prevData,
		capacity: {
			...prevData.capacity,
			errors,
		},
	}));
	console.log('capacity errors', errors);
	return Object.keys(errors).length === 0;
};

export const validateAudioForm = (formData, setFormData) => {
	const audio = formData?.audio || {};
	const errors = {};

	// Validate indoor music allowed time only if indoor music is allowed
	if (audio.indoorMusicAllowed && !audio.indoorMusicAllowedTime) {
		errors.indoorMusicAllowedTime =
			'Indoor music allowed time is required.';
	}

	// Validate outdoor music allowed time only if outdoor music is allowed
	if (audio.outdoorMusicAllowed && !audio.outdoorMusicAllowedTime) {
		errors.outdoorMusicAllowedTime =
			'Outdoor music allowed time is required.';
	}

	// Update the form data with errors, ensuring errors are defined even if empty
	setFormData((prevData) => ({
		...prevData,
		audio: {
			...prevData.audio,
			errors: errors, // This ensures that errors are always defined
		},
	}));

	console.log(errors, 'audio errors');
	return Object.keys(errors).length === 0;
};

export const validateFacilitiesForm = (formData, setFormData) => {
	const facilities = formData?.facilities || {};
	const errors = {};

	// Validate activitiesForKidsIncludes if activitiesForKids is true
	if (
		facilities.activitiesForKids &&
		facilities.activitiesForKidsIncludes.length === 0
	) {
		errors.activitiesForKidsIncludes =
			'Please provide at least one activity for kids.';
	}

	// Validate that at least one value inside facilities.facilities is true
	const facilityValues = Object.values(facilities.facilities || {});
	const atLeastOneTrue = facilityValues.some(
		(value) => value === true
	);

	if (!atLeastOneTrue) {
		errors.facilities =
			'At least one facility option must be selected.';
	}

	// Update the form data with errors, ensuring errors are defined even if empty
	setFormData((prevData) => ({
		...prevData,
		facilities: {
			...prevData.facilities,
			errors: errors, // This ensures that errors are always defined
		},
	}));
	return Object.keys(errors).length === 0;
};

export const validateCateringForm = (formData, setFormData) => {
	const cateringAndDrinks = formData?.cateringAndDrinks || {};
	const errors = {};
	console.log(cateringAndDrinks, 'check it');
	// Check if the venue provides in-house catering
	const venueProvideInHouseCatering =
		cateringAndDrinks.venueProvideInHouseCatering;
	if (venueProvideInHouseCatering) {
		// Validate Venue Menu
		// const v1 = cateringAndDrinks?.availableMenu || {};
		// const availableMenu = Object.values(v1).flat().length;

		// if (availableMenu < 1) {
		//   errors.availableMenu = "You must select at least 1 available menu.";
		// }

		// Validate Cuisine
		const venueStyle = cateringAndDrinks.cuisine || {};
		const totalVenueStyles = Object.values(venueStyle).flat().length;

		if (totalVenueStyles < 1) {
			errors.cuisine = 'You must select at least 1 cuisine.';
		}
		// Validate Serving Style
		const venueStyle2 = cateringAndDrinks.servingStyle || {};
		const totalVenueStyles2 =
			Object.values(venueStyle2).flat().length;
		if (totalVenueStyles2 < 1) {
			errors.servingStyle =
				'You must select at least 1 serving style.';
		}
	}
	setFormData((prevData) => ({
		...prevData,
		cateringAndDrinks: {
			...prevData.cateringAndDrinks,
			errors,
		},
	}));
	return Object.keys(errors).length === 0;
};

export const validateSpaceTypeForm = (formData, setFormData) => {
	const spaceRules = formData?.spaceRules || {};
	const errors = {};

	// if (!spaceRules.agePolicy > 0) {
	//   errors.agePolicy = 'Age Policy   is required';
	// }
	// if (!spaceRules.sitting) {
	//   errors.sitting = 'sitting is required';
	// }

	setFormData((prevData) => ({
		...prevData,
		spaceRules: {
			...prevData.spaceRules,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validatUploadForm = (formData, setFormData) => {
	const UploadDocuments = formData.UploadDocuments || {};
	const errors = {};

	// Define documents with "required" status for the first two
	const documents = [
		{ name: 'floorPlans', optional: false, multiple: true },
		{ name: 'tradeLicense', optional: false, multiple: false },
		{ name: 'sampleMenus', optional: true, multiple: true },
		{ name: 'brochure', optional: true, multiple: true },
	];

	// Validate each selected document
	documents.forEach((doc) => {
		const documentFiles = UploadDocuments[doc.name] || [];
		// Check if required documents are uploaded
		if (!doc.optional && documentFiles.length === 0) {
			errors[doc.name] = `${doc.name} is required`;
		}
	});
	// Update the formData with validation errors
	setFormData((prevData) => ({
		...prevData,
		UploadDocuments: {
			...prevData.UploadDocuments,
			errors,
		},
	}));

	console.log({ errors });

	// Return true if no errors, otherwise false
	return Object.keys(errors).length === 0;
};

// export const validatUploadForm = (formData, setFormData) => {
//   const UploadDocuments = formData.UploadDocuments || {};
//   const selectedDocuments = formData.UploadDocuments?.selectedDocuments || [];
//   const errors = {};
//   console.log(selectedDocuments)
//   console.log(UploadDocuments)
//   // Validate each selected document
//   selectedDocuments.forEach((doc) => {
//     const documentFiles = UploadDocuments[doc] || [];

//     // Check if required documents are uploaded
//     if (documentFiles.length === 0) {
//       errors[doc] = `${doc} is required`;
//     }
//   });

//   // Update the formData with validation errors
//   setFormData((prevData) => ({
//     ...prevData,
//     UploadDocuments: {
//       ...prevData.UploadDocuments,
//       errors,
//     },
//   }));

//   return Object.keys(errors).length === 0;
// };

export const validatePhotoVideoForm = (formData, setFormData) => {
	const photoVideo = formData?.imagesAndVideos; // Default to empty arrays if undefined
	const errors = {};

	if (!photoVideo?.images.length) {
		errors.images = 'At least one image is required';
	}
	// if (!photoVideo.videos.length && !photoVideo.videoLinks.length) {
	//     errors.videos = 'At least one video or video link is required';
	// }

	setFormData((prevData) => ({
		...prevData,
		imagesAndVideos: {
			...prevData.imagesAndVideos,
			errors,
		},
	}));
	return Object.keys(errors).length === 0;
};

// Validation for indicating that this step is not fully completed

// export const validateCateringAndDrinks = (cateringAndDrinks) => {
//   const requiredFields = [
//     'availableMenu',
//     'cuisine',
//     'servingStyle',
//   ];

//   const errors = requiredFields.filter(field => {
//     if (Array.isArray(cateringAndDrinks[field])) {
//       return cateringAndDrinks[field].length === 0;
//     }
//     return !cateringAndDrinks[field];
//   });

//   return errors.length > 0;
// };
export const validateCateringAndDrinks = (cateringAndDrinks) => {
	const errors = [];

	// Check if the venue provides in-house catering
	const venueProvideInHouseCatering =
		cateringAndDrinks.venueProvideInHouseCatering;

	// Only validate 'cuisine' and 'servingStyle' if in-house catering is provided
	if (venueProvideInHouseCatering) {
		const requiredFields = ['cuisine', 'servingStyle'];

		requiredFields.forEach((field) => {
			if (Array.isArray(cateringAndDrinks[field])) {
				if (cateringAndDrinks[field].length === 0) {
					errors.push(field);
				}
			} else if (!cateringAndDrinks[field]) {
				errors.push(field);
			}
		});
	}

	return errors.length > 0;
};

export const validateSpaceRules = (spaceRules) => {
	const requiredFields = ['moreSpaceRules'];

	const errors = requiredFields.filter((field) => {
		if (Array.isArray(spaceRules[field])) {
			return spaceRules[field].length === 0;
		}
		// return !spaceRules[field];
		return false;
	});

	return errors.length > 0;
};
// offer and packages
export const validateOffersAndPackages = (offersAndPackages) => {
	const requiredFields = ['description'];
	// Check if offersAndPackages is undefined, null, or not an array
	if (!Array.isArray(offersAndPackages)) {
		return true; // Return true indicating errors if not an array
	}
	// Validate each offer/package item in the array
	const hasErrors = offersAndPackages.some((offerPackage) => {
		// If offerPackage is not an object or is null, it's invalid
		if (typeof offerPackage !== 'object' || offerPackage === null) {
			return true;
		}
		// Check if any required field is missing or empty
		return requiredFields.some((field) => {
			if (Array.isArray(offerPackage[field])) {
				return offerPackage[field].length === 0;
			}
			return !offerPackage[field];
		});
	});
	return hasErrors;
};

// validation.js
export const validatePricing = (pricingData) => {
	// Ensure there are selected options and at least one valid entry
	const hasValidOption = Object.keys(pricingData || {}).some(
		(option) => pricingData[option] && pricingData[option].length > 0
	);
	return !hasValidOption;
};

// export const validatePricing = (pricing) => {
//   const errors = {};

//   // Define the required fields for the pricing
//   const requiredFields = ['Minimum Spend Pricing', 'Venue Rental', 'Min spend per person'];

//   // Check if each required field has at least one entry
//   for (const field of requiredFields) {
//     const fieldData = pricing?.[field] || [];
//     if (fieldData.length === 0) {
//       errors[field] = `${field} is required`;
//     }
//   }

//   // Define the required fields for the formDetails
//   const requiredFormDetails = ['minSpend', 'currency', 'duration', 'from', 'to', 'selectedDays'];

//   // Check if each formDetails field is provided and not empty
//   for (const field of requiredFormDetails) {
//     const fieldValue = pricing?.[field];
//     if (fieldValue === undefined || fieldValue === null || fieldValue === "" || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
//       errors[field] = `${field} is required`;
//     }
//   }

//   // Return true if all required fields are valid
//   return true;
// };

export const validateBasicDetails = (basicDetails = {}) => {
	// Define the required fields
	const requiredFields = [
		'venueName',
		'hotelName',
		'venueDescription',
	];

	// Check if basicDetails is an object
	if (typeof basicDetails !== 'object' || basicDetails === null) {
		return true; // Return true indicating errors if not an object
	}

	// Check if any required field is empty
	const hasErrors = requiredFields.some((field) => {
		return !basicDetails[field] || basicDetails[field].trim() === '';
	});

	// Return true if there are errors (i.e., if any required field is empty)
	return hasErrors;
};
// additional details
export const validateAdditionalDetails = (additionalDetails) => {
	const { venueView, venueSetting, venueStyle, venueHire } =
		additionalDetails;

	// Check if venueStyle is valid
	const isVenueStyleValid = Object.values(venueStyle).every(
		(styleArray) => Array.isArray(styleArray) && styleArray.length > 0
	);

	// Check if all required fields have values
	const isValid =
		Array.isArray(venueView) &&
		venueView.length > 0 &&
		Array.isArray(venueSetting) &&
		venueSetting.length > 0 &&
		isVenueStyleValid &&
		venueHire !== '';

	return isValid;
};
export const validateFacilitiesStepper = (facilities = {}) => {
	const errors = {};
	// Validate activitiesForKidsIncludes if activitiesForKids is true
	if (
		facilities.activitiesForKids &&
		(!Array.isArray(facilities.activitiesForKidsIncludes) ||
			facilities.activitiesForKidsIncludes.length === 0)
	) {
		errors.activitiesForKidsIncludes =
			'Please provide at least one activity for kids.';
	}
	// Validate that at least one value inside facilities.facilities is true
	const facilityValues = Object.values(facilities.facilities || {});
	const atLeastOneTrue = facilityValues.some(
		(value) => value === true
	);
	if (!atLeastOneTrue) {
		errors.facilities =
			'At least one facility option must be selected.';
	}
	const hasErrors = Object.keys(errors).length > 0;
	console.log(errors);
	return hasErrors;
};
export const validateUploadDocumentstepper = (
	UploadDocuments = {}
) => {
	const errors = {};

	// Define documents with "required" status for the first two
	const documents = [
		{ name: 'floorPlans', optional: false, multiple: true },
		{ name: 'tradeLicense', optional: false, multiple: false },
		{ name: 'sampleMenus', optional: true, multiple: true },
		{ name: 'brochure', optional: true, multiple: true },
	];

	// Validate each selected document
	documents.forEach((doc) => {
		const documentFiles = UploadDocuments[doc.name] || [];
		// Check if required documents are uploaded
		if (!doc.optional && documentFiles.length === 0) {
			errors[doc.name] = `${doc.name} is required`;
		}
	});

	// Determine if there are any errors
	const hasErrors = Object.keys(errors).length > 0;

	// Optionally log errors for debugging
	console.log(errors);

	// Return hasErrors, indicating whether there are validation issues
	return hasErrors;
};
