import React, { useState, useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { BsUpload } from "react-icons/bs";
import AWS from "aws-sdk";
import { HotelContext } from "../../../context/HotelContext";
import { Link } from "react-router-dom";
import { PiFilePdfThin } from "react-icons/pi";
import { toast } from "react-toastify";

// Configure AWS SDK
const S3_BUCKET = "st-findmyvenue";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIATCKAPHDJJECWAMJF",
  secretAccessKey: "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",
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const FileUpload = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [file, setFile] = useState(
    formData?.overview?.additionalDetails?.tradeLicenseFile || null
  );
  const [fileType, setFileType] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(""); // For storing validation errors

  const handleFileUpload = (file, type) => {
    setUploading(true);

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${type}/${file.name}`,
    };

    myBucket
      .upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err.message);
          setUploading(false);
          setProgress(0);
          return;
        }
        console.log("File uploaded successfully:", data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          overview: {
            ...prevFormData.overview,
            additionalDetails: {
              ...prevFormData.overview.additionalDetails,
              tradeLicenseFile: data.Location, // Save the file URL in context
            },
          },
        }));
        // setFile(data.Location); // Update file URL after successful upload
        setUploading(false);
        setProgress(0); // Reset progress after upload
      })
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    console.log("selectedFile", selectedFile);
    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        // 2 MB
        toast.error("File size should not exceed 2 MB.");
        return;
      }
      if (!allowedTypes.includes(selectedFile?.type)) {
        toast.error("PNG, JPG and PDF files are allowed.");
        return;
      }
    //   toast.error(""); // Clear previous errors
      handleFileUpload(selectedFile, "tradeLicense");
      const fileUrl = URL.createObjectURL(selectedFile);
      setFile(fileUrl);
      setFileType(selectedFile?.type);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);

    // Update context
    setFormData((prevFormData) => ({
      ...prevFormData,
      overview: {
        ...prevFormData.overview,
        additionalDetails: {
          ...prevFormData.overview.additionalDetails,
          tradeLicenseFile: null, // Remove the file from context
        },
      },
    }));
  };

  return (
    <div className="w-full p-2 bg-white rounded-lg">
      <h2 className="text-2xl font-medium text-darkGray text-base mb-4">
        Trade Licence
      </h2>
      {formData.overview?.additionalDetails?.errors?.tradeLicenseFile && (
        <p className="text-red-500 pb-3">
          {formData.overview.additionalDetails.errors.tradeLicenseFile}
        </p>
      )}
      <div className="flex justify-between gap-5 items-start">
        <div className="w-full md:w-1/2">
          <p className="text-secondary text-sm mb-1">
            Upload Trade License Document
          </p>

          <div className="w-4.5/5 h-40 relative border-dashed border-2 border-gray-300 rounded-lg p-6 py-2 flex flex-col items-center justify-center">
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              className="opacity-0 absolute cursor-pointer w-full  h-full"
              id="file-upload"
              onChange={handleFileChange}
            />
            <label
              htmlFor="file-upload"
              className="cursor-pointer text-center text-lg text-red-500"
            >
              <BsUpload
                className="mx-auto mb-2 font-semibold text-gray-400"
                size={30}
              />
              <div className="flex flex-wrap justify-center gap-x-2 items-center w-full">
                <p className="mb-1 font-medium underline">Click to upload</p>
                <p className="text-offGray">or drag and drop</p>
              </div>
              <p className="text-secondary text-xs mt-2">
                PDF, PNG, JPG (Upto 2 MB)
              </p>
            </label>
          </div>
        </div>

        {file && <div className="h-[12rem] bg-lightGray w-[1px]"></div>}

        {file && (
          <div className="w-full md:w-2/5 overflow-hidden mt-6 md:mt-0  flex flex-col">
            <h3 className="text-secondary text-sm mb-2">Document Uploaded</h3>
            {/* <Link to={file} target="_blank" className="relative w-full "> */}
            {/* <img
                                src={file}
                                alt="Uploaded file"
                                className="w-full h-full object-cover rounded-lg"
                            /> */}

            {/* {file} */}
            {/* <div>
              <iframe
                src={file}
                title="Uploaded PDF"
                className="w-full h-60 overflow-hidden border rounded-lg"
              />
            </div>
            <button
              className="absolute top-0 right-0 mt-2 mr-2 text-white bg-red-500 rounded-full p-1"
              onClick={handleRemoveFile}
            >
              <FaTimes size={14} />
            </button> */}
            <div className="relative">
              <div className="w-[100px] h-[100px] border rounded-lg overflow-hidden flex bg-gray-100">
                {fileType === "application/pdf" ? (
                  //   <iframe
                  //     src={file}
                  //     title="Uploaded PDF"
                  //     className="w-full h-full"
                  //   />
                  <div className="relative w-full h-full flex items-center justify-center">
                    <PiFilePdfThin size={30} />
                    <button
                      className="absolute top-0 right-0 mt-2 mr-2 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center"
                      onClick={handleRemoveFile}
                    >
                      <img src="/close.svg" />
                    </button>
                  </div>
                ) : (
                  <div className="relative h-full w-full">
                    <img
                      src={file}
                      alt="Uploaded Image"
                      className="w-[100px] h-[100px] rounded-lg object-cover"
                    />
                    <button
                      className="absolute top-0 right-0 mt-2 mr-2 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center"
                      onClick={handleRemoveFile}
                    >
                      <img src="/close.svg" />
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* </Link> */}
          </div>
        )}
      </div>
      {/* {uploading && (
        <div className="mt-4 w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )} */}
    </div>
  );
};

export default FileUpload;
