import React, { useContext, useEffect, useState } from "react";
import SelectableTags from "../../shared/common/selectableTags";
import { BiPlus } from "react-icons/bi";
import PricingForm from "./form";
import { VenueContext } from "../../../context/VenueContext";
import { Select, MenuItem, FormControl, Checkbox, ListItemText, Chip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { validatePricing } from "../validation";  // Import the validation function

const PricingOptions = () => {
  const { formData, setFormData } = useContext(VenueContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const options = [
    "Minimum Spend Pricing",
    "Venue Rental",
    "Min spend per person"
  ];
  // console.log('dd', formData?.pricing?.selectedOptions)
  useEffect(() => {
    if (formData?.pricing?.selectedOptions && selectedOptions.length === 0) {
      setSelectedOptions(formData.pricing.selectedOptions);
    }

    const isInvalid = validatePricing(formData?.pricing);
    setErrors(prevErrors => ({
      ...prevErrors,
      pricing: isInvalid ? 'Please select at least one pricing option.' : ''
    }));
  }, [formData]);
  const toggleOption = (option) => {
    setSelectedOptions(prev => {
      const isSelected = prev.some(opt => opt.type === option);

      if (isSelected) {
        // If the option is already selected, remove it and its details
        const updatedForms = formData?.pricing?.[option] || [];
        updatedForms.forEach((form) => removeForm(form.id, option));
        return prev.filter((opt) => opt.type !== option);
      } else {
        // Add the new option and its details
        addForm(option);
        const newOption = {
          type: option,
          details: formData?.pricing?.[option]?.[0] || {} // Store details for the option
        };
        return [...prev, newOption];
      }
    });
  };



  const addForm = (type) => {
    const newForm = { id: Date.now(), type, selectedDays: [] };
    setFormData(prevData => {
      const updatedPricing = {
        ...prevData?.pricing,
        [type]: [...(prevData?.pricing?.[type] || []), newForm] // Add new form to the pricing details
      };
      const updatedSelectedOptions = selectedOptions.some(option => option.type === type)
        ? [...selectedOptions, { type, details: newForm }]
        : selectedOptions;

      return {
        ...prevData,
        pricing: updatedPricing,
        selectedOptions: updatedSelectedOptions
      };
    });
  };
  // console.log('sel', selectedOptions)
  // Update removeForm function to handle removing form data when the option is deselected
  const removeForm = (id, type) => {
    setFormData(prevData => {
      const updatedForms = (prevData?.pricing?.[type] || []).filter((form) => form.id !== id);
      const updatedPricing = { ...prevData?.pricing, [type]: updatedForms };

      return {
        ...prevData,
        pricing: updatedPricing
      };
    });
  };

  // Adjust handleSelectChange to update selectedOptions with all selected option details
  const handleSelectChange = (event) => {
    const value = event.target.value;

    // Update selected options with corresponding details
    const selectedOptionsWithDetails = value.flatMap(option => {
      const forms = formData?.pricing?.[option] || [];
      return forms.map(form => ({
        type: option,
        details: form
      }));
    });

    setSelectedOptions(selectedOptionsWithDetails);

    setFormData(prevData => {
      const updatedPricing = {
        ...prevData?.pricing,
        selectedOptions: selectedOptionsWithDetails // Store all selected option details
      };

      const isInvalid = validatePricing(updatedPricing);
      setErrors(prevErrors => ({
        ...prevErrors,
        pricing: isInvalid ? 'Please select at least one pricing option.' : ''
      }));

      return {
        ...prevData,
        pricing: updatedPricing
      };
    });
  };

  // Adjust handleDelete to remove only the selected option details when a chip is deleted
  const handleDelete = (itemToDelete) => {
    setSelectedOptions(prev => {
      const updatedSelectedOptions = prev.filter(item => item.details.id !== itemToDelete.details.id);

      setFormData(prevData => {
        const updatedPricing = {
          ...prevData?.pricing,
          selectedOptions: updatedSelectedOptions // Update the formData with the remaining selected options
        };

        const isInvalid = validatePricing(updatedPricing);
        setErrors(prevErrors => ({
          ...prevErrors,
          pricing: isInvalid ? 'Please select at least one pricing option.' : ''
        }));

        return {
          ...prevData,
          pricing: updatedPricing
        };
      });

      return updatedSelectedOptions;
    });
  };

  return (
    <div className="p-4 w-full mx-auto">
    <h1 className="text-2xl font-bold mb-4">Pricing</h1>
    <p className="mb-4 text-gray-500">
      Select the pricing structure that best fits your venue. You can choose one or multiple options.
    </p>
    {errors.pricing && <p className="text-red-500 mb-4">{errors.pricing}</p>}
    <div className="mb-6"> 
      {options.map((option) => (
        <div key={option}>
          {(formData?.pricing?.[option] || []).map((form) => (
            <div key={form.id} className="mb-2"> 
              <PricingForm
                id={form.id}
                type={form.type}
                removeForm={() => removeForm(form.id, form.type)}
              />
            </div>
          ))}
          {selectedOptions.some(sel => sel.type === option) && (
            <button
              className="text-green1 font-medium flex space-x-3 items-center mt-1 mb-8" 
              onClick={() => addForm(option)}
            >
              <BiPlus /> Add more to {option}
            </button>
          )}
        </div>
      ))}
    </div>
    <div className="flex flex-wrap gap-4 mt-6"> 
      {options?.map((option) => (
        <SelectableTags
          key={option}
          tags={[option]}
          selectedTags={selectedOptions.map(sel => sel.type)}
          onTagClick={() => toggleOption(option)}
        />
      ))}
    </div>
    <div className="mb-4">
      <FormControl fullWidth>
        <label className="text-[14px] font-normal mb-2">
          Select two pricing options to display on listing <span className="text-[#B0B0B0]">(optional)</span>
        </label>
        <Select
          labelId="pricing-select-label"
          multiple
          value={selectedOptions.map(opt => opt.type)}
          onChange={handleSelectChange}
          renderValue={(selected) => (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
              {selected.slice(0, 2).map((value) => {
                const itemArray = formData?.pricing?.[value] || [];
                return itemArray.map(item => (
                  <Chip
                    key={item.id}
                    label={`${value} (${item.minSpend || "N/A"})`}
                    onDelete={() => handleDelete({ type: value, details: item })}
                    deleteIcon={<Close className="text-[#0E4942]" style={{ color: '#0E4942' }} />}
                    style={{ backgroundColor: '#EAF9F7', color: '#0E4942' }}
                  />
                ));
              })}
            </div>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
          }}
        >
          {Object.keys(formData?.pricing || {}).map(option => (
            formData.pricing[option].map(item => (
              item.minSpend &&
              <MenuItem key={item.id} value={option}>
                <Checkbox checked={selectedOptions.some(sel => sel.details.id === item.id)} />
                <ListItemText primary={`${option} (${item.minSpend || "N/A"})`} />
              </MenuItem>
            ))
          ))}
        </Select>
  
      </FormControl>
    </div>
  </div>
  
  );
};

export default PricingOptions;
