import React, { useState } from 'react';
import {
	FaHeart,
	FaEye,
	FaInfoCircle,
	FaRegHeart,
} from 'react-icons/fa';
import { ReactComponent as BedSVG } from '../../assets/icons/bed_hotel_listing.svg';
import { ReactComponent as HouseSVG } from '../../assets/icons/house_hotel_listing.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';

const HotelCardPreview = ({ hotels }) => {
	// Create a reusable card item component for individual hotel cards
	const CardItem = ({ hotel }) => {
		const [isLiked, setIsLiked] = useState(false);
		// console.log('photoVideo', hotel)

		const CustomNextArrow = ({ onClick }) => (
			<div
				className='absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50'
				onClick={onClick}
			>
				<SlArrowRight className='text-[#000]' />
			</div>
		);

		const CustomPrevArrow = ({ onClick }) => (
			<div
				className='absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50'
				onClick={onClick}
			>
				<SlArrowLeft className='text-[#000]' />
			</div>
		);

		const imageSettings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: false,
			nextArrow: <CustomNextArrow />,
			prevArrow: <CustomPrevArrow />,
		};

		const handleLikeClick = () => {
			setIsLiked(!isLiked);
		};

		const CustomSkeleton = () => (
			<div className='w-full h-[300px] lg:w-full rounded-lg bg-gray-200 animate-pulse'></div>
		);

		return (
			<div className='bg-white rounded-lg w-full max-w-md mb-6'>
				<div className='rounded-lg overflow-hidden shadow-lg mb-4 lg:w-full '>
					<div className='relative'>
						<Slider {...imageSettings}>
							{hotel?.photoVideo?.images.length > 0 ? (
								hotel.photoVideo?.images.map((image, imageIndex) => (
									<div key={imageIndex} className=''>
										<img
											src={image}
											alt=''
											className='w-full h-[300px] rounded-t-lg object-cover'
										/>
									</div>
								))
							) : (
								<CustomSkeleton />
							)}
						</Slider>
						<button
							className={`absolute top-2 right-2 ${
								isLiked ? 'text-red-500' : 'text-red-500'
							} bg-white rounded-full p-1 shadow`}
							onClick={handleLikeClick}
						>
							{isLiked ? (
								<FaHeart size={20} />
							) : (
								<FaRegHeart size={20} />
							)}
						</button>
					</div>
					<div className='p-4'>
						<h3 className='text-xl font-semibold'>
							{hotel.overview?.basicDetails.hotelName ||
								'Hotel Name Not Available'}
						</h3>
						<p className='text-gray-600'>
							{hotel.overview?.locationDetails?.city +
								', ' +
								hotel.overview?.locationDetails?.area ||
								'Location Not Available'}
						</p>
						<div className='flex items-center text-gray-600 my-2'>
							<BedSVG className='mr-1 text-red-500' />
							<span className='mr-3'>
								{hotel?.accommodation?.accommodations || '0 Rooms'}
							</span>
							<HouseSVG className='mr-1 text-red-500' />
							<span>
								{hotel?.accommodation?.venues || '0 Venues'}
							</span>
						</div>
						<p className='text-red-500 font-semibold'>
							AED {hotel?.pricing?.basePrice || '0 AED'}
						</p>
						<p className='text-sm text-gray-500'>
							Starting price per room per night
						</p>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-5 gap-6'>
			{hotels &&
				hotels.map((hotel, index) => (
					<CardItem key={index} hotel={hotel} />
				))}
		</div>
	);
};

export default HotelCardPreview;
