/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import {
	FaHeart,
	FaEye,
	FaBed,
	FaHotel,
	FaInfoCircle,
	FaRegHeart,
} from 'react-icons/fa';
import { ReactComponent as BedSVG } from '../../assets/icons/bed_hotel_listing.svg';
import { ReactComponent as HouseSVG } from '../../assets/icons/house_hotel_listing.svg';
import preview from '../../assets/pics/previe.svg';
import { Link } from 'react-router-dom';
import { HotelContext } from '../../context/HotelContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

const CardPreview = () => {
	const { formData } = useContext(HotelContext);
	const [isLiked, setIsLiked] = useState(false);
	// Fallback image in case no image is provided
	const imageSrc =
		formData.photoVideo.images.length > 0
			? formData.photoVideo.images
			: '';
	// Destructure formData to simplify access
	const basicDetails = formData.overview?.basicDetails;
	const hotelName =
		basicDetails?.hotelName || 'Hotel Name Not Available';
	const hotelDescription =
		basicDetails?.hotelDescription || 'Description Not Available';
	const location =
		formData.overview?.locationDetails?.city + ', ' + formData.overview?.locationDetails?.area ||
		'Location Not Available';
	const rooms = formData.accommodation?.accommodations || '0 Rooms';
	const venues = formData.accommodation?.venues || '0 Venues';
	const price = formData.pricing?.basePrice || '0 AED';
	const navigate = useNavigate();
	const handlePreviewClick = () => {
		let errorMessage = '';
		let hasErrors = false;
		// Check for errors in each step
		if (formData.errors.overview) {
			errorMessage += 'Data is missing in Overview.\n';
			hasErrors = true;
		}
		if (formData.errors.accommodation) {
			errorMessage += 'Data is missing in Accommodation.\n';
			hasErrors = true;
		}
		if (formData.errors.pricing) {
			errorMessage += 'Data is missing in Pricing.\n';
			hasErrors = true;
		}
		if (formData.errors.photoVideo) {
			errorMessage += 'Data is missing in Photos & Videos.\n';
			hasErrors = true;
		}
		if (hasErrors) {
			toast.error(errorMessage.trim());
		}
		// localStorage.setItem('hotelData', JSON.stringify(formData));
		navigate('/hotel-preview');
	};

	const handleLikeClick = () => {
		setIsLiked(!isLiked);
	};

	const CustomNextArrow = ({ onClick }) => (
		<div
			className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50"
			onClick={onClick}
		>
			<SlArrowRight className="text-[#000]" />
		</div>
	);

	const CustomPrevArrow = ({ onClick }) => (
		<div
			className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50"
			onClick={onClick}
		>
			<SlArrowLeft className="text-[#000]" />
		</div>
	);

	const imageSettings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
	};


	const CustomSkeleton = () => (
		<div className="w-full h-[300px] lg:w-[300px] rounded-lg bg-gray-200 animate-pulse"></div>
	);
	return (
		<div className='bg-white rounded-lg w-full max-w-md '>
			<h2 className='text-3xl font-bold mb-6'>Card Preview</h2>
			<div className='rounded-lg overflow-hidden shadow-lg mb-4 lg:w-[300px]'>
				<div className='relative'>
					{/* // src={imageSrc} */}

					<Slider {...imageSettings}>
						{formData.photoVideo.images.length > 0 ? (
							formData.photoVideo.images.map((e, imageIndex) => (
								<div key={imageIndex} className="">
									<img src={e} alt="" className="w-full h-[300px]  rounded-t-lg object-cover" />
								</div>
							))
						) : (
							<CustomSkeleton />
						)}
					</Slider>
					{/* <img
						src={
							imageSrc != ''
								? imageSrc
								: 'https://i.imgur.com/1U40OeO.png'
						}
						alt={hotelName}
						className='w-full'
					/> */}

					{/* className="w-full h-60 object-cover" */}
					<button
						className={`absolute top-2 right-2 ${isLiked ? 'text-red-500' : 'text-red-500'
							} bg-white rounded-full p-1 shadow`}
						onClick={handleLikeClick}
					>
						{isLiked ? (
							<FaHeart size={20} />
						) : (
							<FaRegHeart size={20} />
						)}
					</button>
				</div>
				<div className='p-4'>
					<h3 className='text-xl font-semibold'>{hotelName}</h3>
					<p className='text-gray-600'>{location}</p>
					<div className='flex items-center text-gray-600 my-2'>
						<BedSVG className='mr-1 text-red-500' />
						<span className='mr-3'>{rooms}</span>
						<HouseSVG className='mr-1 text-red-500' />
						<span>{venues}</span>
					</div>
					<p className='text-red-500 font-semibold'>AED {price}</p>
					<p className='text-sm text-gray-500'>
						Starting price per room per night
					</p>
				</div>
			</div>
			{/* <div className='text-sm text-gray-500 flex items-center mb-4'>
				<FaInfoCircle className='mr-2 ' />
				Your venues currently show as zero. Once you add venues, this
				will be updated.
			</div> */}
			{/* <button
				onClick={handlePreviewClick}
				className='bg-primary text-white px-4 py-2 rounded-lg justify-center flex items-center'
			>
				<FaEye className='mr-2 ' />
				Preview detail page
			</button> */}

			{/* <Link
                onClick={handlePreviewClick}
                to=""
                className="bg-black justify-center lg:w-1/3 text-white px-4 py-2 rounded-lg flex items-center">
                <FaEye className="mr-2" />
                Preview detail page
            </Link> */}
		</div>
	);
};

export default CardPreview;
