import React, { useContext } from "react";
import { VenueContext } from "../../../context/VenueContext"; // Import your context
import { categories } from "../venu-data"; // Import the categories data
import SelectableTags from "../../shared/common/selectableTags"; // Assuming you have this component
import CustomCheckbox from "../../shared/common/checkBox"; // Assuming you have this component
import ToggleSwitch from "../../shared/common/custom-toggle"; // Assuming you have this component

const venueKid = [
  "Play date",
  "Birthdays",
  "Teen birthdays",
  "Toddler Birthdays",
  "Halloween Birthdays",
  "Christmas Party",
  "Eid Party",
  "Book launch",
];

const EventTypeForm = () => {
  const { formData, setFormData } = useContext(VenueContext);

  // Initialize state from context
  const selectedOptions = formData.eventType || {};
  const kidVenue = formData.eventVenuesForKids || false;

  // Update context with new selected options
  const updateSelectedOptions = (subcategoryName, options) => {
    setFormData((prevData) => ({
      ...prevData,
      eventType: {
        ...prevData.eventType,
        [subcategoryName]: options,
      },
    }));
  };

  // Toggle selected options
  const handleSelectAll = (subcategoryName) => {
    const subcategory = categories
      .flatMap((cat) => cat.subcategories)
      .find((sub) => sub.name === subcategoryName);

    if (subcategory) {
      const updatedOptions =
        selectedOptions[subcategoryName]?.length === subcategory.options.length
          ? []
          : subcategory.options;

      updateSelectedOptions(subcategoryName, updatedOptions);
    }
  };

  // Handle option change
  const handleOptionChange = (subcategoryName, option) => {
    const currentOptions = selectedOptions[subcategoryName] || [];
    const updatedOptions = currentOptions.includes(option)
      ? currentOptions.filter((opt) => opt !== option)
      : [...currentOptions, option];

    updateSelectedOptions(subcategoryName, updatedOptions);
  };

  // Check if all options are selected
  const isAllSelected = (subcategoryName) => {
    const subcategory = categories
      .flatMap((cat) => cat.subcategories)
      .find((sub) => sub.name === subcategoryName);

    return subcategory
      ? selectedOptions[subcategoryName]?.length === subcategory.options.length
      : false;
  };

  // Handle toggle for kid-friendly venues
  const handleKidVenueToggle = () => {
    setFormData((prevData) => ({
      ...prevData,
      eventVenuesForKids: !kidVenue,
      // Clear the list if toggling off, otherwise keep the existing list
      eventVenuesForKidsIncludes: !kidVenue
        ? []
        : prevData.eventVenuesForKidsIncludes,
    }));
  };

  // Handle selected kid venues change
  const handleKidVenueOptionChange = (option) => {
    const currentOptions = formData.eventVenuesForKidsIncludes || [];
    const updatedOptions = currentOptions.includes(option)
      ? currentOptions.filter((opt) => opt !== option)
      : [...currentOptions, option];

    setFormData((prevData) => ({
      ...prevData,
      eventVenuesForKidsIncludes: updatedOptions,
    }));
  };

  return (
    <div className="p-2">
      <h1 className="text-3xl lg:text-2xl text-darkGray font-semibold mb-4">
        Event Type
      </h1>

      {categories.map((category) => (
        <div key={category.name} className="mb-6">
          <h2 className="text-base font-medium text-darkGray mb-2">
            {category.name}
          </h2>
          {category.subcategories.map((subcategory) => (
            <div key={subcategory.name} className="mb-4">
              <div className="flex items-center justify-between w-full mb-2">
                <h3 className="text-sm text-secondary font-normal mr-2">
                  {subcategory.name}
                </h3>
                <CustomCheckbox
                  label="Select all"
                  checked={isAllSelected(subcategory.name)}
                  onChange={() => handleSelectAll(subcategory.name)}
                />
              </div>
              <div className="flex flex-wrap">
                <SelectableTags
                  tags={subcategory.options}
                  selectedTags={selectedOptions[subcategory.name] || []}
                  onTagClick={(option) =>
                    handleOptionChange(subcategory.name, option)
                  }
                />
              </div>
            </div>
          ))}
        </div>
      ))}
      <div className="mb-4">
        <label className="flex items-center">
          <span className="text-darkGray font-normal mr-2">
            Do you have event venues for kids
          </span>
          <ToggleSwitch isOn={kidVenue} handleToggle={handleKidVenueToggle} />
        </label>
      </div>
      {kidVenue && (
        <div className="flex flex-wrap">
          {venueKid.map((option) => (
            <SelectableTags
              max={5}
              key={option}
              tags={[option]}
              selectedTags={formData.eventVenuesForKidsIncludes || []}
              onTagClick={() => handleKidVenueOptionChange(option)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default EventTypeForm;
