/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import icon from "../../assets/icon2.svg";
import { FaSave } from "react-icons/fa";
import { FaEye, } from "react-icons/fa";
import { MdSync } from "react-icons/md";
import Navbar from "../../components/Navbar";
import Stepper from "../../components/venu-listing-form/stepper";
import BasicForm from "../../components/venu-listing-form/overview/basic-form";
import AdditionalDetailsForm from "../../components/venu-listing-form/overview/additionl-details";
import VenueType from "../../components/venu-listing-form/overview/venu-type";
import EventTypeForm from "../../components/venu-listing-form/overview/event-type";
import LocationVenueForm from "../../components/venu-listing-form/overview/location";
import CapacityForm from "../../components/venu-listing-form/capacity-form";
import PricingOptions from "../../components/venu-listing-form/pricing";
import OfferForm from "../../components/venu-listing-form/offer-package/form";
import CateringAndDrinks from "../../components/venu-listing-form/catering-drinks-form";
import SpaceRules from "../../components/venu-listing-form/space-rules";
import FacilitiesForm from "../../components/venu-listing-form/facilities-equipments/facilities";
import EquipmentForm from "../../components/venu-listing-form/facilities-equipments/equipments-form";
import AudioForm from "../../components/venu-listing-form/audio-from";
import UploadDocuments from "../../components/venu-listing-form/upload-document";
import UploadPhotosVideos from "../../components/venu-listing-form/photo-video-form";
import CardPreview from "../../components/venu-listing-form/card-preview";
import { VenueContext } from "../../context/VenueContext";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    validateAdditionForm,
    validateBasicForm,
    validateCapacityTypeForm,
    validateCateringForm,
    validateLocationForm,
    validatePhotoVideoForm,
    validateSpaceTypeForm,
    validatUploadForm,
    valiVenuedateTypeForm,
} from "../../components/venu-listing-form/validation";
import { getSingleVenue, getVenueData, saveVenueData } from "../../api/venue-api";
import { useQuery } from "@tanstack/react-query";
import { getResData, saveResData } from "../../api/restaurant-api";
import { tipsVenue } from "../../constants/tips";
import { fetchLoggedUser } from "../../api/loggedUser";

const EditResListingForm = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [currentSubStep, setCurrentSubStep] = useState(0);
    const { formData, setFormData, resetForm } = useContext(VenueContext);
    const navigate = useNavigate();
    const vendorId = localStorage?.getItem("vendorId");
    const [isAutoSaveEnabled, setIsAutoSaveEnabled] = useState(true);
    const { category, id } = useParams();

    const { data: user } = useQuery({
        queryKey: ["loggedin-user"],
        queryFn: async () => {
            const response = await fetchLoggedUser();
            return response?.vendor;
        },
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!category || !id) return;

            try {
                const response = await getSingleVenue({ category, id });
                resetForm()
                setFormData(response);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error("Error fetching venue data.");
            }
        };

        fetchData();
    }, [category, id, setFormData]);

    console.log('d----', formData)
    const handleSaveLater = async (status = "pending") => {
        try {
            const updatedData = {
                ...formData,
                vendorId,
                status,
            };
            const response = await saveResData(updatedData);
            if (response.success) {
                toast.success(``);
                navigate("/dashboard?restaurantPending=true");
            }
        } catch (error) {
            toast.error(
                error.response?.data?.error || "An error occurred. Please try again."
            );
            console.error("Error:", error);
        }
    };

    const handleSaveData = async (status = "pending") => {
        try {
            const updatedData = {
                ...formData,
                vendorId,
                status,
            };
            const response = await saveResData(updatedData);
            if (response.success) {
                // toast.success(`Restaurant ${status === 'completed' ? 'created' : 'saved'} successfully!`);
                if (status === "completed") {
                    toast.success("Created!");
                    setFormData("");
                    setTimeout(() => {
                        navigate("/dashboard?restaurantRegister=true");
                    }, 1000);
                }
            } else {
                toast.error(response.error || "An error occurred. Please try again.");
            }
        } catch (error) {
            toast.error(
                error.response?.data?.error || "An error occurred. Please try again."
            );
            console.error("Error:", error);
        }
    };

    const handleSubmit = async () => {
        await handleSaveData("completed");
    };

    const handleSaveForLater = async () => {
        await handleSaveLater("pending");
    };

    const subSteps = [
        [
            "BasicForm",
            "AdditionalDetailsForm",
            "VenueType",
            "EventTypeForm",
            "LocationForm",
        ], // Sub-steps for step 0
        [], // No sub-steps for step 1
        [], // No sub-steps for step 2
        [], // No sub-steps for step 3
        [], // No sub-steps for step 4
        [], // No sub-steps for step 5
        ["Facilities", "Equipment"], // Sub-steps for step 6
        [], // No sub-steps for step 7
        [], // No sub-steps for step 8
    ];

    const renderForm = () => {
        if (currentStep === 0) {
            switch (currentSubStep) {
                case 0:
                    return <BasicForm />;
                case 1:
                    return <AdditionalDetailsForm />;
                case 2:
                    return <VenueType />;
                case 3:
                    return <EventTypeForm />;
                case 4:
                    return <LocationVenueForm />;
                default:
                    return <BasicForm />;
            }
        }
        if (currentStep === 6) {
            switch (currentSubStep) {
                case 0:
                    return <FacilitiesForm />;
                case 1:
                    return <EquipmentForm />;
                default:
                    return <FacilitiesForm />;
            }
        }
        switch (currentStep) {
            case 1:
                return <CapacityForm />;
            case 2:
                return <PricingOptions />;
            case 3:
                return <OfferForm />;
            case 4:
                return <CateringAndDrinks />;
            case 5:
                return <SpaceRules />;
            case 7:
                return <AudioForm />;
            case 8:
                return <UploadDocuments />;
            case 9:
                return <UploadPhotosVideos />;
            case 10:
                return <CardPreview />;
            default:
                return <BasicForm />;
        }
    };

    const handleContinue = async () => {
        setFormData((prevData) => ({
            ...prevData,
            vendorId: vendorId,
        }));

        const lastStep = 10; // Assuming the last step is step 10
        const currentSubStepCount = subSteps[currentStep]?.length;

        if (
            currentStep === 0 &&
            currentSubStep === 0 &&
            !validateBasicForm(formData, setFormData)
        ) {
            return;
        }
        if (
            currentStep === 0 &&
            currentSubStep === 1 &&
            !validateAdditionForm(formData, setFormData)
        ) {
            return;
        }
        if (
            currentStep === 0 &&
            currentSubStep === 2 &&
            !valiVenuedateTypeForm(formData, setFormData)
        ) {
            return;
        }
        if (
            user?.onBoardingDetails?.type !== "HotelStayandVenue" &&
            currentStep === 0 &&
            currentSubStep === 4 &&
            !validateLocationForm(formData, setFormData)
        ) {
            return;
        }
        if (currentStep === 1 && !validateCapacityTypeForm(formData, setFormData)) {
            return;
        }
        if (currentStep === 4 && !validateCateringForm(formData, setFormData)) {
            return;
        }
        if (currentStep === 8 && !validatUploadForm(formData, setFormData)) {
            return;
        }

        if (currentStep === lastStep) {
            await handleSubmit(); // Submit data when on the last step
        } else {
            if (isAutoSaveEnabled) {
                await handleSaveData("pending");
            }
            if (currentSubStep < currentSubStepCount - 1) {
                setCurrentSubStep(currentSubStep + 1);
            } else {
                setCurrentStep(currentStep + 1);
                setCurrentSubStep(0);
            }
        }
    };
    // if (isLoading) {
    //   return <div>Loading...</div>;
    // }

    // if (error) {
    //   return <div>Error fetching venue data: {error.message}</div>;
    // }
    const renderTip = () => {
        if (currentStep === 0 || currentStep === 6) {
            return tipsVenue[currentStep][currentSubStep] || ""; // Return empty string if no tip
        }
        return tipsVenue[currentStep] || ""; // Return empty string if no tip
    };

    const path = useLocation();


    const handlePreviewClick = () => {
        let errorMessage = "";
        let hasErrors = false;

        // Use optional chaining to safely access properties
        if (formData?.overviewbasicdetailsmissing?.isError) {
            errorMessage += "Data is missing in overview basic details.\n";
            hasErrors = true;
        }

        if (formData?.cateringmissing?.isError) {
            errorMessage += "Data is missing in  Catering & Drinking.\n";
            hasErrors = true;
        }

        if (formData?.spacerulesmissing?.isError) {
            errorMessage += "Data is missing in Space Rules.\n";
            hasErrors = true;
        }

        if (formData?.pricingmissing?.isError) {
            errorMessage += "Data is missing in Pricing.\n";
            hasErrors = true;
        }
        // Show appropriate toast notification based on the presence of errors
        if (hasErrors) {
            toast.error(errorMessage.trim());
        }
        // Set form data to localStorage if no errors are found
        //   localStorage.setItem('hotelData', JSON.stringify(formData));
        // navigate('/venue-preview');
        if (path.pathname?.includes("venu-Listing-Form")) {
            if (hasErrors) {
                localStorage.setItem("Errors", errorMessage);
                navigate("/venue-preview");
            } else {
                localStorage.removeItem("Errors");
                navigate("/venue-preview");
            }
        } else {
            navigate("/restaurant-preview");
        }
    };

    const currentTip = renderTip();
    return (
        <>
            <Navbar />
            <div className="flex px-5 lg:px-10 py-5 min-h-[90vh] bg-[#F3F3F3] w-full">
                <div className="flex justify-between flex-wrap gap-5 w-full">
                    <div className="flex justify-start flex-wrap rounded-2xl bg-white lg:w-[78%] w-full">
                        <div className="lg:w-1/4 w-full border-r border-lightGray">
                            <Stepper
                                currentStep={currentStep}
                                currentSubStep={currentSubStep}
                                setCurrentStep={setCurrentStep}
                                setCurrentSubStep={setCurrentSubStep}
                            />
                        </div>
                        <div className="lg:w-3/4 w-full px-5 py-5">{renderForm()}</div>
                        <div className="w-full bg-[#F3F3F3] mt-5 p-2 flex justify-between items-center">
                            <button
                                className="flex items-center font-medium text-mediumGray hover:text-mediumGray"
                                onClick={handleSaveForLater}
                            >
                                <FaSave className="mr-2" />
                                Save for later
                            </button>
                            <div className="flex gap-5 items-center">
                                <button
                                    onClick={() => setIsAutoSaveEnabled(!isAutoSaveEnabled)}
                                    className={`flex space-x-2 text-base font-outfitt font-medium items-center text-mediumGray ${isAutoSaveEnabled
                                        ? "text-green-500 animate-bounce"
                                        : "text-mediumGray"
                                        }`}
                                >
                                    <span className="text-sm font-normal">
                                        Auto Save is {isAutoSaveEnabled ? "on" : "off"}
                                    </span>
                                    <MdSync className="mr-2 text-mediumGray" size={15.5} />
                                </button>
                                {
                                    currentStep < 10 &&
                                    <button
                                        className="bg-primary text-white px-10 py-2 lg:w-48 rounded-lg hover:bg-red-600"
                                        onClick={handleContinue}
                                    >
                                        Continue
                                    </button>
                                }
                                {
                                    currentStep === 10 &&
                                    <button
                                        onClick={handlePreviewClick}
                                        className="bg-black text-white px-4 py-2
                 rounded-lg justify-center flex items-center"
                                    >
                                        <FaEye className="mr-2" />
                                        Preview detail page
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/5 px-2 bg-[#F3F3F3] w-full">
                        {currentTip && (
                            <div className="flex flex-col bg-[#F7F7F7] border border-[#EBEBEB] rounded-lg p-4 items-start shadow-md">
                                <img src={icon} alt="" />
                                <div>
                                    <h3 className="text-lg font-semibold text-black">Tips</h3>
                                    <p className="text-secondary">{currentTip}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditResListingForm;
