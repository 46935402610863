import React from 'react'
import imgBanner from '../../assets/pics/fmvImage.png'


const Enquiries = () => {

    const data = [
        { head: 'Direct Client Connection', des: 'Access direct contact information to connect, negotiate and confirm bookings with venue seekers and event planners to maximize your conversions.' },
        { head: 'Multi-venue and Comprehensive Listings', des: 'List multiple venues under one account and effectively communicate the unique qualities of each venue in detail.' },
        { head: 'Automated Responses', des: 'Streamline your responses with professional, automated replies that cater to various scenarios, saving you time and ensuring prompt communication with clients.' },
        { head: 'Edit Venue Details', des: 'Add and update existing venue details anytime, anywhere with ease.' },
    ]

    return (
        <div className='flex justify-center pt-20' >
            <div className='w-[90%]'>
                <h1 className='text-[#222222] sm:text-4xl text-3xl font-semibold'>Get Verified Inquiries Promptly</h1>
                <div className='flex items-center mt-10 gap-8'>
                    <div className='lg:w-[60%] w-full h-full'>
                        <div className=' grid grid-cols-2 gap-6'>
                            {
                                data.map((e, i) => (
                                    <div key={i} className='text-[#B0B0B0]' >
                                        <h1 className='sm:text-xl text-lg font-semibold  ' >{e.head}</h1>
                                        <p className='mt-2 sm:text-base text-sm'>{e.des}</p>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                    <div className='w-[40%] lg:flex hidden justify-center'>
                        <img src={imgBanner} className='w-full rounded-xl h-full' alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Enquiries
