/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

import Navbar from '../../components/Navbar';
import { VenueContext } from '../../context/VenueContext';
import HeroSection from '../../components/venue-preview/hero-section';
import OverviewSection from '../../components/venue-preview/overview-section';
import AboutThisHotel from '../../components/venue-preview/about-hotel';
import { useQuery } from '@tanstack/react-query';
import { getVenueData } from '../../api/venue-api';
import Highlights from '../../components/venue-preview/heighlight';
import OffersAndPackages from '../../components/venue-preview/offer-package';
import Pricing from '../../components/venue-preview/pricing';
import CapacityLayout from '../../components/venue-preview/capacity';
import WhatThisPlaceOffers from '../../components/venue-preview/catering-drinking';
import Facilities from '../../components/venue-preview/facilities-equilment';
import EventType from '../../components/venue-preview/event-type';
import GoogleMapComponent from '../../components/venue-preview/location';
import { getVendorHotel } from '../../api/hotel-api';
import { fetchLoggedUser } from '../../api/loggedUser';

const VenuePreview = () => {
	const { formData, setFormData } = useContext(VenueContext);
	const [locationDetails, setLocationDetails] = useState({
		addressLine1: '',
		addressLine2: '',
		city: '',
		area: '',
		poBox: '',
		country: '',
		mapLink: '',
		lat: null,
		lng: null,
	});

	console.log('form data', formData);

	const vendorId = localStorage?.getItem('vendorId');

	const { data: user } = useQuery({
		queryKey: ['loggedin-user'],
		queryFn: async () => {
			const response = await fetchLoggedUser();
			return response?.vendor;
		},
	});

	const { data: hotel, isPending } = useQuery({
		queryKey: ['vendor-hotel'],
		queryFn: async () => {
			if (user?.onBoardingDetails?.type === 'HotelStayandVenue') {
				const res = await getVendorHotel(vendorId);
				console.log('hotel res', res);
				setLocationDetails({
					...locationDetails,
					addressLine1: res?.overview?.locationDetails?.addressLine1,
					addressLine2: res?.overview?.locationDetails?.addressLine2,
					city: res?.overview?.locationDetails?.city,
					area: res?.overview?.locationDetails?.area,
					poBox: res?.overview?.locationDetails?.poBox,
					country: res?.overview?.locationDetails?.country,
					mapLink: res?.overview?.locationDetails?.mapLink,
					lat: res?.overview?.locationDetails?.lat,
					lng: res?.overview?.locationDetails?.lng,
				});
				return res;
			} else {
				setLocationDetails({
					...locationDetails,
					addressLine1: formData?.location?.addressLine1,
					addressLine2: formData?.location?.addressLine2,
					city: formData?.location?.city,
					area: formData?.location?.area,
					poBox: formData?.location?.poBox,
					country: formData?.location?.country,
					mapLink: formData?.location?.mapLink,
					lat: formData?.location?.lat,
					lng: formData?.location?.lng,
				});
				return true;
			}
		},
		enabled: !!user,
	});

	const { data, error, isLoading } = useQuery({
		queryKey: ['venueData', vendorId],
		queryFn: async () => {
			if (!vendorId) return null;
			const response = await getVenueData(vendorId);
			setFormData(response);
			return response;
		},

		enabled: !!vendorId,
		refetchOnWindowFocus: false,
	});

	return (
		<div>
			<Navbar />
			<HeroSection
				data2={formData}
				data={formData?.imagesAndVideos?.images}
			/>

			<OverviewSection
				data={formData}
				locationDetails={locationDetails}
			/>
			<Highlights data={formData} />
			<AboutThisHotel
				data={formData}
			/>

			<OffersAndPackages data={formData?.offersAndPackages} />
			<Pricing data={formData?.pricing} />
			<CapacityLayout data={formData?.capacity} />
			<WhatThisPlaceOffers data={formData} />
			<Facilities data={formData} />

			<EventType data={formData} />
			<div id='map'>
				<GoogleMapComponent data={locationDetails} />
			</div>
		</div>
	);
};

export default VenuePreview;
