/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import addres from '../../assets/pics/addres.svg';
import addyourleam from '../../assets/pics/addyourleam.svg';
import venuedetail from '../../assets/pics/venuedetail.svg';
import Popup from './Popup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	ActiveHotelIcon,
	ActiveResturentIcon,
	ActiveTeamIcon,
	ActiveVenueIcon,
	CompleteHotelIcon,
	CompleteResturentIcon,
	CompleteTeamIcon,
	CompleteVenueIcon,
	CountFourIcon,
	CountOneIcon,
	CountThreeIcon,
	CountTwoIcon,
	HotelIcon,
	ResturentIcon,
	TeamIcon,
	VenueIcon,
} from '../../svgs';
import { useQuery } from '@tanstack/react-query';
import {
	getCurrentVendor,
	getOnboarding,
} from '../../api/signup.request';
import toast from 'react-hot-toast';
import { Category } from '@mui/icons-material';
import Loader from '../shared/common/loader';

export default function DashboardBody(props) {
	const [HotelProfileInprogress, setHotelProfileInprogress] =
		useState(true);
	const [HotelProfileICompleted, setHotelProfileICompleted] =
		useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [isRegister, setIsRegister] = useState(false);
	const [isRegisterVenue, setIsRegisterVenue] = useState(false);
	const [isRegisterRes, setIsRegisterRes] = useState(false);
	const [isPendingRes, setIsPendingRes] = useState(false);
	const [isPendingVenue, setIsPendingVenue] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const [data, setdata] = useState([]);
	const vendorId = localStorage.getItem('vendorId');
	const {
		data: boardingData,
		error,
		isLoading,
	} = useQuery({
		queryKey: ['onboardingData'],
		queryFn: async () => {
			return await getOnboarding(vendorId);
		},
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (boardingData) {
			setdata(boardingData);
		}
	}, [boardingData]);

	const {
		data: user,
		error: usererror,
		isLoading: userLoading,
	} = useQuery({
		queryKey: ['userData'],
		queryFn: async () => {
			return await getCurrentVendor(vendorId);
		},
		refetchOnWindowFocus: false,
	});
	console.log(data, "current user")
	// useEffect(() => {
	//   const fetchData = async () => {
	//     try {
	//       const response = await getOnboarding();
	//       // console.log(response.vendor)
	//       setdata(response?.data?.onboarding)
	//     } catch (error) {
	//       console.log(error)
	//     }
	//   }
	//   fetchData();
	// }, [])

	console.log('onboarding', data);
	const navigate = useNavigate();
	const location = useLocation();

	const togglePopup = () => {
		setShowPopup(!showPopup);
	};

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		// if (query.get("hotelRegister") === "true") {
		//   setIsRegister(true);
		// } else
		// if (query.get("hotelRegisterPending") === "true") {
		//   setIsPending(true);
		// } else
		if (query.get('venueRegister') === 'true') {
			setIsRegisterVenue(true);
			setIsRegister(true);
		} else if (query.get('venuePending') === 'true') {
			setIsPendingVenue(true);
			setIsRegister(true);
		} else if (query.get('restaurantPending') === 'true') {
			setIsPendingRes(true);
			setIsRegisterVenue(true);
			setIsRegister(true);
		} else if (query.get('restaurantRegister') === 'true') {
			setIsRegisterVenue(true);
			setIsRegisterRes(true);
			setIsRegister(true);
		}
	}, [location.search]);

	const getIcon = (type) => {
		switch (type) {
			case 'hotel':
				if (data?.hotels[0]?.status === 'completed')
					return <CompleteHotelIcon />;
				if (data?.hotels[0]?.status === 'pending')
					return <ActiveHotelIcon />;
				return <HotelIcon />;
			case 'venue':
				if (
					data?.venueForms?.some(
						(venue) => venue.status === 'pending'
					)
				)
					return <ActiveVenueIcon />;
				if (
					data?.venueForms?.some(
						(venue) => venue.status === 'completed'
					)
				)
					return <CompleteVenueIcon />;
				return <VenueIcon />;
			case 'restaurant':
				if (
					data?.restaurantForms?.some(
						(restaurant) => restaurant.status === 'pending'
					)
				)
					return <ActiveResturentIcon />;

				if (
					data?.restaurantForms?.some(
						(restaurant) => restaurant.status === 'completed'
					)
				)
					return <CompleteResturentIcon />;
				return <ResturentIcon />;
			case 'team':
				// Add logic for team icon if necessary
				return <TeamIcon />;
			default:
				return null;
		}
	};

	const getCardClassNames = (type) => {
		switch (type) {
			case 'hotel':
				return data?.hotels[0]?.status === 'pending'
					? 'border-[#FFDC99] border bg-[#FFF6E6]'
					: data?.hotels[0]?.status === 'completed'
					? 'border-[#27C2B0] border bg-[#d0e8e8]'
					: 'bg-white';

			case 'venue':
				return data?.venueForms?.some(
					(venue) => venue.status === 'pending'
				)
					? 'border-[#FFDC99] border bg-[#FFF6E6]'
					: data?.venueForms?.some(
							(venue) => venue.status === 'completed'
					  )
					? 'border-[#27C2B0] border bg-[#d0e8e8]'
					: 'bg-white';

			case 'restaurant':
				return data?.restaurantForms?.some(
					(restaurant) => restaurant.status === 'pending'
				)
					? 'border-[#FFDC99] border bg-[#FFF6E6]'
					: data?.restaurantForms?.some(
							(restaurant) => restaurant.status === 'completed'
					  )
					? 'border-[#27C2B0] border bg-[#d0e8e8]'
					: 'bg-white';

			case 'team':
				// Add logic for team card style if necessary
				return 'bg-white';

			default:
				return 'bg-white';
		}
	};

	const getStatusText = (type) => {
		switch (type) {
			case 'hotel':
				return data?.hotels[0]?.status === 'Completed'
					? 'Completed'
					: data?.hotels[0]?.status === 'pending'
					? 'In Progress'
					: '';

			case 'venue':
				return data?.venueForms?.some(
					(venue) => venue.status === 'pending'
				)
					? 'In Progress'
					: data?.venueForms?.some(
							(venue) => venue.status === 'completed'
					  )
					? 'Completed'
					: '';
			case 'restaurant':
				return data?.restaurantForms?.some(
					(restaurant) => restaurant.status === 'pending'
				)
					? 'In Progress'
					: data?.restaurantForms?.some(
							(restaurant) => restaurant.status === 'completed'
					  )
					? 'Completed'
					: '';
			case 'team':
				// Add logic for team status if necessary
				return '';
			default:
				return '';
		}
	};

	const handlegetstarted = () => {
		if (HotelProfileInprogress) {
			//  navigate('/hostelListingForm');
			setHotelProfileInprogress(false);
		} else {
			navigate('/hostel-Listing-Form');
			setHotelProfileInprogress(true);
			setHotelProfileICompleted(true);
		}
	};

	const getPendingVenueCount = () => {
		return;
	};

	const handleCardClick = (type, category) => {
		// if (type === "venue" && category === "HotelStayandVenue" && data?.venueForms?.filter(venue => venue.status === 'completed')?.length >= data?.VenuesCount) {
		//   toast.error("Venue limit is full. ");
		//   return;
		// }

		if (
			type === 'hotel' &&
			category === 'HotelStayandVenue' &&
			data?.hotels?.filter((res) => res.status === 'completed')
				?.length >= 1
		) {
			toast.error('Hotel limit is full. ');
			return;
		}

		if (
			type === 'restaurant' &&
			category === 'HotelStayandVenue' &&
			data?.restaurantForms.filter(
				(res) => res.status === 'completed'
			)?.length >=
				data?.withAlcoholNumber + data?.withoutAlcoholNumber
		) {
			toast.error('Restaurant limit  is full. ');
			return;
		}

		// venue restaurant
		if (
			type === 'restaurant' &&
			category === 'venues' &&
			data?.restaurantForms.filter(
				(res) => res.status === 'completed'
			)?.length >=
				data?.withAlcoholNumber + data?.withoutAlcoholNumber
		) {
			toast.error('Restaurant limit  is full. ');
			return;
		}

		//venue yes or no
		const canCreateVenue = (type, category) => {
			if (type === 'venue' && category === 'venues') {
				// Initialize counts
				let uniqueCitiesWithVenues = new Set();
				let multipleVenuesInSameCity = 0;

				// Loop through each venue to calculate counts
				const cityCounts = {};

				data?.venueForms?.forEach((venue) => {
					if (venue.status === 'completed') {
						const city = venue.location.city;

						// Track the count of venues in each city
						if (cityCounts[city]) {
							cityCounts[city]++;
						} else {
							cityCounts[city] = 1;
						}
					}
				});

				// Process the counts to fill uniqueCitiesWithVenues and multipleVenuesInSameCity
				for (const city in cityCounts) {
					if (cityCounts[city] > 1) {
						multipleVenuesInSameCity++;
					} else {
						uniqueCitiesWithVenues.add(city);
					}
				}

				console.log(
					'uniqueCitiesWithVenues:',
					uniqueCitiesWithVenues.size
				);
				console.log(
					'multipleVenuesInSameCity:',
					multipleVenuesInSameCity
				);

				// Check if the number of cities with venues exceeds noCount
				if (uniqueCitiesWithVenues.size > data?.noCount) {
					toast.error(
						'You have reached the venue limit for different cities.'
					);
					return false;
				}

				// Check if the number of venues in the same city exceeds yesCount
				if (multipleVenuesInSameCity > data?.yesCount) {
					toast.error(
						'You have reached the maximum number of venues for a single city.'
					);
					return false;
				}
			}
			return true;
		};

		console.log(type, isVenueYesNo, !isKidsOnly);
		if (
			type === 'venue' &&
			!isKidsOnly &&
			(isVenueYesNo || (isVenueYesNo && isRestaurant))
		) {
			navigate('/myVenue');
			return;
		}

		if (type === 'venue' && !canCreateVenue(type, category)) {
			return;
		}

		// Navigate to the appropriate form based on type
		switch (type) {
			case 'hotel':
				navigate('/hostel-Listing-Form');
				break;
			case 'venue':
				// navigate('/venu-Listing-Form');
				navigate('/myVenue');
				break;
			case 'restaurant':
				navigate('/venu-restaurant-Form');
				break;
			case 'team':
				navigate('/team');
				break;
			default:
				break;
		}
	};

	const isRestaurant =
		data?.RestaurantswithAlcohol > 0 ||
		data?.RestaurantswithoutAlcohol > 0 ||
		data?.withAlcoholNumber > 0 ||
		data?.withoutAlcoholNumber > 0;
	// const isRestaurant = data?.withAlcoholNumber > 0 || data?.withoutAlcoholNumber > 0
	const isVenueYesNo = data?.yesCount > 0 || data?.noCount > 0;
	const isKidsOnly =
		data?.selectedChips?.length === 1 &&
		data?.selectedChips[0] === 'Kids Play Area';

	const cardsData =
		data && data?.type === 'HotelStayandVenue'
			? [
					{
						src: '/hostel-Listing-Form',
						type: 'hotel',
						category: 'HotelStayandVenue',
						icons: {
							normal: <HotelIcon />,
							active: <ActiveHotelIcon />,
							complete: <CompleteHotelIcon />,
						},
						count: <CountOneIcon />,
						name: 'Setup Hotel Profile',
					},
					{
						// src: '/venu-Listing-Form',
						src: '/myVenue',
						category: 'HotelStayandVenue',
						type: 'venue',
						icons: {
							normal: <VenueIcon />,
							active: <ActiveVenueIcon />,
							complete: <CompleteVenueIcon />,
						},
						count: <CountTwoIcon />,
						name: 'Add Venue & Restaurant Details',
					},
					// data?.type === "HotelStayandVenue" &&
					// isRestaurant && {
					//   src: "/venu-restaurant-Form",
					//   category: "HotelStayandVenue",
					//   type: "restaurant",
					//   icons: {
					//     normal: <ResturentIcon />,
					//     active: <ActiveResturentIcon />,
					//     complete: <CompleteResturentIcon />,
					//   },
					//   count: <CountThreeIcon />,
					//   name: "Add Restaurants",
					// },
					{
						src: '/team',
						category: 'HotelStayandVenue',
						type: 'team',
						icons: {
							normal: <TeamIcon />,
							active: <ActiveTeamIcon />,
							complete: <CompleteTeamIcon />,
						},
						count: <CountFourIcon />,
						name: 'Add Your Team',
					},
			  ]
			: [
					isKidsOnly && {
						src: '/myVenue',
						category: 'venues',
						type: 'venue',
						icons: {
							normal: <VenueIcon />,
							active: <ActiveVenueIcon />,
							complete: <CompleteVenueIcon />,
						},
						count: <CountTwoIcon />,
						name: 'Add Venue Details',
					},
					isVenueYesNo &&
						!isKidsOnly && {
							src: '/myVenue',
							category: 'venues',
							type: 'venue',
							icons: {
								normal: <VenueIcon />,
								active: <ActiveVenueIcon />,
								complete: <CompleteVenueIcon />,
							},
							count: <CountTwoIcon />,
							name: 'Add Venue & Restaurant',
						},
					isRestaurant &&
						!isVenueYesNo && {
							src: '/venu-restaurant-Form',
							category: 'venues',
							type: 'restaurant',
							icons: {
								normal: <ResturentIcon />,
								active: <ActiveResturentIcon />,
								complete: <CompleteResturentIcon />,
							},
							count: <CountThreeIcon />,
							name: 'Add Restaurants',
						},
					{
						src: '/team',
						category: 'venues',
						type: 'team',
						icons: {
							normal: <TeamIcon />,
							active: <ActiveTeamIcon />,
							complete: <CompleteTeamIcon />,
						},
						count: <CountFourIcon />,
						name: 'Add Your Team',
					},
			  ];

	return (
		<>
			<main className=' h-full flex items-center'>
				<div className='text-center flex flex-col items-center justify-around w-full h-full lg:py-5'>
					<div>
						<h1 className='text-3xl font-semibold xl:mb-1 mb-4 font-outfitt text-darkGray'>
							Welcome to Findmyvenue
						</h1>
						<p className='text-[#5E5E5E] font-normal mb-8 font-outfitt'>
							Let's get started on creating your profile.
						</p>
					</div>
					{isLoading && data === undefined && <Loader />}

					{/* lg:w-4/5 */}
					<div className='w-full px-5 flex flex-wrap gap-5 justify-center mx-auto'>
						{cardsData.filter(Boolean).map((e, index) => (
							<div
								key={index}
								onClick={() => handleCardClick(e.type, e.category)}
								className={`${getCardClassNames(
									e.type
								)} py-8 px-5 cursor-pointer flex flex-wrap  shadow-lg p-3 relative rounded-lg lg:w-1/5 w-full`}
							>
								<div className='flex lg:w-full w-1/4 justify-between items-center'>
									{getIcon(e.type)}
									<div className='h-full textColor lg:text-[40px] lg:block hidden text-2xl font-sans font-bold'>
										0{index + 1}
									</div>
								</div>
								<div className='lg:my-8 lg:px-0 px-5 w-3/4 lg:w-full'>
									<button className='text-lg xl:text-xl block font-semibold mb-2 font-outfitt text-darkGray'>
										{e.name}
									</button>
									<p className='text-start text-black'>
										{getStatusText(e.type)}
									</p>
								</div>

								<div className='h-full textColor lg:text-[40px] lg:hidden block  absolute right-1 top-1 text-2xl font-sans font-bold'>
									0{index + 1}
								</div>
							</div>
						))}
					</div>
					<div className='w-full flex justify-center items-center flex-col'>
  {data?.hotels?.length == 0 && data?.hotels[0]?.status !== 'completed' ? (
    <>
      <Link
        to={`${
          data?.type === 'HotelStayandVenue'
            ? '/hostel-Listing-Form'
            : '/venu-Listing-Form'
        } `}
      >
        <button className='font-outfitt mt-8 bg-red-500 text-white py-2 px-4 rounded w-[350px]'>
          Get Started
        </button>
      </Link>
    </>
  ) : (
    <>
      <Link to='/myVenue'>
        <button className='font-outfitt mt-8 bg-red-500 text-white py-2 px-4 rounded w-[350px]'>
          Continue
        </button>
      </Link>
    </>
  )}

  {/* Render PopupComponent conditionally */}
  {showPopup && <Popup onClose={togglePopup} />}
  <p
    style={{ cursor: 'pointer' }}
    onClick={togglePopup}
    className='mt-4 text-black-500 underline font-medium font-outfitt text-darkGray'
  >
    Required Documents List
  </p>
</div>

				</div>
			</main>
		</>
	);
}
