import React, { useContext, useEffect, useState } from "react";
import {
  FaBriefcase,
  FaVideo,
  FaCrown,
  FaMicrophone,
  FaUserTie,
  FaWifi,
  FaTrash,
} from "react-icons/fa";
import ToggleSwitch from "../../shared/common/custom-toggle";
import AddFeatureModal from "./add-feature-form";
import { HotelContext } from "../../../context/HotelContext";
import Modal from "../../shared/custom-modal/custom-modal";
import * as FaIcons from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

const defaultFeatures = [
  {
    name: "Business Centre",
    icon: <img src="/business-features/business.svg" />,
  },
  {
    name: "Video Conference",
    icon: <img src="/business-features/video-conference.svg" />,
  },
  { name: "VIP Services", icon: <img src="/business-features/vip.svg" /> },
  {
    name: "AV Equipment",
    icon: <img src="/business-features/av-equipements.svg" />,
  },
  {
    name: "Onsite AV Staff",
    icon: <img src="/business-features/support.svg" />,
  },
  {
    name: "High Speed Internet (100 Mbps)",
    icon: <img src="/business-features/internet.svg" />,
  },
];

const BusinessFeatures = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [features, setFeatures] = useState(defaultFeatures); // Track custom features
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureToEdit, setFeatureToEdit] = useState(null);

  const businessFeatures = formData?.amenities?.businessFeatures || {};
  const businessFeaturesIcons = formData?.amenities?.businessFeaturesIcons || {};

  useEffect(() => {
    // Create a map of feature names to icons from businessFeaturesIcons
    const iconsMap = new Map(Object.entries(businessFeaturesIcons));

    // Map existing features into an array with proper icons
    const updatedFeatures = Object.keys(businessFeatures).map((feature) => ({
      name: feature,
      icon: iconsMap.get(feature) || "FaAccusoft", // Default to "FaAccusoft" if no icon is found
    }));

    // Update the features state with the merged list
    setFeatures((prevFeatures) => {
      // Create a map of previous features to avoid duplicates
      const existingFeaturesMap = new Map(prevFeatures.map((item) => [item.name, item]));

      // Combine the existing features with the updated features
      const combinedFeatures = [...prevFeatures];

      updatedFeatures.forEach((newFeature) => {
        if (!existingFeaturesMap.has(newFeature.name)) {
          combinedFeatures.push(newFeature);
        }
      });

      return combinedFeatures;
    });
  }, [businessFeatures, businessFeaturesIcons]);




  const handleToggle = (featureName) => {
    const updatedFeatures = {
      ...businessFeatures,
      [featureName]: !businessFeatures[featureName],
    };

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        businessFeatures: updatedFeatures,
      },
    }));
  };

  const handleUpdateFeature = (feature) => {
    const updatedFeatures = features.map((e, i) =>
      i === feature.index ? { name: feature.name, icon: feature.icon } : e
    );

    setFeatures(updatedFeatures);

    const icons = {};
    const names = {};

    updatedFeatures.forEach((facility, index) => {
      icons[facility.name] = facility.icon;
      names[facility.name] = index === feature.index ? true : businessFeatures[facility.name];
    });

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        businessFeatures: names,
        businessFeaturesIcons: icons,
      },
    }));
  };
  
  const handleAddFeature = (newFeature) => {
    if (featureToEdit) {
      // Update the existing feature if editing
      const updatedFeatures = features.map((feature) =>
        feature.name === featureToEdit.name ? newFeature : feature
      );
      setFeatures(updatedFeatures);
      setFeatureToEdit(null);
    } else {
      // Add a new feature
      setFeatures((prevFeatures) => [...prevFeatures, newFeature]);
    }

    setFormData((prevData) => {
      // Ensure previous data and amenities are defined
      const amenities = prevData?.amenities || {};
      const businessFeatures = amenities?.businessFeatures || {};
      const businessFeaturesIcons = amenities?.businessFeaturesIcons || {};

      return {
        ...prevData,
        amenities: {
          ...amenities,
          businessFeaturesIcons: {
            ...businessFeaturesIcons,
            [newFeature.name]: newFeature.icon,
          },
          businessFeatures: {
            ...businessFeatures,
            [newFeature.name]: true, // Enable the new feature by default
          },
        },
      };
    });
  };


  const handleEditFeature = (feature) => {
    setFeatureToEdit(feature);
    setIsModalOpen(true);
  };

  const handleDeleteFeature = (featureName) => {
    const updatedFeatures = features.filter(
      (feature) => feature.name !== featureName
    );
    setFeatures(updatedFeatures);

    const updatedBusinessFeatures = { ...businessFeatures };
    delete updatedBusinessFeatures[featureName];

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        businessFeatures: updatedBusinessFeatures,
      },
    }));
  };

  const renderIcon = (iconName) => {
    const IconComponent = FaIcons[iconName];
    return IconComponent ? <IconComponent size={32} /> : null;
  };

  return (
    <div className="">
      <div className="flex">
        <div className="w-full">
          <div className="flex justify-between items-center w-full pb-5">
            <h2 className="text-2xl lg:text-3xl font-semibold pb-2 text-neutralBlack font-outfitt">
              Business Features
            </h2>
            <button
              onClick={() => setIsModalOpen(true)}
              className="text-primary font-medium text-base underline"
            >
              Add Custom Feature
            </button>
          </div>
          {features &&
            features.map((feature, index) => {
              // Check if the feature is in defaultFeatures
              const isDefaultFeature = defaultFeatures.find(
                (defaultFeature) => defaultFeature?.name === feature?.name
              );

              return (
                <div
                  key={index}
                  className="flex py-1 items-center justify-between lg:w-[60%] w-full mb-4"
                >
                  <div className="flex items-center w-[80%] justify-between">
                    <div className="flex items-center space-x-4">
                      {/* Render icon only if it's in defaultFeatures */}
                      {isDefaultFeature ? (
                        feature.icon
                      ) : (
                        <span className="text-2xl mr-2">
                          {renderIcon(feature.icon)}
                        </span>
                      )}
                      <span className="text-base text-darkGray">
                        {feature.name}
                      </span>
                    </div>
                    <ToggleSwitch
                      isOn={businessFeatures[feature.name] || false}
                      handleToggle={() => handleToggle(feature.name)}
                    />
                  </div>
                  {isDefaultFeature ? null : (
                    <div className="flex items-center gap-1">
                      <button
                        className="text-sm text-[#1252B1] hover:text-red-600"
                        onClick={() => handleEditFeature({ ...feature, index })}

                      >
                        <img src="/edit.svg" />
                      </button>
                      <button
                        className="text-red-500 hover:text-red-600"
                        onClick={() => handleDeleteFeature(feature.name)}
                      >
                        <img src="/trash.svg" />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
             {formData?.amenities?.errors && (
          <p className="text-red-500 text-base pt-1">{formData?.amenities?.errors?.businessFeatures}</p>
        )}
        </div>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <AddFeatureModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onSave={handleAddFeature}
          featureToEdit={featureToEdit}
          onUpdate={handleUpdateFeature}
        />
      </Modal>
    </div>
  );
};

export default BusinessFeatures;
