import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import backgroundIcon from "../../assets/images/background_icon.svg";
import MessageCard from "./components/MessageCard";
import { ReactComponent as TickGreenIcon } from "../../assets/images/tick_green_icon.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import { useNavigate, Link } from "react-router-dom";

function PaymentSuccess() {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/dashboard'); // Redirect to the dashboard after 5 seconds
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Clean up the timer if the component is unmounted
  }, [navigate]);

  return (
    <>
      <ResponsiveAppBar />
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Use "center" for alignment
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <MessageCard
            loaderComponent={
              <CheckCircleIcon
                sx={{
                  fontSize: 60,
                  mt: 4,
                  color: theme.palette.green.text,
                }}
              />
            }
            primaryText={"Payment Successful"}
            secondaryText={`Creating your dashboard experience...`}
          />
          {/* <Link to="/dashboard" className="bg-green-400 text-white py-3 px-5 rounded-lg">Dashboard</Link> */}
        </Container>
      </div>
    </>
  );
}

export default PaymentSuccess;
