/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Card, Box, Button, TextField } from "@mui/material";
import TitleDescriptionCoupon from "../components/TitleDescriptionCoupon";
import MultiChipSelector from "../components/MultiChipSelector";
import MessageCard from "../components/MessageCard";
import CircularProgressLoader from "../../../components/CircularProgressLoader";
import RestaurantCard from "../components/RestaurantCard";
import LabelledRadioGroup from "../components/LabelledRadioGroup";
import { useTheme } from "@mui/material";
import VenueCard from "../components/VenueCard";
import PaymentRestaurantView from "../payment/PaymentRestaurantView";
import PaymentVenueView from "../payment/PaymentVenueView";
import PaymentKidsView from "../payment/PaymentKidsView";
import PaymentRestaurantVenueView from "../payment/PaymentRestaurantVenueView";
import calculateRestaurantPayment from "../../../utils/calculateRestaurantPayment";
import calculateRestaurantVenuePayment from "../../../utils/calculateRestaurantVenuePayment";
import calculateVenuePayment from "../../../utils/calculateVenuePayment";
import calculateKidsPayment from "../../../utils/calculateKidsPayment";
import BillingForm from "../components/BillingForm";
import { payment } from "../../../api/hotelPayment";
import Questions from "../components/Questions";
import { getCurrentVendor, updateOnboarding, updateOnboardingbytoken } from "../../../api/signup.request";
import { fetchLoggedUser } from "../../../api/loggedUser";
import { useQuery } from "@tanstack/react-query";

function MultipleFlow({
  activeStep,
  handleContinue,
  toggleBackButton,
  isBackButtonClicked,
  resetBackButtonClicked,
  handleBack,
}) {
  const [user, setUser] = useState();
  // console.log('user', user)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        // console.log(response.vendor)
        setUser(response.vendor)
      } catch (error) {
        console.log(error)
      }
    }
    fetchUser();
  }, [])
  // console.log("active in multiple", user)
  const theme = useTheme();

  const [hotelGroupName, setHotelGroupName] = useState("");
  const [partOfGroup, setPartOfGroup] = useState("no");
  const [entityName, setEntityName] = useState("");
  const [sameLocation, setSameLocation] = useState("no");
  const [venueType, setVenueType] = useState("no");
  const [withAlcoholNumber, setWithAlcoholNumber] = useState(0);
  const [withoutAlcoholNumber, setWithoutAlcoholNumber] = useState(0);
  const [paymentData, setPaymentData] = useState([]);
  const [totalPaymentData, setTotalPaymentData] = useState([]);
  //   Venue Quantity Number
  const [quantityNumber, setQuantityNumber] = useState(1);
  const [selectedChips, setSelectedChips] = useState([]);

  //   States for Rendering different Views for Multiple Flow
  const [restaurantsView, setRestaurantsView] = useState(false);
  const [restaurantsVenueView, setRestaurantVenueView] = useState(false);
  const [restaurantsOtherView, setRestaurantotherView] = useState(false);
  const [venueView, setVenueView] = useState(false);
  const [kidsVenueView, setKidsVenueView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const vendorId = localStorage.getItem('vendorId');
  const refreshToken = localStorage.getItem('token');

  const { data: currentuser, error: usererror, isLoading: userLoading } = useQuery({
    queryKey: ["userData"],
    queryFn: async () => {
      return await getCurrentVendor(vendorId);
    },
    refetchOnWindowFocus: false,
  });
  // console.log(currentuser, "current user")
  // console.log(vendorId, "vednor id")
  // console.log(refreshToken, "current token")
  // const [onBoardingDetails, setonBoardingDetails] = useState(
  //   {
  //     type: 'venues',
  //     withAlcoholNumber: withAlcoholNumber,
  //     withoutAlcoholNumber: withoutAlcoholNumber,
  //     selectedChips: selectedChips,
  //     items: items,
  //     payments: paymentData,
  //     payment: true,
  //   }
  // );
  // console.log(onBoardingDetails)
  // Need this to display another component between activeStep === 3 and 4 of the normal flow
  const [showAdditionalStep, setShowAdditionalStep] = useState(false);

  let isRestaurantsSelected = false;
  let hasOtherSelections = false;
  let isOnlyOneSelection = false;
  let isKidsPlayAreaSelected = false;
  let isOtherSelected = false;

  const handleWithAlcoholNumberChange = (event) => {
    setWithAlcoholNumber(event.target.value);
    // setonBoardingDetails((prevDetails) => ({
    //   ...prevDetails,
    //   withAlcoholNumber: event.target.value,
    // }));
  };

  const handleWithoutAlcoholNumberChange = (event) => {
    setWithoutAlcoholNumber(event.target.value);
    // setonBoardingDetails((prevDetails) => ({
    //   ...prevDetails,
    //   withoutAlcoholNumber: event.target.value,
    // }));
  };
  const handleContinueForNextProcess = () => {
    if (activeStep === 4 && entityName === "" && hotelGroupName === "") {
      return
    }
    if (activeStep === 4 && partOfGroup === 'yes' && hotelGroupName === "") {
      return
    }

    handleContinue();
  };

  const handleSameLocationChange = (event) => {
    setSameLocation(event.target.value);

    // if (event.target.value === "yes") {
    //   //   Need to display the additional step for same location
    //   setShowAdditionalStep(true);
    // } else {
    //   //   Don't need to display the additional step for different location
    //   setShowAdditionalStep(false);
    // }
    setShowAdditionalStep(false);

  };
  // console.log(paymentData)
  const handlePayment = async () => {
    console.log("this one")
    // Retrieve values from local storage
    const restaurantview = localStorage.getItem('restaurantview');
    const amountrestaurantview = restaurantview ? parseInt(restaurantview, 10) : 0;
    const kidsview = localStorage.getItem('kidsview');
    const amountkidsview = kidsview ? parseInt(kidsview, 10) : 0;
    const restaurantandvenuecombined = localStorage.getItem('restaurantandvenuecombined');
    const amountrestaurantvenuwcombined = restaurantandvenuecombined ? parseInt(restaurantandvenuecombined, 10) : 0;
    const others = localStorage.getItem('others');
    const amountothers = others ? parseInt(others, 10) : 0;
    // Prepare the payment payload
    const paymentPayload = {
      email: user?.email,
      cardHolderName: "Hotel and Venue",
      savedForFuture: true,
      amount: (restaurantsVenueView && kidsVenueView) || restaurantsOtherView
        ? amountrestaurantvenuwcombined
        : restaurantsView
          ? amountrestaurantview
          : kidsVenueView
            ? amountkidsview
            : venueView
              ? amountothers
              : paymentData.grandTotal,
      description:
        (restaurantsVenueView && kidsVenueView) || restaurantsOtherView
          ? "Payment for restaurant and venue combined"
          : restaurantsView
            ? "Payment for restaurant view"
            : kidsVenueView
              ? "Payment for kids venue view"
              : venueView
                ? "Payment for other venues"
                : "General payment for hotel and venue",
    };

    try {
      setLoading(true);
      // Store onboarding details on the server before redirecting

      // Make a request to save onboarding details to the server
      // if (!vendorId || vendorId.trim() === ''){
      //   await updateOnboardingbytoken(onBoardingDetails,refreshToken);
      // }else{
      //   // await updateOnboarding(onBoardingDetails,vendorId);
      //   await updateOnboardingbytoken(onBoardingDetails,refreshToken);
      // }
      // Proceed with the payment
      const response = await payment(paymentPayload);
      if (response.success && response.session && typeof response.session.url === "string") {

        const onBoardingDetails = {
          type: 'venues',
          withAlcoholNumber: withAlcoholNumber,
          withoutAlcoholNumber: withoutAlcoholNumber,
          selectedChips: selectedChips,
          items: items,
          payments: paymentData,
          payment: true,
        };
        await updateOnboarding(onBoardingDetails);

        window.location.href = response.session.url;
      } else {
        console.error("URL not found or invalid");
      }
      if (!response.ok) {
        throw new Error("Payment failed");
      }

      const result = await response.json();

      if (result.session && result.session.url) {

        window.location.href = result.session.url;
        return; // Exit the function early as we're redirecting
      }

      handleContinue();
    } catch (error) {
      console.error("Error processing payment or hotel booking:", error);
      // Handle the error appropriately (e.g., show an error message to the user)
    } finally {
      setLoading(false);
    }
  };


  const handlePartOfGroupChange = (event) => {
    setPartOfGroup(event.target.value);
  };

  const handleHotelGroupNameChange = (event) => {
    setHotelGroupName(event.target.value);
  };

  const handleSelectedChipsChange = (chips) => {
    setSelectedChips(chips);
    // setonBoardingDetails((prevDetails) => ({
    //   ...prevDetails,
    //   selectedChips: chips,
    // }));
  };
  // console.log('selectChips', selectedChips)
  // console.log('item', items)

  const handleQuantityNumberChange = (event) => {
    setQuantityNumber(event.target.value);
  };

  const handleVenueTypeChange = (event) => {
    setVenueType(event.target.value);
  };

  useEffect(() => {
    isRestaurantsSelected = selectedChips.includes("Restaurants, Cafe's & Bar");
    isOnlyOneSelection = selectedChips.length == 1;
    hasOtherSelections = selectedChips.length >= 1;
    isKidsPlayAreaSelected = selectedChips.includes("Kids Play Area");
    isOtherSelected = selectedChips.includes("Other");
    // console.log(isOtherSelected)
    // Using these setFunctions as radio buttons, only one true at a time.
    // These states such as restaurantView, restaurantVenueView will help us in flows for multiple venues.
    if (isRestaurantsSelected && isOnlyOneSelection) {
      setRestaurantsView(true);
      setRestaurantVenueView(false);
      setVenueView(false);
      setKidsVenueView(false);
    }
    else if (
      isRestaurantsSelected &&
      isKidsPlayAreaSelected && isOtherSelected
    ) {
      setRestaurantsView(false);
      // setRestaurantVenueView(true);
      setRestaurantotherView(true)

      setVenueView(false);
      setKidsVenueView(false);
    } else if (
      isRestaurantsSelected &&
      isKidsPlayAreaSelected && !isOtherSelected
    ) {
      setRestaurantsView(false);
      setRestaurantVenueView(true);
      setVenueView(false);
      setKidsVenueView(true);
    }
    else if (
      isRestaurantsSelected &&
      hasOtherSelections &&
      !isKidsPlayAreaSelected
    ) {
      setRestaurantsView(false);
      setRestaurantVenueView(false);
      setRestaurantotherView(true)
      setVenueView(false);
      setKidsVenueView(false);
    }
    else if (
      !isRestaurantsSelected &&
      !isKidsPlayAreaSelected &&
      hasOtherSelections
    ) {
      setRestaurantsView(false);
      setRestaurantVenueView(false);
      setVenueView(true);
      setKidsVenueView(false);
    } else if (isKidsPlayAreaSelected && !isRestaurantsSelected && isOnlyOneSelection) {
      setRestaurantsView(false);
      setRestaurantVenueView(false);
      setVenueView(false);
      setKidsVenueView(true);
    } else if (
      isKidsPlayAreaSelected &&
      hasOtherSelections &&
      !isRestaurantsSelected
    ) {
      setRestaurantsView(false);
      setRestaurantVenueView(false);
      setRestaurantotherView(false)
      setVenueView(true);
      setKidsVenueView(false);
    }
    else {
      setRestaurantsView(false);
      setRestaurantVenueView(false);
      setVenueView(false);
      setKidsVenueView(false);
    }
  }, [selectedChips]);


  const [errors, setErrors] = useState({});
  useEffect(() => {
    let validationErrors = {};

    if (!entityName) {
      validationErrors.entityName = "Entity name is required.";
    }

    if (partOfGroup === "yes" && !hotelGroupName) {
      validationErrors.hotelGroupName = "Group name is required.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Proceed to the next step
      console.log("Proceeding to the next step...");
    }

    toggleBackButton(true);
    // Other logic...
  }, [activeStep, toggleBackButton, entityName, hotelGroupName, partOfGroup]);

  // To hide the Back Button on the Message View
  useEffect(() => {
    let timeoutId;

    if (activeStep === 5) {
      toggleBackButton(true);
      let data = paymentData;

      if (restaurantsView === true && paymentData.length === 0) {
        data = calculateRestaurantPayment(
          withAlcoholNumber,
          withoutAlcoholNumber
        );
        data = {
          ...data,
          withAlcoholNumber: withAlcoholNumber,
          withoutAlcoholNumber: withoutAlcoholNumber,
        };
        console.log(data)
        setPaymentData(data);
      }

      if (restaurantsVenueView === true && paymentData.length === 0) {
        data = calculateRestaurantVenuePayment(
          withAlcoholNumber,
          withoutAlcoholNumber,
          sameLocation
        );
        console.log(data)
        data = {
          ...data,
          quantityNumber: quantityNumber,
        };
        setPaymentData(data);
      }

      if (venueView === true && paymentData.length === 0) {
        data = calculateVenuePayment(quantityNumber, sameLocation, selectedChips, items);
        console.log(data);
        setPaymentData(data);
      }

      if (kidsVenueView === true && paymentData.length === 0) {
        data = calculateKidsPayment(quantityNumber, sameLocation, items);
        console.log(data);
        if (!restaurantsVenueView) { setPaymentData(data) }
      }

      if (restaurantsVenueView === true && kidsVenueView === true) {
        const restaurantPayment = calculateRestaurantVenuePayment(withAlcoholNumber, withoutAlcoholNumber, sameLocation, items);
        const kidsPayment = calculateKidsPayment(quantityNumber, sameLocation, items);
        const combinedPayment = {
          ...restaurantPayment,
          grandTotal: restaurantPayment.grandTotal + kidsPayment.grandTotal,
          subTotal: restaurantPayment.subTotal + kidsPayment.subTotal,
          vat: restaurantPayment.vat + kidsPayment.vat,
          quantityNumber: 1
        };
        console.log(combinedPayment);
        setTotalPaymentData(combinedPayment);
      }

      if (restaurantsOtherView === true) {
        const restaurantPayment = calculateRestaurantVenuePayment(withAlcoholNumber, withoutAlcoholNumber, sameLocation, items);
        const kidsPayment = calculateVenuePayment(quantityNumber, sameLocation, items);
        const combinedPayment = {
          ...restaurantPayment,
          grandTotal: restaurantPayment.grandTotal + kidsPayment.grandTotal,
          subTotal: restaurantPayment.subTotal + kidsPayment.subTotal,
          vat: restaurantPayment.vat + kidsPayment.vat,
          quantityNumber: 1

        };
        console.log(combinedPayment);
        setTotalPaymentData(combinedPayment);
      }

      if (restaurantsVenueView === true) {
        const restaurantPayment = calculateRestaurantVenuePayment(withAlcoholNumber, withoutAlcoholNumber, sameLocation, items);
        const kidsPayment = calculateVenuePayment(quantityNumber, sameLocation, items);
        const combinedPayment = {
          ...restaurantPayment,
          grandTotal: restaurantPayment.grandTotal + kidsPayment.grandTotal,
          subTotal: restaurantPayment.subTotal + kidsPayment.subTotal,
          vat: restaurantPayment.vat + kidsPayment.vat,
          quantityNumber: 1

        };
        console.log(combinedPayment);
        setTotalPaymentData(combinedPayment);
      }

      // Delay the continue action by 3 seconds
      timeoutId = setTimeout(() => {
        handleContinue();
      }, 3000);
    } else {
      toggleBackButton(true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeStep, toggleBackButton, handleContinue]);

  // Basically the logic is to redirect the user to the type of venue screen by reloading, when the user clicks the backbutton on the active step 6 which is plan screen.
  // useEffect(() => {
  //   if (activeStep == 6 && isBackButtonClicked) {
  //     window.location.reload();
  //     resetBackButtonClicked();
  //   }
  // }, [activeStep, isBackButtonClicked]);

  const toggleAdditionalStep = () => {
    setShowAdditionalStep(!showAdditionalStep);
  };
  const handleContinueWithPayment = (shouldHandlePayment) => {
    if (shouldHandlePayment) {
      handlePayment();
    } else {
      handleContinue();
    }
  };

  return (
    <>
      {activeStep === 2 && (
        <Box sx={{ width: { xs: "100", md: "70%" } }}>
          <TitleDescriptionCoupon
            title={"Tell us more about your venue"}
            description={"We found this plan to be best suited for your needs"}
            isCoupon={true}
          />
          <MultiChipSelector
            onSelectedChipsChange={handleSelectedChipsChange}
          />
        </Box>
      )}
      {activeStep === 3 && (
        <Box sx={{ width: { xs: "100", md: "70%" } }}>
          <TitleDescriptionCoupon
            title={"Tell us more about your venue"}
            description={"We found this plan to be best suited for your needs"}
            isCoupon={true}
          />
          {/* Remove Same Location Radio Group if Only Restaurant is selected */}
          {/* Display Restaurant View only if Restaurants View or Restaurants Venue View */}
          {(restaurantsView || restaurantsVenueView || restaurantsOtherView) && (
            <RestaurantCard
              isSameLocation={restaurantsView ? true : false}
              sameLocation={restaurantsView ? sameLocation : null}
              handleSameLocationChange={
                restaurantsView ? handleSameLocationChange : null
              }
              description={"Cafes, bars, restaurants and food places."}
              withAlcoholNumber={withAlcoholNumber}
              withoutAlcoholNumber={withoutAlcoholNumber}
              handleWithAlcoholNumberChange={handleWithAlcoholNumberChange}
              handleWithoutAlcoholNumberChange={
                handleWithoutAlcoholNumberChange
              }
            />
          )}

          {/* Display Venue Card for Restaurant & Venue View */}
          {(restaurantsVenueView || venueView || kidsVenueView || restaurantsOtherView) && (
            <Questions items={items} setItems={setItems} />
            // <VenueCard
            //   isLocation={true}
            //   labelText={"Are your venues at the same location?"}
            //   sameLocation={sameLocation}
            //   handleSameLocationChange={handleSameLocationChange}
            //   isOtherText={"true"}
            //   otherText={
            //     "Choose the total no. of venue available in your property"
            //   }
            //   toolTipText={
            //     "A multi-venue is a complex or facility that houses multiple event spaces within the same location, excluding any areas designated for restaurant services."
            //   }
            //   quantityNumber={quantityNumber}
            //   handleQuantityNumberChange={handleQuantityNumberChange}
            //   title={kidsVenueView ? "Unqiue Venue Branches" : "Venues"}
            //   description={
            //     kidsVenueView ? "Kid's Area" : "Ballroom, Dance Studio +4"
            //   }
            // />
          )}
        </Box>
      )}
      {activeStep === 4 && (
        <Box sx={{ width: { xs: "100", md: "70%" } }}>
          <TitleDescriptionCoupon
            title={"Tell us more about your venue"}
            description={"We found this plan to be best suited for your needs"}
            isCoupon={true}
          />
          {/* {showAdditionalStep && (
            <Card
              style={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                backgroundColor: "white",
                width: { xs: "80%", md: "90%" },
                padding: "2rem",
                marginTop: "2rem",
                border: `2px solid ${theme.palette.grey.border}`,
                borderRadius: "12px",
                boxShadow: "none",
              }}
            >
              <LabelledRadioGroup
                label={"Are the venues part establishment complex or building?"}
                value={venueType}
                handleChange={handleVenueTypeChange}
              />
            </Card>
          )} */}
          {!showAdditionalStep && (
            <>
              <Card
                style={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  backgroundColor: "white",
                  width: { xs: "80%", md: "70%" },
                  padding: "2rem",
                  marginTop: "2rem",
                  border: `2px solid ${theme.palette.grey.border}`,
                  borderRadius: "12px",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "90%",
                  }}
                >
                  <LabelledRadioGroup
                    label={"Are you part of a group?"}
                    value={partOfGroup}
                    handleChange={handlePartOfGroupChange}
                  />
                  {partOfGroup === "yes" && (
                    <TextField
                      required
                      fullWidth
                      id={"groupName"}
                      placeholder={"Enter your group name"}
                      variant="outlined"
                      type={"text"}
                      value={hotelGroupName}
                      onChange={handleHotelGroupNameChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "45px",
                          [theme.breakpoints.up("sm")]: {
                            width: "45%", // Width for sm screens and larger
                          },
                          [theme.breakpoints.up("xs")]: {
                            width: "60%", // Width for sm screens and larger
                          },
                        },
                        sx: {
                          "& .MuiInputBase-input::placeholder": {
                            fontSize: "0.9rem",
                            color: theme.palette.grey.text,
                            opacity: 1,
                          },
                          "& .MuiInputBase-input": {
                            display: "flex",
                            alignItems: "center",
                            padding: "0 15px",
                            height: "100%",
                          },
                        },
                      }}
                      error={!!errors.hotelGroupName}
                      helperText={errors.hotelGroupName}
                    />
                  )}
                </Box>
                {/* Display a textfield here if the value for this is yes. */}
              </Card>

              <Card
                style={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  backgroundColor: "white",
                  width: { xs: "80%", md: "70%" },
                  padding: "2rem",
                  marginTop: "2rem",
                  border: `2px solid ${theme.palette.grey.border}`,
                  borderRadius: "12px",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "90%",
                    gap: 3
                  }}
                >
                  <label className="text-black font-medium ">
                    Name of Entity/Establishment/Hotel
                  </label>
                  <TextField
                    required
                    fullWidth
                    id="entityName"
                    placeholder="Name of Entity/Establishment/Hotel"
                    variant="outlined"
                    type="text"
                    value={entityName}
                    onChange={(e) => setEntityName(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      sx: {
                        borderRadius: '10px',
                        height: '45px',
                        width: {
                          xs: '60%',  // Width for xs screens
                          sm: '45%',  // Width for sm screens and larger
                        },
                        '& .MuiInputBase-input': {
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0 15px',
                          height: '100%',
                          fontSize: '0.9rem',
                          color: theme.palette.black.text,
                        },
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '0.9rem',
                          color: theme.palette.grey.text,
                          opacity: 1,
                        },
                      },
                    }} error={!!errors.entityName}
                    helperText={errors.entityName}
                  />
                </Box>
                {/* Display a textfield here if the value for this is yes. */}
              </Card>
            </>
          )}
        </Box>
      )}
      {activeStep === 5 && (
        <MessageCard
          loaderComponent={<CircularProgressLoader color={"red"} />}
          primaryText={"Creating a plan suitable to your venue. Please wait a moment"}
          secondaryText={"Please wait for a moment..."}
        />
      )}

      {activeStep === 6 && restaurantsView && (
        <PaymentRestaurantView
          paymentData={paymentData}
          handleContinue={handleContinue}
        />
      )}
      {activeStep === 6 && restaurantsVenueView && kidsVenueView && (
        <PaymentRestaurantVenueView
          paymentData={totalPaymentData}
          handleContinue={handleContinue}
          sameLocation={sameLocation}
          items={items}
          setItems={setItems}
          kidsVenueView={kidsVenueView}
        />
      )}
      {activeStep === 6 && restaurantsOtherView && (
        <PaymentRestaurantVenueView
          paymentData={totalPaymentData}
          handleContinue={handleContinue}
          sameLocation={sameLocation}
          items={items}
          setItems={setItems}
          kidsVenueView={kidsVenueView}

        />
      )}
      {/* {activeStep === 6 && restaurantsVenueView && (
        <PaymentRestaurantVenueView
          paymentData={totalPaymentData}
          handleContinue={handleContinue}
          sameLocation={sameLocation}
          items={items}
          setItems={setItems}
          kidsVenueView={kidsVenueView}
        />
      )} */}
      {activeStep === 6 && venueView && (
        <PaymentVenueView
          paymentData={paymentData}
          handleContinue={handleContinue}
          sameLocation={sameLocation}
          items={items}
          setItems={setItems}
        />
      )}
      {activeStep === 6 && kidsVenueView && !restaurantsVenueView && (
        <PaymentKidsView
          paymentData={paymentData}
          handleContinue={handleContinue}
          sameLocation={sameLocation}
          items={items}
          setItems={setItems}
        />
      )}
      {/* 7 - Billing */}

      {activeStep === 7 && (
        <BillingForm
          handleContinue={handleContinueWithPayment}
          shouldHandlePayment={true}
        />
      )}

      {/* 8 - Payment Message Card */}
      {activeStep === 8 && <>{/* Redirect logic here */}</>}

      {activeStep < 5 && (
        <Box sx={{ width: "70%", mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              textTransform: "none",
              mt: "1rem",
              boxShadow: "none",
              width: { xs: "100%", md: "70%" },
            }}
            onClick={() => {
              if (activeStep === 4 && showAdditionalStep) {
                toggleAdditionalStep();
              } else {
                handleContinueForNextProcess()
              }
            }}
            disabled={selectedChips.length === 0} // Disable button if no chips are selected

          >
            Continue
          </Button>
        </Box>
      )}
    </>
  );
}

export default MultipleFlow;
