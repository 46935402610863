import React, { useContext, useEffect } from "react";
import { FaMusic, FaHeadphones, FaMicrophone, FaUser, FaClock } from "react-icons/fa";
import ToggleSwitch from "../shared/common/custom-toggle";
import { VenueContext } from "../../context/VenueContext";
import { validateAudioForm } from "./validation";

const AudioForm = () => {
  const { formData, setFormData } = useContext(VenueContext);

  const audio = formData?.audio || {
    indoorMusicAllowed: false,
    indoorMusicAllowedTime: "",
    outdoorMusicAllowed: false,
    outdoorMusicAllowedTime: "",
    paSystemSpeakers: false,
    clientsCanPlayOwnMusic: false,
    clientsCanBringOwnDJ: false,
    spaceHasNoiseRestrictions: false,
    errors: {},
  };

  const {
    indoorMusicAllowed,
    indoorMusicAllowedTime,
    outdoorMusicAllowed,
    outdoorMusicAllowedTime,
    paSystemSpeakers,
    clientsCanPlayOwnMusic,
    clientsCanBringOwnDJ,
    spaceHasNoiseRestrictions,
  } = audio;

  const handleToggleChange = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      audio: {
        ...prevData.audio,
        [field]: !prevData.audio?.[field],
      },
    }));
  };

  const handleTimeChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      audio: {
        ...prevData.audio,
        [field]: value,
      },
    }));
  };

  const sections = [
    {
      name: "Indoor music allowed",
      subtitle: "Space has indoor music restrictions",
      icon: <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_843_44572)">
      <path d="M5.8125 19.5081C4.26163 19.5081 2.99988 20.7698 2.99988 22.3207C2.99988 23.8717 4.26163 25.1334 5.8125 25.1334C7.36337 25.1334 8.62512 23.8716 8.62512 22.3207C8.62512 20.7699 7.36337 19.5081 5.8125 19.5081ZM5.8125 23.2584C5.2955 23.2584 4.87488 22.8378 4.87488 22.3207C4.87488 21.8037 5.2955 21.3831 5.8125 21.3831C6.3295 21.3831 6.75012 21.8037 6.75012 22.3207C6.75012 22.8378 6.3295 23.2584 5.8125 23.2584Z" fill="#222222"/>
      <path d="M20.375 27.0311H19.1344L16.875 23.9934V18.9065H17.5938V17.0315H16.875V15.5313H15V17.0315H14.1979V18.9065H15V23.9934L12.7406 27.0311H11.625V11.5939H0V32.4688H32V11.5939H20.375V27.0311ZM15.9375 25.8746L16.7976 27.0311H15.0774L15.9375 25.8746ZM1.875 13.4689H9.75V27.0311H1.875V13.4689ZM30.125 30.5938H1.875V28.9061H30.125V30.5938ZM22.25 13.4689H30.125V27.0311H22.25V13.4689Z" fill="#222222"/>
      <path d="M26.1875 19.5081C24.6366 19.5081 23.3749 20.7698 23.3749 22.3207C23.3749 23.8717 24.6366 25.1334 26.1875 25.1334C27.7384 25.1334 29.0001 23.8716 29.0001 22.3207C29.0001 20.7699 27.7384 19.5081 26.1875 19.5081ZM26.1875 23.2584C25.6705 23.2584 25.2499 22.8378 25.2499 22.3207C25.2499 21.8037 25.6705 21.3831 26.1875 21.3831C26.7045 21.3831 27.1251 21.8037 27.1251 22.3207C27.1251 22.8378 26.7045 23.2584 26.1875 23.2584Z" fill="#222222"/>
      <path d="M0 0.53125V2.40625H8.34831L6.81875 4.73319L11.467 7.7885L13.1146 5.282V8.03131H18.6771V5.28206L20.3246 7.78856L24.9729 4.73325L23.4434 2.40625H32V0.53125H0ZM11.8055 3.86006L10.9301 5.19187L9.41556 4.19625L10.291 2.86444C10.4239 2.66219 10.6277 2.52381 10.8647 2.47481C11.1018 2.42562 11.3438 2.472 11.546 2.60494C11.9636 2.87944 12.08 3.44244 11.8055 3.86006ZM16.8021 6.15631H14.9896V3.93756C14.9896 3.43788 15.3962 3.03131 15.8959 3.03131C16.3956 3.03131 16.8021 3.43788 16.8021 3.93756V6.15631ZM20.8616 5.19187L19.9862 3.86006C19.7118 3.44244 19.8282 2.87944 20.2458 2.60494C20.3954 2.50663 20.5667 2.45569 20.7417 2.45569C20.8032 2.45569 20.8653 2.462 20.927 2.47481C21.1641 2.52381 21.3679 2.66219 21.5008 2.8645L22.3762 4.19631L20.8616 5.19187Z" fill="#222222"/>
      <path d="M25.25 15.375H27.125V17.3022H25.25V15.375Z" fill="#222222"/>
      <path d="M4.875 15.375H6.75V17.3022H4.875V15.375Z" fill="#222222"/>
      </g>
      <defs>
      <clipPath id="clip0_843_44572">
      <rect width="32" height="32" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
      </svg>
      ,
      toggleState: indoorMusicAllowed,
      toggleField: "indoorMusicAllowed",
      timeField: "indoorMusicAllowedTime",
    },
    {
      name: "Outdoor music allowed",
      subtitle: "Space has outdoor music restrictions",
      icon: <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_843_44584)">
      <path d="M30.7888 19.3157C30.9886 18.7697 31.0926 18.1886 31.0926 17.6055C31.0926 15.1869 29.3551 13.1676 27.0629 12.7274V6.52599H28.2847V4.64868H23.9639V6.52599H25.1856V12.7274C23.5719 13.0373 22.2341 14.13 21.583 15.5939C20.991 14.9841 20.3059 14.4784 19.5552 14.0948V11.3687H20.9633V9.49144H11.0761V11.3687H12.4841V14.0994C9.97832 15.3874 8.26015 17.9996 8.26015 21.0056C8.26015 22.9677 8.99255 24.7618 10.1979 26.1296L8.6199 28.7651H10.7563L11.6313 27.4013C12.8803 28.261 14.3921 28.7651 16.0197 28.7651C18.0815 28.7651 20.0509 27.9362 21.494 26.5025C22.5704 27.8788 24.2457 28.7651 26.1243 28.7651C29.3642 28.7651 32.0001 26.1293 32.0001 22.8894C32 21.5847 31.5738 20.3374 30.7888 19.3157ZM14.3614 11.3687H17.678V13.4244C17.1402 13.3076 16.5851 13.2461 16.0197 13.2461C15.4506 13.2461 14.8959 13.3082 14.3614 13.425V11.3687ZM16.0197 26.8878C12.7762 26.8878 10.1375 24.2491 10.1375 21.0056C10.1375 17.7621 12.7762 15.1234 16.0197 15.1234C18.1883 15.1234 20.1618 16.3025 21.1936 18.2062C21.24 18.5849 21.3289 18.9579 21.4599 19.3157C20.6749 20.3374 20.2488 21.5847 20.2488 22.8894C20.2488 23.541 20.3556 24.168 20.5522 24.7542C19.439 26.0967 17.7732 26.8878 16.0197 26.8878ZM26.1243 26.8878C23.9197 26.8878 22.126 25.0942 22.126 22.8894C22.126 21.8549 22.5196 20.8736 23.2344 20.1264L23.7012 19.6384L23.3869 19.0407C23.1523 18.5945 23.0334 18.1116 23.0334 17.6055C23.0334 16.2283 23.9389 15.059 25.1858 14.6606V21.8514H27.0631V14.6607C28.3098 15.059 29.2155 16.2283 29.2155 17.6055C29.2155 18.1117 29.0965 18.5946 28.8619 19.0408L28.5477 19.6385L29.0144 20.1264C29.7291 20.8737 30.1228 21.8551 30.1228 22.8895C30.1227 25.0941 28.3291 26.8878 26.1243 26.8878Z" fill="#222222"/>
      <path d="M16.0197 17.9393C14.3289 17.9393 12.9534 19.3148 12.9534 21.0056C12.9534 22.6964 14.3289 24.0719 16.0197 24.0719C17.7104 24.0719 19.0859 22.6964 19.0859 21.0056C19.0859 19.3148 17.7104 17.9393 16.0197 17.9393ZM16.0197 22.1945C15.3641 22.1945 14.8307 21.6612 14.8307 21.0056C14.8307 20.35 15.3641 19.8166 16.0197 19.8166C16.6753 19.8166 17.2086 20.35 17.2086 21.0056C17.2086 21.6612 16.6753 22.1945 16.0197 22.1945Z" fill="#222222"/>
      <path d="M5.24857 14.3724H8.00196V8.74052H5.24857V6.11229H8.00196V4.23499H0.617884V6.11229H3.37127V8.74052H0.617884V14.3724H3.37127V22.8833L0 28.7651H2.16378L4.30992 25.0208L6.45606 28.7651L8.6199 28.7651L5.24857 22.8833V14.3724ZM1.87731 10.6178H5.50676V12.4951H1.87731V10.6178Z" fill="#222222"/>
      </g>
      <defs>
      <clipPath id="clip0_843_44584">
      <rect width="32" height="32" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
      </svg>
      ,
      toggleState: outdoorMusicAllowed,
      toggleField: "outdoorMusicAllowed",
      timeField: "outdoorMusicAllowedTime",
    },
    {
      name: "PA system / Speakers",
      subtitle: "",
      icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_843_44596)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78636 31.0563H7.66136V1.87122H24.3376V31.0563H26.2126V-0.00378418H5.78636V31.0563Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4725 30.1188H4.52696V31.9938H27.4725V30.1188Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4116 31.0658V25.2616C11.4116 22.7278 13.4656 20.6737 15.9995 20.6737C18.5333 20.6737 20.5874 22.7278 20.5874 25.2616V31.0658H22.4624V25.2616C22.4624 21.6923 19.5688 18.7987 15.9995 18.7987C12.4301 18.7987 9.53657 21.6923 9.53657 25.2616V31.0658H11.4116Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0991 25.2616C13.0991 26.8635 14.3976 28.162 15.9995 28.162C17.6013 28.162 18.8998 26.8635 18.8998 25.2616C18.8998 23.6598 17.6013 22.3613 15.9995 22.3613C14.3976 22.3613 13.0991 23.6598 13.0991 25.2616ZM15.9995 26.287C15.4332 26.287 14.9741 25.8279 14.9741 25.2616C14.9741 24.6953 15.4332 24.2363 15.9995 24.2363C16.5658 24.2363 17.0248 24.6953 17.0248 25.2616C17.0248 25.8279 16.5658 26.287 15.9995 26.287Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52097 10.3454C9.52097 13.9233 12.4215 16.8239 15.9995 16.8239C19.5774 16.8239 22.478 13.9233 22.478 10.3454C22.478 6.76743 19.5774 3.86688 15.9995 3.86688C12.4215 3.86688 9.52097 6.76743 9.52097 10.3454ZM15.9995 14.9489C13.457 14.9489 11.396 12.8878 11.396 10.3454C11.396 7.80296 13.457 5.74188 15.9995 5.74188C18.5419 5.74188 20.603 7.80296 20.603 10.3454C20.603 12.8878 18.5419 14.9489 15.9995 14.9489Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2221 10.3454C13.2221 11.8792 14.4655 13.1228 15.9995 13.1228C17.5334 13.1228 18.7769 11.8793 18.7769 10.3454C18.7769 8.81151 17.5334 7.56795 15.9995 7.56795C14.4655 7.56795 13.2221 8.81153 13.2221 10.3454ZM15.9995 11.2478C15.5011 11.2478 15.0971 10.8438 15.0971 10.3454C15.0971 9.84698 15.5011 9.44295 15.9995 9.44295C16.4979 9.44295 16.9019 9.847 16.9019 10.3454C16.9019 10.8438 16.4979 11.2478 15.9995 11.2478Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M31.9995 15.0575H29.2543V16.9325H31.9995V15.0575Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4708 10.8276L27.5296 12.7687L28.8555 14.0946L30.7966 12.1534L29.4708 10.8276Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7966 19.8366L28.8555 17.8955L27.5296 19.2213L29.4708 21.1624L30.7966 19.8366Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000488281 16.9325H2.74464V15.0575H-0.000488281V16.9325Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.20239 12.1534L3.14351 14.0946L4.46934 12.7687L2.52821 10.8276L1.20239 12.1534Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52821 21.1624L4.46934 19.2213L3.14351 17.8955L1.20239 19.8366L2.52821 21.1624Z" fill="#222222"/>
      </g>
      <defs>
      <clipPath id="clip0_843_44596">
      <rect width="32" height="32" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      ,
      toggleState: paSystemSpeakers,
      toggleField: "paSystemSpeakers",
    },
    {
      name: "Clients can play their own music",
      subtitle: "Customers can connect their devices to the in-house speakers",
      icon: <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9922 15.3586L14.9889 13.3619L16.3201 14.693L14.3234 16.6897L12.9922 15.3586Z" fill="#222222"/>
      <path d="M25.044 23.4132L23.4719 21.8412C22.1366 20.5058 19.9637 20.5057 18.628 21.8412L10.6889 29.7803C9.58511 30.8841 7.79871 30.8841 6.6949 29.7803L5.23711 28.3225C4.56583 27.6513 4.54261 26.5971 5.12854 25.8979C6.17562 26.3931 7.46784 26.2108 8.33537 25.3433L9.11033 24.5683C10.1696 25.0925 11.4591 24.9388 12.3642 24.1404L22.0124 15.6272C23.8339 15.5477 25.5834 14.7521 26.9741 13.3613C29.9105 10.4249 29.9105 5.64707 26.9741 2.7106C24.0377 -0.2258 19.2598 -0.2258 16.3234 2.7106C14.9326 4.10155 14.1364 5.8511 14.0571 7.67276L5.54338 17.3216C4.73478 18.2381 4.59922 19.5317 5.11586 20.5749L4.34134 21.3494C3.47256 22.2183 3.28987 23.5168 3.79213 24.5671C2.47022 26.01 2.50399 28.2521 3.90585 29.654L5.36357 31.1118C7.20306 32.9513 10.1806 32.9516 12.0202 31.1118L19.9594 23.1725C20.5607 22.5711 21.5391 22.5711 22.1406 23.1725L23.7126 24.7445L25.044 23.4132ZM7.00411 24.012C6.63703 24.379 6.03981 24.379 5.67273 24.012C5.30565 23.6449 5.30565 23.0477 5.67273 22.6806L6.33842 22.015L7.66974 23.3464L7.00411 24.012ZM24.9276 12.6462C24.0504 13.2966 23.0531 13.6761 22.0288 13.7414L15.9434 7.65594C16.0087 6.63177 16.3882 5.63439 17.0386 4.75713L24.9276 12.6462ZM25.6427 4.04199C27.6182 6.01741 27.8203 9.10344 26.2519 11.3078L18.3769 3.43284C20.5813 1.86441 23.6672 2.06656 25.6427 4.04199ZM6.95516 18.5673L15.035 9.41021L20.2745 14.6497L11.1185 22.7286C10.7003 23.0977 10.0638 23.0778 9.66946 22.6834L7.00035 20.0143C6.60659 19.6205 6.5867 18.985 6.95516 18.5673Z" fill="#222222"/>
      <path d="M5.64838 9.84093V5.41373L8.04473 6.04434L8.5239 4.22355L3.76558 2.97137V7.17866C1.93068 6.52784 0 7.8977 0 9.84093C0 11.3982 1.26693 12.6651 2.82419 12.6651C4.38145 12.6651 5.64838 11.3982 5.64838 9.84093ZM2.82419 10.7823C2.3051 10.7823 1.88279 10.36 1.88279 9.84093C1.88279 9.32184 2.3051 8.89953 2.82419 8.89953C3.34327 8.89953 3.76558 9.32184 3.76558 9.84093C3.76558 10.36 3.34327 10.7823 2.82419 10.7823Z" fill="#222222"/>
      <path d="M27.3005 21.7937V26.948C25.4656 26.2972 23.5349 27.667 23.5349 29.6102C23.5349 31.1676 24.8018 32.4344 26.3591 32.4344C27.9163 32.4344 29.1833 31.1676 29.1833 29.6102V24.2472L31.5129 24.8712L32 23.0525L27.3005 21.7937ZM26.3591 30.5516C25.8399 30.5516 25.4177 30.1294 25.4177 29.6102C25.4177 29.0912 25.8399 28.6688 26.3591 28.6688C26.8782 28.6688 27.3005 29.0912 27.3005 29.6102C27.3005 30.1293 26.8782 30.5516 26.3591 30.5516Z" fill="#222222"/>
      </svg>
      ,
      toggleState: clientsCanPlayOwnMusic,
      toggleField: "clientsCanPlayOwnMusic",
    },
    {
      name: "Clients can bring their own DJ",
      subtitle: "Customers can invite their own DJ with equipment",
      icon: <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 26.8529H32V32.5H0V26.8529ZM1.88235 28.7353V30.6176H30.1176V28.7353H1.88235Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3529 5.20588C10.3529 2.6069 12.4598 0.5 15.0588 0.5H16.9412C19.5401 0.5 21.647 2.6069 21.647 5.20588V10.8529C21.647 13.9859 19.0957 16.5231 15.9575 16.4999M15.0588 2.38235C13.4994 2.38235 12.2353 3.6465 12.2353 5.20588V10.7939C12.2353 12.8872 13.909 14.6023 15.9713 14.6175C18.0638 14.6331 19.7647 12.9417 19.7647 10.8529V5.20588C19.7647 3.6465 18.5005 2.38235 16.9412 2.38235H15.0588Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7059 8.97059H11.2941V7.08824H20.7059V8.97059Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.4118 8.97059H20.7059V7.08824H25.4118V8.97059Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6183 20.0598C10.6182 20.0599 10.6184 20.0598 10.6183 20.0598L9.11216 20.3686C7.62793 20.7483 6.58821 22.0863 6.58821 23.6203V26.8529H25.4117V23.6203C25.4117 22.0863 24.372 20.7483 22.8878 20.3686L21.3816 20.0598C21.3816 20.0598 21.3817 20.0599 21.3816 20.0598C19.0093 19.5739 17.2523 17.6194 16.9788 15.2613L18.8486 15.0444C19.0294 16.6028 20.1913 17.8946 21.7593 18.2158L23.3064 18.5329L23.3259 18.5378C25.6581 19.1207 27.2941 21.2165 27.2941 23.6203V28.7353H4.70586V23.6203C4.70586 21.2164 6.34183 19.1207 8.67405 18.5378L8.69355 18.5329L10.2404 18.2158C11.8084 17.8947 12.9706 16.6028 13.1513 15.0444L15.0212 15.2613C14.7476 17.6194 12.9906 19.5739 10.6183 20.0598Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0542 18.5911L13.3852 17.2601L16 19.8749L18.6148 17.2601L19.9458 18.5911L16 22.5369L12.0542 18.5911Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0588 27.7941V21.2059H16.9412V27.7941H15.0588Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.82353 10.8529C2.30373 10.8529 1.88235 11.2743 1.88235 11.7941C1.88235 12.3139 2.30373 12.7353 2.82353 12.7353C3.34333 12.7353 3.76471 12.3139 3.76471 11.7941C3.76471 11.2743 3.34333 10.8529 2.82353 10.8529ZM0 11.7941C0 10.2347 1.26414 8.97059 2.82353 8.97059C4.38292 8.97059 5.64706 10.2347 5.64706 11.7941C5.64706 13.3535 4.38292 14.6176 2.82353 14.6176C1.26414 14.6176 0 13.3535 0 11.7941Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76471 4.84125L7.82704 6.19536L7.23179 7.98111L5.64706 7.45287V11.7941H3.76471L3.76471 4.84125Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3529 24.9706V27.7941H8.47057V24.9706H10.3529Z" fill="#222222"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5294 24.9706V27.7941H21.6471V24.9706H23.5294Z" fill="#222222"/>
      </svg>
      ,
      toggleState: clientsCanBringOwnDJ,
      toggleField: "clientsCanBringOwnDJ",
    },
    {
      name: "Space has noise restrictions",
      subtitle: "Loud noise permitted until designated hours",
      icon: <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.85519 19.482H5.75031V21.357H3.85519V19.482Z" fill="#222222"/>
<path d="M7.53438 19.482H9.42956V21.357H7.53438V19.482Z" fill="#222222"/>
<path d="M0 15.5168V28.7097H3.86525V32.5H28.1348V28.7096H32V15.5168H0ZM26.2598 30.625H5.74025V25.4948H7.51337V28.405H9.38838V25.4948H11.3038V28.405H13.1788V25.4948H15.0941V28.405H16.9691V25.4948H18.8845V28.405H20.7595V25.4948H22.6749V28.405H24.5499V25.4948H26.2598L26.2598 30.625ZM30.125 26.8346H28.1348V23.6197H3.86525V26.8346H1.875V17.3918H30.125V26.8346Z" fill="#222222"/>
<path d="M19.1964 0.5C17.9159 0.5 16.7701 1.08713 16.0138 2.00619C15.2574 1.08713 14.1116 0.5 12.8312 0.5H5V13.5522H27V0.5H19.1964ZM19.1964 2.375H25.125V7.83712H19.1964C18.3691 7.83712 17.5981 8.08213 16.9519 8.5035V4.62069C16.9519 4.61263 16.9513 4.60469 16.9513 4.59662C16.9643 3.36944 17.9661 2.375 19.1964 2.375ZM6.875 2.375H12.8312C14.0614 2.375 15.0633 3.36944 15.0763 4.59662C15.0762 4.60469 15.0756 4.61263 15.0756 4.62069V8.50344C14.4294 8.08212 13.6584 7.83712 12.8312 7.83712H6.875V2.375ZM6.875 9.71212H12.8312C13.9744 9.71212 14.9201 10.571 15.0587 11.6772H6.875V9.71212ZM25.125 11.6772H16.9688C17.1074 10.5709 18.0531 9.71212 19.1963 9.71212H25.125V11.6772Z" fill="#222222"/>
</svg>
,
      toggleState: spaceHasNoiseRestrictions,
      toggleField: "spaceHasNoiseRestrictions",
    },
  ];
  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      const hour = i % 12 || 12;
      const ampm = i < 12 ? "AM" : "PM";
      options.push(
        <option key={`${i}:00`} value={`${String(i).padStart(2, '0')}:00`}>
          {`${hour}:00 ${ampm}`}
        </option>
      );
      options.push(
        <option key={`${i}:30`} value={`${String(i).padStart(2, '0')}:30`}>
          {`${hour}:30 ${ampm}`}
        </option>
      );
    }
    return options;
  };
  
  return (
    <div className="p-2 mx-auto">
  <h1 className="lg:text-3xl text-2xl font-bold mb-6">Audio</h1>

  {sections?.map((item, i) => (
    <div className="mb-6 w-full flex flex-wrap gap-3 items-start" key={i}>
      <div className="flex items-center justify-between mb-2 lg:w-3/5 w-full">
        <div className="flex items-start gap-5">
          {item.icon}
          <div>
            <span className="text-lg font-medium">{item.name}</span>
            <p className="text-secondary">{item.subtitle}</p>
          </div>
        </div>
        <ToggleSwitch
          isOn={item.toggleState}
          handleToggle={() => handleToggleChange(item.toggleField)}
        />
      </div>

      {item.toggleState && item.timeField && (
        <div className="mt-2 lg:w-1/4 w-full">
          <div className="select-container relative">
            <select
              name={item.timeField}
              value={audio[item.timeField] || ""}
              onChange={(e) => handleTimeChange(item.timeField, e.target.value)}
              className="border rounded-lg p-2 w-full appearance-none"
            >
              <option value="" disabled>
                Select Time
              </option>
              {generateTimeOptions()}
            </select>
            <div className="custom-arrow absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none">
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.174019 0.688203L0.17402 0.688202C0.281236 0.571237 0.42254 0.5125 0.599191 0.5125H9.40081C9.57742 0.5125 9.71871 0.571783 9.82594 0.689882C9.93375 0.808622 9.9875 0.946799 9.9875 1.10496C9.9875 1.11275 9.9843 1.12731 9.97655 1.14984C9.96895 1.17191 9.95748 1.20032 9.94204 1.23517C9.91131 1.3045 9.8652 1.39859 9.80364 1.51753L5.62731 5.69385C5.53216 5.78901 5.43416 5.85811 5.33338 5.90156C5.23256 5.94502 5.12149 5.96684 5 5.96684C4.87851 5.96684 4.76744 5.94502 4.66662 5.90156C4.56584 5.85811 4.46785 5.789 4.37269 5.69382L0.194725 1.51589C0.138084 1.45925 0.0935546 1.39609 0.0610483 1.3264C0.0287014 1.25702 0.0125 1.18268 0.0125 1.1032C0.0125 0.94387 0.0662612 0.805735 0.174019 0.688203Z"
                  fill="#222222"
                  stroke="#222222"
                  strokeWidth="0.025"
                />
              </svg>
            </div>
          </div>

          {/* Show validation error message under the relevant time input */}
          {formData.audio.errors?.[item.timeField] && (
            <p className="text-red-500 pt-1 w-full whitespace-nowrap">{formData.audio.errors[item.timeField]}</p>
          )}
        </div>
      )}
    </div>
  ))}
</div>

  );
};

export default AudioForm;
