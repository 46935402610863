import React, { useContext } from 'react';
import ToggleSwitch from '../shared/common/custom-toggle';
import { VenueContext } from '../../context/VenueContext';
import p1 from '../../assets/icons/venuPre1.svg';
import p2 from '../../assets/icons/venuPre2.svg';
import p3 from '../../assets/icons/venuPre3.svg';
import p4 from '../../assets/icons/venuPre4.svg';
import p5 from '../../assets/icons/venuPre5.svg';
import p6 from '../../assets/icons/venuPre6.svg';
import p7 from '../../assets/icons/venuPre7.svg';

const data = [
	{
		name: 'dining',
		icon: <img src={p3} className='w-8 h-8' alt='dinning' />,
	},
	{
		name: 'theatre',
		icon: <img src={p4} className='w-8 h-8' alt='theatre' />,
	},
	{
		name: 'boardroom',
		icon: <img src={p5} className='w-8 h-8' alt='boardroom' />,
	},
	{
		name: 'ushaped',
		icon: <img src={p6} className='w-8 h-8' alt='ushaped' />,
	},
	{
		name: 'cabaret',
		icon: <img src={p7} className='w-8 h-8' alt='cabaret' />,
	},
];

const CapacityForm = () => {
	const { formData, setFormData } = useContext(VenueContext);
	const capacity = formData?.capacity || {};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			capacity: {
				...prevData.capacity,
				[name]: value,
			},
		}));
	};

	const handleToggleChange = (layout) => {
		setFormData((prevData) => ({
			...prevData,
			capacity: {
				...prevData.capacity,
				additionalLayout: {
					...prevData.capacity?.additionalLayout,
					[layout]: {
						...prevData.capacity?.additionalLayout?.[layout],
						enabled:
							!prevData.capacity?.additionalLayout?.[layout]?.enabled,
					},
				},
			},
		}));
	};

	const handleLayoutChange = (layout, value) => {
		setFormData((prevData) => ({
			...prevData,
			capacity: {
				...prevData.capacity,
				additionalLayout: {
					...prevData.capacity?.additionalLayout,
					[layout]: {
						...prevData.capacity?.additionalLayout?.[layout],
						value,
					},
				},
			},
		}));
	};

	return (
		<div className='p-4 w-full flex flex-wrap items-center justify-between mx-auto'>
			<h1 className='lg:text-3xl text-2xl font-semibold mb-4 w-full'>
				Capacity
			</h1>

			<div className='mb-4 lg:w-[47%] w-full'>
				<label
					className='block text-sm font-medium mb-2'
					htmlFor='venueSize'
				>
					Venue size
				</label>
				<div className='flex'>
					<input
						type='number'
						id='venueSize'
						name='venueSize'
						value={capacity?.venueSize || ''}
						onChange={handleChange}
						className='p-2 border rounded w-full mr-2'
						placeholder='Enter venue size'
						pattern='\d*'
						inputMode='numeric'
						onKeyPress={(e) => {
							if (!/^[0-9]+$/.test(e.key)) {
								e.preventDefault();
							}
						}}
						min='0'
					/>
				</div>
				{formData?.capacity?.errors?.venueSize && (
					<p className='text-red-500 text-base pt-1'>
						{formData.capacity.errors.venueSize}
					</p>
				)}
			</div>

			<div className='lg:w-[47%] w-full mt-4'>
				<select
					name='unit'
					value={capacity?.unit || ''}
					onChange={handleChange}
					className='p-2 border rounded w-full'
				>
					<option value='Sq. feet'>Sq. feet</option>
					<option value='Sq. meters'>Sq. meters</option>
				</select>
			</div>

			<div className='py-4 w-full '>
				<label className='block text-lg font-medium mb-2'>
					Configure by layout
				</label>
				<div className='flex items-center  mb-4'>
					<div className='w-1/2 flex gap-2 items-center'>
						<img src={p1} className='w-8 h-8' alt='standing' />{' '}
						Standing
					</div>
					<div className='w-1/2'>
						<input
							type='number'
							name='standing'
							value={capacity?.standing || ''}
							onChange={handleChange}
							className='p-2 border rounded w-full'
							placeholder='Number of people'
							pattern='\d*'
							inputMode='numeric'
							onKeyPress={(e) => {
								if (!/^[0-9]+$/.test(e.key)) {
									e.preventDefault();
								}
							}}
							min='0'
						/>
					</div>
				</div>
				{formData?.capacity?.errors?.standing && (
					<p className='text-red-500 text-base pt-1'>
						{formData.capacity.errors.standing}
					</p>
				)}
				<div className='flex items-center mb-4'>
					<div className='w-1/2 flex gap-2 items-center'>
						<img src={p2} className='w-8 h-8' alt='sitting' /> Sitting
					</div>
					<div className='w-1/2'>
						<input
							type='number'
							name='sitting'
							value={capacity?.sitting || ''}
							onChange={handleChange}
							className='p-2 border rounded w-full'
							placeholder='Number of people'
							pattern='\d*'
							inputMode='numeric'
							onKeyPress={(e) => {
								if (!/^[0-9]+$/.test(e.key)) {
									e.preventDefault();
								}
							}}
							min='0'
						/>
					</div>
				</div>
				{formData?.capacity?.errors?.sitting && (
					<p className='text-red-500 text-base pt-1'>
						{formData.capacity.errors.sitting}
					</p>
				)}
			</div>

			<div className='mb-4 w-full space-y-7'>
				<label className='block text-lg font-medium mb-2'>
					Additional Layout
				</label>
				{data?.map((layout) => (
					<div key={layout?.name} className='flex flex-col mb-4'>
						<div className='flex items-center'>
							<div className='w-1/2 flex gap-2 items-center capitalize'>
								{layout?.icon} {layout?.name}
							</div>
							<ToggleSwitch
								isOn={
									capacity?.additionalLayout?.[layout.name]
										?.enabled || false
								}
								handleToggle={() => handleToggleChange(layout.name)}
							/>
							<input
								type='number'
								name={`${layout?.name}.value`}
								value={
									capacity?.additionalLayout?.[layout.name]?.value ||
									''
								}
								onChange={(e) =>
									handleLayoutChange(layout?.name, e.target.value)
								}
								className={`p-2 border ml-2 rounded w-2/5 ${
									capacity?.additionalLayout?.[layout.name]?.enabled
										? 'block'
										: 'hidden'
								}`}
								placeholder='Number of people'
								disabled={
									!capacity?.additionalLayout?.[layout.name]?.enabled
								}
								min='0'
							/>
						</div>
						{/* Error message for additional layout */}
						{formData?.capacity?.errors?.[layout.name] && (
							<p className='text-red-500 text-base pt-1'>
								{formData.capacity.errors[layout.name]}
							</p>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default CapacityForm;
