import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";

const BillingInformationBox = ({ handleFormValidation, trn, setTrn }) => {
  const theme = useTheme();
  const [name, setName] = useState("");


  // Validate form based on the name and TRN fields

  useEffect(() => {
    const isValid = name.trim() !== "";
    handleFormValidation(isValid);
  }, [name, trn, handleFormValidation]);

  // Handler to restrict input to numbers only
  const handleTrnKeyPress = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      setTrn(value);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 4,
        borderRadius: 2,
        border: `2px solid ${theme.palette.grey.border}`,
        width: { md: "100%" },
      }}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          sx={{ fontWeight: "500", textAlign: "left", mb: 2 }}
        >
          Billing Information
        </Typography>
        <Grid container spacing={2.5}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "0.9rem", textAlign: "left" }}
            >
              What name should we use on the invoice?
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter Your Input"
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              error={name.trim() === ""} // Display error if name is empty
              helperText={name.trim() === "" ? "Name is required" : ""}
              sx={{
                mt: 1,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& input": { textAlign: "left" },
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <div className="flex items-center justify-between">
              <Typography
                variant="body1"
                sx={{ fontSize: "0.9rem", textAlign: "left" }}
              >
                TRN No.
              </Typography>

            </div>
            <TextField
              fullWidth
              placeholder="Enter Your Input (Optional)"
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              value={trn}
              onChange={handleTrnKeyPress}
              sx={{
                mt: 1,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& input": { textAlign: "left" },
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BillingInformationBox;
