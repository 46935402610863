import React, { useContext } from "react";
import { HotelContext } from "../../context/HotelContext";
import TextfieldNumber from "../../pages/onboarding/components/TextfieldNumber";

const AccommodationForm = () => {
  const { formData, setFormData } = useContext(HotelContext);
  // Ensure errors is always an object, with a default empty object
  const errors = formData?.accommodation?.errors || {};
  // Ensure accommodations is a number, with a default value of 0
  const accommodations = formData?.accommodation?.accommodations || '';


  const handleInputChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      accommodation: {
        ...prevFormData.accommodation,
        accommodations: Number(value), // Convert value to a number
      },
    }));
  };

  return (
    <div className="p-6 bg-white rounded-lg">
      <h2 className="text-2xl font-semibold text-neutralBlack mb-4">
        Accommodation
      </h2>
      <form className="w-full flex flex-wrap gap-4 justify-between mx-auto">
        <div className="w-full mb-4 border border-offGray rounded-xl flex items-center py-5 justify-between px-5">
          <label className="block text-base text-darkGray mb-2">
            How many Guest Rooms, Suites, Villas, Apartments, Penthouses can
            your Hotel accommodate?
          </label>

          <TextfieldNumber
            value={accommodations}
            onChange={handleInputChange}
          />
        </div>
        {errors.accommodations && (
          <p className="text-red-500 text-base pt-1">{errors.accommodations}</p>
        )}
      </form>
    </div>
  );
};

export default AccommodationForm;
