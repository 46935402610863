import React, { useContext, useState } from 'react';
import {
	MdKeyboardArrowDown,
	MdKeyboardArrowUp,
} from 'react-icons/md';

const OffersAndPackages = ({ data = [] }) => {
	const [expandedIndex, setExpandedIndex] = useState(null);

	const toggleExpand = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};
	const getCurrentDate = () => {
		const today = new Date();
		const year = today.getFullYear();
		const month = (today.getMonth() + 1).toString().padStart(2, '0');
		const day = today.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	const filteredOffers = data?.filter((offer) => {
		const currentDate = getCurrentDate(); // Get the current date dynamically

		// If there is an endDate, compare it to the current date
		if (offer?.endDate) {
			// Ensure endDate is in the same format as currentDate
			const endDate = offer.endDate;
			return endDate >= currentDate; // Include offers where endDate is today or in the future
		}

		// Include offers without an endDate
		return true;
	});

	return (
		<div className='bg-white rounded-lg mb-8 lg:w-3/4 w-full px-5 mx-auto py-10'>
			<h2 className='text-2xl font-bold mb-4'>Offers & Packages</h2>
			{filteredOffers &&
				filteredOffers.map((offer, index) => (
					<div key={index} className='mb-4'>
						<div
							className='cursor-pointer bg-gray-100 p-4 rounded-lg flex justify-between items-center'
							onClick={() => toggleExpand(index)}
						>
							<h3 className='text-xl font-semibold'>
								{offer.title || 'No Title'}
							</h3>
							<span>
								{expandedIndex === index ? (
									<MdKeyboardArrowUp className='text-3xl' />
								) : (
									<MdKeyboardArrowDown className='text-3xl' />
								)}
							</span>
						</div>
						{expandedIndex === index && (
							<div className='mt-4 p-4 border rounded-lg bg-gray-50'>
								{offer.startDate && (
									<div className='mb-2'>
										<strong>From:</strong>{' '}
										{new Date(offer.startDate).toLocaleDateString(
											'en-GB',
											{
												day: '2-digit',
												month: '2-digit',
												year: 'numeric',
											}
										)}
									</div>
								)}
								{offer.endDate && (
									<div className='mb-2'>
										<strong>To:</strong>{' '}
										{new Date(offer.endDate).toLocaleDateString(
											'en-GB',
											{
												day: '2-digit',
												month: '2-digit',
												year: 'numeric',
											}
										)}
									</div>
								)}
								{offer.description && <p>{offer.description}</p>}
							</div>
						)}
					</div>
				))}
		</div>
	);
};

export default OffersAndPackages;
