export const tipsHotel = {
    0: {
        0: "Highlight unique features, describe room types, mention location advantages, and be concise and clear to stand out for a perfect event.",
        1: "Showcase the hotel's achievements with awards, and clarify when it was built and renovated. This helps guests trust the quality and feel confident about their stay.",
        2: "Include both your street name and number for the entire property The floor number is address line 2 where relevant Individual apartment or floor numbers can be shared later. Provide the post/zip code . Correctly spell the street name. Use the physical address of the property, not your office or home address"
    },
    1: "Adding the number of rooms to your venue's profile is an excellent idea to help venue seekers match their requirements for group bookings.",
    2: "It is important to mention the room price to attract the right type of enquiries. Indicating the starting price helps customers understand the cost and encourages them to contact you directly for a personalized quote",
    3: "What should I include in my offers and packages? Consider incorporating seasonal offers, such as summer deals, brunch packages, or wedding packages, to attract a larger clientele.",
    4: {
        0: "Emphasis distinctive room features to captivate and attract potential guests.",
        1: "Emphasis distinctive business features to captivate and attract potential guests.",
        2: "Emphasis distinctive recreational features to captivate and attract potential guests."
    },
    5: "Exterior shot, Entrance, Reception area, Amenities, Past Events in your venue. Please note the individual venue photos within the hotel can be added when creating a listing for that particular venue.",
    6: "Exterior shot, Entrance, Reception area, Amenities, Past Events in your venue. Please note the individual venue photos within the hotel can be added when creating a listing for that particular venue.Consider a promotional video showcasing past venues or highlighting available venue spaces.",
    7: "Preview the card to see how your listing will appear to potential guests.",
};


export const tipsVenue = {
    0: {
        0: "A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.",
        1: "Displaying the type of venue hire available will appeal to ideal venue seekers.",
        2: "",
        3: "",
        4: "Offer accurate location information, comprising your address, neighborhood, and map coordinates, to simplify client searches and filter results effectively.",
    },
    1: "",
    2: "Offer tiered pricing options that account for seasonality and group size, providing flexibility and transparency to guests when planning their accommodation, and consider highlighting seasonal discounts or promotions for group bookings.",
    3: "Incorporating special offers and packages into your venue search results can attract more venue seekers and generate additional inquiries, thereby broadening your reach. Ensure that your offers and packages are appealing to captivate potential clients. Optionally, you can include start and end dates for added clarity.",
    4: "Clearly indicating catering options on the forms helps venue seekers understand the available services upfront, streamlining their decision-making process and increasing the likelihood of inquiries. Including a menu for venue seekers will give them a better idea of the food offerings, increasing the likelihood of them sending an inquiry.",
    5: "Highlight the unique features, outline its location advantages and vividly describe its ambience to entice more clients.",
    6: {
        0: "Adding your Hotel Bio will provide the personalized information unique to your space.",
        1: "Adding your Hotel Bio will provide the personalized information unique to your space.",
    },
    7: "Adding your Hotel Bio will provide the personalized information unique to your space.",
    8: "Post at least 4 photos to have your listing live on our site. Our most booked listings have 12+ photos.",
    9: "Post at least 4 photos to have your listing live on our site. Our most booked listings have 12+ photos.",
    10: "Adding your Hotel Bio will provide the personalized information unique to your space.",
};