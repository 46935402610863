import { Box, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import TextfieldNumber from "./TextfieldNumber";
import LabelledRadioGroup from "./LabelledRadioGroup";
import { toast } from "react-toastify";

const Questions = ({ items, setItems }) => {
  // const [items, setItems] = useState([]);
  const [noOfLocations, setNoOfLocations] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [singleLocationAnswer, setSingleLocationAnswer] = useState("no");
  const handleChange = (e) => {
    const value = e.target?.value;
    if (value > 20) {
      toast.error("Maximum 20 number of locations are allowed");
      return;
    }
    setNoOfLocations(value);
    const newItems = Array.from({ length: value }, (_, index) => ({
      answer: "no",
      amount: 1,
    }));
    setItems(newItems);
    // console.log(value);
  };

  const handleChangeAnswer = (value, index) => {
    const updatedItems = items?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          answer: value,
          amount: value === "yes" ? 1 : 1,
        };
      }
      return item;
    });
    setItems(updatedItems);
  };

  return (
    <Box className="flex flex-col justify-start items-center w-full mt-5">
      <Box className="flex items-center space-x-2">
        <Typography
          variant="body1"
          gutterBottom
          fontWeight="400"
          align="center"
          sx={{
            fontSize: {
              xs: "subtitle2.fontSize",
              md: "body1.fontSize",
            },
          }}
        >
          How many unique locations do you have (You can give 1 to 20 locations)
        </Typography>
        <TextfieldNumber value={noOfLocations || items.length} onChange={handleChange} />
      </Box>
      {noOfLocations === 1 ? (
        <Box className="flex items-center space-x-2">
          <LabelledRadioGroup
            label={
              "Would you like to list more than one venue within the same location?"
            }
            value={singleLocationAnswer}
            handleChange={(e) => setSingleLocationAnswer(e?.target?.value)}
          />
        </Box>
      ) : (
        items?.map((item, index) => (
          <Box key={index} className="flex items-center space-x-2 ">
            <LabelledRadioGroup
              label={`Would you like to list more than one venue in location ${index + 1
                }?`}
              value={item?.answer}
              handleChange={(e) => handleChangeAnswer(e?.target?.value, index)}
            />
          </Box>
        ))
      )}
    </Box>
  );
};

export default Questions;
