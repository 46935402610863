import React from 'react'
import Navbar from '../../components/webComps/Navbar'
import HowWorks from './HowWorks'
import Enquiries from './Enquiries'
import Effortless from './Effortless'
import Feedback from './Feedback'



const ListVenues = () => {
    return (
        <div>
            <Navbar />
            <HowWorks />
            <Enquiries />
            <Effortless/>
            <Feedback/>
        </div>
    )
}

export default ListVenues
