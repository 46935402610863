import { ImPencil } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";

const UserCard = ({ data, handleEdit, deleteModalFun }) => {
  const checkAdmin = localStorage.getItem("checkAdmin");
  return (
    <div className="w-full bg-white border-b p-2 space-y-2">
      <div className="flex items-center justify-between w-full">
        <Link
          to={`/team/team-details/${data?._id}`}
          className="flex items-end gap-2"
        >
          <span className="text-sm font-bold capitalize">{data?.name}</span>
          <span className="text-xs text-[#717171] capitalize">
            {data?.role}
          </span>
        </Link>
        <ImPencil
          color="#222222"
          onClick={() => handleEdit(data)}
          role="button"
        />
      </div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center flex-wrap gap-2">
          <div className="bg-[#E4EFFF] flex items-center p-1 px-2 text-sm rounded-sm text-[#1252B1]">
            Venue
          </div>
        </div>
        {/* <RiDeleteBin6Line
          color="#222222"
          onClick={() => deleteModalFun(data?._id)}
          role="button"
        /> */}
         {checkAdmin == 'true' &&  <RiDeleteBin6Line
    color="#222222"
    onClick={() => deleteModalFun(data)}
    role="button"
  />}
      </div>
    </div>
  );
};

export default UserCard;
