import React from "react";
import { GoPlus } from "react-icons/go";
import { IoMdCheckmark } from "react-icons/io";

const SelectableTags = ({
  label,
  tags,
  selectedTags,
  onTagClick,
  max,
  disabledTags = [],
}) => {
  // Determine if adding more tags is allowed based on the max value
  const isDisabled = (tag) =>
    max && selectedTags.length >= max && !selectedTags.includes(tag);
  const isDisabledMonth = (tag) => disabledTags.includes(tag);
  return (
    <div className="mb-4">
      {label && (
        <label className="block text-[#B0B0B0] text-sm font-bold mb-2">
          {label}
        </label>
      )}
      <div className="flex flex-wrap">
        {tags?.map((tag, index) => (
          <button
            key={index}
            onClick={() => !isDisabled(tag) && onTagClick(tag)}
            className={`m-1 px-4 py-2 font-normal rounded-full border flex items-center justify-between ${selectedTags.includes(tag)
                ? "border-green1 text-green1"
                : "bg-white text-offGray border-offGray"
              } ${isDisabled(tag) || isDisabledMonth(tag)
                ? "opacity-50 cursor-not-allowed"
                : ""
              }`}
            disabled={isDisabled(tag) || isDisabledMonth(tag)}
          >
            <span>{tag}</span>
            {selectedTags?.includes(tag) ? (
              <IoMdCheckmark className="ml-2 text-green1 w-[16px] h-[16px]" />
            ) : (
              <GoPlus className="ml-2 text-offGray w-[16px] h-[16px]" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectableTags;
