import React, { useState } from "react";

const Tooltip = ({
  children,
  tooltipText,
  bgColor = "bg-black",
  textColor = "text-white",
  borderColor = "gray-700",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleMouseEnter = () => setIsVisible(true);
  const handleMouseLeave = () => {}; // Tooltip remains visible until closed manually
  const handleClose = () => setIsVisible(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isVisible && (
        <div className="relative">
          {/* Tooltip Content */}
          <div
            className={`absolute top-full left-1/2 transform -translate-x-1/2 mt-2 p-4 ${bgColor} ${textColor} border ${borderColor} shadow-lg flex items-center w-[249px] h-[195px] rounded-xl`}
            style={{ border: `2px solid ${borderColor}` }}
          >
            <span className="text-left text-[14px]">{tooltipText}</span>
            <button
              onClick={handleClose}
              className={`absolute top-2 w-[32px] h-[32px] right-2 ${textColor} text-3xl hover:text-gray-400`}
              aria-label="Close tooltip"
            >
              &times;
            </button>
          </div>

          {/* Tooltip Arrow */}
          <div
            className="absolute top-full left-1/2 transform -translate-x-1/2 mt-0 w-0 h-0 border-l-[10px] border-r-[10px] border-b-[10px] border-l-transparent border-r-transparent"
            style={{
              borderBottomColor:
                bgColor === "bg-black" ? "black" : bgColor.slice(3),
              borderTop: "none",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
