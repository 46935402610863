import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/pics/logoMain.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CrownIcon from "./shared/common/icons/crown-icon";
import DashboardIcon from "./shared/common/icons/dashboard-icon";
import DescriptionIcon from "./shared/common/icons/description-icon";
import TeamIcon from "./shared/common/icons/team-icons";
import { fetchLoggedUser } from "../api/loggedUser";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [hideDashboard, setHideDashboard] = useState(false);
  const handleClick = (to) => {
    navigate(to);
  };
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        setUser(response.vendor);
        if (
          response.vendor?.isAdmin == true &&
          (response.vendor?.RestaurantForm?.length > 0 ||
            response.vendor?.venueForms?.length > 0) &&
          response.vendor?.Hotel?.length > 0
        ) {
          setHideDashboard(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);
  console.log(user, "con");

  return (
    <div
      className={`min-h-screen  top-0 left-0  h-full  w-full bg-gray-50 border-r shadow-md`}
    >
      <div className="p-4 hidden md:flex flex-col  items-start w-full justify-center">
        <img src={logo} alt="Logo" className="" />
        {/* if admin full access else not */}
        {user?.isAdmin ? (
          <>
            <nav className="mt-10 md:mt-4 w-full ">
              <ul className="space-y-2">
                {!hideDashboard && (
                  <li>
                    <div
                      role="button"
                      onClick={() => handleClick("/dashboard")}
                      style={{
                        backgroundColor:
                          location.pathname === "/dashboard"
                            ? props.colour.backgroundcolour
                            : "",
                        marginRight: "7px",
                        borderRadius: "9px",
                      }}
                      className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg"
                    >
                      <DashboardIcon
                        color={
                          location.pathname === "/dashboard"
                            ? props.colour.colour
                            : "#B0B0B0"
                        }
                      />
                      <span
                        className="ml-3"
                        style={{
                          color:
                            location.pathname === "/dashboard"
                              ? props.colour.colour
                              : "",
                        }}
                      >
                        Dashboard
                      </span>
                    </div>
                  </li>
                )}

                {/* <li>
            <div
              role="button"
              onClick={() => handleClick("#")}
              style={{
                backgroundColor:
                  location.pathname === "/enquiry"
                    ? props.colour.backgroundcolour
                    : "",
              }}
              className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
            >
              <CrownIcon
                color={
                  location.pathname === "/enquiry"
                    ? props.colour.colour
                    : "#B0B0B0"
                }
              />
              <span
                className="ml-3"
                style={{
                  color:
                    location.pathname === "/enquiry" ? props.colour.colour : "",
                }}
              >
                Enquiry
              </span>
            </div>
          </li> */}
                <li>
                  <div
                    role="button"
                    style={{
                      backgroundColor:
                        location.pathname === "/team"
                          ? props.colour.backgroundcolour
                          : "",
                    }}
                    onClick={() => handleClick("/team")}
                    className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                  >
                    {/* <img src={TeamIcon} alt="My Team" className="h-6 w-6" /> */}
                    <TeamIcon
                      color={
                        location.pathname === "/team"
                          ? props.colour.colour
                          : "#B0B0B0"
                      }
                    />
                    <span
                      className="ml-3"
                      style={{
                        color:
                          location.pathname === "/team"
                            ? props.colour.colour
                            : "",
                      }}
                    >
                      My Team
                    </span>
                  </div>
                </li>
                <li>
                  <div
                    role="button"
                    style={{
                      backgroundColor:
                        location.pathname === "/venues"
                          ? props.colour.backgroundcolour
                          : "",
                    }}
                    onClick={() => handleClick("/myVenue")}
                    className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                  >
                    {/* <img src={descriptionIcon} alt="My Venues" className="h-6 w-6" /> */}
                    <DescriptionIcon
                      color={
                        location.pathname === "/myVenue"
                          ? props.colour.colour
                          : "#B0B0B0"
                      }
                    />
                    <span
                      className="ml-3"
                      style={{
                        color:
                          location.pathname === "/myVenue"
                            ? props.colour.colour
                            : "",
                      }}
                    >
                      My Venues
                    </span>
                  </div>
                </li>
                {/* <li>
            <div
              role="button"
              style={{
                backgroundColor:
                  location.pathname === "/subscription"
                    ? props.colour.backgroundcolour
                    : "",
              }}
              onClick={() => handleClick("#")}
              className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
            >
              <CrownIcon
                color={
                  location.pathname === "/subscription"
                    ? props.colour.colour
                    : "#B0B0B0"
                }
              />
              <span
                className="ml-3"
                style={{
                  color:
                    location.pathname === "/subscription"
                      ? props.colour.colour
                      : "",
                }}
              >
                My Subscription
              </span>
            </div>
          </li> */}
                {/* <li>
            <div
              role="button"
              style={{
                backgroundColor:
                  location.pathname === "/reports"
                    ? props.colour.backgroundcolour
                    : "",
              }}
              onClick={() => handleClick("#")}
              className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
            >
              <DescriptionIcon
                color={
                  location.pathname === "/reports"
                    ? props.colour.colour
                    : "#B0B0B0"
                }
              />
              <span
                className="ml-3"
                style={{
                  color:
                    location.pathname === "/reports" ? props.colour.colour : "",
                }}
              >
                Reports
              </span>
            </div>
          </li> */}
              </ul>
            </nav>
          </>
        ) : (
          <>
            <nav className="mt-10 md:mt-4 w-full ">
              <ul className="space-y-2">
                {/* <li>
              <div
                role="button"
                onClick={() => handleClick("/dashboard")}
                style={{
                  backgroundColor:
                    location.pathname === "/dashboard"
                      ? props.colour.backgroundcolour
                      : "",
                  marginRight: "7px",
                  borderRadius: "9px",
                }}
                className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg"
              >
                <DashboardIcon
                  color={
                    location.pathname === "/dashboard"
                      ? props.colour.colour
                      : "#B0B0B0"
                  }
                />
                <span
                  className="ml-3"
                  style={{
                    color:
                      location.pathname === "/dashboard"
                        ? props.colour.colour
                        : "",
                  }}
                >
                  Dashboard
                </span>
              </div>
            </li> */}
                {/* <li>
            <div
              role="button"
              onClick={() => handleClick("#")}
              style={{
                backgroundColor:
                  location.pathname === "/enquiry"
                    ? props.colour.backgroundcolour
                    : "",
              }}
              className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
            >
              <CrownIcon
                color={
                  location.pathname === "/enquiry"
                    ? props.colour.colour
                    : "#B0B0B0"
                }
              />
              <span
                className="ml-3"
                style={{
                  color:
                    location.pathname === "/enquiry" ? props.colour.colour : "",
                }}
              >
                Enquiry
              </span>
            </div>
          </li> */}
                <li>
                  <div
                    role="button"
                    style={{
                      backgroundColor:
                        location.pathname === "/team"
                          ? props.colour.backgroundcolour
                          : "",
                    }}
                    onClick={() => handleClick("/team")}
                    className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                  >
                    {/* <img src={TeamIcon} alt="My Team" className="h-6 w-6" /> */}
                    <TeamIcon
                      color={
                        location.pathname === "/team"
                          ? props.colour.colour
                          : "#B0B0B0"
                      }
                    />
                    <span
                      className="ml-3"
                      style={{
                        color:
                          location.pathname === "/team"
                            ? props.colour.colour
                            : "",
                      }}
                    >
                      My Team
                    </span>
                  </div>
                </li>
                <li>
                  <div
                    role="button"
                    style={{
                      backgroundColor:
                        location.pathname === "/venues"
                          ? props.colour.backgroundcolour
                          : "",
                    }}
                    onClick={() => handleClick("/myVenue")}
                    className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                  >
                    {/* <img src={descriptionIcon} alt="My Venues" className="h-6 w-6" /> */}
                    <DescriptionIcon
                      color={
                        location.pathname === "/myVenue"
                          ? props.colour.colour
                          : "#B0B0B0"
                      }
                    />
                    <span
                      className="ml-3"
                      style={{
                        color:
                          location.pathname === "/myVenue"
                            ? props.colour.colour
                            : "",
                      }}
                    >
                      My Venues
                    </span>
                  </div>
                </li>
              </ul>
            </nav>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
