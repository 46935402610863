import React, { useContext } from 'react';
import { FaPhone, FaConciergeBell, FaLuggageCart, FaBed, FaBusinessTime, FaMicrophone, FaSwimmingPool, FaSpa, FaShuttleVan, FaTaxi, FaParking, FaInfoCircle } from 'react-icons/fa';
import { HotelContext } from '../../context/HotelContext';
import * as FaIcons from "react-icons/fa";

const featuresData = [
    {
        category: 'Room features',
        key: 'roomFeatures',
        items: [
            {
                name: "Calls (Local)",
                icon: <img alt='' src="/room-features/call.svg" />,
                isDefault: true,
            },
            {
                name: "Calls (Toll-free)",
                icon: <img alt='' src="/room-features/toll-free.svg" />,
                isDefault: true,
            },
            {
                name: "Concierge Services",
                icon: <img alt='' src="/room-features/concierge.svg" />,
                isDefault: true,
            },
            {
                name: "Laundry Service",
                icon: <img alt='' src="/room-features/laundry.svg" />,
                isDefault: true,
            },
            {
                name: "Luggage Storage",
                icon: <img alt='' src="/room-features/luggage.svg" />,
                isDefault: true,
            },
            {
                name: "Internet Access",
                icon: <img alt='' src="/room-features/internet.svg" />,
                isDefault: true,
            },
            {
                name: "Room Service",
                icon: <img alt='' src="/room-features/food-tray.svg" />,
                isDefault: true,
            },
            {
                name: "Air conditioning",
                icon: <img alt='' src="/room-features/air-condition.svg" />,
                isDefault: true,
            },
            {
                name: "Kitchen",
                icon: <img alt='' src="/room-features/kitchen.svg" />,
                isDefault: true,
            },
        ]
    },
    {
        category: 'Business features',
        key: 'businessFeatures',
        items: [
            {
                name: "Business Centre",
                icon: <img alt='' src="/business-features/business.svg" />,
            },
            {
                name: "Video Conference",
                icon: <img alt='' src="/business-features/video-conference.svg" />,
            },
            { name: "VIP Services", icon: <img alt='' src="/business-features/vip.svg" /> },
            {
                name: "AV Equipment",
                icon: <img alt='' src="/business-features/av-equipements.svg" />,
            },
            {
                name: "Onsite AV Staff",
                icon: <img alt='' src="/business-features/support.svg" />,
            },
            {
                name: "High Speed Internet (100 Mbps)",
                icon: <img alt='' src="/business-features/internet.svg" />,
            },
        ]
    },
    {
        category: 'Recreational features',
        key: 'recreationalFeatures',
        items: [
            { name: "Health Club", icon: <img alt='' src="/features/medical.svg" /> },
            { name: "Indoor Pool", icon: <img alt='' src="/features/pool.svg" /> },
            { name: "Outdoor Pool", icon: <img alt='' src="/features/outdoor-pool.svg" /> },
            { name: "Spa", icon: <img alt='' src="/features/massage.svg" /> },
            { name: "Salon", icon: <img alt='' src="/features/scissors.svg" /> },
            { name: "Tennis Courts", icon: <img alt='' src="/features/sports.svg" /> },
            { name: "Whirlpool", icon: <img alt='' src="/features/whirlpool.svg" /> },
        ]
    },
];
const locationData = [
    {
        category: 'Accessibility',
        key: 'accessibilityFeatures',
        items: [
            { name: 'Airport Shuttle', icon: FaShuttleVan, key: 'airportShuttle' },
            { name: 'Bus', icon: FaShuttleVan, key: 'bus' },
            { name: 'Taxi', icon: FaTaxi, key: 'taxi' },
            { name: 'Metro', icon: FaTaxi, key: 'metro' },
        ],
    },
    {
        category: 'Parking',
        key: 'parkingFeatures',
        items: [
            { name: 'Parking available', icon: FaParking, key: 'parkingAvailable' },
            { name: 'Valet Parking', icon: FaParking, key: 'valetParking' },
        ],
    },
];

const renderIcon = (iconName) => {
    const IconComponent = FaIcons[iconName];
    return IconComponent ? <IconComponent size={32} /> : null;
};

const FeatureItem = ({ feature, available }) => {
    const IconComponent = feature.icon;
    return (
        <div className={`flex items-center gap-4 mb-2 ${available ? 'block' : 'hidden'}`}>
            {feature.icon}
            <span>{feature.name}</span>
        </div>
    );
};

const FeatureItem1 = ({ feature, available }) => {
    const IconComponent = feature.icon;
    return (
        <div className={`flex items-center gap-4 mb-2 ${available ? 'block' : 'hidden'}`}>
            {<IconComponent className="text-3xl mr-2" />}
            <span>{feature.name}</span>
        </div>
    );
};

const FeaturesSection = () => {
    const { formData } = useContext(HotelContext);

    // Add custom features to the respective categories in featuresData
    Object.keys(formData?.amenities || {}).forEach((categoryKey) => {
        const category = featuresData.find(cat => cat.key === categoryKey);
        if (category) {
            Object.keys(formData.amenities[categoryKey]).forEach((featureKey) => {
                if (!category.items.find(item => item.name === featureKey)) {
                    category.items.push({
                        name: featureKey,
                        icon: <FaIcons.FaInfoCircle />,
                        isDefault: false
                    });
                }
            });
        }
    });

    return (
        <div className="py-5 lg:w-3/4  w-full px-5 border-t mx-auto">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-2xl gap-5 font-bold mb-6">What this place offers</h2>
                {featuresData.map((category, index) => (
                    <div key={index} className="mb-6 flex gap-5 items-start flex-wrap">
                        <h3 className="text-xl font-bold mb-4 lg:w-1/4 w-full">{category.category}</h3>
                        <div className="grid grid-cols-2 lg:w-3/5 justify-between">
                            {category.items.map((feature, itemIndex) => {
                                let available = false;
                                if (category.key === 'roomFeatures') {
                                    available = formData?.amenities?.roomFeatures?.[feature.name] || false;
                                } else if (category.key === 'businessFeatures') {
                                    available = formData?.amenities?.businessFeatures?.[feature.name] || false;
                                } else if (category.key === 'recreationalFeatures') {
                                    available = formData?.amenities?.recreationalFeatures?.[feature.name] || false;
                                }
                                return (
                                    <FeatureItem
                                        key={itemIndex}
                                        feature={feature}
                                        available={available}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ))}

                {locationData.map((category, index) => (
                    <div key={index} className="mb-6 flex gap-5 items-start flex-wrap">
                        <h3 className="text-xl font-bold mb-4 lg:w-1/4 w-full">{category.category}</h3>
                        <div className="grid grid-cols-2 lg:w-3/5 gap-4">
                            {category.items.map((feature, itemIndex) => {
                                let available = false;
                                if (category.key === 'accessibilityFeatures') {
                                    available = formData?.overview?.locationDetails?.accessibilityFeatures?.[feature.key] || false;
                                } else if (category.key === 'parkingFeatures') {
                                    available = formData?.overview?.locationDetails?.[feature.key] || false;
                                }
                                return (
                                    <FeatureItem1
                                        key={itemIndex}
                                        feature={feature}
                                        available={available}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesSection;
