import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosStar } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";
import ReviewCarousel from './ReviewCarousel';


const Feedback = () => {


    return (
        <div className=''>
            <div className='w-[90%] flex flex-col items-center' >
                <h1 className='sm:text-4xl text-2xl text-darkGray font-semibold text-center my-4'>What our guests are saying</h1>
                <div className='w-[90%]'>
                <ReviewCarousel />
                </div>
                <div className='flex justify-center w-full sm:my-10 my-6'>
                    <button className='bg-primary text-white rounded-lg w-[180px] py-2'>Share Your Experience</button>
                </div>
            </div>
        </div>
    )
}

export default Feedback

