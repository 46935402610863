import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import logo from "../assets/images/logo.svg";
import profileIcon from "../assets/images/profile_icon.svg"; // Ensure you have the correct path

function ResponsiveAppBar() {
  const [anchorElExplore, setAnchorElExplore] = useState(null);
  const [anchorElProfile, setAnchorElProfile] = useState(null);

  const handleOpenExploreMenu = (event) => {
    setAnchorElExplore(event.currentTarget);
  };

  const handleCloseExploreMenu = () => {
    setAnchorElExplore(null);
  };

  const handleOpenProfileMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            src={logo}
            alt="logo"
            sx={{
              height: 50,
              marginRight: 2,
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            {/* <Typography
              variant="body2"
              component="div"
              sx={{ mr: 6, cursor: "pointer", fontSize: "1rem" }}
              onClick={handleOpenExploreMenu}
            >
              Explore
            </Typography> */}
            {/* <Menu
              anchorEl={anchorElExplore}
              open={Boolean(anchorElExplore)}
              onClose={handleCloseExploreMenu}
            >
              <MenuItem onClick={handleCloseExploreMenu}>Option 1</MenuItem>
            </Menu> */}
            <Link
              variant="body2"
              component={Link}
              to="/register"
              sx={{ mr: 6, pr:8, textDecoration: "none", color: "inherit" }}
            >
              Register your Venue
            </Link>
            <IconButton onClick={handleOpenProfileMenu}>
              <Box
                component="img"
                src={profileIcon}
                alt="profile"
                sx={{ height: 45, width: 45 }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorElProfile}
              open={Boolean(anchorElProfile)}
              onClose={handleCloseProfileMenu}
            >
              {/* Placeholder for future menu items */}
              <MenuItem onClick={handleCloseProfileMenu}>Profile</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
