import React from 'react'
import logo from '../../assets/pics/footerLogo.svg'
import { GoArrowUp } from "react-icons/go";
import { Link } from 'react-router-dom';
import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoTwitter } from "react-icons/io";


const Footer = ({ onScrollDownClick }) => {
    return (
        <div className='bg-[#222222] sm:p-16 p-4 py-20 '>
            <div className='flex lg:flex-row flex-col lg:gap-0 gap-10 justify-between items-start'>
                <div className='lg:w-[35%] w-full text-white' >
                    <img src={logo} alt="" />
                    <div className='flex flex-col items-start gap-8'>
                        <p className='text-3xl mt-8'>Your guide to event venue discovery in the UAE</p>
                        <div className=''>
                            <p>support@findmyvenue.com</p>
                        </div>
                    </div>
                </div>
                <div className='lg:w-[60%] w-full grid sm:grid-cols-3 grid-cols-2 text-white gap-10' >
                    <div>
                        <h1 className='text-lg font-medium'>Users</h1>
                        <p className="mt-2">Why Find My Venue</p>
                        {/* <p>Event Partners</p> */}
                        <Link to='/login'>User Login</Link>
                        {/* <Link href='/login'>FAQs</Link> */}
                    </div>
                    <div>
                        <h1 className='text-lg font-medium'>Venues</h1>
                        <p className="mt-2">Why list with us</p>
                        <Link to='/login' className='block'>List your venue</Link>
                        <Link to='/list-venues' className='block'>Venue Login</Link>
                    </div>
                    <div>
                        <h1 className='text-lg font-medium'>Info</h1>
                        <Link to={'/about'} className="mt-2 block">About Us</Link>
                        {/* <p>Careers</p> */}
                        <Link className='block' to={'/privacy-policy'}> Privacy Policy</Link>
                        <Link className='block' to={'/terms&condition'}>Terms & Condition</Link>
                        <Link className='block' to={'/contact'}>Contact Us</Link>
                    </div>
                    <div>
                        <h1 className='text-lg font-medium'>Follow Us On</h1>
                        <div className='flex gap-3 mt-2 text-white items-center'>
                            <FaSquareFacebook className='text-xl' />
                            <FaWhatsapp className='text-xl' />
                            <IoLogoLinkedin className='text-xl' />
                            <IoLogoTwitter className='text-xl' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between mt-20 gap-4'>
                <p className='text-white font-thin '>© 2024 Find My Venue. All rights reserved. |  Privacy | Security</p>
                <div onClick={onScrollDownClick} className='p-3 border cursor-pointer rounded-md text-white'>
                    <GoArrowUp className='text-white' />
                </div>
            </div>
        </div>
    )
}

export default Footer
