import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Link,
	Checkbox,
	Divider,
	Box,
	useTheme,
} from '@mui/material';
import TextfieldNumber from './../components/TextfieldNumber';
import TitleDescriptionCoupon from './../components/TitleDescriptionCoupon';
import { ReactComponent as CouponIcon } from './../../../assets/images/coupon_icon.svg';
import { ReactComponent as EditBoxIcon } from './../../../assets/images/edit_box_icon.svg';
import benefitsIcon from './../../../assets/images/benefits_icon.svg';
import BenefitsList from './../components/BenefitsList';
import TableComponent from './../components/TableComponent';
import calculateHotelPayment from '../../../utils/calculateHotelPayment';
import costStructure from '../../../constants/costStructure';
import whatsapp_icon from './../../../assets/images/whatsapp_icon.svg';
import CouponTextfield from '../components/CouponTextfield';
import CouponInputField from '../components/CouponInputField';
import { calculateSixMonthsFromNow } from '../../../utils/calculateNextDate';

function createData(plan, rate, quantity, total) {
	return { plan, rate, quantity, total };
}

function createThirdTableData(description, action) {
	return { description, action };
}

const firstTableHeaders = [
	{ label: 'Plan Breakdown', key: 'plan', align: 'left' },
	{ label: 'Rate', key: 'rate', align: 'right' },
	{
		label: 'Quantity',
		key: 'quantity',
		align: 'right',
		width: '15%',
	},
	{ label: 'Total', key: 'total', align: 'right' },
];

const secondTableHeaders = [
	{ label: 'Description', key: 'plan', align: 'left' },
	{ label: 'Rate', key: 'rate', align: 'right' },
	{
		label: 'Quantity',
		key: 'quantity',
		align: 'right',
		width: '15%',
	},
	{ label: 'Total', key: 'total', align: 'right' },
];

const thirdTableHeaders = [
	{ label: 'Description', key: 'description', align: 'left' },
	{ label: 'Action', key: 'action', align: 'right' },
];

function PaymentHotelView({ paymentData, handleContinue }) {
	const theme = useTheme();
	const [editMode, setEditMode] = useState(false);
	const [withAlcoholNumber, setWithAlcoholNumber] = useState(0);
	const [withoutAlcoholNumber, setWithoutAlcoholNumber] = useState(0);
	const [quantityNumber, setQuantityNumber] = useState(0);
	const [isChecked, setIsChecked] = useState(false);

	const [discount, setDiscount] = useState(0)
	const handleCheckboxChange = (event) => {
		setIsChecked(event.target.checked);
	};

	const [calculatedValues, setCalculatedValues] = useState({
		subTotal: 0,
		grandTotal: 0,
		withAlcoholCost: 0,
		withoutAlcoholCost: 0,
		withAlcoholSaving: 0,
		withoutAlcoholSaving: 0,
		withAlcoholRate: 0,
		withoutAlcoholRate: 0,
	});
	const handleApplyDiscount = (discountAmount) => {
		setDiscount(discountAmount);
	};

	
	useEffect(() => {
		if (paymentData) {
			setQuantityNumber(paymentData.quantityNumber);
			setWithAlcoholNumber(paymentData.withAlcoholNumber);
			setWithoutAlcoholNumber(paymentData.withoutAlcoholNumber);

			// Check if discount is applied, and set grandTotal accordingly
			let finalGrandTotal = discount > 0 
				? calculatedValues?.grandTotal 
				: paymentData?.grandTotal;

			
			// Round the finalGrandTotal to avoid decimals
			if (finalGrandTotal) {
				finalGrandTotal = Math.round(finalGrandTotal);
				// Store the rounded grandTotal in local storage
				localStorage.setItem(
					'grandTotal',
					finalGrandTotal.toString()
				);
			}
			console.log(paymentData, 'job ha');
			console.log(calculatedValues?.grandTotal, 'after discount');
			console.log(discount, "discount value is");
		}
	}, [paymentData, calculatedValues]);



	useEffect(() => {
		const updatedValues = calculateHotelPayment(
			withAlcoholNumber,
			withoutAlcoholNumber
		);

		// Calculate subtotal
		const subtotal = updatedValues.subTotal;

		// Calculate the discount amount
		const discountAmount = (discount / 100) * subtotal;

		// Apply the discount to the subtotal
		const discountedSubtotal = subtotal - discountAmount;

		// Update the grand total based on discounted subtotal and VAT
		const vatServiceFee = (discountedSubtotal * costStructure.vat) / 100;
		const grandTotal = discountedSubtotal + vatServiceFee;

		setCalculatedValues({
			...updatedValues,
			subTotal: discountedSubtotal, // Update subtotal with discount
			grandTotal: grandTotal,       // Update grand total with discount
			discountAmount: discountAmount // Optionally store the discount amount
		});
	}, [withAlcoholNumber, withoutAlcoholNumber, discount]);


	const handleWithAlcoholNumberChange = (event) => {
		setWithAlcoholNumber(Number(event.target.value));
	};

	const handleWithoutAlcoholNumberChange = (event) => {
		setWithoutAlcoholNumber(Number(event.target.value));
	};

	const handleQuantityNumberChange = (event) => {
		setQuantityNumber(Number(event.target.value));
	};

	const firstTableRows = paymentData
		? [
			createData(
				<Typography sx={{ fontSize: { xs: '0.8rem' } }}>
					Base charge (Inclusive of stay){' '}
				</Typography>,
				'',
				'',
				`$${paymentData.baseCharge}`
			),
			createData(
				<Typography sx={{ fontSize: { xs: '0.8rem' } }}>
					Unlimited Venues (Inclusive in base charge){' '}
				</Typography>,
				'',
				editMode ? (
					<TextfieldNumber
						value={quantityNumber}
						onChange={handleQuantityNumberChange}
					/>
				) : (
					quantityNumber
				),
				'$0'
			),
			createData(
				<Typography
					sx={{ color: '#5e5e5e', fontSize: { xs: '0.8rem' } }}
				>
					Restaurants with alcohol{' '}
					<span style={{ color: '#039F8D' }}>
						(${calculatedValues.withAlcoholSaving} saved)
					</span>
				</Typography>,
				`$${calculatedValues.withAlcoholRate}`,
				editMode ? (
					<TextfieldNumber
						value={withAlcoholNumber}
						onChange={handleWithAlcoholNumberChange}
					/>
				) : (
					withAlcoholNumber
				),
				`$${calculatedValues.withAlcoholCost}`
			),
			createData(
				<Typography
					sx={{ color: '#5e5e5e', fontSize: { xs: '0.8rem' } }}
				>
					Restaurants without alcohol{' '}
					<span style={{ color: '#039F8D' }}>
						(${calculatedValues.withoutAlcoholSaving} saved)
					</span>
				</Typography>,
				`$${calculatedValues.withoutAlcoholRate}`,
				editMode ? (
					<TextfieldNumber
						value={withoutAlcoholNumber}
						onChange={handleWithoutAlcoholNumberChange}
					/>
				) : (
					withoutAlcoholNumber
				),
				`$${calculatedValues.withoutAlcoholCost}`
			),
		]
		: [];

	const subTotal = calculatedValues.subTotal;
	const vatServiceFee = (subTotal * costStructure.vat) / 100;

	const secondTableRows = paymentData
		? [
			createData('Sub Total', '', '', `$${subTotal.toFixed(2)}`),
			createData(
				'VAT (5%)',
				'',
				'',
				`$${vatServiceFee.toFixed(2)}`
			),
		]
		: [];

	const grandTotal = calculatedValues.grandTotal;

	const thirdTableRows = paymentData
		? [
			createThirdTableData(
				<Typography variant='h6'>Grand Total</Typography>,
				`$${grandTotal.toFixed(2)}/year`
			),
			createThirdTableData(
				`We will send an email reminder before your  subscription begins on ${calculateSixMonthsFromNow()}. Cancel anytime before trial ends`,
				<Typography variant='h6' sx={{ fontWeight: '600' }}>
					Pay now $0
				</Typography>
			),
		]
		: [];

	const leftBox = (
		<Box
			sx={{
				width: { xs: '100%', md: '100%' },
				border: `2px solid ${theme.palette.grey.border}`,
				backgroundColor: theme.palette.white,
				borderRadius: '8px',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '200px',
				pl: 2,
				pr: 2,
			}}
		>
			<Box sx={{ display: 'flex' }}>
				<Typography
					variant='h5'
					sx={{
						fontWeight: '600',
						textAlign: 'left',
						px: 4,
						py: 2,
						pl: 2,
					}}
				>
					Hotel Plan
				</Typography>
				<Button
					variant='text'
					startIcon={<EditBoxIcon />}
					onClick={() => setEditMode(!editMode)}
					sx={{ color: 'primary.main', textTransform: 'none', m: 0 }}
				>
					{editMode ? 'Save Plan' : 'Edit Plan'}
				</Button>
			</Box>

			<Box
				sx={{
					width: '95%',
					mt: -2.8,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Divider sx={{ width: '95%', mt: 2 }} />
			</Box>

			<TableComponent
				headers={firstTableHeaders}
				rows={firstTableRows}
				showHeader={true}
			/>

			<Divider sx={{ borderStyle: 'dashed' }} />

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					mt: 2,
					mb: 2,
				}}
			>
				{/* Coupon Field*/}
				<CouponTextfield />
				<CouponInputField onApplyDiscount={handleApplyDiscount} />

			</Box>

			<Divider sx={{ borderStyle: 'dashed' }} />

			<TableComponent
				headers={secondTableHeaders}
				rows={secondTableRows}
			/>

			<Box
				sx={{
					width: '100%',
					mt: -2.8,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Divider sx={{ width: '95%', mt: 3, mb: -1 }} />
			</Box>
			<TableComponent
				headers={thirdTableHeaders}
				rows={thirdTableRows}
			/>
		</Box>
	);

	const rightBox = (
		<Box
			sx={{
				width: { xs: '100%', md: '40%' },
				border: `2px solid ${theme.palette.grey.border}`,
				backgroundColor: theme.palette.white,
				borderRadius: '8px',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '200px',
				px: 4,
			}}
		>
			{/* For Icon + Plan Benefits*/}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					mt: 2,
				}}
			>
				<Box
					sx={{
						backgroundColor: theme.palette.primary.light,
						display: 'flex',
						justifyContent: 'center',
						borderRadius: '8px',
						mr: 2,
					}}
				>
					<img
						src={benefitsIcon}
						alt='Benefits Icon'
						style={{
							width: '40px',
							height: '40px',
						}}
					/>
				</Box>
				<Typography
					variant='h5'
					color={theme.palette.primary.main}
					fontWeight={500}
					sx={{ fontSize: '1.6rem' }}
				>
					Plan Benefits
				</Typography>
			</Box>
			{/* For Base Charge*/}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					mt: 2,
					ml: 3,
					justifyContent: 'space-between',
				}}
			>
				<Typography
					variant='body1'
					color={theme.palette.grey.dark}
					fontWeight={500}
				>
					Base Charge ${paymentData?.baseCharge}
				</Typography>
			</Box>
			<BenefitsList
				additionalCharges={[
					{
						highlight: 'Restaurants with Alcohol',
					},
					{
						text: ' for 1 additional restaurant managed by the group',
						highlight: '$1,500 ',
					},
					{
						text: ' for 2 to 5 restaurants',
						highlight: '$1,300 ',
					},
					{
						text: ' for 5+ restaurants',
						highlight: '$1,200 ',
					},
					{
						highlight: 'Restaurants without Alcohol',
					},
					{
						text: ' for 1 restaurant',
						highlight: '$1,000 ',
					},
					{
						text: ' for 2 to 5 restaurants',
						highlight: '$900 ',
					},
					{
						text: ' for 5+ restaurants',
						highlight: '$800 ',
					},
				]}
			/>
		</Box>
	);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: theme.palette.white,
				borderRadius: theme.shape.borderRadius,
				width: { xs: '100%', sm: '80%', md: '100%' },
				pl: 2,
			}}
		>
			<TitleDescriptionCoupon
				title={'Take a look at our plan suitable for your needs!'}
				description={
					'We found this plan to be best suited for your needs'
				}
			/>
			{/* Parent Box */}
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
					gap: 5,
					alignItems: { xs: 'center', md: 'flex-start' },
					mt: 4,
					pl: 2,
				}}
			>
				{leftBox}
				{rightBox}
			</Box>

			{/* Terms and Conditions Section */}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					mt: 2,
				}}
			>
				<Checkbox
					color='primary'
					checked={isChecked}
					onChange={handleCheckboxChange}
					required
				/>
				<Box>
					<Typography>
						{'I agree to all '}
						<Link href='/terms&condition' target='_blank'>
							<span style={{ color: theme.palette.primary.main }}>
								Terms & Conditions
							</span>
						</Link>
						{' and '}
						<Link href='/cancellation-policy' target='_blank'>
							<span style={{ color: theme.palette.primary.main }}>
								Cancellation policy
							</span>
						</Link>
						{' including usage of cookies'}
					</Typography>
				</Box>
			</Box>

			{/* Start Your Free 6 Months Button */}
			<Button
				type='submit'
				variant='contained'
				size='large'
				onClick={handleContinue}
				disabled={!isChecked} // Disable button if isChecked is false
				sx={{
					mb: { xs: 1, md: 3 },
					borderRadius: '8px',
					width: { xs: '100%', md: '30%' },
					mt: 2,
					backgroundColor: isChecked
						? theme.palette.primary.main
						: theme.palette.grey.border,
					textTransform: 'none',
				}}
			>
				Start Your Free 6 Months
			</Button>
			{/* Whatsapp related Text */}
			<Box sx={{ display: 'flex', gap: 1 }}>
				<img
					src={whatsapp_icon}
					alt='Whatsapp'
					style={{ height: 22, width: 22, pl: '5px' }} // Added marginRight for spacing
				/>
				<Typography
					variant='body2'
					sx={{
						color: theme.palette.grey.text,
						fontWeight: '500',
					}}
				>
					<span style={{}}>For any queries </span>
					<Link
						target='_blank'
						href='/contact'
						sx={{ textDecoration: 'none' }}
					>
						<span
							style={{
								textTransform: 'none',
								color: theme.palette.primary.main,
							}}
						>
							contact us
						</span>
					</Link>
					<span> here—we’re here to help!</span>
				</Typography>
			</Box>
		</Box>
	);
}

export default PaymentHotelView;
