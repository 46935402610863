import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layout/dashboard-layout';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import VenueCard from './VenueCard';
import { useQuery } from '@tanstack/react-query';
import { getOnboarding } from '../../api/signup.request';
import { useTeamMember } from '../../queries/team-members';
import { fetchLoggedUser } from '../../api/loggedUser';

const MyVenues = () => {

	const[user,setUser]=useState(null)

	const location = useLocation();
	const checkAdmin = localStorage.getItem('checkAdmin');
	// if it is super admin than  localStorage.getItem("vendorId"); will work
	// but if it is admin that was created by invitation than  localStorage.getItem("invitedBy"); will work
	// than the id will be used of that super admin to fetch venues (For admin show all venues of super admin)
	const invitedBy = localStorage.getItem('invitedBy');
	const vendorId = invitedBy || localStorage.getItem('vendorId');
	const limiteduser = localStorage.getItem('vendorId');

	const [data, setData] = useState([]);
	const [invitedData, setInvitedData] = useState(false);
	const [hotelVenue, setHotelVenue] = useState(null);
	const [
		uniqueRestaurantsWithVenues,
		setUniqueRestaurantsWithVenues,
	] = useState([]);
	const [multipleVenuesInSameCity, setMultipleVenuesInSameCity] =
		useState([]);

	const {
		data: boardingData,
		error,
		isLoading,
	} = useQuery({
		queryKey: ['onboardingData'],
		queryFn: async () => await getOnboarding(vendorId),
		refetchOnWindowFocus: false,
	});
	const { data: limitedvenues, isPending } =
		useTeamMember(limiteduser);
	  console.log(limitedvenues?.user?.assignedVenues,"for limited venues")
	// console.log(boardingData, 'for full admin venues');

	useEffect(() => {
		if (
			boardingData &&
			boardingData.restaurantForms &&
			boardingData.restaurantForms.length > 0
		) {
			setData(boardingData);
			setInvitedData(false);
		} else {
			// If restaurantForms is empty or undefined
			setData(boardingData);
			setInvitedData(true);
		}
	}, [boardingData]);

	console.log(boardingData, 'res of venues');

	// inside hotels for limited ones exclude whore are without hotel and venue name
	useEffect(() => {
		if (
			(limitedvenues?.user?.assignedVenues?.length > 0 ||
				limitedvenues?.user?.Hotel?.length > 0) &&
			(checkAdmin == 'false' || checkAdmin == 'null')
		) {
			// Filter the hotel
			const filteredHotel =
				data?.hotels?.find((hotel) =>
					limitedvenues?.user?.Hotel?.some(
						(venue) => venue?._id === hotel?._id
					)
				) || null;

			// Filter the venues
			const filteredVenues =
				limitedvenues?.user?.assignedVenues.filter((venue) => {
					return data?.venueForms?.some((v) => v?._id === venue._id);
				});

			// Process filtered venues if any matches are found
			if (filteredHotel !== null) {
				const hotelData = data.hotels[0];
				const hotelName =
					hotelData?.overview?.basicDetails?.hotelName;

				if (filteredVenues.length > 0) {
					// Find venues matching the hotelName in the filtered venues
					const matchedVenues = filteredVenues.filter(
						(venue) => venue.basicDetails.hotelName === hotelName
					);

					setHotelVenue({
						hotelName:
							filteredHotel?.overview?.basicDetails?.hotelName,
						venues: [filteredHotel, ...matchedVenues],
					});
				} else {
					setHotelVenue({
						hotelName:
							filteredHotel?.overview?.basicDetails?.hotelName,
						venues: [filteredHotel],
					});
				}
			} else {
				if (filteredVenues.length > 0) {
					const hotelData = data.hotels[0];
					const hotelName =
						hotelData?.overview?.basicDetails?.hotelName;
					// Find venues matching the hotelName in the filtered venues
					const matchedVenues = filteredVenues.filter(
						(venue) => venue.basicDetails.hotelName === hotelName
					);

					setHotelVenue({
						hotelName:
							filteredHotel?.overview?.basicDetails?.hotelName,
						venues: [...matchedVenues],
					});
				}
			}
		} else if (
			data?.hotels &&
			data?.hotels.length > 0 &&
			data?.venueForms
		) {
			// Fallback logic when limitedvenues is empty or not available
			const hotelData = data.hotels[0];
			const hotelName = hotelData?.overview?.basicDetails?.hotelName;

			const initialHotelVenue = {
				hotelName: hotelName,
				venues: [hotelData],
			};

			const matchedVenues = data?.venueForms.filter(
				(venue) => venue?.basicDetails?.hotelName === hotelName
			);

			setHotelVenue({
				...initialHotelVenue,
				venues: [hotelData, ...matchedVenues],
			});
		}
	}, [limitedvenues, data]);

	// for restaurants for limites users showing only assign ones after filtering
	useEffect(() => {
		if (data?.restaurantForms) {
			const alcoholicRestaurantCount = data?.withAlcoholNumber;
			const nonAlcoholicRestaurantCount = data?.withoutAlcoholNumber;

			const combineRestaurantSlots = [];

			// Step 1: Create slots for restaurants based on alcohol serving status
			for (let i = 0; i < alcoholicRestaurantCount; i++) {
				combineRestaurantSlots.push({
					restaurantName: `Restaurant w/Alcohol ${i + 1}`,
					restaurants: [],
				});
			}
			for (let i = 0; i < nonAlcoholicRestaurantCount; i++) {
				combineRestaurantSlots.push({
					restaurantName: `Restaurant w/o Alcohol ${i + 1}`,
					restaurants: [],
				});
			}

			// Helper function to find the next available slot for alcoholic restaurants
			const findNextAvailableAlcoholicSlot = () => {
				return combineRestaurantSlots.find(
					(slot) =>
						slot.restaurantName.includes('w/Alcohol') &&
						slot.restaurants.length === 0
				);
			};

			// Helper function to find the next available slot for non-alcoholic restaurants
			const findNextAvailableNonAlcoholicSlot = () => {
				return combineRestaurantSlots.find(
					(slot) =>
						slot.restaurantName.includes('w/o Alcohol') &&
						slot.restaurants.length === 0
				);
			};

			// Track processed restaurants to avoid duplicates
			const processedRestaurantNames = new Set();

			// Step 2: Process primary and non-primary restaurants
			data.restaurantForms.forEach((restaurant) => {
				const primaryRestaurantName =
					restaurant.basicDetails.venueName;
				const isPrimary = restaurant?.basicDetails?.isPrimaryVenue;
				const isAlcoholic =
					restaurant?.cateringAndDrinks?.isVenueAlcohlic; // Assuming there's a flag for this

				// Check if this restaurant or its group has already been processed
				if (processedRestaurantNames.has(primaryRestaurantName)) {
					return; // Skip already processed
				}

				if (isPrimary) {
					processedRestaurantNames.add(primaryRestaurantName);

					// Find matching restaurants with the same hotelName as primary restaurant's venueName
					const matchedRestaurants = data.restaurantForms.filter(
						(rest) =>
							rest.basicDetails.hotelName === primaryRestaurantName
					);

					// Mark all matched restaurants as processed
					matchedRestaurants.forEach((rest) =>
						processedRestaurantNames.add(rest.basicDetails.venueName)
					);

					// Combine primary restaurant with its matched restaurants
					const combinedRestaurants = [
						restaurant,
						...matchedRestaurants.filter(
							(rest) => rest !== restaurant
						), // Avoid duplicate
					];

					// Find the appropriate slot based on alcohol status
					let availableSlot;
					if (isAlcoholic) {
						availableSlot = findNextAvailableAlcoholicSlot();
					} else {
						availableSlot = findNextAvailableNonAlcoholicSlot();
					}

					// If an available slot is found, add the combined restaurants to that slot
					if (availableSlot) {
						availableSlot.restaurants.push(...combinedRestaurants);
					}
				} else {
					// Find matching restaurants with the same hotelName as primary restaurant's venueName
					const parentRestaurant = data.restaurantForms.filter(
						(rest) =>
							rest.basicDetails.venueName ===
							restaurant?.basicDetails?.hotelName
					);
					console.log({ parentRestaurant });
					if (parentRestaurant.length > 0) return;

					processedRestaurantNames.add(primaryRestaurantName);

					// Find matching restaurants with the same hotelName as primary restaurant's venueName
					const matchedRestaurants = data.restaurantForms.filter(
						(rest) =>
							rest.basicDetails.hotelName ===
							restaurant?.basicDetails?.hotelName
					);

					// Mark all matched restaurants as processed
					matchedRestaurants.forEach((rest) =>
						processedRestaurantNames.add(rest.basicDetails.venueName)
					);

					// Combine primary restaurant with its matched restaurants
					const combinedRestaurants = [
						restaurant,
						...matchedRestaurants.filter(
							(rest) => rest !== restaurant
						), // Avoid duplicate
					];

					// Find the appropriate slot based on alcohol status
					let availableSlot;
					if (isAlcoholic) {
						availableSlot = findNextAvailableAlcoholicSlot();
					} else {
						availableSlot = findNextAvailableNonAlcoholicSlot();
					}

					// If an available slot is found, add the combined restaurants to that slot
					if (availableSlot) {
						availableSlot.restaurants.push(...combinedRestaurants);
					}
				}
			});

			// only for limited users limit condition will run means if condition
			if (
				limitedvenues &&
				(checkAdmin == 'false' || checkAdmin == 'null')
			) {
				// Access the assigned venues of limited user
				const limitedAssignedVenues =
					limitedvenues?.user?.RestaurantForm;

				console.log({ limitedAssignedVenues });

				// Check if assignedVenues is an array and has data
				if (
					Array.isArray(limitedAssignedVenues) &&
					limitedAssignedVenues.length > 0
				) {
					// Extract hotelName and venueName from all assigned venues
					const filteredVenues = combineRestaurantSlots
						?.map((slot) => {
							console.log(slot, 'slot');
							// Filter the restaurats within the current slot
							const filteredSlotVenues = slot?.restaurants?.filter(
								(venue) => {
									return limitedAssignedVenues?.some(
										(assignedVenue) => {
											return assignedVenue._id === venue._id;
										}
									);
								}
							);

							// Return the slot with only the matched restaurants
							return {
								...slot,
								restaurants: filteredSlotVenues,
							};
						})
						.filter((slot) => slot?.restaurants?.length > 0); // Only include slots with matched venues

					setUniqueRestaurantsWithVenues(filteredVenues);
				}
			} else {
				// If limitedvenues is undefined or null, return all restaurants
				setUniqueRestaurantsWithVenues(combineRestaurantSlots);
			}
		}
	}, [data]);

	// for slotss one multi-slots single slots
	useEffect(() => {
		console.log({ data });
		if (data?.venueForms && data?.items) {
			const items = data.items;
			const combineVenue = [];

			// Step 1: Create slots in combineVenue based on the `items` array
			items.forEach((item, i) => {
				if (item.answer === 'no') {
					combineVenue.push({
						venueName: `${
							data.selectedChips.length === 1 &&
							data.selectedChips.includes('Kids Play Area')
								? `Unique Location ${i + 1} - Kids`
								: `Unique Venue ${i + 1}`
						}`,
						venues: [],
					});
				} else {
					combineVenue.push({
						venueName: `${
							data.selectedChips.length === 1 &&
							data.selectedChips.includes('Kids Play Area')
								? `Unique Location ${i + 1} - Kids`
								: `Multi Venue ${i + 1}`
						}`,
						venues: [],
					});
				}
			});

			// Step 2: Process venue forms and assign them to the correct slots
			data.venueForms.forEach((venue) => {
				const venueHotelName = venue?.basicDetails?.slotName;

				// Find the matching slot where the venueName includes the hotelName
				const matchingSlot = combineVenue.find((slot) =>
					slot.venueName.includes(venueHotelName)
				);

				if (matchingSlot) {
					// If a matching slot is found, push the venue into that slot's venues array
					matchingSlot.venues.push(venue);
				}
			});

			// only for limited users limit condition will run means if condition
			if (
				limitedvenues &&
				(checkAdmin == 'false' || checkAdmin == 'null')
			) {
				// Access the assigned venues of limited user
				const limitedAssignedVenues =
					limitedvenues?.user?.assignedVenues;

				// Check if assignedVenues is an array and has data
				if (
					Array.isArray(limitedAssignedVenues) &&
					limitedAssignedVenues.length > 0
				) {
					// Extract hotelName and venueName from all assigned venues
					const filteredVenues = combineVenue
						.map((slot) => {
							// Filter the venues within the current slot
							const filteredSlotVenues = slot.venues.filter(
								(venue) => {
									return limitedAssignedVenues.some(
										(assignedVenue) => {
											return assignedVenue._id === venue._id;
										}
									);
								}
							);

							// Return the slot with only the matched venues
							return {
								...slot,
								venues: filteredSlotVenues,
							};
						})
						.filter((slot) => slot.venues.length > 0); // Only include slots with matched venues

					setMultipleVenuesInSameCity(filteredVenues);
				}
			} else {
				// If limitedvenues is undefined or null, return all venues
				setMultipleVenuesInSameCity(combineVenue);
			}
		}
	}, [data, hotelVenue, uniqueRestaurantsWithVenues]);

	// worst way to fetch user
	useEffect(() => {
		const fetchUser = async () => {
		  try {
			const response = await fetchLoggedUser();
			console.log(response?.vendor,"hole")
			setUser(response.vendor)
		  } catch (error) {
			console.log(error)
		  }
		}
		fetchUser();
	  }, [])
	return (
		<DashboardLayout>
			<div className='flex justify-center bg-white w-full'>
				<div className='w-[95%]'>
					<h1 className='text-2xl font-semibold'>My Venues</h1>
					<div className='flex items-center gap-3'>
						<p className='font-medium text-primary'>Dashboard</p>
						<MdKeyboardArrowRight />
						<p className='font-medium'>
							{location.pathname === '/myVenue' ? 'My venues' : ''}
						</p>
					</div>

					<div className='p-4'>
						<div className='text-2xl font-bold mb-4'>My Venues</div>

						{/* Hotel Venues */}
						{/* {hotelVenue?.venues?.length > 0 &&
							hotelVenue.venues.every(
								(venue) => venue !== undefined
							) && (
								<VenueCard
									// title={hotelVenue.hotelName}
									title='Hotel'
									type=''
									category='Venue'
									combineData='hotel'
									multiVenue={true}
									data={hotelVenue.venues}
								/>
							)} */}
							{/* hotels,veues part only  */}
{(user?.admin || user?.assignedVenues.length > 0 || (user?.Hotel && user?.Hotel?.length > 0)) ? (
  hotelVenue?.venues?.length > 0 &&
  hotelVenue.venues.every((venue) => venue !== undefined) && (
    <VenueCard
      title='Hotel'
      type=''
      category='Venue'
      combineData='hotel'
      multiVenue={true}
      data={hotelVenue.venues}
    />
  )
) : (
	// for limited if he is not admin than dont show
  hotelVenue?.venues?.length > 0 && checkAdmin =="true" &&
  hotelVenue.venues.every((venue) => venue !== undefined) && (
    <VenueCard
      title='Hotel'
      type=''
      category='Venue'
      combineData='hotel'
      multiVenue={true}
      data={hotelVenue.venues}
    />
  )
)}


						
{/* venues part */}
						{multipleVenuesInSameCity?.length > 0 ? (
							multipleVenuesInSameCity?.map((cityVenue, index) => (
								<VenueCard
									key={index}
									category='Venue'
									combineData='Venue'
									title={cityVenue.venueName}
									type={
										cityVenue.venueName.includes('Multi Venue')
											? 'Multi Venue'
											: 'Single Venue'
									}
									multiVenue={
										cityVenue.venueName.includes('Multi Venue')
											? true
											: cityVenue.venueName.includes(
													'Unique Venue'
											  ) && cityVenue?.venues?.length < 1
											? true
											: false
									}
									data={cityVenue?.venues}
								/>
							))
						) : (
							<p></p> // You can customize the fallback message here.
						)}

						{/* Unique Venues by City */}
						{/* {
              uniqueCitiesWithVenues?.map((cityVenue, index) => (
                <VenueCard
                  key={index}
                  category="Venue"
                  combineData="Venue"
                  title={cityVenue.venueName}
                  type={cityVenue.venueName.includes("Multi Venue in Slot") ? "Multi Venue" : "Single Venue"}
                  multiVenue={
                    cityVenue.venueName.includes("Multi Venue in Slot")
                      ? true
                      : cityVenue.venueName.includes("Unique Venue in Slot") && cityVenue?.venues?.length < 1
                        ? true
                        : false
                  }
                  data={cityVenue?.venues}
                />

              ))
            ) : (
              <p></p> // You can customize the fallback message here.
            )}
            {/* Multiple Resturant */}
						{/* {uniqueRestaurantsWithVenues.length > 0 ? (
							uniqueRestaurantsWithVenues.map((cityVenue, index) => (
								<VenueCard
									key={index}
									category='Venue'
									combineData='Restaurant'
									title={cityVenue.restaurantName}
									type=''
									multiVenue={true}
									data={cityVenue.restaurants}
								/>
							))
						) : (
							<p></p>
						)} */}

						{/* multiple restaurants */}
{(user?.admin || (user?.RestaurantForm && user?.RestaurantForm.length > 0)) ? (
  uniqueRestaurantsWithVenues.length > 0 ? (
    uniqueRestaurantsWithVenues.map((cityVenue, index) => (
      <VenueCard
        key={index}
        category='Venue'
        combineData='Restaurant'
        title={cityVenue.restaurantName}
        type=''
        multiVenue={true}
        data={cityVenue.restaurants}
      />
    ))
  ) : (
    <p></p> // Placeholder when there are no venues and the user is either an admin or has submitted the form
  )
) : (
  uniqueRestaurantsWithVenues.length > 0 ? (
    uniqueRestaurantsWithVenues.map((cityVenue, index) => (
      <VenueCard
        key={index}
        category='Venue'
        combineData='Restaurant'
        title={cityVenue.restaurantName}
        type=''
        multiVenue={true}
        data={cityVenue.restaurants}
      />
    ))
  ) : (
    <p></p> // Placeholder when there are no venues and the user is neither an admin nor has submitted the form
  )
)}


					</div>
				</div>
			</div>
		</DashboardLayout>
	);
};

export default MyVenues;
