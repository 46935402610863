'use client'
import React, { useState } from 'react'
import Navbar from '../../components/webComps/Navbar'
import { IoIosArrowForward } from "react-icons/io";
import img from '../../assets/pics/fmvImage.png'
import banner from '../../assets/pics/aboutBanner.png'
import shadeLeft from '../../assets/pics/shadeLeft.svg'
import shadeRight from '../../assets/pics/shadeRight.svg'
import trustImg from '../../assets/pics/trustImg.png'
import Footer from '../../components/webComps/Footer';

const About = () => {
    const [activeTab, setActiveTab] = useState('venueOperators');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='overflow-hidden'>
            <Navbar />
            <div className='w-full xl:h-[80vh] h-[40vh] bg-aboutBanner bg-center bg-cover relative flex justify-center items-center bg-no-repeat'>
                <div className='bg-[#000000CC] absolute top-0 left-0 w-full h-full z-40'></div>
                <div className='flex items-center text-xs absolute top-4 left-8 z-50'>
                    <p className='text-[#B0B0B0]'>Home</p>
                    <IoIosArrowForward className='text-white' />
                    <p className='text-[#B0B0B0]'>Search Result</p>
                    <IoIosArrowForward className='text-white' />
                    <p className='text-[#FE4747]'>About us</p>
                </div>
                <div className='text-white flex flex-col items-center z-50 gap-3 lg:w-[45%] w-[70%]'>
                    <h1 className='sm:text-5xl text-3xl text-center'>About Us</h1>
                    <p className='text-center sm:text-lg text-sm'>We connect event planners with exceptional venues across the UAE.</p>
                </div>
            </div>
            <div className='flex justify-center py-16'>
                <div className='w-[88%] flex flex-col gap-40'>

                    {/* Effortless Sections */}
                    <div className='flex lg:flex-row flex-col  items-start gap-12'>
                        <div className='lg:w-1/2 w-full flex lg:justify-start justify-center  flex-col items-start gap-4'>
                            <h1 className='text-4xl font-semibold'>Effortless event planning starts here</h1>
                            <p className='text-lg text-justify'>Welcome to Find My Venue, your go-to destination for discovering the ideal venue.
                                Launched in 2024, we are UAE's fastest-growing online marketplace for event spaces.
                                From chic restaurants and spacious conference centers to unconventional spaces,
                                planning your next corporate gathering or personal celebration is just a click
                                away. Explore and compare venues with ease.  </p>
                            <p className='underline text-lg font-medium'>Find My Venue</p>
                        </div>
                        <div className='lg:w-1/2 w-full flex lg:justify-start justify-center'>
                            <img src={img} alt="" />
                        </div>
                    </div>

                    {/* idea to innovation */}
                    <div className='flex flex-col items-center gap-8 relative'>
                        <img src={shadeRight} className='w-full h-full absolute top-20 -right-[30%]' alt="" />
                        <img src={shadeLeft} className='w-full h-full absolute top-20 -left-[30%]' alt="" />
                        <div className='w-full'>
                            <img src={banner} className='w-full h-full' alt="" />
                        </div>
                        <h1 className='sm:text-4xl text-3xl font-semibold mt-6'>From idea to innovation: Our story</h1>
                        <p className='sm:text-lg text-sm text-justify'>Find My Venue was born from a simple desire; to create a seamless way to
                            discover unique event spaces. It all began when our seasoned event planners
                            Jharna and Harsha witnessed firsthand the frustration of clients struggling
                            to find distinctive venues. Clients yearned for hidden gems, venues with
                            distinctive personalities, and settings that aligned with their unique
                            visions. However, the existing search methods proved tedious and disjointed,
                            leaving both venue seekers and operators frustrated. Their shared vision
                            sparked the idea of Find My Venue, a premier solution for efficient
                            venue discovery and a verified lead generator for our fellow venue
                            managers.</p>
                    </div>

                    {/* Find My Venue */}
                    <div className='flex items-center lg:flex-row flex-col'>
                        <h1 className='sm:text-4xl text-3xl sm:text-start text-center font-semibold'>The Find My Venue Advantage</h1>

                        <div className=' flex flex-col items-center gap-4 rounded-xl mt-8 bg-[#F1F1F1] p-6 sm:px-12 px-4'>
                            <div className='flex sm:gap-8 gap-4  justify-between md:w-[60%] sm:w-[90%] w-full'>
                                <h1
                                    className={`sm:text-3xl text-2xl font-semibold cursor-pointer ${activeTab === 'venueOperators' ? 'text-[#F51919]' : ''}`}
                                    onClick={() => handleTabClick('venueOperators')}
                                >
                                    For Venue Operators
                                </h1>
                                <h1
                                    className={`sm:text-3xl text-2xl font-semibold cursor-pointer ${activeTab === 'eventPlanners' ? 'text-[#F51919]' : ''}`}
                                    onClick={() => handleTabClick('eventPlanners')}
                                >
                                    For Event Planners
                                </h1>
                            </div>

                            <div className='flex flex-col gap-10 mt-12'>
                                {activeTab === 'venueOperators' && (
                                    <div className='gap-8'>
                                        <div>
                                            <h1 className='sm:text-2xl text-xl font-semibold cursor-pointer'>Targeted Marketing</h1>
                                            <p className='text-lg mt-2'>Get discovered by event planners ensuring your space is always highly visible. </p>
                                        </div>
                                        <div>
                                            <h1 className='sm:text-2xl text-xl font-semibold cursor-pointer'>Reduced Downtime</h1>
                                            <p className='text-lg mt-2'>Say goodbye to empty calendars. Get year-round exposure and minimise the risk of seasonal downtime.</p>
                                        </div>
                                        <div>
                                            <h1 className='sm:text-2xl text-xl font-semibold cursor-pointer'>Global Reach</h1>
                                            <p className='text-lg mt-2'>Tap into a worldwide network of event planners, expanding your venue's reach beyond borders. </p>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'eventPlanners' && (
                                    <div className='gap-8'>
                                        <div>
                                            <h1 className='sm:text-2xl text-xl font-semibold cursor-pointer'>Unique Venues</h1>
                                            <p className='text-lg mt-2'>We list distinctive venues across the UAE that are otherwise difficult to discover. </p>
                                        </div>
                                        <div>
                                            <h1 className='sm:text-2xl text-xl font-semibold cursor-pointer'>Hassle-Free Search</h1>
                                            <p className='text-lg mt-2'>Our advanced technology and filters ensure you effortlessly find the venue that perfectly matches your needs.
                                            </p>
                                        </div>
                                        <div>
                                            <h1 className='sm:text-2xl text-xl font-semibold cursor-pointer'>Absolutely Free
                                            </h1>
                                            <p className='text-lg mt-2'>Explore endless list of venues with no hidden charges or expenses.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>


                    {/* Verified Section */}
                    <div className='flex lg:flex-row flex-col items-start gap-12'>
                        <div className='lg:w-1/2 w-full flex lg:justify-start justify-center'>
                            <img src={trustImg} alt="" />
                        </div>
                        <div className='lg:w-1/2 w-full flex flex-col items-start gap-4'>
                            <h1 className='sm:text-4xl text-3xl font-semibold'>Verified Venues Vetted By Our Team</h1>
                            <p className='sm:text-lg text-sm text-justify'>All venue listings are personally vetted by our team. We meticulously research and curate a collection of exceptional event spaces. Discover hidden gems, rooftop terraces, art galleries, and more across the UAE, catering to a variety of event themes, sizes, and budgets.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default About
