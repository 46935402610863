/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { saveHotelData, updateHotelForm } from '../../api/hotel-api';
import { toast } from 'react-toastify';
import { HotelContext } from '../../context/HotelContext';

const HeroSection = ({ data }) => {
    const { formData, setFormData } = useContext(HotelContext);
    const navigate = useNavigate();
    const vendorId = localStorage.getItem('vendorId');

    const handleSaveData = async (status = 'pending') => {
        let errorMessage = '';
        let hasErrors = false;

        // Validate Overview step
        const validateOverview = () => {
            const basicDetails = formData?.overview?.basicDetails || {};
            const additionalDetails = formData?.overview?.additionalDetails || {};
            return (
                !basicDetails?.hotelName ||
                !basicDetails?.hotelRating ||
                !basicDetails?.hotelWebsite ||
                !basicDetails?.hotelDescription ||
                !additionalDetails?.hotelBuiltYear ||
                !additionalDetails?.tradeLicenseFile
            );
        };

        // Validate Accommodation step
        const validateAccommodation = () => {
            const accommodation = formData?.accommodation || {};
            return !accommodation?.accommodations && accommodation?.accommodations !== 0;
        };

        // Validate Pricing step
        const validatePricing = () => {
            const pricing = formData?.pricing || {};
            return !pricing?.basePrice;
        };

        // Validate Photos & Videos step
        const validatePhotosAndVideos = () => {
            const photoVideo = formData?.photoVideo || {};
            return !photoVideo?.images || photoVideo.images.length === 0;
        };

        // Run validations
        if (validateOverview()) {
            errorMessage += 'Data is missing in Overview.\n';
            hasErrors = true;
        }
        if (validateAccommodation()) {
            errorMessage += 'Data is missing in Accommodation.\n';
            hasErrors = true;
        }
        if (validatePricing()) {
            errorMessage += 'Data is missing in Pricing.\n';
            hasErrors = true;
        }
        if (validatePhotosAndVideos()) {
            errorMessage += 'Data is missing in Photos & Videos.\n';
            hasErrors = true;
        }

        // Show error messages if there are any
        if (hasErrors) {
            toast.error(errorMessage.trim());
            return; // Exit the function early if there are errors
        }

        // Determine the updated status
        const updatedItemStatus = formData?.itemStatus === 'Approved'
            ? 'Edit Pending'
            : formData?.itemStatus === 'Rejected'
                ? 'ReSubmitting'
                : 'pending';

        // Manually construct the updated form data
        const updatedFormData = {
            ...formData,
            itemStatus: updatedItemStatus,
            vendorId: vendorId,
            status: status === 'completed' ? 'completed' : 'pending' // Ensure status is set correctly
        };

        // Save the hotel data
        try {
            const response = await updateHotelForm(updatedFormData);

            if (response.success) {
                toast.success('Hotel detail sent for review!');
                if (status === 'completed') {
                    setTimeout(() => {
                        navigate('/dashboard?hotelRegister=true');
                    }, 1000);
                }
            } else {
                toast.error(response.error || 'An error occurred. Please try again.');
            }
        } catch (error) {
            toast.error(error.response?.data?.error || 'An error occurred. Please try again.');
            console.error('Error:', error);
        }
    };



    return (
        <div className="p-6">
            <div className="flex flex-wrap justify-between gap-5  px-5 lg:px-16 items-center w-full mb-6">
                <Link to="/hostel-Listing-Form?lastStep=lastStep" className="flex items-center text-gray-600 cursor-pointer">
                    <FaArrowLeft className="mr-2" />
                    <span>Back</span>
                </Link>
                <div className='flex gap-3 items-center'>
                    <button
                        className="bg-black text-white px-4 py-2 rounded-lg mr-2"
                        onClick={() => handleSaveData('completed')}
                    >
                        Submit for review
                    </button>
                    <Link
                        to="/hostel-Listing-Form"
                        className="bg-red-500 text-white px-4 py-2 rounded-lg flex items-center"
                    >
                        <AiOutlineEdit className="mr-2" /> Edit
                    </Link>
                </div>
            </div>
            <div className="flex justify-between flex-wrap gap-1 px-5 lg:px-16 w-full lg:gap-0 mb-4">
                {data[0] && (
                    <div className="relative lg:w-1/2 w-full">
                        <img
                            src={data[0]}
                            alt="Main Image"
                            className="rounded-lg w-full h-[500px] object-cover"
                        />
                    </div>
                )}
                <div className="flex flex-col justify-between lg:w-[49%] w-full">
                    {data[1] && (
                        <img
                            src={data[1]}
                            alt="Image 2"
                            className="rounded-lg mb-4 h-[250px] object-cover"
                        />
                    )}
                    <div className="flex justify-between gap-1 flex-wrap">
                        {data[2] && (
                            <img
                                src={data[2]}
                                alt="Image 3"
                                className="rounded-lg mb-0 h-[250px] w-[49%] object-cover"
                            />
                        )}
                        {data.length > 3 && (
                            <div className="h-[250px] w-[49%] bg-black bg-opacity-50 flex items-center justify-center rounded-lg text-white">
                                <span>Show all photos</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
