import costStructure from "../constants/costStructure";

function calculateVenuePayment(quantityNumber, sameLocation, items = []) {
  let baseCharge = 0;
  let subTotal = 0;


  // Count the number of "yes" and "no" answers
  // let yesCount = 0;
  // let noCount = 0;

  // items.forEach(item => {
  //   if (item.answer === 'yes') {
  //     yesCount++;
  //   } else if (item.answer === 'no') {
  //     noCount++;
  //   }
  // });

  // console.log("Yes Count:", quantityNumber);
  // console.log("No Count:", noCount);

  // Calculate base charge based on the counts
  baseCharge = 1000 * items?.length;

  // Calculate subtotal based on quantityNumber and sameLocation logic
  // if (quantityNumber > 1) {
  //   subTotal = baseCharge * quantityNumber;
  // } else if (sameLocation === "yes") {
  //   subTotal = baseCharge;
  // }

  console.log("Base Charge:", baseCharge);
  subTotal = baseCharge;
  console.log("Subtotal:", subTotal);

  // Calculate VAT
  const vatPercentage = costStructure.vat;
  const vat = (subTotal * vatPercentage) / 100;

  // Calculate Grand Total
  const grandTotal = subTotal + vat;

  console.log("VAT:", vat);
  console.log("Grand Total:", grandTotal);

  return {
    baseCharge,
    quantityNumber,
    subTotal,
    vat,
    grandTotal,
  };
}


export default calculateVenuePayment;


// import costStructure from "../constants/costStructure";

// function calculateKidsPayment(quantityNumber, sameLocation , items) {
//   let baseCharge = 1000;
//   let subTotal = 0;

//   if (sameLocation === "no") {
//     subTotal = baseCharge * quantityNumber;
//   } else if (sameLocation === "yes") {
//     subTotal = baseCharge;
//   }

//   const vatPercentage = costStructure.vat;
//   const vat = (subTotal * vatPercentage) / 100;

//   const grandTotal = subTotal + vat;

//   return {
//     baseCharge: baseCharge,
//     quantityNumber: quantityNumber,
//     subTotal: subTotal,
//     vat: vat,
//     grandTotal: grandTotal,
//   };
// }

// export default calculateKidsPayment;
