import React from 'react'
import { IoIosArrowDown } from "react-icons/io";
import img from '../../assets/pics/homeImg.png'
import { IoIosCheckmarkCircle } from "react-icons/io";
import shadeRight from '../../assets/pics/shadeRight.svg'
import shadeLeft from '../../assets/pics/shadeLeft.svg'


const Hero = () => {
    return (
        <div className='relative mx-auto'>
            <div className='flex justify-center w-full sm:pt-20 pt-12 mx-auto '>
                <div className='w-[90%]  flex items-center  gap-10 justify-between' >
                    <div className='lg:w-[60%] w-full flex flex-col sm:items-start items-center sm:gap-10 gap-4'>
                        <h1 className='xl:text-[65px] sm:text-5xl text-3xl sm:text-start text-center font-bold leading-none'>
                            Search & Compare
                            <span className='text-[#FF3939]'> Venues Across UAE.</span>
                        </h1>
                        <p className='sm:text-lg text-sm text-center'>The UAE’s #1 venue search platform for discovering venues for meetings, corporates, parties, wedding & beyond. </p>
                        <div className='bg-white py-3 px-5 flex md:gap-0 gap-4 justify-between md:flex-row w-full flex-col h-full rounded-lg shadow-lg' >

                            <div className='xl:w-[84%] w-full md:gap-0 gap-4 grid md:grid-cols-3 grid-cols-1 h-full' >
                                <div className='flex md:w-auto w-full'>
                                    <div className='md:w-auto w-full'>
                                        <h1 className='text-lg '>Event/Venue</h1>
                                        <div className='flex items-center gap-2 w-full'>
                                            <input type="text" className='w-[90%]' placeholder='Start typing...' name="" id="" />
                                            <IoIosArrowDown className='text-[#FE4747] w-[10%] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-[2px] md:block hidden h-full bg-[#CECECE] mx-8' ></div>
                                </div>


                                <div className='flex md:w-auto w-full'>
                                    <div className='md:w-auto w-full'>
                                        <h1 className='text-lg '>No. of guests</h1>
                                        <div className='flex items-center gap-2 w-full'>
                                            <input type="text" className='w-[90%]' placeholder='Enter no. of guests' name="" id="" />
                                            <IoIosArrowDown className='text-[#FE4747] w-[10%] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-[2px] md:block hidden h-full bg-[#CECECE] mx-8' ></div>
                                </div>


                                <div className='flex md:w-auto w-full'>
                                    <div className='md:w-auto w-full'>
                                        <h1 className='text-lg '>City</h1>

                                        <div className='flex items-center gap-2 w-full'>
                                            <input type="text" className='w-[90%]' placeholder='Enter Location' name="" id="" />
                                            <IoIosArrowDown className='text-[#FE4747] w-[10%] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-[2px] md:block hidden h-full  mx-8' ></div>
                                </div>
                            </div>

                            <div className='flex h-full  md:w-[16%] w-full justify-center items-center'>
                                <button className='bg-[#FE4747] md:w-auto w-full rounded-lg text-white py-2 px-4' >Search</button>
                            </div>

                        </div>
                        <p className='underline text-[#FF3939]'>Search options for Stay+Venue</p>
                    </div>
                    <div className='w-[40%] lg:flex hidden  justify-center'>
                        <img src={img} alt="" />
                    </div>
                </div>
            </div>
            <div className='bg-[#FFF5F5] flex justify-center py-3 px-2 w-full mt-10'>
                <div className='sm:flex hidden items-center gap-2'>
                    <IoIosCheckmarkCircle className='text-[#FE4747] text-2xl' />
                    <h1 className='text-[#222222] text-lg font-semibold'>No Booking fees, No commission - Just Connections.</h1>
                </div>
                <div className='sm:hidden flex items-center justify-between gap-5'>
                    <div className='flex items-center gap-2'>
                        <IoIosCheckmarkCircle className='text-[#FE4747] text-xl' />
                        <h1 className='text-[#222222] text-xs font-semibold'>No Booking fees</h1>
                    </div>
                    <div className='w-[2px] h-full bg-[#FE4747]' ></div>
                    <div className='flex items-center gap-2'>
                        <IoIosCheckmarkCircle className='text-[#FE4747] text-xl' />
                        <h1 className='text-[#222222] text-xs font-semibold'>No commission - Just Connections.</h1>
                    </div>
                </div>
            </div>
            <img src={shadeRight} className='absolute -top-[20%] right-0' alt="" />
            <img src={shadeLeft} className='absolute -top-[20%] left-0' alt="" />
        </div>
    )
}

export default Hero
