import React from 'react';
import ResponsiveAppBar from "../../components/ResponsiveAppBar";


const PrivacyPolicy = () => {
    return (
        <>
            <ResponsiveAppBar />
            <div

                className='flex justify-center '
            >
                <div className="min-h-screen  sm:p-8 p-4 md:w-[60%] sm:w-[80%] w-full">
                    <h1 className="text-3xl font-bold text-center mb-8 text-custom">Privacy Policy</h1>
                    <div className="mt-8 text-custom">

                        <p className='mb-4 font-bold'>
                            INTRODUCTION
                        </p>
                        <p className='mb-4'>
                            This domain name www.findmyvenue.com and the associated mobile application along with all upgrades, updates, enhancement and modifications thereto (hereinafter, the “Platform”) is owned by Find My Venue, a company having its registered office address at Meydan Grand Stand, 6th floor, Meydan Road, Nad Al Sheba, Dubai, UAE (hereinafter referred to as “Find My Venue” which expression shall, unless it be repugnant to the context thereof, be deemed to include its successors, affiliates, and permitted assigns).
                        </p>
                        <p className='mb-4'>
                            Find My Venue is committed to respecting your online privacy and recognizes your need for appropriate protection and management of any information you share with Find My Venue on the Platform. This privacy policy (“Policy”) explains how Find My Venue will collect, use, share and process information in relation to the Services provided on the Platform.
                        </p>
                        <p className='mb-4'>
                            This Policy shall be deemed to be incorporated into the terms of use (“Terms”) and shall be read in addition to the Terms. In the event of any conflict between this Policy and the Terms, the interpretation placed by Find My Venue shall be final and binding on you.
                        </p>
                        <p className='mb-4'>
                            Find My Venue, through the Platform, assists and connects the Users (as defined in the Terms) with Venue Operators wherein the Users can browse through the services offered by the Venue Operators and send User Enquiries to Venue Operators on the Platform, in accordance with the provisions of the Terms.
                        </p>
                        <p className='mb-4'>
                            By accepting this Policy, through an affirmative action, you provide free, specific, informed, unconditional and unambiguous consent to Find My Venue and understand and agree to the collection, use, sharing and processing of personal information as described herein. If you provide Find My Venue with personal information about someone else, you confirm that (a) such information is accurate and up-to-date; (b) such person is aware that you have provided their information; and (c) they consent to both, the disclosure and the use/processing of their information in accordance with this Policy. This Policy applies to all the current and former visitors, users, vendors and others who access the Platform.
                        </p>


                        <p className='mb-4 font-bold'>
                            SCOPE
                        </p>
                        <p className='mb-4'>
                            This Policy is an electronic record in the form of an electronic contract formed under the Federal Decree Law No.46 of 2021 on Electronic Transactions and Trust Services. This Policy does not require any physical, electronic, or digital signature.
                        </p>
                        <p className='mb-4'>
                            By agreeing, accessing or using the Platform or giving Find My Venue your information or otherwise clicking to accept this Policy, if and when prompted on the Platform, you undertake that you have the capacity to enter into a legally binding contract vide this Policy, which constitutes a legally binding document between you and Find My Venue under applicable law. Find My Venue will collect and process your personal information and third-party data carefully, only for the purposes described in this Policy and only to the extent necessary as defined herein and within the scope of the applicable legal regulations. This Policy seeks to ensure that any personal information or third-party information handled by Find My Venue is managed in a way that is ethical, compliant and adheres to best industry practices.
                        </p>
                        <p className='mb-4'>
                            Please read the terms and conditions of this Policy carefully, before accepting the same and/or accessing or using the Platform. By accessing or using the Platform including its Services or otherwise clicking to accept this Policy, if and when prompted on the Platform, you agree to the terms of this Policy. If you are accepting this Policy on behalf of another person or company or other legal entity, you represent and warrant that you have full authority to bind such person, company or legal entity to these terms. If you are accepting this Policy on behalf of a child, you represent and warrant that you are a parent or legal guardian of such a child.
                        </p>
                        <p className='mb-4'>
                            This Policy describes the types of information Find My Venue collects, why and how Find My Venue uses the information, with whom Find My Venue shares it, and the choices you can make about Find My Venue’s use of the information. This Policy also describes the measures Find My Venue takes to protect the security of the information and how you can contact Find My Venue about its privacy practices.
                        </p>
                        <p className='mb-4'>
                            This Policy describes Find My Venue’s current data protection policies and practices and may be amended/updated from time to time. The Policy shall come to effect from the date of such update, change or modification. It is recommended that you regularly check this Policy to apprise yourself of any updates. Your acceptance or continued use of the Platform or provision of data or information thereafter will imply your unconditional acceptance of such updates to this Policy.
                        </p>


                        <p className='mb-4 font-bold'>
                            CONSENT
                        </p>
                        <p className='mb-4'>
                            By performing the affirmative action, as deem fit by Find My Venue, you expressly, freely, unconditionally, and unambiguously consent to Find My Venue’s use and disclosure of your Personal Information (as defined below) and third-party information in accordance with this Policy. If you do not agree with the terms of this Policy, please do not accept this Policy or use the Platform.
                        </p>
                        <p className='mb-4'>
                            In case you wish to avail any or all of the Services provided by Find My Venue on the Platform, you are required to register on the Platform and thereafter access the Platform using the login credentials provided by you at the time of registration as set out in the Terms (“Login Credentials”). You hereby explicitly agree that your use of the Login Credentials shall be governed by the Terms read with the terms of this Policy.
                        </p>

                        <p className='mb-4 font-bold'>
                            TYPES OF INFORMATION
                        </p>
                        <p className='mb-4'>
                            Personal Information is defined as any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such person.
                        </p>
                        <p className='mb-4'>
                            Personal Information: Personal Information means any information that may be used to identify an individual, including, but not limited to, the first and last names, telephone number, e-mail address, or any other contact information, date of birth and/or date of anniversary (as the case may be). Find My Venue limits the collection of Personal Information to that which is necessary for its intended purpose.
                        </p>
                        <p className='mb-4'>
                            Non-Personal Information: Non-personal information means information that does not specifically identify an individual, but includes without limitation information from you, such as your browser type, the URL of the previous websites you visited, your Internet Service Provider (ISP), operating system and your Internet Protocol (IP) address. Find My Venue may gather any non-personal information regarding how many people visit the Platform, the pages they visit, their IP address, browser types and versions, time zone settings and locations, operating systems, device, connection information, screen resolution, usage statistics, default communication applications and other technology on the devices you use to access the Platform (hereinafter referred to as “Non-Personal Information”). Find My Venue may also collect Non-Personal Information that you voluntarily provide, such as information included in response to a questionnaire, or a survey conducted by Find My Venue.
                        </p>
                        <p className='mb-4'>
                            Usage Information: Usage Information includes without limitation all data and information collected automatically through the Platform (or through the third party analytics service providers), by use and access of the Platform in the nature of system administrative data, statistical and demographical data, and operational information and data generated by or characterizing use of the Platform including without limitation Non-Personal Information, cookies, Platform traffic, time spent on the Platform, number of visits to the Platform, length of the visit, pages viewed, searches made and other similar information and behaviour indicating the mode and manner of use of the Platform (hereinafter referred to as the “Usage Information”).
                        </p>
                        <p className='mb-4'>
                            Personal Information, Non-Personal Information and Usage Information hereinafter shall be referred to as “Information”.
                        </p>


                        <p className='mb-4 font-bold'>
                            COLLECTION OF INFORMATION
                        </p>
                        <p className='mb-4'>
                            Find My Venue may collect Information from you when you (a) register on the Platform; (b) update or change personal details in your Account; (c) voluntarily participate in campaigns conducted by Find My Venue or respond to questionnaires published by Find My Venue on the Platform (if any); (d) voluntarily complete a survey or provide feedback in relation to the Services and offerings of Venue Operators provided on the Platform; (e) browse through the offerings and/or services on the Platform; and (f) make User Enquiries on the Platform.
                        </p>
                        <p className='mb-4'>
                            You hereby acknowledge and agree that all Information is provided by you to Find My Venue voluntarily and the Information provided by you is not subject to any undue influence.
                        </p>
                        <p className='mb-4'>
                            Find My Venue may use cookies to monitor the Platform usage in accordance with paragraph 13 of this Policy including, without limitation, to provide useful features to simplify your experience when you return to the Platform, like remembering your login id and to deliver relevant content based on your preferences, usage patterns and location. You may also be required to provide your billing address as well as credit/debit card details which may be aligned with third party payment processors. Find My Venue will use this Information only for billing purposes.
                        </p>
                        <p className='mb-4'>
                            Find My Venue may also collect Non-Personal Information based on your browsing activity and in relation to your use or access to the Platform like your Internet Protocol (IP) address, your operating system, your prior search results, etc.
                        </p>
                        <p className='mb-4'>
                            Information collected by Find My Venue from a particular browser or device may be used with another computer or device that is linked to the browser or device on which such information was collected.
                        </p>


                        <p className='mb-4 font-bold'>
                            USE OF INFORMATION
                        </p>
                        <p className='mb-4'>
                            Find My Venue uses the Information you provide to (a) manage your Account; (b) fulfil your requests for the Services offered on the Platform; (c) enable Venue Operators to respond to your enquiries about its offerings available on the Platform; (d) provide you with information about Services available on the Platform and offer you other services that Find My Venue believes may be of interest to you; (e) resolve any glitches on the Platform including addressing any technical problems; (f) improve the Services and content on the Platform and your experience of navigating through the Platform; and (f) manage Find My Venue’s relationship with you.
                        </p>
                        <p className='mb-4'>
                            Find my Venue will share the User Information with the Venue Operators, and/or Vendor Information with the Users for the purpose of responding and/or managing the User Enquiries and any other queries.
                        </p>
                        <p className='mb-4'>
                            Find My Venue may use the Information to monitor your use of the Services and may review and analyse the Information provided by you to provide you with customized services.
                        </p>
                        <p className='mb-4'>
                            Find My Venue may use your Information for internal business purposes, such as marketing, data analysis, database management, reporting statistics, research, developing new features, enhancing and improving existing Services and identifying usage trends. Find My Venue may permit third parties to utilize their own cookies to gather information about your visits to the Platform.
                        </p>
                        <p className='mb-4'>
                            Find My Venue reserves the right to combine the data gathered from cookies on the Platform, as well as information collected through analytics software on the Platform, with other relevant information. This may include information collected by third parties through their own cookies. By amalgamating this information, Find My Venue’s aim is to offer improved and more relevant services and advertising on the Platform. Find My Venue’s service providers and other third parties may also engage in similar practices to deliver more tailored services and advertising across other websites you may visit.
                        </p>
                        <p className='mb-4'>
                            Subject to and in accordance with applicable laws, Find My Venue has the right to use your Information for the purpose of conducting promotional/marketing related activities on the Platform, including but not limited to, using your Personal Information for making posters/banners to promote Find My Venue’s Services.
                        </p>
                        <p className='mb-4'>
                            When you send an email message or otherwise contact Find My Venue through the Platform, Find My Venue may use the Information provided by you to respond to your communication by way of phone calls, messages on the Platform, Short Message Service (SMS), WhatsApp messages, email, messages through social media channels or any other communication channels that Find My Venue may deem fit. Find My Venue may also archive such Information and/or use it for future communications with you to inform you regarding updates, newsletters, offers, new services and promotions.
                        </p>


                        <p className='mb-4 font-bold'>
                            INFORMATION SHARING
                        </p>
                        <p className='mb-4'>
                            Find My Venue maintains your Information in electronic form on its servers. The Information is made accessible to Venue Operators, employees, agents or partners and third-parties only on a need-to-know basis.
                        </p>
                        <p className='mb-4'>
                            Find My Venue does not rent, sell, or share Information with other people or with other non-affiliated entities, except with your consent or to provide Services you have requested for or under the following circumstances:
                        </p>
                        <p className='mb-4'>
                            (a) Find My Venue may share Information with Venue Operators or Find My Venue’s employees to contact you via email, phone, or otherwise for the provision of Services being availed by you on the Platform.
                        </p>
                        <p className='mb-4'>
                            (b) Find My Venue may engage third party vendors and/or contractors, to perform certain support services, who may have limited access to Information.
                        </p>
                        <p className='mb-4'>
                            (c) Find My Venue may share Information with government authorities in response to subpoenas, court orders, or other legal processes; to establish or exercise legal rights; to defend against legal claims; or as otherwise required by law. This may be done in response to a law enforcement agency's request.
                        </p>

                        <p className='mb-4 font-bold'>
                            THIRD PARTY SERVICE PROVIDERS
                        </p>
                        <p className='mb-4'>
                            Find My Venue may engage third party vendors and/or contractors to perform certain support services, including, without limitation, fulfilment of your service requests, software maintenance services, advertising and marketing services, web hosting services and such other related services which are required by Find My Venue to provide its Services efficiently. These third parties may have limited access to Information. If they do, this limited access is provided so that they may perform these tasks for Find My Venue and they are not authorized by Find My Venue to otherwise use or disclose Information, except to the extent required by law. Find My Venue does not make any representations concerning the privacy practices or policies or terms of use of such websites/apps, nor does it control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, messages or other materials available on such websites/apps. The inclusion or exclusion does not imply any endorsement by Find My Venue of the website/app, the website/app's provider, or the information on such website/app.
                        </p>
                        <p className='mb-4'>
                            The Platform may contain links and interactive functionality interacting with the websites of third parties. Find My Venue is not responsible for and has no liability for the functionality, actions, inactions, privacy settings, privacy policies, terms, or content of any such websites. Before enabling any sharing functions to communicate with any such websites or otherwise visiting any such websites, Find My Venue strongly recommends that you review and understand the terms and conditions, privacy policies, settings, and information-sharing functions of each such third-party website.
                        </p>

                        <p className='mb-4 font-bold'>
                            DISCLOSURE TO ACQUIRERS
                        </p>
                        <p className='mb-4'>
                            Find My Venue may disclose and/or transfer Information to an investor, acquirer, assignee or other successor entity in connection with a sale, merger, or reorganization of all or substantially all of Find My Venue’s equity, business or assets.
                        </p>


                        <p className='mb-4 font-bold'>
                            CONTROL OVER YOUR PERSONAL INFORMATION
                        </p>
                        <p className='mb-4'>
                            You have the right to withdraw your consent at any point, provided such withdrawal of the consent is intimated to Find My Venue in writing through an email at support@findmyvenue.com requesting the same. If you at any time wish to rectify your Personal Information, you may write to Find My Venue as per paragraph 11 of this Policy.
                        </p>
                        <p className='mb-4'>
                            Once you withdraw your consent to share the Personal Information collected by Find My Venue, Find My Venue shall have the option not to fulfil the purposes for which the said Personal Information was sought and Find My Venue may restrict you from using the Services on the Platform and/or the Platform itself.
                        </p>
                        <p className='mb-4'>
                            If you wish to delete your Account, and thereby discontinue using the Services provided by Find My Venue on the Platform, you may do so at any time, in accordance with the Terms. In other cases, you may discontinue using the Services provided by Find My Venue.
                        </p>


                        <p className='mb-4 font-bold'>
                            RECTIFICATION/CORRECTION/ERASURE OF PERSONAL INFORMATION
                        </p>
                        <p className='mb-4'>
                            You shall have the right to review your Personal Information submitted by you on the Platform and to modify, correct, erase or delete any Personal Information provided by you directly on the Platform. You hereby understand that any such modification, correction, erasure or deletion may affect your ability to use the Platform. Further, it may affect Find My Venue’s ability to provide its Services to you.
                        </p>
                        <p className='mb-4'>
                            Find My Venue reserves the right to verify and authenticate your identity and your Account Information in order to ensure accurate delivery of Services. Access to or correction, erasure, updation or deletion of your Personal Information may be denied or limited by Find My Venue if it would violate another person’s rights and/or is not otherwise permitted by applicable law.
                        </p>
                        <p className='mb-4'>
                            If you need to update, erase or correct your Personal Information that Find My Venue may have collected to offer you Services including, personalized services and offers, you may send updates, request for erasure and corrections to Find My Venue at support@findmyvenue.com citing the reason for such rectification or erasure of Personal Information. Find My Venue will take all reasonable efforts to incorporate the changes within a reasonable period of time and such corrections shall be subject to validations, necessary documents, etc., as per requirement.
                        </p>


                        <p className='mb-4 font-bold'>
                            TERM OF STORAGE OF PERSONAL INFORMATION
                        </p>
                        <p className='mb-4'>
                            Find My Venue shall store your Personal Information at least for such period as may be required and permitted by law or for a period necessary to satisfy the purpose for which the Personal Information has been collected. These periods vary depending on the nature of the information and your interactions with Find My Venue.
                        </p>
                        <p className='mb-4'>
                            You agree that you will not submit any false information or any illegal or damaging content to the Platform.
                        </p>
                        <p className='mb-4'>
                            Find My Venue reserves the right to terminate access to or the ability to interact with the Platform in response to any concerns Find My Venue may have about false, illegal, or damaging content, or for any other reason, in its sole discretion.
                        </p>

                        <p className='mb-4 font-bold'>
                            COOKIES
                        </p>
                        <p className='mb-4'>
                            Find My Venue uses cookies and/or other tracking technologies to distinguish you from other users of the services and to remember your preferences on the Platform. This helps Find My Venue to provide you with a good experience when you use the Services on the Platform and allows Find My Venue to improve such Services. Cookies are text files that Find My Venue places in your mobile phone, tablet or other devices to store your preferences. Cookies, by themselves, do not tell Find My Venue your e-mail address or other personally identifiable information unless you choose to provide this information to Find My Venue, for example, by registering on the Platform. They are designed to hold a marginal amount of data specific to a particular user. However, once you choose to furnish the Platform with personally identifiable information, this information may be linked to the data stored in the cookie. Find My Venue uses cookies for a number of purposes including but not limited to (a) understand Platform usage and to improve the content and offerings on the Platform. For example, Find My Venue may use cookies to personalize your experience on the Platform (e.g., to recognize you by name when you return to the Platform) and save your password in password-protected areas; (b) gain insight into, assess and improve the ease of navigation, usability and functionality of the Platform; (c) understand, monitor, analyse and improve the effectiveness of the Platform as well as of content, advertisements, communications or other features; and/or (d) keep a record of those who have taken part in surveys, filled in questionnaires or provided feedback. Find My Venue may also use cookies to offer you specific offerings or services. Cookies may be placed on the Platform by third-parties as well, the use of which Find My Venue does not control.
                        </p>
                        <p className='mb-4'>
                            Session cookies are automatically deleted from your hard drive once a session ends, whereas, persistent cookies remain on your device after you close your browser, and can be used again the next time you access the Platform. You may decline the cookies, however, if you decline the cookies, you may be unable to use certain features on the Platform and you may be required to re-enter your password frequently. You may opt to leave the cookie turned on.
                        </p>

                        <p className='mb-4 font-bold'>
                            PROTECTION OF INFORMATION
                        </p>
                        <p className='mb-4'>
                            Find My Venue has taken adequate measures to protect the security of Information and to ensure that your choices for its intended use are honoured. Find My Venue takes robust precautions to protect your data from loss, misuse, unauthorized access or disclosure, alteration, or destruction.
                        </p>
                        <p className='mb-4'>
                            Find My Venue considers the confidentiality and security of your information to be of utmost importance. It therefore uses adequate security measures to keep Information confidential and secure and Find My Venue will not share your Information with third parties, except as otherwise provided in this Policy. Please be advised that, however, while Find My Venue strives to protect Information and privacy, Find My Venue cannot guarantee or warrant its absolute security when Information is transmitted over the internet into the Platform.
                        </p>
                        <p className='mb-4'>
                            Access to your Account on the Platform is via your Login Credentials, which are password protected and this helps to secure your Account information. You are solely responsible for maintaining the confidentiality of your Login Credentials in accordance with the Terms. To ensure safety of your Information, you are advised against sharing your Login Credentials with anyone. If you suspect any unauthorized use of your Account, you must immediately notify Find My Venue. You shall be liable to indemnify Find My Venue for any loss suffered by Find My Venue due to such unauthorized use of your Account.
                        </p>
                        <p className='mb-4'>
                            For any loss or theft of Information, due to unauthorized access to your device through which you use the Platform or other reasons solely attributable to you, Find My Venue shall not be held liable or responsible under any circumstance whatsoever. Further, Find My Venue shall not be responsible for any breach of security or for any actions of any third parties or events that are beyond Find My Venue’s reasonable control including but not limited to acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of Internet service or telephone service of the user, etc.
                        </p>

                        <p className='mb-4 font-bold'>
                            MINOR & USAGE ON BEHALF OF ANOTHER PERSON
                        </p>
                        <p className='mb-4'>
                            Find My Venue does not intend to attract anyone under the relevant age of consent or any person that is not competent to contract, to enter into binding legal contracts under the laws of their respective jurisdictions. Find My Venue does not intentionally or knowingly collect Personal Information through the Platform from anyone under the relevant age of consent or from any person that is not legally permissible. Find My Venue encourages parents and guardians to be involved in the online activities of minors and such persons to ensure that no Personal Information is collected without their prior consent. If you are using the Platform on behalf of someone else, including but not limited to, on behalf of your minor child/children/employer or any third party, you represent and warrant that you are legally authorised by such person to accept this Policy on their behalf and to consent on behalf of such person to Find My Venue’s use of such person’s Personal Information as described in this Policy. If you are accepting this Policy on behalf of a child you represent and warrant that you are a parent or legal guardian of such a child.
                        </p>

                        <p className='mb-4 font-bold'>
                            LIMITATION OF LIABILITY
                        </p>
                        <p className='mb-4'>
                            16.1. Find My Venue shall not be liable to you for any loss of profit, production, anticipated savings, goodwill or business opportunities or any type of direct or indirect, incidental, economic, compensatory, punitive, exemplary or consequential losses arising out of performance or non-performance of its obligations under this Policy.
                        </p>
                        <p className='mb-4'>
                            Find My Venue is not responsible for any actions or inactions of any third parties that receive your Information.
                        </p>
                        <p className='mb-4'>
                            Notwithstanding anything contained in this Policy or elsewhere, Find My Venue shall not be held responsible for any loss, damage or misuse of your Information, if such loss, damage or misuse is attributable to a Force Majeure Event. The term “Force Majeure Event” shall mean any event that is beyond Find My Venue’s reasonable control and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes, lockouts or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, civil disturbances, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, pandemic or national/state lockdown due to any reason and any other similar events not within Find My Venue’s control and which Find My Venue is not able to overcome.
                        </p>

                        <p className='mb-4 font-bold'>
                            OPT-OUT
                        </p>
                        <p className='mb-4'>
                            Once you register on the Platform, you may receive communication, including but not limited to messages on the Platform, Short Message Service (SMS), email newsletters and phone calls from Find My Venue on the registered mobile number; e-mails on your registered e-mail address; and digital communications such as WhatsApp notifications, etc. These messages, e-mails and calls could relate to your registration, Booking Enquiries that you send through the Platform and marketing activities and promotions that are undertaken by Find My Venue. You have the option to 'opt-out' of all newsletters and other general promotional communications from Find My Venue by way of links provided at the bottom of the mailers or by contacting the customer service team. It is hereby clarified that in the event of such opt-out, communications pertaining to offerings and services shall continue. Find My Venue respects your privacy and in the event that you choose to not receive such mailers, Find My Venue shall take all adequate steps to remove you from such lists. However, you will not be able to opt-out of receiving administrative messages, customer service responses or other transactional communications.
                        </p>

                        <p className='mb-4 font-bold'>
                            GOVERNING LAW AND DISPUTE RESOLUTION
                        </p>
                        <p className='mb-4'>
                            This Policy shall be governed by and interpreted and construed in accordance with the laws of the United Arab Emirates. The place of jurisdiction shall exclusively be in Dubai, United Arab Emirates. In the event of any dispute arising out of this Policy the same shall be settled by a binding arbitration under the Arbitration Rules of the Dubai International Arbitration Centre, which Rules are deemed to be incorporated by reference into this clause. The arbitration proceedings shall be conducted by a sole arbitrator, appointed jointly by both parties. The venue and seat of arbitration shall be Dubai, United Arab Emirates. The language to be used in the arbitration shall be English.
                        </p>

                        <p className='mb-4 font-bold'>
                            CONTACT DETAILS
                        </p>
                        <p className='mb-4'>
                            For registering your concerns, complaints or grievances, or if you have questions or concerns about this Policy, please contact the designated data protection officer of Find My Venue at the following email address: support@findmyvenue.com or by calling on the following number: +971-4-3380909.
                        </p>

                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;