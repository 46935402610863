import React, { useContext, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { VenueContext } from '../../context/VenueContext';
import { saveDataForVenue } from '../../api/venue-api'; // Ensure this path is correct
import { createResForm } from '../../api/restaurant-api'; // Ensure this path is correct
import toast from 'react-hot-toast';

const AddNewVenue = ({
	onClose,
	data,
	onAddSubVenue,
	category,
	combineData,
	title,
}) => {
	const { formData, setFormData, resetForm } =
		useContext(VenueContext);
		// for sub admins invitedBy will be used and for others super admin vendorId will be used
	const invitedBy = localStorage.getItem('invitedBy');
	const vendorId = invitedBy || localStorage.getItem('vendorId');
	// const vendorId = localStorage.getItem('vendorId');
	const navigate = useNavigate();

	const [selectedOption, setSelectedOption] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSaveData = async () => {
		setLoading(true);
		try {
			const updatedFormData = resetForm();
			const firstItem = data[0];
			let updatedData;

			if (combineData === 'Venue' && data?.length < 1) {
				resetForm();
				updatedData = {
					...updatedFormData,
					basicDetails: {
						...updatedFormData?.basicDetails,
						isPrimaryVenue: combineData === 'Venue' ? true : false,
						slotName: combineData === 'Venue' ? title : '',
					},
					vendorId,
					status: 'pending',
				};
			} else {
				resetForm();
				updatedData = {
					...updatedFormData,
					vendorId,
					basicDetails: {
						...updatedFormData?.basicDetails,
						hotelName:
							combineData === 'hotel'
								? firstItem?.overview?.basicDetails?.hotelName
								: firstItem?.basicDetails?.hotelName,
						slotName: combineData === 'Venue' ? title : '',
					},
					location:
						combineData === 'hotel'
							? firstItem?.overview?.locationDetails
							: firstItem?.location,
					status: 'pending',
				};
			}

			const response = await saveDataForVenue(updatedData);
			if (response.success && response.venueForm?._id) {
				// const cat =
				// 	combineData === 'Restaurant' ? combineData : category;
				navigate(
					`/create-Listing/${category}/${response.venueForm._id}`
				);
			} else {
				toast.error('Failed to save venue data.');
			}
		} catch (error) {
			toast.error(
				error.response?.data?.error ||
					'An error occurred. Please try again.'
			);
		} finally {
			setLoading(false);
		}
	};

	const handleCreateRes = async () => {
		setLoading(true);
		try {
			const updatedFormData = resetForm();

			let updatedData;

			if (data?.length < 1) {
				updatedData = {
					...updatedFormData,
					vendorId,
					basicDetails: {
						...updatedFormData?.basicDetails,
						isPrimaryVenue: true,
					},
					cateringAndDrinks: {
						...updatedFormData?.cateringAndDrinks,
						isVenueAlcohlic: title.slice(11, -2).includes('w/Alcohol'),
						isParentAlcoholic: title
							.slice(11, -2)
							.includes('w/Alcohol'),
					},
					status: 'pending',
				};
			} else {
				const firstItem = data[0];
				console.log({ firstItem });
				updatedData = {
					...updatedFormData,
					vendorId,
					basicDetails: {
						...updatedFormData?.basicDetails,
						hotelName: firstItem?.basicDetails?.isPrimaryVenue
							? firstItem?.basicDetails?.venueName
							: firstItem?.basicDetails?.hotelName,
						isPrimaryVenue: false,
					},
					cateringAndDrinks: {
						...updatedFormData?.cateringAndDrinks,
						isVenueAlcohlic:
							firstItem?.cateringAndDrinks?.isVenueAlcohlic,
						isParentAlcoholic:
							firstItem?.cateringAndDrinks?.isVenueAlcohlic,
					},
					location: firstItem?.location,
					status: 'pending',
				};
			}

			console.log('dd', updatedData);
			const response = await createResForm(updatedData);
			if (response.success && response.venueForm?._id) {
				navigate(
					`/venu-restaurant-Form/Restaurant/${response.venueForm._id}`
				);
			} else {
				toast.error('Failed to save restaurant data.');
			}
		} catch (error) {
			toast.error(
				error.response?.data?.error ||
					'An error occurred. Please try again.'
			);
		} finally {
			setLoading(false);
		}
	};

	const handleContinue = () => {
		resetForm();
		if (selectedOption === 'createNew') {
			if (combineData === 'Restaurant') {
				handleCreateRes();
			} else {
				handleSaveData();
			}
		} else if (selectedOption === 'addSubVenue') {
			onAddSubVenue(); // Open the ListonVenue modal
		}
	};

	return (
		<div className='bg-white relative rounded-lg py-0 px-4'>
			<RxCross2
				onClick={onClose}
				className='absolute text-xl cursor-pointer top-2 right-2'
			/>
			<div className='flex flex-col'>
				<h1 className='text-3xl text-center font-semibold'>
					Add new venue
				</h1>
				<p className='text-xs text-center text-offGray'>
					Add an offer on top of your pricing
				</p>
			</div>
			<div className='flex flex-col gap-3 mt-4'>
				<div className='flex items-start gap-3'>
					<input
						type='radio'
						name='venueOption'
						className='mt-1 w-5 h-5'
						id='createNew'
						checked={selectedOption === 'createNew'}
						onChange={() => setSelectedOption('createNew')}
					/>
					<div>
						{/* <h1 className='text-base font-medium'>Add new Details</h1> */}
						<h1 className='text-sm font-medium'>
							Create a new venue listing
						</h1>
						<p className='text-xs text-secondary'>
							This will create a complete new listing without any data
							being prefilled
						</p>
					</div>
				</div>

				<div className='flex items-start gap-3'>
					<input
						type='radio'
						name='venueOption'
						className='mt-1 w-5 h-5'
						id='addSubVenue'
						checked={selectedOption === 'addSubVenue'}
						onChange={() => setSelectedOption('addSubVenue')}
					/>
					<div>
						{/* <h1 className='text-base font-medium'>Autofills details from existing venue to save time</h1> */}

						<h1 className='text-sm font-medium'>
							Add sub-venue listing to Primary venue
						</h1>
						<p className='text-xs text-secondary'>
							Copy venue details from existing listings to speed up
							the process.
						</p>
					</div>
				</div>
			</div>
			<div className='flex justify-center mt-8'>
				<button
					className={`py-2 w-[85%] px-2 rounded-lg text-white text-sm bg-primary ${
						loading ? 'bg-opacity-50 cursor-not-allowed' : ''
					}`}
					onClick={handleContinue}
					disabled={loading}
				>
					{loading ? 'Saving...' : 'Continue'}
				</button>
			</div>
		</div>
	);
};

export default AddNewVenue;
