import React, { useContext, useEffect, useState } from "react";
import HeroSection from "../../components/hostel-preview/hero-section";
import OverviewSection from "../../components/hostel-preview/overview-section";
import OffersAndPackages from "../../components/hostel-preview/offer-package";
import VenuesAvailable from "../../components/hostel-preview/venu-avaliable";
import AboutThisHotel from "../../components/hostel-preview/about-hotel";
import Pricing from "../../components/hostel-preview/pricing";
import FeaturesSection from "../../components/hostel-preview/place-offers";
import EventType from "../../components/hostel-preview/event-type";
import MapComponent from "../../components/hostel-preview/location";
import { HotelContext } from "../../context/HotelContext";
import { useQuery } from "@tanstack/react-query";
import { getHotelData } from "../../api/hotel-api";
import PreviewNavbar from "../../components/shared/previewNavbar";

const HotelPreview = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const vendorId = localStorage?.getItem("vendorId");

  // Fetch hotel data when vendorId is available

  const { data, error, isLoading } = useQuery({
    queryKey: ["hotelData", vendorId],
    queryFn: async () => {
      if (!vendorId) return null;
      return await getHotelData(vendorId);
    },

    enabled: !!vendorId,
    refetchOnWindowFocus: false,
  });

  // Update formData when data is fetched
  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);
  return (
    <div>
      <PreviewNavbar />
      <HeroSection data={formData?.photoVideo?.images} />

      <OverviewSection data={formData} />
      <OffersAndPackages data={formData?.offerPackage} />
      {/* <VenuesAvailable /> */}
      <AboutThisHotel data={formData?.overview} />
      <Pricing data={formData?.pricing} />
      <FeaturesSection data={formData} />
      <EventType data={formData?.overview?.additionalDetails} />
      <div id="map">
        <MapComponent data={formData?.overview?.locationDetails} />
      </div>
    </div> 
  );
};

export default HotelPreview;
