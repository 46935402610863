import { base_URL } from "../config/config";
import axios from "axios";

const API_BASE_URL = `${base_URL}/api/vendor`;

// for getting team
export const fetchTeamMembers = async (vendorId, searchQuery) => {
  const response = await axios.get(
    `${API_BASE_URL}/${vendorId}/team-members?searchQuery=${searchQuery}`
  );
  return response.data;
};

export const fetchTeamMember = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/team-members/${id}`);
  return response.data;
};
export const fetchTeamMemberrestandvenues = async (id) => {
  const response = await axios.get(
    `${API_BASE_URL}/team-members-venues-restaurant/${id}`
  );
  return response.data;
};

export const createTeamMember = async (teamMember) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/team-members`,
      teamMember
    );
    return response.data;
  } catch (error) {
    // Return a structure that includes error information
    return {
      success: false,
      message: error.response?.data?.message || "An unknown error occurred.",
    };
  }
};

export const updateTeamMember = async (id, updateData) => {
  const response = await axios.put(
    `${API_BASE_URL}/team-members/${id}`,
    updateData
  );
  return response.data;
};

export const deleteTeamMember = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/team-members/${id}`);
  return response.data;
};

export const deleteAssignedVenue = async (id, venueId) => {
  const response = await axios.delete(
    `${API_BASE_URL}/team-members/${id}/venue/${venueId}`
  );
  return response.data;
};
