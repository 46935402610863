import React, { useState, useEffect, useRef } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import Input from "../../shared/common/customInput";

const OfferModal = ({ onClose, onSave, offerToEdit }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState({});

  // Character count state
  const [descriptionLength, setDescriptionLength] = useState(0);

  const currentDate = new Date().toISOString().split("T")[0];
  const modalRef = useRef(null);

  useEffect(() => {
    if (offerToEdit) {
      setTitle(offerToEdit.title);
      setDescription(offerToEdit.description);
      setStartDate(offerToEdit.startDate);
      setEndDate(offerToEdit.endDate);
      setDescriptionLength(offerToEdit.description.length);
    } else {
      setStartDate(currentDate);
      setEndDate(currentDate);
    }
  }, [offerToEdit, currentDate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSave = () => {
    const newErrors = {};

    if (!title.trim()) {
      newErrors.title = "Offer title is required.";
    }
    if (!description.trim()) {
      newErrors.description = "Offer description is required.";
    }
    if (!startDate) {
      newErrors.startDate = "Start date is required.";
    }
    if (!endDate) {
      newErrors.endDate = "End date is required.";
    }

    if (new Date(startDate) < new Date(currentDate)) {
      newErrors.startDate = "Start date must be today or a future date.";
    }
    if (new Date(endDate) < new Date(currentDate)) {
      newErrors.endDate = "End date must be today or a future date.";
    } else if (new Date(endDate) < new Date(startDate)) {
      newErrors.endDate = "End date must be after the start date.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    console.log({ title, description, startDate, endDate });

    onSave({ title, description, startDate, endDate });
    onClose();
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    setDescriptionLength(newDescription.length); // Update character count
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-lg w-1/2 flex flex-wrap justify-between"
      >
        <h2 className="text-2xl font-semibold mb-4">Offer & Packages</h2>

        <div className="mb-4 w-full">
          <Input
            label="Offer Title"
            type="text"
            placeholder="Enter Offer Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className={errors.title ? "border-red-500" : ""}
          />
          {errors.title && (
            <p className="text-red-500 text-sm mt-1">{errors.title}</p>
          )}
        </div>

        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-gray-700 font-medium mb-2">From</label>
          <div className="relative">
            <FaRegCalendarAlt className="absolute left-3 top-3 text-gray-400" />
            <input
              type="date"
              min={currentDate}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={`w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
                errors.startDate ? "border-red-500" : ""
              }`}
              required
            />
            {errors.startDate && (
              <p className="text-red-500 text-sm mt-1">{errors.startDate}</p>
            )}
          </div>
        </div>

        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-gray-700 font-medium mb-2">To</label>
          <div className="relative">
            <FaRegCalendarAlt className="absolute left-3 top-3 text-gray-400" />
            <input
              type="date"
              min={currentDate}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={`w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
                errors.endDate ? "border-red-500" : ""
              }`}
              required
            />
            {errors.endDate && (
              <p className="text-red-500 text-sm mt-1">{errors.endDate}</p>
            )}
          </div>
        </div>

        <div className="mb-4 w-full">
          <label className="block text-gray-700 font-medium mb-2">
            Offer Description
          </label>
          <textarea
            rows={5}
            value={description}
            onChange={handleDescriptionChange} // Updated handler
            className={`w-full p-2 border border-gray-300 rounded-md ${
              errors.description ? "border-red-500" : ""
            }`}
            maxLength={300}
            required
          />
          <p className="text-gray-500 text-sm mt-1">{descriptionLength}/300</p>
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description}</p>
          )}
        </div>

        <button
          className="bg-primary w-full text-white px-4 py-2 rounded-md"
          onClick={handleSave}
        >
          {offerToEdit ? "Update Offer" : "Add Offer"}
        </button>
      </div>
    </div>
  );
};

export default OfferModal;
