import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import backgroundIcon from '../../assets/images/background_icon.svg';
import { useTheme } from '@mui/material/styles';
import MessageCard from '../onboarding/components/MessageCard';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { fetchUserById } from '../../api/loggedUser';
import CircularProgress from '@mui/material/CircularProgress'; // Import for a loading spinner

function EmailVerificationSuccessful() {
	const theme = useTheme();
	const [buttonRef, setButtonRef] = useState('/onboarding');
	const [isLoading, setIsLoading] = useState(false);

	const checkUser = async () => {
		try {
			setIsLoading(true);
			const storedVendorId = localStorage.getItem('vendorId');
			const user = await fetchUserById(storedVendorId);
			console.log(user?.vendor?.onBoardingDetails?.type);
			if (user?.vendor?.onBoardingDetails?.type) {
				setButtonRef('/dashboard');
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		checkUser();
	}, []);

	return (
		<>
			<ResponsiveAppBar />
			<div
				style={{
					backgroundImage: `url(${backgroundIcon})`,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'right',
					minHeight: '75vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Container
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							alignItems: 'center',
						}}
					>
						{isLoading ? (
							<CircularProgress /> // Show a loading spinner while fetching data
						) : (
							<MessageCard
								loaderComponent={
									<MarkEmailReadIcon
										sx={{
											fontSize: 60,
											mt: 4,
											color: theme.palette.green.text,
										}}
									/>
								}
								primaryText={'Email Verification Successful!'}
								secondaryText={
									"Let's start the listing process. Click the button below to continue."
								}
								buttonText={'Start Now'}
								buttonHref={buttonRef}
							/>
						)}
					</Box>
				</Container>
			</div>
		</>
	);
}

export default EmailVerificationSuccessful;
