import React from "react";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import backgroundIcon from "../../assets/images/background_icon.svg";
import MessageCard from "./components/MessageCard";
import { ReactComponent as CrossBadgeIcon } from "../../assets/images/cross_badge_icon.svg";

function PaymentRejected() {
  return (
    <>
      <ResponsiveAppBar />
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: { md: "center" },
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <MessageCard
            loaderComponent={
              <CrossBadgeIcon
                sx={{
                  fontSize: 60,
                  mt: 4,
                }}
              />
            }
            primaryText={"Payment Rejected"}
            secondaryText={`Please try again later...`}
          />
        </Container>
      </div>
    </>
  );
}

export default PaymentRejected;
