import React, { useState, useEffect } from 'react';
import {
    FaHeart,
    FaEye,
    FaInfoCircle,
    FaRegHeart,
} from 'react-icons/fa';
import { ReactComponent as BedSVG } from '../../assets/icons/bed_hotel_listing.svg';
import { ReactComponent as HouseSVG } from '../../assets/icons/house_hotel_listing.svg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import auto from '../../assets/icons/auto.svg';


const VenueCardPreview = ({ hotels }) => {
    // Create a reusable card item component for individual hotel cards
    const CardItem = ({ venue }) => {
        const [isLiked, setIsLiked] = useState(false);
        console.log('photoVideo', venue);

        const CustomNextArrow = ({ onClick }) => (
            <div
                className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50"
                onClick={onClick}
            >
                <SlArrowRight className="text-[#000]" />
            </div>
        );

        const CustomPrevArrow = ({ onClick }) => (
            <div
                className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50"
                onClick={onClick}
            >
                <SlArrowLeft className="text-[#000]" />
            </div>
        );

        const imageSettings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            nextArrow: <CustomNextArrow />,
            prevArrow: <CustomPrevArrow />,
        };

        const handleLikeClick = () => {
            setIsLiked(!isLiked);
        };

        const CustomSkeleton = () => (
            <div className="w-full h-[300px] lg:w-full rounded-lg bg-gray-200 animate-pulse"></div>
        );

        const [minSpendPricing, setMinSpendPricing] = useState(null);
        const [minSpendPerPerson, setMinSpendPerPerson] = useState(null);

        const calculateMinSpendFromSelectedOptions = () => {
            if (venue?.pricing?.selectedOptions?.length) {
                let minSpendPricing = null;
                let minSpendPerPerson = null;
                let hasMinimumSpendPricing = false;

                venue.pricing.selectedOptions.forEach(option => {
                    const minSpendValue = Number(option.details.minSpend);

                    switch (option.type) {
                        case "Minimum Spend Pricing":
                            hasMinimumSpendPricing = true;
                            minSpendPricing = minSpendPricing === null
                                ? minSpendValue
                                : Math.min(minSpendPricing, minSpendValue);
                            break;

                        case "Venue Rental":
                            if (!hasMinimumSpendPricing) {
                                minSpendPricing = minSpendPricing === null
                                    ? minSpendValue
                                    : Math.min(minSpendPricing, minSpendValue);
                            }
                            break;

                        case "Min spend per person":
                            minSpendPerPerson = minSpendPerPerson === null
                                ? minSpendValue
                                : Math.min(minSpendPerPerson, minSpendValue);
                            break;

                        default:
                            break;
                    }
                });

                setMinSpendPricing(minSpendPricing);
                setMinSpendPerPerson(minSpendPerPerson);
            } else {
                setMinSpendPricing(null);
                setMinSpendPerPerson(null);
            }
        };

        useEffect(() => {
            calculateMinSpendFromSelectedOptions();
        }, [venue]);

        const imageSrc =
            venue?.imagesAndVideos?.images.length > 0
                ? venue?.imagesAndVideos?.images[0]
                : '';

        const {
            basicDetails,
            photoVideo,
            overviewbasicdetailsmissing,
            cateringmissing,
            spacerulesmissing,
            pricingmissing,
        } = venue;

        const venueName =
            basicDetails?.venueName || 'Venue Name Not Available';
        const venueDescription =
            basicDetails?.venueDescription || 'Description Not Available';
        const location =
            venue.location?.city || venue.location?.area
                ? `${venue.location?.city}, ${venue.location?.area}`
                : 'Location Not Available';

        const path = useLocation();
        const navigate = useNavigate();

        const handlePreviewClick = () => {
            let errorMessage = '';
            let hasErrors = false;

            if (venue?.overviewbasicdetailsmissing?.isError) {
                errorMessage += 'Data is missing in overview basic details.\n';
                hasErrors = true;
            }

            if (venue?.cateringmissing?.isError) {
                errorMessage += 'Data is missing in Catering & Drinking.\n';
                hasErrors = true;
            }

            if (venue?.spacerulesmissing?.isError) {
                errorMessage += 'Data is missing in Space Rules.\n';
                hasErrors = true;
            }

            if (venue?.pricingmissing?.isError) {
                errorMessage += 'Data is missing in Pricing.\n';
                hasErrors = true;
            }

            if (hasErrors) {
                toast.error(errorMessage.trim());
            }

            if (path.pathname?.includes('venu-Listing-Form') || path.pathname?.includes("/create-Listing/Venue")) {
                if (hasErrors) {
                    localStorage.setItem('Errors', errorMessage);
                    navigate('/venue-preview');
                } else {
                    localStorage.removeItem('Errors');
                    navigate('/venue-preview');
                }
            } else {
                navigate('/restaurant-preview');
            }
        };

        return (
            <div className='bg-white rounded-lg w-full'>
                <div className='rounded-lg overflow-hidden shadow-lg mb-4 lg:w-full'>
                    <div className='relative'>
                        <Slider {...imageSettings}>
                            {venue?.imagesAndVideos?.images.length > 0 ? (
                                venue?.imagesAndVideos?.images.map((e, imageIndex) => (
                                    <div key={imageIndex} className="">
                                        <img src={e} alt="" className="w-full h-[300px] rounded-t-lg object-cover" />
                                    </div>
                                ))
                            ) : (
                                <CustomSkeleton />
                            )}
                        </Slider>
                        <button
                            className={`absolute top-2 right-2 ${isLiked ? 'text-red-500' : 'text-red-500'} bg-white rounded-full p-1 shadow`}
                            onClick={() => setIsLiked(!isLiked)}
                        >
                            {isLiked ? (
                                <FaHeart size={20} />
                            ) : (
                                <FaRegHeart size={20} />
                            )}
                        </button>
                    </div>
                    <div className='p-4 text-[#5E5E5E]'>
                        <h3 className='text-xl font-medium'>{venue?.basicDetails?.hotelName}</h3>
                        <p className=''>
                            {venue?.locationDetails?.city || venue?.locationDetails?.area
                                ? `${venue?.locationDetails?.city}, ${venue?.locationDetails?.area}`
                                : 'Location Not Available'}
                        </p>
                        <div className='flex items-center my-2'>
                            {/* <LiaChairSolid className='mr-1 text-2xl text-primary' /> */}
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2272_13995)">
                                    <path d="M15.0644 10.2681V0.5H13.8925V1.75015H6.10748V0.5H4.93561V10.2681C4.24789 10.6965 3.78906 11.4592 3.78906 12.3274V20.5H4.96094V16.1075H15.0389V20.5H16.2108V12.3272C16.2108 11.4592 15.752 10.6965 15.0644 10.2681ZM4.96094 12.3272C4.96094 11.6363 5.52307 11.0742 6.21399 11.0742H13.7859C14.4768 11.0742 15.0389 11.6363 15.0389 12.3272V12.6655H4.96094V12.3272ZM13.8925 6.41217H6.10748V2.92203H13.8925V6.41217ZM6.10748 7.58405H13.8925V9.90506C13.8571 9.90353 13.8217 9.90231 13.7859 9.90231H6.21399C6.17828 9.90231 6.14288 9.90353 6.10748 9.90506V7.58405ZM15.0389 14.9356H4.96094V13.8374H15.0389V14.9356Z" fill="#FE4747" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2272_13995">
                                        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <span className='mr-3'>
                                {venue?.capacity?.sitting || '-'}
                            </span>
                            {/* <IoPeopleSharp className='mr-1 text-2xl text-primary' />
						 */}
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.5 6.5C17.89 7.2 14.83 7.5 12 7.5C9.17 7.5 6.11 7.2 3.5 6.5L3 8.5C4.86 9 7 9.33 9 9.5V22.5H11V16.5H13V22.5H15V9.5C17 9.33 19.14 9 21 8.5L20.5 6.5ZM12 6.5C13.1 6.5 14 5.6 14 4.5C14 3.4 13.1 2.5 12 2.5C10.9 2.5 10 3.4 10 4.5C10 5.6 10.9 6.5 12 6.5Z" fill="#FE4747" />
                            </svg>

                            <span>{venue?.capacity?.standing || '-'}</span>
                        </div>
                        <div className='flex items-center my-2 gap-2'>
                            <img src={auto} alt='' />
                            <span className='mr-3'>
                                Auditorium . Indoor & Outdoor
                            </span>
                        </div>
                        <div className='flex justify-between gap-5'>
                            {minSpendPerPerson && !Number.isNaN(minSpendPerPerson) && (
                                <div className=''>
                                    <p className='text-primary font-medium'>
                                        AED {minSpendPerPerson}
                                    </p>
                                    <p className='text-sm '>per guest </p>
                                </div>
                            )
                            }
                            {minSpendPricing && !Number.isNaN(minSpendPricing) && (
                                <div className=''>
                                    <p className='text-primary font-medium'>
                                        AED {minSpendPricing}
                                    </p>
                                    <p className='text-sm '>Min. spend </p>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full pt-5 gap-6'>

            {hotels.map((venue, index) => (
                <CardItem key={index} venue={venue} />
            ))}
        </div>
    );
};

export default VenueCardPreview;
