import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import Navbar from '../../components/webComps/Navbar';
import Footer from '../../components/webComps/Footer';
import { Link } from 'react-router-dom';

const ContactUs = () => {
	const data = [
		{
			name: 'Have questions?',
			des: 'Take a look at some of the most frequently asked questions by the users of our platform. Read User FAQ’s.',
		},
		{
			name: 'Need contact support?',
			des: 'If you require assistance with your account, please submit the form below.',
		},
		{
			name: 'Want to list your venue?',
			des: (
				<>
					Join UAE’s leading marketplace for venue hire.{' '}
					<Link to='/' className='text-blue-500'>
						List Your Venue
					</Link>{' '}
					and get started today!
				</>
			),
		},
	];

	return (
		<div>
			<Navbar />
			<div className='w-full xl:h-[60vh] h-[40vh] bg-contactBanner bg-center bg-cover relative flex justify-center items-center bg-no-repeat'>
				<div className='bg-[#000000CC] absolute top-0 left-0 w-full h-full z-40'></div>
				<div className='flex items-center text-xs absolute top-4 left-8 z-50'>
					<p className='text-[#B0B0B0]'>Home</p>
					<IoIosArrowForward className='text-white' />
					<p className='text-[#FE4747]'>Contact us</p>
				</div>
				<div className='text-white flex flex-col items-center z-50 gap-3 lg:w-[45%] w-[70%]'>
					<h1 className='sm:text-5xl text-3xl text-center'>
						Get in Touch
					</h1>
					<p className='text-center sm:text-lg text-sm'>
						Our friendly team would love to hear from you and help you
						with any queries.
					</p>
				</div>
			</div>
			<div className='flex justify-center pt-20'>
				<div className='w-[90%] grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8'>
					{data.map((e, i) => (
						<div key={i} className=''>
							<h1 className='text-darkGray sm:text-2xl text-xl font-semibold'>
								{e.name}
							</h1>
							<p className='text-darkGray sm:text-base text-sm font-medium mt-2'>
								{e.des}
							</p>
						</div>
					))}
				</div>
			</div>
			<div className='flex justify-center py-20'>
				<div className='w-[90%] flex items-start md:flex-row flex-col md:gap-32 gap-8'>
					<div className='flex md:w-1/2 w-full flex-col gap-5'>
						<div>
							<p className='text-darkGray font-medium'>Full Name</p>
							<input
								type='text'
								className='bg-[#F7F7F7] w-full mt-2 py-2 px-2 rounded-lg'
								name=''
								id=''
							/>
						</div>
						<div>
							<p className='text-darkGray font-medium'>
								Email Address*
							</p>
							<input
								type='email'
								className='bg-[#F7F7F7] w-full mt-2 py-2 px-2 rounded-lg'
								name=''
								id=''
							/>
						</div>
						{/* <div>
                            <p className='text-darkGray font-medium'>Mobile Number*</p>
                            <input type="text" className='bg-[#F7F7F7] w-full mt-2 py-2 px-2 rounded-lg' name="" id="" />
                        </div> */}
						<div>
							<h1 className='text-darkGray font-medium'>Are you a</h1>
							<div className='flex md:flex-col flex-row gap-3 mt-3'>
								<div className='flex item-center gap-2'>
									<input
										type='radio'
										className='w-5 h-5'
										name='radio1'
										id=''
									/>
									<p>Venue Seeker</p>
								</div>
								<div className='flex item-center gap-2'>
									<input
										type='radio'
										className='w-5 h-5'
										name='radio1'
										id=''
									/>
									<p>Venue Operator</p>
								</div>
							</div>
						</div>
						<div>
							<p className='text-darkGray '>Message </p>
							<textarea
								name=''
								cols={40}
								className='w-full h-full bg-[#F7F7F7] rounded-lg p-2'
								id=''
							></textarea>
						</div>
						<div className='w-full flex justify-start mt-6'>
							<Link
								to='/'
								className='bg-primary rounded-lg text-white p-2 px-6'
							>
								Submit
							</Link>
						</div>
					</div>
					<div className='flex flex-col gap-4 md:w-1/2 w-full'>
						<h1 className='sm:text-3xl text-2xl font-semibold'>
							Contact us
						</h1>
						<p className='underline'>support@findmyvenue.com</p>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ContactUs;
