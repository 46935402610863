import React, { createContext, useState, useEffect } from "react";

export const HotelContext = createContext();



export const HotelProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    vendorId: "",
    status: "pending",
    itemStatus: "pending",
    overview: {
      basicDetails: {
        hotelName: "",
        hotelDescription: "",
        hotelWebsite: "",
        hotelRating: "",
        hotelChain: "",
        hotelBrand: "",
        selectedTags: {
          types: [],
          views: [],
          styles: {
            traditional: [],
            modern: [],
            eclectic: [],
            cultural: [],
          },
        },
      },
      additionalDetails: {
        isSustainable: false,
        hotelBuiltYear: "",
        lastRenovatedYear: "",
        selectedEventTypes: [],
        awards: [{ name: "", year: "" }],
        tradeLicenseFile: null,
      },
      locationDetails: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        area: "",
        poBox: "",
        country: "United Arab Emirates",
        mapLink: "",
        parkingAvailable: false,
        valetParking: false,
        lat: null,
        lng: null,
        accessibilityFeatures: {
          airportShuttle: false,
          metro: false,
          taxi: false,
          bus: false,
        },
      },
    },

    accommodation: {
      accommodations: 0,

    },
    pricing: {
      basePrice: "",
      mentionSeasons: false,
      highDemandMonths: [],
      midDemandMonths: [],
      lowDemandMonths: [],
    },
    offerPackage: [],
    amenities: {
      businessFeaturesIcons: {},
      roomFeaturesIcons: {},
      recreationalFeaturesIcons: {},
      roomFeatures: {},
      businessFeatures: {},
      recreationalFeatures: {}
    },
    photoVideo: {
      images: [],
      videos: [],
      videoLinks: [],
      coverPhoto: "",
    },
    errors: {
      overview: false,
      accommodation: false,
      pricing: false,
      photoVideo: false,
    },
  });
  // console.log("hotel", formData)
  return (
    <HotelContext.Provider value={{ formData, setFormData }}>
      {children}
    </HotelContext.Provider>
  );
};

