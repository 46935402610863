import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosStar } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";


const ReviewCarousel = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    const data = [
        { review: '“Exploring this platform was a seamless experience. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs.”', name: 'Stillen Jenasen', type: 'Event Planner' },
        { review: '“Exploring this platform was a seamless experience. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs.”', name: 'Stillen Jenasen', type: 'Event Planner' },
        { review: '“Exploring this platform was a seamless experience. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs.”', name: 'Stillen Jenasen', type: 'Event Planner' },
    ]

    return (
        <div className=''>
            {/* <div className='w-[90%] flex flex-col items-center' > */}
                {/* <h1 className='sm:text-4xl text-2xl text-darkGray font-semibold text-center my-4'>What our guests are saying</h1> */}
                <Slider {...settings}>
                    {data.map((e, i) => (
                        <div key={i} className='px-2 w-full flex flex-col gap-6'>
                            <p className='text-[#7C7C7C]'>{e.review}</p>
                            <div className='flex items-center gap-2 mt-2'>
                                <IoIosStar className='text-primary' />
                                <IoIosStar className='text-primary' />
                                <IoIosStar className='text-primary' />
                                <IoIosStar className='text-primary' />
                                <IoIosStarOutline />
                            </div>
                            <h1 className='text-secondary font-medium' >{e.name}</h1>
                            <p className='text-[#7C7C7C] text-sm'>{e.type}</p>
                        </div>
                    ))}
                </Slider>
                {/* <div className='flex justify-center w-full sm:my-10 my-6'>
                    <button className='bg-primary text-white rounded-lg w-[140px] py-2'>Share Your Experience</button>
                </div> */}
            {/* </div> */}
        </div>
    )
}

export default ReviewCarousel

