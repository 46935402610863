import { useQuery } from "@tanstack/react-query";
import { getOnboarding } from "../../api/signup.request";
import { useFetchVendorVenues } from "../../queries/venues";
import CustomCheckbox from "../shared/common/checkBox";
import axios from "axios";
import { useEffect, useState } from "react";
import { base_URL } from "../../config/config";

const AssignVenues = ({
  assignedVenues,
  setAssignedVenues,
  assignedRestaurants,
  setAssignedRestaurants,
  assignedHotel,
  setAssignedHotel,
}) => {
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const [hotelAssignNames, setHotelAssignNames] = useState(null);
  const [restaurantAssignNames, setRestaurantAssignNames] = useState(null);
  const [assignedHotelIDS, setAssignedHotelIDS] = useState(null);
  const [assignedResIDS, setAssignedResIDS] = useState(null);
  // getting hotels and venues of vendor
  const { data: venues, isPending } = useFetchVendorVenues(vendorId);

  // getting restaurants of vendor
  const {
    data: boardingData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["onboardingData"],
    queryFn: async () => await getOnboarding(vendorId),
    refetchOnWindowFocus: false,
  });

  // hotel assign
  const handleHotelChange = (value) => {
    if (assignedHotel === value) {
      setAssignedHotel(null); // Unset if the same hotel is selected
    } else {
      setAssignedHotel(value); // Set the selected hotel
    }
  };
  // venus assign
  const handleVenueChange = (value) => {
    const found = assignedVenues?.find((venue) => venue === value);
    if (found) {
      const updated = assignedVenues?.filter((venue) => venue !== value);
      setAssignedVenues(updated);
    } else {
      setAssignedVenues([...assignedVenues, value]);
    }
  };

  const handleRestaurantChange = (value) => {
    const found = assignedRestaurants?.find(
      (restaurant) => restaurant === value
    );
    if (found) {
      const updated = assignedRestaurants?.filter(
        (restaurant) => restaurant !== value
      );
      setAssignedRestaurants(updated);
    } else {
      setAssignedRestaurants([...assignedRestaurants, value]);
    }
  };

  // Ensure venues?.venues is an array by defaulting it to an empty array if it's undefined
  const filteredVenues = (venues?.venues || []).filter(
    (venue) => venue?.basicDetails?.hotelName || venue?.basicDetails?.venueName
  );

  // Map restaurants to the same structure as venues
  const restaurants = (boardingData?.restaurantForms || []).map(
    (restaurant) => ({
      _id: restaurant._id, // assuming restaurants also have an _id
      basicDetails: {
        venueName: restaurant?.basicDetails?.venueName, // use venueName for restaurants
        hotelName: restaurant?.basicDetails?.hotelName, // assuming hotelName exists for restaurants as well
      },
      assignedVendors: restaurant?.assignedVendors || [],
    })
  );

  const hotel = (boardingData?.hotels || []).map((hotel) => ({
    _id: hotel._id,
    basicDetails: {
      hotelName: hotel?.overview?.basicDetails?.hotelName,
    },
    assignedVendors: hotel?.assignedVendors || [],
  }));

  // for hotels
  useEffect(() => {
    const fetchVendorData = async () => {
      const updatedHotels = await Promise.all(
        (boardingData?.hotels || []).map(async (hotel) => {
          const hotelName = hotel?.overview?.basicDetails?.hotelName;

          try {
            const response = await axios.post(
              `${base_URL}/api/hote-to-whom-assign`,
              {
                hotelName,
              }
            );

            // Update to handle an array of vendors
            const vendors = response?.data?.vendors || [];

            return {
              _id: hotel._id,
              basicDetails: {
                hotelName,
              },
              assignedVendors: vendors, // Update with fetched vendors
            };
          } catch (error) {
            console.error("Error fetching vendor data:", error);
            return {
              _id: hotel._id,
              basicDetails: {
                hotelName,
              },
              assignedVendors: hotel?.assignedVendors || [],
            };
          }
        })
      );
      //   console.log(updatedHotels, "updated hotels with vendors");
      setHotelAssignNames(updatedHotels);
    };

    fetchVendorData();
  }, [boardingData?.hotels]);

  // Fetch vendor data for restaurants
  useEffect(() => {
    const fetchRestaurantData = async () => {
      const updatedRestaurants = await Promise.all(
        (boardingData?.restaurantForms || []).map(async (restaurant) => {
          const venueName = restaurant?.basicDetails?.venueName;

          try {
            const response = await axios.post(
              `${base_URL}/api/restaurant-to-whom-assign`,
              {
                restaurantName: venueName,
              }
            );

            const vendors = response?.data?.vendors || [];

            return {
              _id: restaurant._id,
              basicDetails: {
                venueName,
                hotelName: restaurant?.basicDetails?.hotelName,
              },
              assignedVendors: vendors,
            };
          } catch (error) {
            console.error("Error fetching vendor data:", error);
            return {
              _id: restaurant._id,
              basicDetails: {
                venueName,
                hotelName: restaurant?.basicDetails?.hotelName,
              },
              assignedVendors: restaurant?.assignedVendors || [],
            };
          }
        })
      );
      // console.log(updatedRestaurants, "updated restaurants with vendors");
      setRestaurantAssignNames(updatedRestaurants);
    };

    fetchRestaurantData();
  }, [boardingData?.restaurantForms]);

  return (
    <div className="flex flex-col w-full max-h-96 overflow-y-auto">
      <p>Assign Venue / Restaurant</p>

      {/* Check if all sections are empty */}
      {isPending || isLoading ? (
        <div className="">
          <p className="text-gray-500"></p>
        </div>
      ) : (
        // Show "No venues or restaurants available" only if all are empty
        !hotel?.length &&
        !filteredVenues?.length &&
        !restaurants?.length && (
          <div className="flex items-center justify-center text-center h-[10vh]">
            <p className="text-gray-500">
              No venues or restaurants available for now.
            </p>
          </div>
        )
      )}
      {/* Hotel Section */}
      <div className="flex flex-col space-y-2 mt-4 mb-4 w-full">
        {hotel.length !== 0 && <h3 className="font-semibold">Hotel</h3>}
        {isPending || isLoading
          ? "Loading venues..."
          : hotel?.map((venue) => {
              // Find the assigned vendors for the current hotel
              const assignedVendors =
                hotelAssignNames?.find((item) => item._id === venue._id)
                  ?.assignedVendors || [];
              return (
                <div key={venue._id} className="flex flex-col w-full space-y-2">
                  <div className="flex items-center w-full justify-between">
                    <CustomCheckbox
                      checked={assignedHotel?.includes(venue._id)}
                      onChange={() => handleHotelChange(venue?._id)}
                      className="relative z-999"
                      label={venue?.basicDetails?.hotelName}
                    />
                    <div className="flex flex-wrap gap-2">
                      {assignedVendors.map((vendor, i) => (
                        <div
                          key={i}
                          className="bg-[#E4EFFF] py-1 px-2 rounded-md text-xs text-darkGray"
                        >
                          {vendor?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>

      {/* Venues Section */}
      <div className="flex flex-col space-y-2 mt-4 mb-4 w-full">
        {filteredVenues.length !== 0 && (
          <h3 className="font-semibold">Venues</h3>
        )}
        {isPending || isLoading
          ? "Loading venues..."
          : filteredVenues?.map((venue) => (
              <div key={venue._id} className="flex flex-col w-full space-y-2">
                <div className="flex items-center w-full justify-between">
                  <CustomCheckbox
                    checked={assignedVenues?.includes(venue?._id)}
                    onChange={() => handleVenueChange(venue?._id)}
                    className="relative z-999"
                    label={
                      venue?.basicDetails?.venueName ||
                      venue?.basicDetails?.hotelName
                    }
                  />
                  <div className="flex flex-wrap gap-2 ">
                    {venue?.assignedVendors?.map((vendor, i) => (
                      <div
                        key={i}
                        className="bg-[#E4EFFF] py-1 px-2 rounded-md text-xs text-darkGray"
                      >
                        {vendor?.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
      </div>

      {/* Restaurants Section */}
      <div className="flex flex-col space-y-2 mt-4 mb-4 w-full">
        {restaurants.length !== 0 && (
          <h3 className="font-semibold">Restaurants</h3>
        )}
        {isPending || isLoading
          ? "Loading restaurants..."
          : restaurants?.map((restaurant) => {
              // Find the assigned vendors for the current restaurant
              const assignedVendors =
                restaurantAssignNames?.find(
                  (item) => item._id === restaurant._id
                )?.assignedVendors || [];

              return (
                <div
                  key={restaurant._id}
                  className="flex flex-col w-full space-y-2"
                >
                  <div className="flex items-center w-full justify-between">
                    {/* <p>{restaurant?._id}</p> */}
                    <CustomCheckbox
                      checked={assignedRestaurants?.includes(restaurant?._id)}
                      onChange={() => handleRestaurantChange(restaurant._id)}
                      className="relative z-999"
                      label={
                        restaurant?.basicDetails?.venueName ||
                        restaurant?.basicDetails?.hotelName
                      }
                    />
                    <div className="flex flex-wrap gap-2">
                      {assignedVendors.map((vendor, i) => (
                        <div
                          key={i}
                          className="bg-[#E4EFFF] py-1 px-2 rounded-md text-xs text-darkGray"
                        >
                          {vendor?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default AssignVenues;
