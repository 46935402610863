import React, { useState } from "react";
import Input from "../shared/common/customInput";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTeamMember } from "../../api/teamMember.request";
import AssignVenues from "./assign-venues";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { base_URL } from "../../config/config";
const AddUserModal = ({ showAddModal, setSnackbar }) => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [role, setRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const [assignVenue, setAssignVenue] = useState(false);
  const [assignedVenues, setAssignedVenues] = useState([]);
  const [assignedRestaurant, setAssignedRestaurant] = useState([]);
  const [assignedHotel, setAssignedHotel] = useState(null);
  const [emailError, setEmailError] = useState(""); // Add state for email error

  // for subadmin use invitedby id as vendor id
  // for superadmin user vendorId directly
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: async (data) => {
      const result = await createTeamMember(data);
      if (!result.success) {
        throw new Error(result.message); // This will trigger the onError callback
      }
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers"]);
      onClose();
      setSnackbar({
        open: true,
        message: "User added successfully!",
        severity: "success",
      });
    },
    onError: (error) => {
      // Error handling for the toast notification
      setSnackbar({
        open: true,
        message: error.message || "User not added!",
        severity: "error",
      });
    },
  });

  const onClose = () => {
    showAddModal(false);
  };

  const isValidEmail = (email) => {
    const freeEmailDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
    ];

    // Split the email into local part and domain
    const emailParts = email.split("@");
    if (emailParts.length !== 2) return false;

    const domain = emailParts[1];

    // Check that the domain isn't from a free email provider and that it follows basic email structure rules
    return (
      !freeEmailDomains.includes(domain.toLowerCase()) &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    );
  };

  // creating user
  const handleSubmit = async () => {
    console.log(assignedVenues, "assign ones");
    setEmailError(""); // Clear error if email is valid
    try {
      await createMutation.mutateAsync({
        name: fullname,
        email,
        role,
        phone: mobileno,
        isAdmin,
        assignedVenues,
        RestaurantForm: assignedRestaurant,
        Hotel: assignedHotel ? [assignedHotel] : [],
        vendor: vendorId,
      });

      // Trigger the middleware that decides which email to send
      await fetch(`${base_URL}/auth/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, vendorId }),
      });
    } catch (error) {
      // Handle any unexpected errors here
      console.error("An unexpected error occurred:", error);
    }
  };

  // const onClick = () => {
  // 	if (isAdmin) {
  // 		// If admin, directly submit
  // 		handleSubmit();
  // 	} else {
  // 		// If not admin, check if venues need to be assigned
  // 		if (assignVenue) {
  // 			handleSubmit();
  // 		} else {
  // 			if (!isValidEmail(email)) {
  // 				toast.error(
  // 					'Invalid email address. Please enter a valid work email.'
  // 				);
  // 				return;
  // 			} else {
  // 				setAssignVenue(true);
  // 			}
  // 		}
  // 	}
  // };
  const onClick = () => {
    // Apply email validation for both admin and non-admin users
    if (!isValidEmail(email)) {
      toast.error("Invalid email address. Please enter a valid work email.");
      return;
    }

    // For non-admins, check if venues need to be assigned before submission
    // if (!isAdmin && !assignVenue) {
    // 	setAssignVenue(true); // Set the flag to assign venues if not yet assigned
    // 	return;
    // }

    // If all conditions are met, submit the form
    handleSubmit();
  };

  return (
    <div className="fixed z-[999] inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 flex flex-wrap justify-between">
        <div className="w-full flex justify-end">
          <p className="cursor-pointer" onClick={() => onClose()}>
            X
          </p>
        </div>
        <h2 className="text-2xl w-full font-semibold mb-4 text-center">
          Add User
        </h2>
        {assignVenue ? (
          <AssignVenues
            assignedVenues={assignedVenues}
            setAssignedVenues={setAssignedVenues}
            assignedRestaurants={assignedRestaurant}
            setAssignedRestaurants={setAssignedRestaurant}
            assignedHotel={assignedHotel}
            setAssignedHotel={setAssignedHotel}
          />
        ) : (
          <>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Full name"
                  type="text"
                  placeholder="Enter full name"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Work Email"
                  type="text"
                  placeholder="user@workapp.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && (
                  <p className="text-red-500 text-sm">{emailError}</p>
                )}
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <label htmlFor="" className="mb-3 text-[#5E5E5E] text-[14px] ">
                  Mobile No.
                </label>
                <PhoneInput
                  countryCodeEditable={false}
                  country={"ae"}
                  placeholder="Enter your contact no."
                  enableSearch={true}
                  className="mt-1.5"
                  value={mobileno}
                  onChange={(mobileno) => setMobileno(mobileno)}
                  inputProps={{
                    name: "mobileno",
                    required: true,
                  }}
                  inputStyle={{
                    border: "1px solid #d1d5db",
                    boxShadow: "none",
                    borderRadius: "6px", // Match border radius if needed
                    width: "100%",
                  }}
                  containerStyle={{
                    border: "none", // Apply the border directly to the container
                    borderRadius: "4px", // Match the border radius
                  }}
                  isValid={(value) => /^[0-9]{10,15}$/.test(value)}
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Role in the company"
                  type="text"
                  placeholder="Enter role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2 items-center">
                <input
                  className="h-[24px] w-[24px]"
                  type="radio"
                  id="admin"
                  name="role"
                  value="admin"
                  onChange={() => setIsAdmin(true)}
                  checked={isAdmin}
                />
                <label htmlFor="admin">Grant Full Admin Access</label>
                <IoIosInformationCircleOutline
                  color="#B0B0B0"
                  style={{ fontSize: "24px" }}
                  data-tooltip-id="admin-tooltip"
                  data-tooltip-content="Grant user administrator rights."
                />
                <ReactTooltip
                  id="admin-tooltip"
                  place="right"
                  style={{
                    maxWidth: "250px",
                    whiteSpace: "normal",
                    textAlign: "left",
                  }}
                />
              </div>
              <div className="flex gap-2 items-center">
                <input
                  className="h-[24px] w-[24px]"
                  type="radio"
                  id="venue"
                  name="role"
                  value="venue"
                  onChange={() => setIsAdmin(false)}
                  checked={!isAdmin}
                />
                <label htmlFor="venue">Grant Access to Venue & Enquiries</label>
                <IoIosInformationCircleOutline
                  color="#B0B0B0"
                  style={{ fontSize: "24px" }}
                  data-tooltip-id="venue-tooltip"
                  data-tooltip-content="Grant access to specific venues and enquiries."
                />
                <ReactTooltip
                  id="venue-tooltip"
                  place="right"
                  style={{
                    maxWidth: "250px",
                    whiteSpace: "normal",
                    textAlign: "left",
                  }}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col items-center w-full gap-3 mt-3">
          <button
            className="bg-primary w-[50%] text-white px-4 py-2 rounded-md"
            onClick={onClick}
          >
            {assignVenue
              ? "Submit"
              : createMutation.isLoading
              ? "Submitting..."
              : "Continue"}
          </button>
          <p
            className="text-primary font-semibold cursor-pointer"
            onClick={() => onClose()}
          >
            Cancel
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
