"use client";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import houses from "../../assets/pics/houses.svg";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteItem } from "../../api/venue-api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { unpublishHotel } from "../../api/hotel-api";

const DetailComponent = ({ data, category, combineData }) => {
  const checkAdmin = localStorage.getItem("checkAdmin");
  console.log(data, "venue type is");
  const [activePopup, setActivePopup] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: deleteItem,
    onSuccess: () => {
      queryClient.invalidateQueries(["venueData"]);
      toast.success("Item deleted successfully");
    },
    onError: (error) => {
      console.error("Error deleting item:", error.message);
    },
  });

  const unpublishMutation = useMutation({
    mutationFn: unpublishHotel,
    onSuccess: () => {
      queryClient.invalidateQueries(["venueData"]);
      toast.success("Hotel unpublished successfully");
    },
    onError: (error) => {
      console.error("Error publishing Hotel:", error.message);
    },
  });

  // const handleDelete = async (id) => {
  //   await mutation.mutateAsync({ category, id });
  // };
  const handleDelete = async (id, index) => {
    let updatedCategory = category;

    // Adjust the category based on conditions
    if (category === "Venue" && combineData === "hotel" && index === 0) {
      updatedCategory = "Hotel";
    } else if (category === "Venue" && combineData === "Restaurant") {
      updatedCategory = "Restaurant";
    } else {
      updatedCategory = "Venue";
    }

    await deleteMutation.mutateAsync({
      category: updatedCategory,
      id,
    });
  };

  const handleEdit = (id, index, item) => {
    if (
      category === "Venue" &&
      combineData === "hotel" &&
      index === 0 &&
      item?.overview
    ) {
      navigate(`/hostel-Listing-Form/Hotel/${id}`);
    } else if (category === "Venue" && combineData === "Restaurant") {
      navigate(`/create-Listing/Restaurant/${id}`);
    } else if (category === "Venue") {
      navigate(`/create-Listing/${category}/${id}`);
    } else {
      navigate(`/venu-restaurant-Form/${category}/${id}`);
    }
  };

  const handlePublish = async (id, isPublished, index) => {
    console.log(isPublished, id);
    let updatedCategory = category;

    // Adjust the category based on conditions
    if (category === "Venue" && combineData === "hotel" && index === 0) {
      updatedCategory = "Hotel";
    } else if (category === "Venue" && combineData === "Restaurant") {
      updatedCategory = "Restaurant";
    } else {
      updatedCategory = "Venue";
    }
    if (isPublished) {
      await unpublishMutation.mutateAsync({
        id,
        category: updatedCategory,
      });
    }
  };

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowLeft className="text-[#000]" />
    </div>
  );

  const imageSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    centerMode: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const handleTogglePopup = (index) => {
    console.log({ index });
    setActivePopup((prevIndex) => (prevIndex === index ? null : index));
  };

  // Status classes with appropriate colors for each status
  const statusClasses = {
    Draft: "bg-[#EBEBEB] text-[#000000]", // Adjusted text color for visibility
    underReview: "bg-[#DFDFFF] text-[#4A49D9]",
    editsunderReview: "bg-[#DFDFFF] text-[#4A49D9]",
    live: "bg-[#EAF9F7] text-[#039F8D]",
    rejected: "bg-red-200 text-red-800",
    default: "bg-gray-200 text-gray-800",
  };

  const getStatusText = (status, itemStatus) => {
    switch (true) {
      case status.toLowerCase() === "pending" &&
        itemStatus.toLowerCase() === "pending":
        return "Draft";
      case status === "completed" &&
        ["pending", "Pending", "ReSubmitting"].includes(itemStatus):
        return "Under Review";
      case status === "completed" && itemStatus === "Edit Pending":
        return "Edits Under Review";
      case status === "completed" && itemStatus === "Approved":
        return "Live";
      case status === "completed" && itemStatus === "Rejected":
        return "Rejected";
      case status === "completed" && itemStatus === "unpublish":
        return "Unpublished";
      default:
        return "Draft";
    }
  };

  // Function to determine the class based on status and itemStatus
  const getStatusClass = (status, itemStatus) => {
    switch (true) {
      case status === "pending" && itemStatus === "pending":
        return statusClasses.Draft;
      case status === "completed" &&
        ["pending", "Pending", "ReSubmitting"].includes(itemStatus):
        return statusClasses.underReview;

      case status === "completed" && itemStatus === "Approved":
        return statusClasses.live;
      case status === "completed" && itemStatus === "Edit Pending":
        return statusClasses.editsunderReview;
      case status === "completed" && itemStatus === "Rejected":
        return statusClasses.rejected;
      default:
        return statusClasses.default;
    }
  };

  const CustomSkeleton = () => (
    <div className="w-full h-[150px] rounded-lg bg-gray-200 animate-pulse"></div>
  );

  return (
    <>
      {data && (
        <div className="grid grid-cols-1 w-full relative bg-gray-100 p-10">
          <Slider {...sliderSettings}>
            {data?.map((item, itemIndex) => {
              const type =
                itemIndex === 0 && combineData === "hotel" && item?.overview
                  ? "hotel"
                  : "venue";
              const images =
                type === "hotel"
                  ? item?.photoVideo?.images || []
                  : item?.imagesAndVideos?.images || [];

              const hasImages = images?.length > 0;

              return (
                <div key={itemIndex} className="px-2 lg:w-[250px]">
                  <div
                    className="bg-white rounded-lg gap-10"
                    style={{ margin: "10px" }}
                  >
                    <div className="w-full relative">
                      <Slider {...imageSettings} dots={false}>
                        {hasImages ? (
                          images?.map((e, imageIndex) => (
                            <div key={imageIndex} className="">
                              <img
                                src={e}
                                alt=""
                                className="w-full h-[150px] rounded-lg object-cover"
                              />
                            </div>
                          ))
                        ) : (
                          <CustomSkeleton />
                        )}
                      </Slider>

                      {activePopup === itemIndex && (
                        <div className="bg-white p-2 flex flex-col absolute right-5 top-36 rounded-lg gap-2 items-start z-50 shadow-md">
                          <p
                            onClick={() =>
                              handleEdit(item?._id, itemIndex, item)
                            }
                            className="py-2 w-full rounded-lg px-4 pr-10 hover:bg-lightGray cursor-pointer"
                          >
                            Edit
                          </p>
                          {item?.itemStatus.toLowerCase() !== "pending" &&
                            item?.itemStatus.toLowerCase() !== "unpublish" &&
                            item?.status !== "pending" &&
                            checkAdmin == "true" && (
                              <p
                                onClick={() =>
                                  handlePublish(
                                    item?._id,
                                    item?.itemStatus,
                                    itemIndex
                                  )
                                }
                                className="py-2 w-full rounded-lg px-4 pr-8 hover:bg-lightGray cursor-pointer"
                              >
                                Unpublish
                              </p>
                            )}{" "}
                          {type !== "hotel" && checkAdmin == "true" && (
                            <p
                              onClick={() => handleDelete(item?._id, itemIndex)}
                              className="py-2 hover:bg-lightGray w-full rounded-lg px-4 pr-10 cursor-pointer"
                            >
                              Delete
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col items-start gap-1 p-2 relative">
                      <div className="flex items-center w-full justify-between">
                        <h1 className="text-darkGray text-base font-medium">
                          {type === "hotel"
                            ? item?.overview?.basicDetails?.hotelName
                            : item.basicDetails?.venueName}
                        </h1>
                        <div
                          className={`text-sm rounded-md p-2 ${getStatusClass(
                            item?.status,
                            item?.itemStatus
                          )}`}
                        >
                          {getStatusText(item?.status, item?.itemStatus)}
                        </div>
                      </div>
                      <p className="text-secondary text-sm">
                        {type === "hotel"
                          ? `${item?.overview?.locationDetails?.city}, ${item?.overview?.locationDetails?.area}`
                          : `${item?.location?.city}, ${item?.location?.area}`}
                      </p>
                      <div className="flex items-center w-full justify-between gap-4">
                        {/* if no venuetype than make it invisible */}
                        {item?.additionalDetails?.venueSetting[0] ? (
                          <>
                            <div className="flex items-center gap-1 mt-3">
                              <img src={houses} alt="" />
                              <p className="text-secondary text-sm">
                                {item?.additionalDetails?.venueSetting
                                  ?.sort()
                                  .map((setting) => setting)
                                  .join(" & ")}
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex items-center gap-1 mt-3 invisible">
                              <img src={houses} alt="" />
                              <p className="text-secondary text-sm">
                                {item?.additionalDetails?.venueSetting
                                  ?.sort()
                                  .map((setting) => setting)
                                  .join(" & ")}
                              </p>
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        className="b absolute bottom-2 right-2 p-1.5 rounded-full cursor-pointer"
                        onClick={() => handleTogglePopup(itemIndex)}
                      >
                        <BiDotsVerticalRounded />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </>
  );
};

export default DetailComponent;
