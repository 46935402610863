/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Link,
	Checkbox,
	Divider,
	Box,
	useTheme,
} from '@mui/material';
import TextfieldNumber from '../components/TextfieldNumber';
import TitleDescriptionCoupon from '../components/TitleDescriptionCoupon';
// import { ReactComponent as CouponIcon } from "./../../../assets/images/coupon_icon.svg";
import { ReactComponent as EditBoxIcon } from './../../../assets/images/edit_box_icon.svg';
import benefitsIcon from './../../../assets/images/benefits_icon.svg';
import BenefitsList from '../components/BenefitsList';
import TableComponent from '../components/TableComponent';
import calculateVenuePayment from '../../../utils/calculateVenuePayment';
import costStructure from '../../../constants/costStructure';
import whatsapp_icon from './../../../assets/images/whatsapp_icon.svg';
import CouponTextfield from '../components/CouponTextfield';
import CouponInputField from '../components/CouponInputField';
import { calculateSixMonthsFromNow } from '../../../utils/calculateNextDate';
import Questions from '../components/Questions';

function createData(plan, rate, quantity, total) {
	return { plan, rate, quantity, total };
}

function createThirdTableData(description, action) {
	return { description, action };
}

const firstTableHeaders = [
	{ label: 'Plan Breakdown', key: 'plan', align: 'left' },
	{ label: 'Rate', key: 'rate', align: 'right' },
	{
		label: 'Quantity',
		key: 'quantity',
		align: 'right',
		width: '15%',
	},
	{ label: 'Total', key: 'total', align: 'right' },
];

const secondTableHeaders = [
	{ label: 'Description', key: 'plan', align: 'left' },
	{ label: 'Rate', key: 'rate', align: 'right' },
	{
		label: 'Quantity',
		key: 'quantity',
		align: 'right',
		width: '15%',
	},
	{ label: 'Total', key: 'total', align: 'right' },
];

const thirdTableHeaders = [
	{ label: 'Description', key: 'description', align: 'left' },
	{ label: 'Action', key: 'action', align: 'right' },
];

function PaymentVenueView({
	paymentData,
	handleContinue,
	sameLocation,
	items,
	setItems,
}) {
	console.log('paymentData', paymentData);
	const theme = useTheme();
	const [editMode, setEditMode] = useState(false);
	const [quantityNumber, setQuantityNumber] = useState(0);
	const [discount, setDiscount] = useState(0)

	const [calculatedValues, setCalculatedValues] = useState({
		baseCharge: 0,
		quantityNumber: 0,
		subTotal: 0,
		vat: 0,
		grandTotal: 0,
		yesCount: 0,
		noCount: 0,
	});
	const [isChecked, setIsChecked] = useState(false);
	const handleCheckboxChange = (event) => {
		setIsChecked(event.target.checked);
	};

	useEffect(() => {
		if (paymentData) {
			setQuantityNumber(paymentData.quantityNumber);
		}
	}, [paymentData]);
	const handleApplyDiscount = (discountAmount) => {
		setDiscount(discountAmount);
	};
	useEffect(() => {
		// Calculate payment values
		const updatedValues = calculateVenuePayment(
			quantityNumber,
			sameLocation,
			items
		);

		// Get the subtotal from the updated values
		const subtotal = updatedValues.subTotal;

		// Calculate the discount amount
		const discountAmount = (discount / 100) * subtotal;

		// Apply the discount to the subtotal
		const discountedSubtotal = subtotal - discountAmount;

		// Update the grand total based on discounted subtotal and VAT
		const vatServiceFee = (discountedSubtotal * costStructure.vat) / 100;
		const grandTotal = discountedSubtotal + vatServiceFee;

		// Update calculated values with the new grand total
		setCalculatedValues({
			...updatedValues,
			subTotal: discountedSubtotal,
			grandTotal: grandTotal,
			discountAmount: discountAmount, // Optionally include discount amount
		});

		// Store the grand total in local storage
		localStorage.setItem('others', grandTotal.toString());

		console.log(updatedValues, 'Updated Values');
		console.log('Grand Total:', grandTotal);
	}, [quantityNumber, sameLocation, items, discount, costStructure.vat]);

	// const handleQuantityNumberChange = (event) => {
	//   setQuantityNumber(Number(event.target.value));
	// };

	const calculateTotal = (cost, number) => {
		return cost * number;
	};

	const handleQuantityNumberChange = (index, event) => {
		const newQuantityNumber = Number(event.target.value);

		const updatedItems = items.map((item, idx) => {
			if (idx === index) {
				// let newAnswer = "no";
				let amount = newQuantityNumber;

				if (newQuantityNumber === 0) {
					// Set amount to 0 and answer to 'no'
					amount = 0;
					// newAnswer = "yes";
				} else if (newQuantityNumber === 1) {
					// For amount 1, set answer to 'no'
					// newAnswer = "no";
				} else {
					// For amounts greater than 1, set answer to 'yes'
					// newAnswer = "yes";
				}

				return {
					...item,
					amount,
					// answer: newAnswer,
				};
			}
			return item;
		});

		setItems(updatedItems);
	};



	const firstTableRows = paymentData
		? items.map((item, index) => {
			// Determine cost based on amount and answer
			let cost = 0;
			if (item.amount === 1) {
				cost = item.answer === "no" ? 1000 : 2000;
			} else if (item.amount > 1) {
				cost = 2000;
			}

			return createData(
				<Typography sx={{ fontSize: { xs: "0.8rem" } }}>
					Unique Location {index + 1} - {item.answer === "no" ? "Single" : "Multi"}
				</Typography>,
				`$${cost}`,
				editMode ? (
					<TextfieldNumber
						min={0}
						max={1}
						value={item.amount}
						onChange={(event) => handleQuantityNumberChange(index, event)}
					/>


				) : (
					item.amount
				),
				`$${cost}`
			);
		})
		: [];


	const subTotal = calculatedValues.subTotal;
	const vatServiceFee = (subTotal * costStructure.vat) / 100;

	const secondTableRows = paymentData
		? [
			createData('Sub Total', '', '', `$${subTotal.toFixed(2)}`),
			createData(
				'VAT service fee (5%)',
				'',
				'',
				`$${vatServiceFee.toFixed(2)}`
			),
		]
		: [];

	const grandTotal = calculatedValues.grandTotal;

	const thirdTableRows = paymentData
		? [
			createThirdTableData(
				<Typography variant='h6'>Grand Total</Typography>,
				`$${grandTotal.toFixed(2)}/year`
			),
			createThirdTableData(
				`We will send an email reminder before your subscription begins on ${calculateSixMonthsFromNow()}. Cancel anytime before trial ends`,

				<Typography variant='h6' sx={{ fontWeight: '600' }}>
					Pay now $0
				</Typography>
			),
		]
		: [];

	const leftBox = (
		<Box
			sx={{
				width: { xs: '100%', md: '100%' },
				border: `2px solid ${theme.palette.grey.border}`,
				backgroundColor: theme.palette.white,
				borderRadius: '8px',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '200px',
				pl: 2,
				pr: 2,
			}}
		>
			<Box sx={{ display: 'flex' }}>
				<Typography
					variant='h5'
					sx={{
						fontWeight: '600',
						textAlign: 'left',
						px: 4,
						py: 2,
						pl: 2,
					}}
				>
					Venue Plan
				</Typography>
				<Button
					variant="text"
					startIcon={<EditBoxIcon />}
					onClick={() => setEditMode(!editMode)}
					sx={{ color: "primary.main", textTransform: "none", m: 0 }}
				>
					{editMode ? "Save Plan" : "Edit Plan"}
				</Button>
			</Box>

			<Box
				sx={{
					width: '95%',
					mt: -2.8,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Divider sx={{ width: '95%', mt: 2 }} />
			</Box>

			<TableComponent
				headers={firstTableHeaders}
				rows={firstTableRows}
				showHeader={true}
			/>
			{/* {editMode && <Questions items={items} setItems={setItems} />} */}

			<Divider sx={{ borderStyle: 'dashed' }} />

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					mt: 2,
					mb: 2,
				}}
			>
				{/* Coupon Field*/}
				<CouponTextfield />
				<CouponInputField onApplyDiscount={handleApplyDiscount} />

			</Box>

			<Divider sx={{ borderStyle: 'dashed' }} />

			<TableComponent
				headers={secondTableHeaders}
				rows={secondTableRows}
			/>

			<Box
				sx={{
					width: '100%',
					mt: -2.8,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Divider sx={{ width: '95%', mt: 3, mb: -1 }} />
			</Box>
			<TableComponent
				headers={thirdTableHeaders}
				rows={thirdTableRows}
			/>
		</Box>
	);

	const rightBox = (
		<Box
			sx={{
				width: { xs: '100%', md: '40%' },
				border: `2px solid ${theme.palette.grey.border}`,
				backgroundColor: theme.palette.white,
				borderRadius: '8px',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '200px',
				px: 4,
			}}
		>
			{/* For Icon + Plan Benefits*/}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					mt: 2,
				}}
			>
				<Box
					sx={{
						backgroundColor: theme.palette.primary.light,
						display: 'flex',
						justifyContent: 'center',
						borderRadius: '8px',
						mr: 2,
					}}
				>
					<img
						src={benefitsIcon}
						alt='Benefits Icon'
						style={{
							width: '40px',
							height: '40px',
						}}
					/>
				</Box>
				<Typography
					variant='h5'
					color={theme.palette.primary.main}
					fontWeight={500}
					sx={{ fontSize: '1.6rem' }}
				>
					Plan Benefits
				</Typography>
			</Box>
			{/* For Base Charge*/}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					mt: 2,
					ml: 3,
					justifyContent: 'space-between',
				}}
			>
				<Typography
					variant='body1'
					color={theme.palette.grey.dark}
					fontWeight={500}
				>
					Base Charge: ${paymentData?.baseCharge}
				</Typography>
			</Box>
			<BenefitsList
				additionalCharges={[
					{
						highlight: 'Restaurants with Alcohol',
					},
					{
						text: ' for 1 additional restaurant managed by the group',
						highlight: '$1,500 ',
					},
					{
						text: ' for 2 to 5 restaurants',
						highlight: '$1,300 ',
					},
					{
						text: ' for 5+ restaurants',
						highlight: '$1,200 ',
					},
					{
						highlight: 'Restaurants without Alcohol',
					},
					{
						text: ' for 1 restaurant',
						highlight: '$1,000 ',
					},
					{
						text: ' for 2 to 5 restaurants',
						highlight: '$900 ',
					},
					{
						text: ' for 5+ restaurants',
						highlight: '$800 ',
					},
				]}
			/>
		</Box>
	);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: theme.palette.white,
				borderRadius: theme.shape.borderRadius,
				width: { xs: '100%', sm: '80%', md: '100%' },
				pl: 2,
			}}
		>
			<TitleDescriptionCoupon
				title={'Take a look at our plan suitable for your needs!'}
				description={
					'We found this plan to be best suited for your needs'
				}
			/>
			{/* Parent Box */}
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
					gap: 5,
					alignItems: { xs: 'center', md: 'flex-start' },
					mt: 4,
					pl: 2,
				}}
			>
				{leftBox}
				{rightBox}
			</Box>

			{/* Terms and Conditions Section */}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					mt: 2,
				}}
			>
				<Checkbox
					color='primary'
					checked={isChecked}
					onChange={handleCheckboxChange}
					required
				/>
				<Box>
					<Typography>
						{'I agree to all '}
						<Link href='/terms&condition' target='_blank'>
							<span style={{ color: theme.palette.primary.main }}>
								Terms & Conditions
							</span>
						</Link>
						{' and '}
						<span style={{ color: theme.palette.primary.main }}>
							<Link href='/cancellation-policy' target='_blank'>
								Cancellation policy
							</Link>
						</span>
						{' including usage of cookies'}
					</Typography>
				</Box>
			</Box>

			{/* Start Your Free 6 Months Button */}
			<Button
				type='submit'
				variant='contained'
				size='large'
				onClick={handleContinue}
				disabled={!isChecked} // Disable button if isChecked is false
				sx={{
					mb: { xs: 1, md: 3 },
					borderRadius: '8px',
					width: { xs: '100%', md: '30%' },
					mt: 2,
					backgroundColor: isChecked
						? theme.palette.primary.main
						: theme.palette.grey.border,
					textTransform: 'none',
				}}
			>
				Start Your Free 6 Months
			</Button>
			{/* Whatsapp related Text */}
			<Box sx={{ display: 'flex', gap: 1 }}>
				<img
					src={whatsapp_icon}
					alt='Whatsapp'
					style={{ height: 22, width: 22, pl: '5px' }} // Added marginRight for spacing
				/>
				<Typography
					variant='body2'
					sx={{
						color: theme.palette.grey.text,
						fontWeight: '500',
					}}
				>
					<span style={{}}>For any queries </span>
					<Link
						target='_blank'
						href='/contact'
						sx={{ textDecoration: 'none' }}
					>
						<span
							style={{
								textTransform: 'none',
								color: theme.palette.primary.main,
							}}
						>
							contact us
						</span>
					</Link>
					<span> here—we’re here to help!</span>
				</Typography>
			</Box>
		</Box>
	);
}

export default PaymentVenueView;
