import React, { useState } from 'react';
import {
	FaWineGlassAlt,
	FaCocktail,
	FaGlassCheers,
	FaUtensils,
	FaShuttleVan,
} from 'react-icons/fa';
import { GiWineBottle } from 'react-icons/gi';
import { IoCheckmark } from 'react-icons/io5';
import { FiRefreshCcw } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const WhatThisPlaceOffers = ({ data }) => {

	const [showAll, setShowAll] = useState(false);
	const handleToggle = () => setShowAll(!showAll);
	// Extract relevant data
	const offers = [
		{
			icon: <FaUtensils />,
			text: 'Offers catering',
			condition: data?.cateringAndDrinks?.venueProvideInHouseCatering,
		},
		{
			icon: <FaShuttleVan />,
			text: 'Outside catering allowed',
			condition: data?.cateringAndDrinks?.externalCatering,
		},
		{
			icon: <GiWineBottle />,
			text: 'Bring your own alcohol Allowed',
			condition: data?.cateringAndDrinks?.bringYourOwnAlcoholAllowed,
		},
		{
			icon: <FaCocktail />,
			text: 'Corkage fee for bring your own alcohol',
			condition: data?.cateringAndDrinks?.corkageFeeForBringYourOwnAlcohol,
		},
		{
			icon: <FaGlassCheers />,
			text: 'Alcohol/ Liquor License until 10:00 PM',
			condition: data?.cateringAndDrinks?.alcoholLiquorLicense,
		},
		{
			icon: <FaWineGlassAlt />,
			text: 'Shisha',
			condition: data?.cateringAndDrinks?.shisha,
		},
		{
			icon: <FiRefreshCcw />,
			text: 'Refreshments',
			condition: data?.cateringAndDrinks?.refreshments,
		},
	];

	const menuItems = data?.cateringAndDrinks?.availableMenu || [];
	const refreshments = data?.cateringAndDrinks?.refreshmentsForGuests
		? ['Tea & Coffee', 'Coffee', 'Water']
		: [];
	const servingStyles = data?.cateringAndDrinks?.servingStyle || [];
	const cuisineOptions = data?.cateringAndDrinks?.cuisine || [];

	return (
		<div className='rounded-lg py-8 border-t border-gray-300 lg:w-3/4 w-full px-5 mx-auto'>
			<h2 className='text-2xl font-bold mb-4'>
				What This Place Offers
			</h2>

			<div className='mb-6 flex justify-between flex-wrap gap-5'>
				<h3 className='text-xl lg:w-1/5 w-full font-semibold mb-2'>
					Catering & Drinks
					<Link
						to='#'
						className='text-red-500 mb-2 text-base underline font-semibold inline-block'
					>
						View Menu
					</Link>
				</h3>
				<div className='lg:w-3/4 w-full grid md:grid-cols-2 grid-cols-1'>
					{offers.map((offer, index) => (
						<div
							key={index}
							className={`flex items-center p-4 rounded-lg ${offer.condition ? 'text-gray-700' : 'text-gray-400 line-through'}`}
						>
							<div className='text-2xl mr-2'>{offer.icon}</div>
							<span>{offer.text}</span>
						</div>
					))}
				</div>

				<h3 className='text-xl flex flex-col lg:w-1/5 w-full font-semibold mb-2'>
					{' '}
				</h3>
				<div className='lg:w-3/4 w-full'>
					<div>
						<h3 className='text-lg lg:w-3/5 w-full font-semibold py-3 mb-2'>
							Menu
						</h3>
						{menuItems.length > 0 && (
							<div className='mb-6'>
								<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
									{menuItems.map((item, index) => (
										<div key={index} className='flex items-center'>
											<IoCheckmark className='text-gray-500 text-2xl mr-2' />
											<span className='text-gray-700 text-lg'>{item}</span>
										</div>
									))}
								</div>
							</div>
						)}
					</div>

					{refreshments.length > 0 && (
						<div className='w-full'>
							<h3 className='text-lg lg:w-3/5 w-full font-semibold py-3 mb-2'>
								Refreshments
							</h3>
							<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
								{refreshments.map((item, index) => (
									<div key={index} className='flex items-center'>
										<IoCheckmark className='text-gray-500 text-2xl mr-2' />
										<span className='text-gray-700 text-lg'>{item}</span>
									</div>
								))}
							</div>
						</div>
					)}

					{showAll && <div className='lg:w-3/4 w-full'>
						{servingStyles.length > 0 && (
							<div className='w-full'>
								<h3 className='text-lg lg:w-3/5 w-full font-semibold py-3 mb-2'>
									Serving style
								</h3>
								<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
									{servingStyles.map((style, index) => (
										<div key={index} className='flex items-center'>
											<IoCheckmark className='text-gray-500 text-2xl mr-2' />
											<span className='text-gray-700 text-lg'>{style}</span>
										</div>
									))}
								</div>
							</div>
						)}

						{cuisineOptions.length > 0 && (
							<div className='w-full'>
								<h3 className='text-lg lg:w-3/5 w-full font-semibold py-3 mb-2'>
									Cuisine type
								</h3>
								<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
									{cuisineOptions.map((cuisine, index) => (
										<div key={index} className='flex items-center'>
											<IoCheckmark className='text-gray-500 text-2xl mr-2' />
											<span className='text-gray-700 text-lg'>{cuisine}</span>
										</div>
									))}
								</div>
							</div>
						)}

					</div>
					}
					<div className="text-center mt-4 md:col-span-2 w-full flex justify-start">
						<button onClick={handleToggle} className="text-[#FE4747] underline">
							{showAll ? 'Show Less' : 'Show More'}
						</button>
					</div>
				</div>
			</div>


		</div>
	);
};

export default WhatThisPlaceOffers;
