import React from 'react';
import "./App.css";
import LandingPage from "./pages/landing_page/LandingPage";
import Login from "./pages/onboarding/Login";
import Register from "./pages/onboarding/Register";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./constants/theme";
import Onboarding from "./pages/onboarding/Onboarding";
import EmailVerificationSuccessful from "./pages/onboarding/EmailVerificationSuccessful";
import Reset from "./pages/onboarding/Reset";
import ResetPassword from "./pages/onboarding/ResetPassword";
import PaymentSuccess from "./pages/onboarding/PaymentSuccess";
import PaymentRejected from "./pages/onboarding/PaymentRejected";
import ResendEmail from "./pages/onboarding/ResendEmail";
import Dashboard from "./pages/hotel_listing/Dashboard";
import HostelListingForm from "./pages/hotel_listing/hotel-listing";
import VenuListingForm from "./pages/hotel_listing/venu-listing";
import HotelPreview from "./pages/hotel_listing/hotel-preview";
import DashboardVenueProfile from "./components/dashboard-components/dashboard-venue-profile/Dashboard-Venue-Profile";
import { HotelProvider } from "./context/HotelContext";
import { VenueProvider } from "./context/VenueContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Team from "./pages/team";
import TeamDetails from "./pages/team/team-details";
import TermsAndConditions from "./pages/onboarding/TermsCondition";
import Policy from "./pages/onboarding/components/policy";
import ProtectedRoute from "../src/hooks/protectRoute";
import RestaurantListingForm from "./pages/hotel_listing/restaurant-listing";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import VenuePreview from './pages/hotel_listing/venue-preview';
import TermsOfUse from './pages/onboarding/term-use';
import PrivacyPolicy from './pages/onboarding/privacy-policey';
import { UserProvider } from './context/user';
import About from './pages/about/About';
import Home from './pages/home/Home';
import ListVenues from './pages/listVenues/ListVenues';
import ContactUs from './pages/contactUs/ContactUs';
import MyVenues from './pages/myVenues/MyVenues';
import RestaurantPreview from './pages/hotel_listing/restaurant-preview';
import EditVenuListingForm from './pages/hotel_listing/edit-venue-form';
import EditHostelListingForm from './pages/hotel_listing/edit-hotel';
import EditVenuePreview from './pages/hotel_listing/edit-venue-preview';
import EditResListingForm from './pages/hotel_listing/edit-restaurant-form';
import LoginActivator from './pages/login-activator/LoginActivator';

const queryClient = new QueryClient();

const App = () => {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <UserProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>

            <HotelProvider>
              <VenueProvider>
                <Router>
                  <Routes>
                    {/* Onboarding */}
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/login-activator/:email" element={<LoginActivator />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    {/* <Route path="/onboarding" element={<Onboarding />} /> */}
                    <Route path="/cancellation-policy" element={<Policy />} />
                    <Route path="/terms&condition" element={<TermsAndConditions />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/success" element={<EmailVerificationSuccessful />} />
                    <Route path="/reset" element={<Reset />} />
                    <Route path="/resetPassword" element={<ResetPassword />} />
                    <Route path="/payment-success" element={<PaymentSuccess />} />
                    <Route path="/payment-failed" element={<PaymentRejected />} />
                    <Route path="/resend" element={<ResendEmail />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/list-venues" element={<ListVenues />} />
                    <Route path="/contact" element={<ContactUs />} />

                    {/* Protected Routes */}

                    <Route
                      path="/onboarding"
                      element={<ProtectedRoute element={Onboarding} />}
                    />
                    <Route
                      path="/dashboard"
                      element={<ProtectedRoute element={Dashboard} />}
                    />
                    <Route
                      path="/hostel-Listing-Form"
                      element={<ProtectedRoute element={HostelListingForm} />}
                    />
                    <Route
                      path="/hostel-Listing-Form/:category/:id"
                      element={<ProtectedRoute element={EditHostelListingForm} />}
                    />
                    <Route
                      path="/venu-Listing-Form"
                      element={<ProtectedRoute element={VenuListingForm} />}
                    />
                    <Route
                      path="/create-Listing/:category/:id"
                      element={<ProtectedRoute element={EditVenuListingForm} />}
                    />
                    <Route
                      path="/venu-restaurant-Form"
                      element={<ProtectedRoute element={RestaurantListingForm} />}
                    />
                    <Route
                      path="/venu-restaurant-Form/:category/:id"
                      element={<ProtectedRoute element={EditResListingForm} />}
                    />
                    <Route
                      path="/user-Profile"
                      element={<ProtectedRoute element={DashboardVenueProfile} />}
                    />
                    <Route
                      path="/hotel-preview"
                      element={<ProtectedRoute element={HotelPreview} />}
                    />
                    <Route
                      path="/venue-preview"
                      element={<ProtectedRoute element={VenuePreview} />}
                    />
                    <Route
                      path="/venue-preview/:category/:id"
                      element={<ProtectedRoute element={EditVenuePreview} />}
                    />
                    <Route
                      path="/restaurant-preview"
                      element={<ProtectedRoute element={RestaurantPreview} />}
                    />
                    <Route
                      path="/team"
                      element={<ProtectedRoute element={Team} />}
                    />
                    <Route
                      path="/team/team-details/:id"
                      element={<ProtectedRoute element={TeamDetails} />}
                    />
                    <Route
                      path="/myVenue"
                      element={<MyVenues />}
                    />
                  </Routes>
                </Router>
              </VenueProvider>
            </HotelProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </UserProvider>

    </div>
  );
};

export default App;
