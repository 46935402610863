import React, { useState } from "react";
import Input from "../shared/common/customInput";
import { IoIosInformationCircleOutline } from "react-icons/io";
import AssignVenues from "./assign-venues";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateTeamMember } from "../../api/teamMember.request";
import { base_URL } from "../../config/config";

const EditUserModal = ({ setShowEditModal, setSnackbar, data }) => {
  // edit data coming in below state
  // console.log('data is this', data);
  const [fullname, setFullname] = useState(data?.name ? data?.name : "");
  const [email, setEmail] = useState(data?.email ? data?.email : "");
  const [mobileno, setMobileno] = useState(data?.phone ? data?.phone : "");
  const [role, setRole] = useState(data?.role ? data?.role : "");
  const [isAdmin, setIsAdmin] = useState(data?.isAdmin ? data?.isAdmin : null);
  const [assignVenue, setAssignVenue] = useState(false);
  const [assignedVenues, setAssignedVenues] = useState(
    data?.assignedVenues ? data?.assignedVenues : []
  );
  const [assignedRestaurant, setAssignedRestaurant] = useState(
    data?.RestaurantForm ? data?.RestaurantForm : []
  );
  const [assignedHotel, setAssignedHotel] = useState(
    data?.Hotel ? data?.Hotel : []
  );
  // for sub admins invitedBy will be used and for others super admin vendorId will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const queryClient = useQueryClient();

  const onClose = () => {
    setShowEditModal(false);
  };
  console.log(vendorId, "ye ha id");

  const updateMutation = useMutation({
    mutationKey: ["teamMembers"],
    mutationFn: async (data) => {
      return updateTeamMember(data?.id, data?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers"]);
      setSnackbar({
        open: true,
        message: "User updated successfully!",
        severity: "success",
      });
      onClose();
    },
  });

  const hanldeSubmit = async () => {
    await updateMutation.mutateAsync({
      id: data?._id,
      data: {
        name: fullname,
        email,
        role,
        phone: mobileno,
        isAdmin,
        assignedVenues,
        RestaurantForm: assignedRestaurant,
        // Hotel: assignedHotel ? [assignedHotel] : [],
        Hotel: Array.isArray(assignedHotel) ? assignedHotel : [assignedHotel],
        vendor: vendorId,
      },
    });
    const res = await fetch(`${base_URL}/auth/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const responseData = await res.json();
    console.log(responseData, "edit venues");
  };

  const onClick = () => {
    if (isAdmin) {
      // If admin, directly submit
      hanldeSubmit();
    } else {
      // If not admin, check if venues need to be assigned
      if (assignVenue) {
        hanldeSubmit();
      } else {
        setAssignVenue(true);
      }
    }
  };

  return (
    <div className="md:fixed z-[999] inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] md:w-1/2 flex flex-wrap justify-between">
        <div className="w-full flex justify-end">
          <p className="cursor-pointer" onClick={() => onClose()}>
            X
          </p>
        </div>
        <h2 className="text-2xl w-full font-semibold mb-4 text-center">
          Edit User
        </h2>
        {assignVenue ? (
          <AssignVenues
            setAssignedVenues={setAssignedVenues}
            assignedVenues={assignedVenues}
            assignedRestaurants={assignedRestaurant}
            setAssignedRestaurants={setAssignedRestaurant}
            assignedHotel={assignedHotel}
            setAssignedHotel={setAssignedHotel}
          />
        ) : (
          <>
            {" "}
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Full name"
                  type="text"
                  placeholder="Enter full name"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Work Email"
                  type="text"
                  placeholder="user@workapp.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Mobile No."
                  type="text"
                  placeholder="Enter mobile number"
                  value={mobileno}
                  onChange={(e) => setMobileno(e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Role in the company"
                  type="text"
                  placeholder="Enter role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2">
                <input
                  className="h-[24px] w-[24px]"
                  type="radio"
                  id="huey"
                  name="drone"
                  value="huey"
                  onChange={(e) => setIsAdmin(true)}
                  checked={isAdmin}
                />
                <label for="huey">Grant Full Admin Access</label>
                <IoIosInformationCircleOutline
                  color="#B0B0B0"
                  style={{ fontSize: "24px" }}
                />
              </div>
              <div>
                <div className="flex gap-2">
                  <input
                    className="h-[24px] w-[24px]"
                    type="radio"
                    id="huey"
                    name="drone"
                    value="huey"
                    onChange={(e) => setIsAdmin(false)}
                    checked={!isAdmin}
                  />
                  <label for="huey">Grant Access to Venue & Enquires</label>
                  <IoIosInformationCircleOutline
                    color="#B0B0B0"
                    style={{ fontSize: "24px" }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col items-center w-full gap-3 mt-3">
          <button
            className="bg-primary w-[50%] text-white px-4 py-2 rounded-md"
            onClick={onClick}
          >
            {assignVenue
              ? "Submit"
              : updateMutation.isPending
              ? "Submitting..."
              : "Continue"}
          </button>
          <p
            className="text-primary font-semibold cursor-pointer"
            onClick={() => onClose()}
          >
            Cancel
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
