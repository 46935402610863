import axios from 'axios';
import { base_URL } from '../config/config';

const API_BASE_URL = `${base_URL}/`;

export const payment = async (paymentData) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    console.log(accessToken,"token")
    const response = await axios.post(`${API_BASE_URL}api/vendor/payment-create`, paymentData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error processing payment:', error);
    throw error;
  }
};
