import React from 'react'
import img1 from '../../assets/pics/space1.png'
import img2 from '../../assets/pics/space2.png'
import img3 from '../../assets/pics/space3.png'
import img4 from '../../assets/pics/space4.png'
import img5 from '../../assets/pics/space5.png'
import img6 from '../../assets/pics/space6.png'
import img7 from '../../assets/pics/space7.png'
import ImageCarousel from './ImageCarousel'


const Spaces = () => {
    return (
        <div className='flex justify-center w-full pt-16' >
            <div className='w-[90%] md:flex md:flex-col items-center' >
                <h1 className='text-[#222] sm:text-4xl text-2xl font-semibold text-center'>Discover Popular Event Spaces in the UAE</h1>
                <div className='md:flex hidden h-full gap-5 mt-10'>
                    <div className='bg-white border rounded-lg flex-col w-fit h-fit flex items-center '>
                        <img src={img1} alt="" className='rounded-lg' />
                        <div className=' flex flex-col items-center'>
                            <h1 className='-mt-16 text-lg font-medium'>Dubai</h1>
                            <p className='text-[#5E5E5E]'>2829 Venues</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5 h-full'>
                        <div className='flex gap-5'>
                            <div className='bg-white border rounded-lg flex-col w-fit flex items-center '>
                                <img src={img2} alt="" className='rounded-lg' />
                                <div className=' flex flex-col items-center'>
                                    <h1 className='-mt-16 text-lg font-medium'>Ajman</h1>
                                    <p className='text-[#5E5E5E]'>2829 Venues</p>
                                </div>
                            </div>
                            <div className='bg-white border rounded-lg flex-col w-fit flex items-center '>
                                <img src={img3} alt="" className='rounded-lg' />
                                <div className=' flex flex-col items-center'>
                                    <h1 className='-mt-16 text-lg font-medium'>Umm Al Quwain</h1>
                                    <p className='text-[#5E5E5E]'>2829 Venues</p>
                                </div>
                            </div>
                            <div className='bg-white border rounded-lg flex-col w-fit flex items-center '>
                                <img src={img4} alt="" className='rounded-lg' />
                                <div className=' flex flex-col items-center'>
                                    <h1 className='-mt-16 text-lg font-medium'>Sharjah</h1>
                                    <p className='text-[#5E5E5E]'>2829 Venues</p>
                                </div>
                            </div>
                        </div>

                        <div className='flex gap-5'>
                            <div className='bg-white border rounded-lg flex-col w-fit flex items-center '>
                                <img src={img5} alt="" className='rounded-lg' />
                                <div className=' flex flex-col items-center'>
                                    <h1 className=' -mt-16 text-lg font-medium'>Abu Dhabi</h1>
                                    <p className='text-[#5E5E5E]'>2829 Venues</p>
                                </div>
                            </div>
                            <div className='bg-white border rounded-lg flex-col w-fit flex items-center '>
                                <img src={img6} alt="" className='rounded-lg' />
                                <div className=' flex flex-col items-center'>
                                    <h1 className='-mt-16 text-lg font-medium'>Fujairah</h1>
                                    <p className='text-[#5E5E5E]'>2829 Venues</p>
                                </div>
                            </div>
                            <div className='bg-white border rounded-lg flex-col w-fit flex items-center '>
                                <img src={img7} alt="" className='rounded-lg' />
                                <div className=' flex flex-col items-center'>
                                    <h1 className='-mt-16 text-lg font-medium'>Al Ain</h1>
                                    <p className='text-[#5E5E5E]'>2829 Venues</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='md:hidden block mt-10'>
                    <ImageCarousel />
                </div>
            </div>
        </div>
    )
}

export default Spaces
