import React from 'react';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';


const TermsAndConditions = () => {
    return (
        <>
            <ResponsiveAppBar />
            <div

                className='flex justify-center'>
                <div className="min-h-screen  sm:p-8 p-4 md:w-[60%] sm:w-[80%] w-full">
                    <h1 className="text-3xl font-bold text-center mb-8 text-custom">Terms & Conditions</h1>
                    <div className="mt-8 text-custom">

                        <p className='mb-4 '>
                            <span className='font-bold '>"Payment Processor"</span>
                            shall mean a third-party service provider of the Company that facilitates online transactions by securely handling the transfer of money between the Vendor and Company. The Payment Processor enables the processing of various payment methods, including but not limited to credit cards, debit cards, and any other method, to facilitate purchases or transactions on the Platform.
                        </p>

                        <p className='mb-4 '>
                            <span className='font-bold '>"Services"</span>
                            shall mean listing of venues and facilitation of User Enquiries on the Platform between the Vendor and the User and such other services as may be made available to Vendors having a Vendor Subscription from time to time by the Company.
                        </p>

                        <p className='mb-4 '>
                            <span className='font-bold '>"User"</span>
                            shall mean any individual who has created an Account on the Platform and visits and/or uses the Platform for the purpose of making User Enquiries, in accordance with the terms applicable to such an individual.
                        </p>

                        <p className='mb-4 '>
                            <span className='font-bold '>"User Enquiry"</span>
                            shall mean an enquiry sent by a User to the Vendor through the Platform.
                        </p>

                        <p className='mb-4 '>
                            <span className='font-bold '>"Vendor"</span>
                            shall mean a firm, company, partnership, society, or other legal entity who for the purpose of listing its venues uses the Platform, is registered as a ‘Venue Operator’ on the Platform and has a valid Venue Subscription, in accordance with the terms contained herein and/or has entered into an agreement with the Company.
                        </p>

                        <p className='mb-4 '>
                            <span className='font-bold '>"Vendor Subscription"</span>
                            shall mean payment of Vendor Subscription Amount by the Vendor, that allows the Vendor access to the Services on the Platform during the Vendor Subscription Period.
                        </p>

                        <p className='mb-4 '>
                            <span className='font-bold '>"Vendor Subscription Amount"</span>
                            shall mean the total subscription amount exclusive of applicable taxes and/or deductions, payable by the Vendor for the Vendor Subscription Period.
                        </p>

                        <p className='mb-4 '>
                            <span className='font-bold '>"Vendor Subscription Period"</span>
                            shall mean a period of 1 (One) year during which a Vendor Subscription shall be valid and active, which shall automatically renew on a yearly basis, unless cancelled or notified in writing by the Vendor 1 (One) month prior to the end of this period.
                        </p>

                        <p className='mb-4 font-bold '>
                            MEMBERSHIP ELIGIBILITY
                        </p>

                        <p className='mb-4 '>
                            Use of the Platform is available only to persons who can enter into legally binding contracts under applicable law. Persons who are "incompetent to contract" within the meaning applicable law including minors and persons of unsound mind are not eligible to use the Platform.
                        </p>

                        <p className='mb-4 '>
                            Any person under the age of 21 (Twenty One) shall not register on the Platform and shall not transact on or use the Platform. If a minor wishes to use or transact on the Platform, such use or transaction may be made by the minor’s legal guardian or parents on the Platform. Any minor accessing, using, or transacting on the Platform in violation of these Vendor Terms shall be doing so at their own risk and the Company shall not be responsible or liable for any activities or interactions of such minor on the Platform.
                        </p>

                        <p className='mb-4 '>
                            The Company, at any time, reserves the right to terminate any person’s membership and/or refuse to provide such person with access to the Platform if it is brought to the Company’s notice or if it is discovered that such person is not eligible to use the Platform.
                        </p>

                        <p className='mb-4 font-bold '>
                            ACCEPTANCE OF VENDOR TERMS
                        </p>

                        <p className='mb-4 '>
                            The Vendor is required to carefully read these Vendor Terms, as it contains the terms and conditions governing the Vendor’s use of the Platform and any content such as text, data, information, software, graphics, audio, video, or photographs etc. that the Company may make available through the Platform along with any Services that the Company may provide through the Platform. By further accessing or using the Platform, the Vendor is agreeing to these Vendor Terms and entering into a legally binding contract with the Company. The Vendor may not use the Platform if the Vendor does not accept the Vendor Terms or is unable to be bound by these Vendor Terms.
                        </p>

                        <p className='mb-4 '>
                            In order to use the Platform, the Vendor must first agree to these Vendor Terms. The Vendor can accept these Vendor Terms by:
                        </p>

                        <ul className='list-disc pl-6 mb-4'>
                            <li className='mb-2 '>
                                Signing up with the Platform and logging onto the Platform by using the Vendor Login Credentials (defined below); or
                            </li>
                            <li className='mb-2 '>
                                By clicking to accept these Vendor Terms, if and when prompted on the Platform.
                            </li>
                        </ul>

                        <p className='mb-4 font-bold '>
                            OPENING AN ACCOUNT
                        </p>

                        <p className='mb-4 '>
                            Registration on the Platform and applying for a Vendor Subscription is mandatory for the Vendor to access the Services provided by the Company on the Platform.
                        </p>

                        <p className='mb-4 '>
                            Before registering with the Company and applying for a Vendor Subscription, the Vendor will have to fill out a form and complete other verification formalities which shall be indicated by the Company. All information requested by the Company will have to be provided in order for the Vendor to register on the Platform and apply for a Vendor Subscription.
                        </p>

                        <p className='mb-4 '>
                            Following further discussions with the Vendor and upon analyzing the information provided by the Vendor, the Company shall at its discretion, proceed to allow the Vendor to apply for a Vendor Subscription and list its venues and related offerings on the Platform through an account created on the Platform, wherein the Vendor will be required to provide the Vendor’s name, an e-mail address, and a password (“Vendor Account”). The Company reserves the right to determine which vendors may be allowed to list their venues on the Platform. The Company further reserves the right to decline to register the vendors on its Platform at its sole discretion. At no point of time can such decisions of the Company be challenged by any vendor.
                        </p>

                        <p className='mb-4 '>
                            While registering with the Company and creating the Vendor Account, the Vendor shall not:
                        </p>
                        <ul className='list-disc pl-6 mb-4 '>
                            <li className='mb-2'>
                                create an account for anyone other than the Vendor, unless such person's prior permission has been obtained;
                            </li>
                            <li className='mb-2'>
                                use a Vendor Account that is in the name of another person with the intent to impersonate that person;
                            </li>
                            <li className='mb-2'>
                                use a name for the Vendor Account on the Platform that the Vendor is not legally authorized to use; and
                            </li>
                            <li className='mb-2'>
                                create more than one Vendor Account on the Platform.
                            </li>
                        </ul>

                        <p className='mb-4 '>
                            The Company cannot and will not be liable for any loss or damage arising from the Vendor’s failure to comply with this Clause
                        </p>

                        <p className='mb-4 '>
                            Once approved by the Company, in order to log into the Vendor Account on the Platform, the Vendor will be required to provide the e-mail address and password used at the time of registration <span className='font-bold'> (“Vendor Login Credentials”).</span> The Vendor, upon being approved to act as a ‘Vendor’ on the Platform, will be provided access to a dashboard (linked to the Vendor Account) which shall contain details of all User Enquiries received by the User in relation to the Vendor’s venues through the Platform.
                        </p>

                        <p className='mb-4 '>
                            The Vendor agrees that the information provided by the Vendor to the Company, at all times (including during registration), will be true, accurate, up-to-date, and complete. The Vendor further agrees that if the Vendor provides any information that is untrue, inaccurate, not up-to-date or incomplete or becomes untrue, inaccurate or incomplete or if the Company has reasonable grounds to suspect that such information is untrue, inaccurate, not up-to-date, incomplete or not in accordance with these Vendor Terms, the Company shall have the right to indefinitely suspend or terminate or block access to the Vendor’s Account on the Platform and refuse to provide the Vendor access to the Platform. The Vendor is advised to use due caution and ensure compliance with applicable laws when providing any information to the Company accordingly.
                        </p>

                        <p className='mb-4 font-bold '>
                            ACCOUNT CONFIDENTIALITY OBLIGATIONS
                        </p>

                        <p className='mb-4 '>
                            The Vendor agrees that the sole responsibility of maintaining the security and confidentiality of the Vendor Login Credentials rests with the Vendor at all times. The Vendor shall not share these Vendor Login Credentials with any third party, and the Company shall not be liable for any loss or damage arising from such breach.
                        </p>

                        <p className='mb-4 '>
                            The Company reserves the right to take any and all action, as it deems necessary or reasonable, regarding the security of the Platform and the Vendor Account.
                        </p>

                        <p className='mb-4 '>
                            The Vendor is solely responsible for all activities that occur under the Vendor Account and hereby undertakes that no such activity shall be unlawful or in violation of any applicable laws, rules, and regulations of the United Arab Emirates.
                        </p>

                        <p className='mb-4 '>
                            In no event and under no circumstances shall the Company be held liable for any liabilities or damages resulting from or arising out of the Vendor’s use of the Platform or the Vendor Login Credentials, theft of the Vendor Login Credentials or release by the Vendor of the Vendor Login Credentials to a third party, or the Vendor’s authorization to allow another person to access and use the Platform using the Vendor Account.
                        </p>

                        <p className='mb-4 '>
                            The Vendor agrees to (a) immediately notify the Company of any misappropriation or unauthorized use of the Vendor Account or any other breach of security via the registered e-mail address that was used at the time of registration or in such other manner as may be indicated by the Company; and (b) exit from the Vendor Account at the end of each session. The Company cannot and will not be liable for any loss or damage arising from the Vendor’s failure to comply with this Clause. The Vendor may be held liable for losses incurred by the Company or any user or visitor of the Platform due to authorized or unauthorized use of the Vendor Account, as a result of the Vendor’s failure in keeping the Vendor Login Credentials confidential.
                        </p>

                        <p className='mb-4 font-bold '>
                            USE OF THE PLATFORM
                        </p>

                        <p className='mb-4 '>
                            The Vendor agrees, undertakes, and covenants that, during the use of the Platform, the Vendor shall not host, display, upload, modify, publish, transmit, store, update or share any information that:
                        </p>
                        <ul className='list-disc list-inside mb-4 '>
                            <li className='mb-2'>
                                Belongs to another person or entity and to which the Vendor does not have any right.
                            </li>
                            <li className='mb-2'>
                                Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libelous, invasive of another person's privacy, insulting or harassing on the basis of gender, hateful or racially or ethnically objectionable, or promoting enmity between different groups on the grounds of religion or caste with the intent to incite violence, or otherwise inconsistent or contrary to the applicable laws in force in any manner whatsoever.
                            </li>
                            <li className='mb-2'>
                                Is harmful to minors.
                            </li>
                            <li className='mb-2'>
                                Is misleading in any way.
                            </li>
                            <li className='mb-2'>
                                Involves the transmission of "junk mail", "chain letters", unsolicited mass mailing or "spamming".
                            </li>
                            <li className='mb-2'>
                                Infringes upon or violates any third party's rights including, but not limited to, any patent, trademark, copyright or other proprietary rights or intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, e-mail address, physical address or phone number) or rights of publicity.
                            </li>
                            <li className='mb-2'>
                                Impersonates another person or provides instructional information about illegal activities such as violating someone's privacy, or providing or creating computer viruses.
                            </li>
                            <li className='mb-2'>
                                Tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, or other areas of the Platform or solicits passwords or personally identifying information for commercial or unlawful purposes from other users of the Platform.
                            </li>
                            <li className='mb-2'>
                                Engages in commercial activities without the Company’s prior written consent such as engaging in contests, sweepstakes, barter, advertising, etc.
                            </li>
                            <li className='mb-2'>
                                Interferes with another user’s use of the Platform.
                            </li>
                            <li className='mb-2'>
                                Refers to any website or URL that, in the Company’s sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Vendor Terms.
                            </li>
                            <li className='mb-2'>
                                Deceives or misleads the addressee/users about the origin of the messages or knowingly and intentionally communicates any information which is patently false or misleading or grossly offensive or menacing in nature but may reasonably be perceived as a fact.
                            </li>
                            <li className='mb-2'>
                                Contains software viruses or any other computer codes, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, diminish value of, covertly intercept or steal any system, data or Personal Information of another User/visitor.
                            </li>
                            <li className='mb-2'>
                                Is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.
                            </li>
                            <li className='mb-2'>
                                Violates any applicable law for the time being in force.
                            </li>
                        </ul>

                        <p className='mb-4 '>
                            The Vendor shall be solely responsible for all acts of its employees and/or representatives that transact on the Platform using the Vendor Login Credentials, as may be applicable.
                        </p>

                        <p className='mb-4 '>
                            The Vendor agrees and acknowledges that (a) the Vendor shall not use the Platform for any illegal or unauthorized purpose; and (b) the use of the Platform and the Services shall be at the sole risk of the Vendor and accordingly represents that it has independently investigated the business of listing the venue through the Platform and the profitability, if any, and risks thereof and is not relying on any representation, guarantee, or statement of the Company other than as expressly set forth in these Vendor Terms or any agreement that the Vendor may enter into with the Company.
                        </p>

                        <p className='mb-4 '>
                            In no event and under no circumstance shall the Company be held liable for any liabilities or damages resulting from or arising out of the Vendor’s use of the Platform. The Vendor shall not use anyone else’s account at any time.
                        </p>

                        <p className='mb-4 font-bold '>
                            SERVICES
                        </p>
                        <p className='mb-4 '>
                            The Vendor will open a Vendor Account, have a valid Vendor Subscription and comply with the Vendor Terms (as applicable) in order to be able to use and access the Services provided by the Company on the Platform.
                        </p>
                        <p className='mb-4 '>
                            The Company only acts as an aggregator to enable Users to connect with Vendors and make User Enquiries on the Platform. The Vendor shall communicate with the User and respond to any User Enquiry on the Platform. The Vendor shall acknowledge the User Enquiries and messages received on the Platform and shall endeavour to respond to the same within the earliest acceptable time frame.
                        </p>
                        <p className='mb-4 '>
                            Any bookings and payments shall be made by the User directly with the Vendor and not to Company on the Platform. The Company is not a party to the booking between the Vendor and User, and the Vendor shall be solely responsible for providing the venue, related services and for any acts/omissions to the User.
                        </p>
                        <p className='mb-4 '>
                            The Vendor shall be responsible for updating the status of each User Enquiry under the Account as “Confirmed” or “Rejected” or “Closed” (as the case maybe). The Vendor shall endeavour to ensure that the status of each User Enquiry accurately reflects the current state of affairs.
                        </p>

                        <p className='mb-4 font-bold '>
                            LISTING GUIDELINES
                        </p>

                        <p className='mb-4 '>
                            The Vendor shall be responsible to provide to the Company, for the purpose of the creation/display on the Platform, the venue description, cancellation and rescheduling policy, images, specifications and such other details and or documents as may be required by the Company, for the venue to be displayed and any other information as may be mandated to be provided by the Vendor under applicable laws. The Vendor acknowledges and understands that the Company will list the venues on the Platform based on such information provided by the Vendor.
                        </p>

                        <p className='mb-4 '>
                            The Vendor shall list the average starting prices of its venue, which may differ from the real-time prices offered by the Vendor at the venue, on their website or other third party websites.
                        </p>

                        <p className='mb-4 '>
                            The Vendor confirms that the contents of the information, text, or images, being uploaded on the Platform, shall not be obscene, defamatory, or scandalous in any manner, or capable of hurting the religious sentiments of any segment of the population.
                        </p>

                        <p className='mb-4 '>
                            The Vendor shall at all times provide accurate information on the Platform. The description of the venues shall not be misleading and shall describe the actual condition of the venue. If the venue does not match the description displayed on the Platform, the Vendor shall be solely liable to the User for any such discrepancies.
                        </p>

                        <p className='mb-4 '>
                            The Vendor shall not list any venue or link or post any related material that (a) infringes any third-party intellectual property rights such as copyright, patent, trademark, service mark, trade secret and/or other proprietary rights, rights of publicity and/ or privacy; or (b) constitutes libel or slander or is otherwise defamatory; or (c) is counterfeited, illegal, stolen, or fraudulent. The Vendor shall be solely responsible for any legal action brought by a third party in case of any violation of these Vendor Terms.
                        </p>

                        <p className='mb-4 '>
                            By agreeing to have its venues exhibited and listed on the Platform, the Vendor hereby grants to the Company, a limited, irrevocable, royalty free, non-exclusive license to use Vendor’s brand name and trademark/copyrights/designs/logos and other intellectual property owned by the Vendor and/or licensed to the Vendor, for the purpose of these Vendor Terms and reproduction on the Platform or in any of the Company’s advertising materials and such other places as the Company may deem necessary. The Vendor additionally grants to the Company the right to carry out advertising, publicity, or other promotional activities in any mode whether online or offline, relating to the Vendor’s venue as the Company may deem necessary, without the requirement of prior consent from the Vendor. The Vendor accordingly represents and warrants that it is entitled to grant such rights to the Company and agrees that all consequent liability for violation of this representation and warranty shall be borne by Vendor alone. The Vendor acknowledges and agrees that the Company does not provide any guarantee on the number of User Enquiries that may be generated on the Platform from such promotional material and/or advertisements and the Company shall not, under any circumstance, be held liable for any failure in generating such User Enquiries towards the Vendor’s venue on the Platform.
                        </p>

                        <p className='mb-4 '>
                            Any violation of the applicable laws and regulations along with these Vendor Terms may result in (a) cancellation of Vendor Account; (b) limitations on usage of the Vendor Account; (c) suspension of Vendor Account; and (d) removal of Vendor Account. The Company reserves the right to analyse in its sole discretion whether a venue listing is appropriate or not and the Vendor shall have no remedy against such a decision.
                        </p>

                        <p className='mb-4 '>
                            The Company reserves the right to select the way in which the listings are displayed to Users on the Platform including the display of search results, the order and/or prominence of listings, search rankings and results based on search optimization and user experience factors including but not limited to advertised price of listing, a User’s search filters, availability of venue, reviews, and ratings.
                        </p>

                        <p className='mb-4 '>
                            The Company shall have the right to determine the content, appearance, design, functionality and all other aspects of the Platform and the Services (including the right to re-design, modify, remove and alter the content, appearance, design, functionality, and other aspects of the Platform and the Services and any element, aspect, portion or feature thereof, from time to time) and to delay or suspend listing of, or to refuse to list, or to de-list, or to require the Vendor not to list, any or all venues, at its sole discretion.
                        </p>

                        <p className='mb-4 font-bold '>
                            VENDOR SUBSCRIPTION TERMS
                        </p>

                        <p className='mb-4 '>
                            In consideration of the Vendor Subscription and Services provided by the Company, the Vendor shall pay the Company the Vendor Subscription Amount, which shall be notified to the Vendor on the Platform. The Vendor Subscription Amount shall be paid in full and prior to the commencement of the Vendor Subscription.
                        </p>

                        <p className='mb-4 '>
                            All Vendor Subscriptions offered by the Company shall commence on the date of payment of the Vendor Subscription Amount and shall continue for the Vendor Subscription Period. At the end of the Vendor Subscription Period, all Vendor Subscriptions shall automatically renew on a yearly basis, unless cancelled or notified in writing by the Vendor at least 1 (One) month prior to the expiry of the Vendor Subscription Period, in accordance with the Vendor Cancellation and Refund Policy.
                        </p>

                        <p className='mb-4 '>
                            Any and all Vendor Subscription Amounts payable to the Company under this Agreement, shall be subject to tax liability including but not limited to value added tax and other deductions as per applicable law for the time being in force.
                        </p>

                        <p className='mb-4 '>
                            The Platform permits payment via various modes to facilitate payment of the Vendor Subscription Amount, including online payments through debit/credit cards or any other method adopted by the Company from time to time.
                        </p>

                        <p className='mb-4 '>
                            While availing any of the payment method/s available on the Platform, the Company will not be responsible for or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to the Vendor due to:
                        </p>

                        <ul className='list-disc list-inside mb-4 '>
                            <li className='mb-2'>
                                Lack of authorization for any transaction(s).
                            </li>
                            <li className='mb-2'>
                                Exceeding the pre-set limit mutually agreed by the Vendor and between bank/s.
                            </li>
                            <li className='mb-2'>
                                Any payment issues arising out of the transaction.
                            </li>
                            <li className='mb-2'>
                                Rejection of transaction for any other reason(s) whatsoever.
                            </li>
                        </ul>

                        <p className='mb-4 '>
                            The Vendor acknowledges that the Company will not be liable for any damages, interests or claims, losses resulting from or suffered on account of not processing a transaction/transaction amount or any delay in processing a transaction/transaction amount which is beyond the control of Company.
                        </p>


                        <p className='mb-4 font-bold '>
                            PROCESSING OF PAYMENTS
                        </p>

                        <p className='mb-4 '>
                            The Vendor hereby agrees and confirms that any and all online payments made for Vendor Subscriptions or other services on the Platform shall be subject to (a) the terms, conditions and other policies of the Payment Processor; and/or (b) the agreements with such Payment Processors, both of which may be amended by the Payment Processor from time to time. The Vendor’s use of Payment Processors facility shall be subject to the Vendor’s compliance with all the applicable terms, conditions, policies and/or agreements of the Payment Processor.
                        </p>

                        <p className='mb-4 '>
                            Use of Payment Processor’s facility and online payment methods requires the Vendor to provide current, accurate and complete information about its business including without limitation, financial information. The Vendor authorises the Company to share this data with the applicable Payment Processor. It is expressly clarified that the Company does not collect, store or retain any bank account details, card-related information or other such payment details of the Vendor. All information provided to Payment Processor will be treated in accordance with the Payment Processor’s privacy policy, which the Vendor acknowledges to read carefully. The Company shall in no way be responsible or liable for any acts or omissions of the Payment Processor.
                        </p>

                        <p className='mb-4 font-bold '>
                            VENDOR’S OBLIGATIONS
                        </p>
                        <p className='mb-4 '>
                            The Vendor hereby declares that the Vendor is authorised to operate venues and provide related offerings to the User. The copy of such ownership/licences/authorization shall be provided to the Company on demand without failure and/or protest.
                        </p>
                        <p className='mb-4 '>
                            The Vendor shall be solely responsible for (a) handling all User Enquiries; (b) facilitating bookings made by Users; (c) collecting any payments due by the User for such booking; and (d) any User complaint for any act or omission of the Vendor and/or its representatives.
                        </p>
                        <p className='mb-4 '>
                            The Vendor shall be solely responsible for the quality, guarantee, and warranties in respect of the venues. The warranty in relation to the venues listed on the Platform shall be solely provided by the Vendor in accordance with these Vendor Terms.
                        </p>
                        <p className='mb-4 '>
                            The Vendor accepts and understands that if the Vendor is found indulging in providing false information, causing third-party infringement and/or any illegal activities, the Company may initiate civil and/or criminal proceedings against the Vendor. The Company further reserves the right to immediately and without prior notice, remove any and all of Vendor’s venue listings from the Platform and suspend/terminate the Vendor Account.
                        </p>
                        <p className='mb-4 '>
                            The Vendor shall conduct its business in highest ethical and professional standards and manner that reflects favourable, at all times, on the Company and the name, goodwill and reputation of the Company.
                        </p>
                        <p className='mb-4 '>
                            The Vendor shall avoid deceptive, misleading, or unethical practices that are or might be detrimental to the Company or the public, including, but not limited to, (a) disparagement of the Platform or any other contract; or (b) any other violation of applicable laws.
                        </p>
                        <p className='mb-4 '>
                            The Vendor shall at all times comply with these Vendor Terms along with any other agreement entered into with the Company.
                        </p>

                        <p className='mb-4 font-bold '>
                            REPORTING, AUDIT, AND INFORMATION RIGHTS
                        </p>
                        <p className='mb-4 '>
                            The Vendor shall from time to time, furnish such information as may be sought by the Company in relation to the venues or other subject matter of these Vendor Terms. The Vendor shall also provide to the Company activity reports and such other particulars at regular intervals and in such formats as may be prescribed by the Company from time to time.
                        </p>
                        <p className='mb-4 '>
                            The Company shall with a prior intimation to the Vendor, either on its own or through authorised representatives, have the right to audit and inspect the premises of the Vendor’s venue. The Vendor agrees to co-operate with the Company and give access of documents, authorisations, and premises as necessary to the Company and /or its authorized representatives, at the time of such audit or inspection.
                        </p>

                        <p className='mb-4 font-bold '>
                            REPRESENTATIONS
                        </p>
                        <p className='mb-4 '>
                            The Vendor hereby represents and warrants to the Company that:
                        </p>
                        <ul className='list-disc list-inside '>
                            <li className='mb-2'>
                                The Vendor’s performance of all its obligations hereunder and any other agreement entered into with the Company will be conducted in compliance with all applicable laws.
                            </li>
                            <li className='mb-2'>
                                There is no private and governmental actions, suit, proceedings, claim, arbitration, or investigation pending before any governmental authority, which is ongoing, or in relation to which a notice has been received by it, that may restrain, prevent or make illegal the performance of all its obligations hereunder and any other agreement entered into with the Company by the Vendor.
                            </li>
                            <li className='mb-2'>
                                It shall not enter into any arrangement which would impose any obligation inconsistent with these Vendor Terms or which would restrict or conflict with the performance of its obligations under these Vendor Terms or any other agreement entered into with the Company.
                            </li>
                        </ul>

                        <p className='mb-4 font-bold '>
                            CANCELLATION AND REFUND POLICY
                        </p>
                        <p className='mb-4 '>
                            The process to be followed in the event of cancellation of a Vendor Subscription by a Vendor prior to expiry of the Vendor Subscription Period shall be as per the cancellation and refund policy <span className='font-bold '> (“Vendor Cancellation and Refund Policy”) </span>which can be accessed <a href="URL_TO_POLICY" className='text-blue-500 underline font-bold '>here</a>.
                        </p>

                        <p className='mb-4 font-bold '>
                            LINKS TO THIRD PARTY WEBSITE
                        </p>
                        <p className='mb-4 '>
                            The Platform may contain links and interactive functionality interacting with the websites of third parties. The Company is not responsible for and has no liability for the functionality, actions, inactions, privacy settings, privacy policies, Vendor Terms, or content of any such website. Before enabling any sharing functions to communicate with any such website or otherwise visiting any such website, the Company strongly recommends that the Vendor reviews and understands the terms and conditions, privacy policies, settings, and information-sharing functions of each such third-party website.
                        </p>

                        <p className='mb-4 font-bold '>
                            LIMITATION OF LIABILITY
                        </p>
                        <p className='mb-4 '>
                            The Vendor hereby acknowledges that the Company shall not be held liable to the Vendor for any special, consequential, incidental, and exemplary or punitive damages, or loss of profit or revenues. The Company shall also not be liable under any circumstances for damages arising out or related in any way to the Vendor’s inability or difficulty in accessing the Platform, any bugs, viruses, trojan horses, or the like, which may be transmitted to or through the Platform by any third party, any loss of the Vendor’s data or content from the Services or failure to keep the Vendor Login Credentials secure and confidential.
                        </p>
                        <p className='mb-4 '>
                            The Company shall not be liable under any circumstances for damages arising out of or in any way related to any Services and/or information offered or provided by third-party vendors accessed through the Platform.
                        </p>

                        <p className='mb-4 font-bold '>
                            DISCLAIMER OF WARRANTIES
                        </p>
                        <p className='mb-4 '>
                            THE PLATFORM AND ALL INFORMATION, CONTENT, MATERIALS AND SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO THE VENDOR THROUGH THE PLATFORM (COLLECTIVELY, THE "CONTENTS") ARE PROVIDED BY THE COMPANY ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND INCLUDING WITHOUT LIMITATION THE WARRANTIES OF QUANTUM OF USER ENQUIRIES, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING IN THE COURSE OF DEALING OR USAGE OF TRADE. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE PLATFORM, THE ACCURACY OR COMPLETENESS OF THE CONTENTS AND THE ACCURACY OF THE INFORMATION. THE COMPANY DOES NOT PROVIDE ANY GUARANTEE ON THE NUMBER OF USER ENQUIRIES THAT MAY BE GENERATED ON THE PLATFORM FOR ANY VENUE AND THE COMPANY SHALL NOT, UNDER ANY CIRCUMSTANCE, BE HELD LIABLE FOR ANY FAILURE IN GENERATING SUCH USER ENQUIRIES ON THE PLATFORM.
                        </p>
                        <p className='mb-4 '>
                            THE COMPANY SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO THE VENDOR’S COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS, DOCUMENT, OR INFORMATION. THE VENDOR EXPRESSLY AGREES THAT THE USE OF THE PLATFORM IS AT THE VENDOR’S SOLE RISK AND THAT THE COMPANY IS NOT RESPONSIBLE FOR THE VOLUME OF USER ENQUIRIES THE VENDOR MAY MAKE ON THE PLATFORM OR LACK THEREOF. THE COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE PLATFORM OR THE CONTENTS INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. TO THE FULL EXTENT PERMITTED BY LAW, THE COMPANY DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE PLATFORM AND ITS CONTENTS, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE OR USE.
                        </p>

                        <p className='mb-4 font-bold '>
                            INDEMNIFICATION
                        </p>
                        <p className='mb-4 '>
                            The Vendor agrees to indemnify and hold harmless the Company, its directors, employees, officers, representatives and agents from and against all losses, costs, damages, claims, suits, actions, proceedings, expenses, liabilities, and demands (“Claims”) howsoever arising from or in connection with or on account of any breach or non-fulfilment of any of Vendor’s obligations, warranties, representations or undertakings or any terms and conditions of these Vendor Terms or any agreement that the Vendor may enter into with the Company or non-performance of any of its obligations under these Vendor Terms or any agreement that the Vendor may enter into with the Company to the satisfaction of the Company, including but not limited to (a) Claim(s) for any infringement of any intellectual property rights or any other rights of any third party; (b) Claim(s) arising out of any breach of Vendor’s obligations contained in these Vendor Terms or any agreement that the Vendor may enter into with the Company; (c) Claim(s) by Users concerning quality or suitability for use of the venue; or (d) Claim(s) arising out of Vendor’s non-compliance or contravention of any applicable laws or regulations.
                        </p>
                        <p className='mb-4 '>
                            Further the Company shall not be liable for any claims, damages, arising out of any negligence, misconduct, or misrepresentation by the Vendor and/or any of Vendor’s representatives.
                        </p>


                        <p className='mb-4 font-bold '>
                            VENDOR’S POSTS AND REVIEWS
                        </p>
                        <p className='mb-4 '>
                            The Platform may allow the Vendor, at the Company’s discretion, to post certain content, data or information belonging to the Vendor, such as reviews and allowing the Vendor to share its/his/her experience and views about the Platform on specific pages of the Platform, as well as submit/post any photos, videos, suggestions, comments, questions, or other information to the Company on the Platform (collectively referred to as <span className='font-bold '>“Vendor Content”</span>).
                        </p>
                        <p className='mb-4 '>
                            The Vendor, being the originator of the Vendor Content, is responsible for the Vendor Content that the Vendor uploads, posts, publishes, transmits, or otherwise makes available on the Platform. The Vendor represents that the Vendor exclusively owns the copyright in the Vendor Content and has obtained all relevant consents and approvals in order to post any Vendor Content. The Vendor further represents that all such Vendor Content will be in accordance with applicable law. The Vendor acknowledges that the Company does not endorse any Vendor Content on the Platform and is not responsible or liable for any Vendor Content. The Company reserves the right to disable access to the Vendor Content on the Platform.
                        </p>
                        <p className='mb-4 '>
                            In case a third party appears in a Vendor Content, the Vendor confirms that the Vendor has obtained prior consent of such third party before uploading or posting such Vendor Content. The Vendor further agrees that the Vendor shall indemnify the Company against any and all claims raised by such third party with respect to the Vendor Content.
                        </p>
                        <p className='mb-4 '>
                            The Vendor hereby grants the Company a perpetual, non-revocable, worldwide, royalty-free and sub-licensable right and license to use, copy, distribute, display, publish, transmit, make available, reproduce, modify, adapt the Vendor Content and create derivative works of the Vendor Content in any manner as deemed appropriate by the Company in any form including but not limited to print, broadcast, online and across any and all websites owned by the Company, any and all platforms owned by the Company and on any social media channels including but not limited to Instagram, Facebook, TikTok and Twitter.
                        </p>
                        <p className='mb-4 '>
                            The Vendor represents and warrants that the Vendor owns or otherwise controls all of the rights to the Vendor Content that the Vendor posts or that the Vendor otherwise provides on or through the Platform; and that, as at the date that the Vendor Content is posted or submitted on the Platform: (a) the Vendor Content is accurate; (b) use of the Vendor Content that the Vendor supplies does not breach these Vendor Terms; and (c) such Vendor Content is lawful.
                        </p>
                        <p className='mb-4 '>
                            The Vendor further represents and warrants that while posting any Vendor Content on the Platform the Vendor shall not use any offensive, libellous, derogatory, hateful, or racially or ethnically objectionable language.
                        </p>
                        <p className='mb-4 '>
                            It is expressly clarified that any reviews, comments, venue description and any other information or content posted by Users through the Platform are not intended as advice or deemed binding. Vendors have the discretion to consider such content at their own discretion, and such content should not be relied upon by the Vendor, as being fully accurate or authoritative.
                        </p>

                        <p className='mb-4 font-bold '>
                            CONTENT AND INTELLECTUAL PROPERTY RIGHTS
                        </p>
                        <p className='mb-4 '>
                            Other than (a) Vendor Content, and (b) the trademarks, logos and service marks displayed on the Platform that are the property of the Vendor or other third parties, the Company solely and exclusively owns copyrights, trademarks, service marks, logos, trade names, and other intellectual and proprietary rights associated with the Company’s services provided on the Platform and Contents <span className='font-bold '> (“Platform Content”) </span> and is protected under applicable law.
                        </p>
                        <p className='mb-4 '>
                            Through the Vendor’s use of the Platform, by no means are any rights impliedly or expressly granted to the Vendor in respect of such Platform Content. The Company reserves the right to change or modify the Services and/or the Platform Content (as the case may be) from time to time at its sole discretion.
                        </p>
                        <p className='mb-4 '>
                            The Vendor hereby acknowledges that the Platform Content and the Services constitute original works and have been developed, compiled, prepared, revised, selected, and arranged by the Company and others through the application of methods and standards of judgment developed and applied through the expenditure of substantial time, effort, and money and constitutes valuable intellectual property of the Company and such others. The Vendor thereby agrees to protect the proprietary rights of the Company and such others during and after the term of these Vendor Terms. The Vendor may not selectively download portions of the Platform without retaining the copyright notices. The Vendor may download material from the Platform only for the Vendor’s own use in compliance with these Vendor Terms and as agreed by the Company in writing.
                        </p>
                        <p className='mb-4 '>
                            The Vendor acknowledges that unless it owns or controls the relevant rights in any content or information on the Platform, it shall not:
                        </p>
                        <ul className='list-disc pl-6 mb-4 '>
                            <li className='mb-2'>
                                Republish, reproduce, duplicate or copy any Platform Content (including republication of such Platform Content on any other website); or
                            </li>
                            <li className='mb-2'>
                                Sell, rent or sub-license any Platform Content.
                            </li>
                        </ul>
                        <p className='mb-4 '>
                            The Vendor shall use the Platform strictly in accordance with these Vendor Terms, and shall not, directly or indirectly, (a) decompile, disassemble, reverse engineer, or attempt to derive the source code of, or in any manner decrypt, the Platform; (b) make any modification, adaptation or improvement, enhancement, translation or derivative work from the Platform; (c) violate any applicable laws, rules or regulations in connection with his/her access or use of the Platform, including his/her use of any Platform Content; (d) remove or obscure any proprietary notice (including any notices of copyright or trademark) forming a part of the Platform; (e) use the Platform for any commercial or revenue generation endeavours, or other purposes for which it is not designed or intended; (f) distribute or transmit the Platform or other services; (g) use the Platform for data mining, scraping, crawling, redirecting, or for any purpose not in accordance with these Vendor Terms; (h) use the Platform for creating a service or software that is directly or indirectly, competitive with the Platform or any Services; or (i) derive any confidential information, processes, data or algorithms from the Platform.
                        </p>
                        <p className='mb-4 '>
                            Any infringement shall lead to appropriate legal proceedings against the Vendor at appropriate forum for seeking all available remedies under applicable laws of the country.
                        </p>

                        <p className='mb-4 font-bold '>
                            TERMINATION OF VENDOR SUBSCRIPTION
                        </p>
                        <p className='mb-4 '>
                            The Vendor Subscription can be cancelled by the Vendor at any time in accordance with the Vendor Cancellation and Refund Policy.
                        </p>
                        <p className='mb-4 '>
                            The Vendor Subscription or any agreement entered into with the Company, can be terminated at any time by the Company forthwith, for any reason including, without limitation,
                        </p>
                        <ul className='list-disc pl-6 mb-4 '>
                            <li className='mb-2'>
                                If the Vendor ceases to carry on its business and/or stops dealing with any or all of the venues;
                            </li>
                            <li className='mb-2'>
                                If interest in the Vendor is sold, transferred, pledged or encumbered to any party which may be a competitor to the Company;
                            </li>
                            <li className='mb-2'>
                                Material breach of any of the provisions of these Vendor Terms or any agreement entered into with the Company; and/or
                            </li>
                            <li className='mb-2'>
                                Any material violation by the Vendor of applicable laws.
                            </li>
                        </ul>
                        <p className='mb-4 '>
                            The Vendor acknowledges that upon the termination of the Vendor Subscription by the Company, the Company shall be entitled to immediately deactivate or delete the Vendor Account and all related information and bar any further access to the Services or the Platform, subject to applicable law. Further, the Vendor agrees that the Company shall not be liable for any discontinuation or termination of services by any third party.
                        </p>
                        <p className='mb-4 '>
                            The disclaimer of warranties, the limitation of liability, and the governing law provisions shall survive any termination of these Vendor Terms.
                        </p>

                        <p className='mb-4 font-bold '>
                            SEVERABILITY AND WAIVER
                        </p>
                        <p className='mb-4 '>
                            These Vendor Terms, any other agreement entered into with the Company (where applicable) and other referenced material herein or on the Platform, are the entire agreement between the Vendor and the Company with respect to the Services offered on the Platform and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between the Vendor and the Company with respect thereto and govern the future relationship.
                        </p>
                        <p className='mb-4 '>
                            If any provision of these Vendor Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Vendor Terms will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.
                        </p>

                        <p className='mb-4 font-bold '>
                            ASSIGNMENT
                        </p>
                        <p className='mb-4 '>
                            It is expressly agreed by the parties that the Company may assign all or part of its rights or duties under these Vendor Terms without such assignment being considered a change to these Vendor Terms and without notice to the Vendor, except to the extent provided by law.
                        </p>

                        <p className='mb-4 font-bold '>
                            GOVERNING LAW & DISPUTE RESOLUTION
                        </p>
                        <p className='mb-4 '>
                            These Vendor Terms shall be governed by and interpreted and construed in accordance with the laws of the United Arab Emirates. The place of jurisdiction shall exclusively be in Dubai, United Arab Emirates. In the event of any dispute arising out of these Vendor Terms the same shall be settled by a binding arbitration under the Arbitration Rules of the Dubai International Arbitration Centre, which Rules are deemed to be incorporated by reference into this clause. The arbitration proceedings shall be conducted by a sole arbitrator, appointed jointly by both parties. The venue and seat of arbitration shall be Dubai, United Arab Emirates. The language to be used in the arbitration shall be English.
                        </p>

                        <p className='mb-4 font-bold '>
                            HOW TO CONTACT US
                        </p>
                        <p className='mb-4 '>
                            If the Vendor has questions or concerns about these Vendor Terms, the Vendor may contact the Company at <a href='mailto:support@findmyvenue.com' className='text-blue-500'>support@findmyvenue.com</a>.
                        </p>

                    </div>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;