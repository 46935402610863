import React from 'react'
import ReviewCarousel from './ReviewCarousel';


const Feedback = () => {


    return (
        <div className='flex justify-center pt-20'>
            <div className='w-full flex flex-col items-center' >

                <div className='w-[100%] bg-[#F7F7F7] p-8'>
                    <h1 className='sm:text-4xl text-2xl text-darkGray font-semibold text-center my-4'>What our guests are saying</h1>
                    <ReviewCarousel />
                    <div className='flex justify-center w-full sm:my-10 my-6'>
                        <button className='bg-primary text-white rounded-lg w-[180px] py-2'>Share Your Experience</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Feedback

