'use client'
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../../assets/pics/space12.png'
import img2 from '../../assets/pics/space2.png'
import img3 from '../../assets/pics/space3.png'
import img4 from '../../assets/pics/space4.png'
import img5 from '../../assets/pics/space5.png'
import img6 from '../../assets/pics/space6.png'
import img7 from '../../assets/pics/space7.png'




const ImageCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    const slidesData = [
        { img: img1, name: 'Dubai', venus: '2829 Venues' },
        { img: img2, name: 'Ajman', venus: '2829 Venues' },
        { img: img3, name: 'Umm Al Quwain', venus: '2829 Venues' },
        { img: img4, name: 'Sharjah', venus: '2829 Venues' },
        { img: img5, name: 'Abu Dhabi', venus: '2829 Venues' },
        { img: img6, name: 'Fujaira', venus: '2829 Venues' },
        { img: img7, name: 'Al Ain', venus: '2829 Venues' },
    ];

    return (
        <div className=''>
            <Slider {...settings}>
                {slidesData.map((e, i) => (
                    <div key={i} className='px-2 w-full'>
                        <div className='bg-white border rounded-lg flex-col w-full flex items-center '>
                            <img src={e.img} alt="" className='rounded-lg h-40 w-full' />
                            <div className=' flex flex-col items-center'>
                                <h1 className=' text-lg font-medium'>{e.name}</h1>
                                <p className='text-[#5E5E5E]'>{e.venus}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageCarousel;
