import React, { createContext, useState } from 'react';

export const VenueContext = createContext();

export const VenueProvider = ({ children }) => {
	const defaultFormData = {
		vendorId: '',
		status: 'pending',
		itemStatus: 'pending',
		equipment: {
			equipment: [],
			equipmentIcons: {},
		},
		location: {
			addressLine1: '',
			addressLine2: '',
			city: '',
			area: '',
			poBox: '',
			country: 'United Arab Emirates',
			mapLink: '',
			lat: null,
			lng: null,
			accessibilityFeatures: {
				wheelchairAccessible: false,
				elevator: false,
				brailleSigns: false,
				hearingLoop: false,
			},
			parkingAvailable: {
				parkingAvailable: false,
				valetParking: false,
			},
		},
		cateringAndDrinks: {
			venueProvideInHouseCatering: false,
			availableMenu: [],
			cuisine: [],
			externalCatering: false,
			alcoholLiquorLicense: false,
			alcoholLiquorLicenseTime: '',
			corkageFeeForBringYourOwnAlcohol: false,
			bringYourOwnAlcoholAllowed: false,
			refreshments: false,
			refreshmentsForGuests: '',
			shisha: false,
			servingStyle: [],
			isVenueAlcohlic: false,
		},
		capacity: {
			venueSize: '',
			unit: 'Sq. feet',
			standing: '',
			sitting: '',
			additionalLayout: {
				dining: { enabled: false, value: '' },
				theatre: { enabled: false, value: '' },
				boardroom: { enabled: false, value: '' },
				ushaped: { enabled: false, value: '' },
				cabaret: { enabled: false, value: '' },
			},
		},
		venueType: {
			venueType: [],
			venueRepresent: '',
		},
		facilities: {
			facilities: [],
			facilitiesFeatureIcons: {},
			activitiesForKids: false,
			activitiesForKidsIncludes: [],
		},
		basicDetails: {
			venueName: '',
			hotelName: '',
			groupName: '',
			hasMultipleEventSpaces: false,
			isPrimaryVenue: false,
			venueDescription: '',
		},
		additionalDetails: {
			venueView: [],
			venueSetting: [],
			venueStyle: {
				traditionalStyle: [],
				modernContemporaryStyle: [],
				eclecticStyle: [],
				culturalRegionalStyle: [],
			},
			venueHire: '',
		},
		eventType: {
			business: {
				conferenceSeminar: [],
				corporateEvents: [],
			},
			weddingAndDining: {
				weddings: [],
				dining: [],
				banquet: [],
			},
			parties: {
				socialParties: [],
				clubbingNightOut: [],
				drinksReceptions: [],
				christmasParties: [],
				babyShowerTeaParty: [],
				childrenTeen: [],
				genericTags: [],
			},
			artAndCultural: {
				filmingAndPhotography: [],
				cultural: [],
				artSpaces: [],
				performancesExhibitions: [],
			},
			eventVenuesForKids: false,
			eventVenuesForKidsIncludes: [],
		},
		pricing: {},
		offersAndPackages: [],
		offerPackage: [],
		spaceRules: {
			agePolicy: [0, 30],
			moreSpaceRules: '',
			ticketedEventsAllowed: false,
		},
		audio: {
			indoorMusicAllowed: false,
			indoorMusicAllowedTime: '',
			outdoorMusicAllowed: false,
			outdoorMusicAllowedTime: '',
			paSystemSpeakers: false,
			clientsCanPlayOwnMusic: false,
			clientsCanBringOwnDJ: false,
			spaceHasNoiseRestrictions: false,
		},
		UploadDocuments: {
			floorPlans: [],
			tradeLicense: [],
			sampleMenus: [],
			brochure: [],
			selectedDocuments: [],
		},
		imagesAndVideos: {
			images: [],
			videos: [],
			videoLinks: [],
		},
		overviewbasicdetailsmissing: {
			isError: false,
		},
		cateringmissing: {
			isError: false,
		},
		spacerulesmissing: {
			isError: false,
		},
		pricingmissing: {
			isError: false,
		},
	};

	// Create state with initial data
	const [formData, setFormData] = useState(defaultFormData);

	const resetForm = () => {
    setFormData(defaultFormData);
    return defaultFormData; // Return the default data immediately
};

	const addPricing = (type, newForm) => {
		setFormData((prev) => ({
			...prev,
			pricing: {
				...prev.pricing,
				[type]: [...(prev?.pricing[type] || []), newForm],
			},
		}));
	};

	const removePricing = (id, type) => {
		setFormData((prev) => ({
			...prev,
			pricing: {
				...prev.pricing,
				[type]: (prev.pricing[type] || []).filter(
					(item) => item.id !== id
				),
			},
		}));
	};

	const updatePricing = (id, type, updatedForm) => {
		setFormData((prev) => ({
			...prev,
			pricing: {
				...prev.pricing,
				[type]: prev.pricing[type].map((item) =>
					item.id === id ? { ...item, ...updatedForm } : item
				),
			},
		}));
	};
	console.log('venu', formData);
	return (
		<VenueContext.Provider
			value={{
				formData,
				setFormData,
				updatePricing,
				addPricing,
				removePricing,
				resetForm,
			}}
		>
			{children}
		</VenueContext.Provider>
	);
};
