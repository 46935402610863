import React, { useContext, useState } from "react";
import Input from "../../shared/common/customInput";
import Dropdown from "../../shared/common/customDropdown";
import SelectableTags from "../../shared/common/selectableTags";
import RatingDropdown from "./rating-dropdown";
import {
  hotelBrands,
  hotelChains,
  hotelStyles,
  hotelTypes,
  hotelViews,
} from "../data";
import { HotelContext } from "../../../context/HotelContext";

const BasicForm = () => {
  const { formData, setFormData } = useContext(HotelContext);

  const [descriptionCount, setDescriptionCount] = useState(calculateWordCount(formData?.overview?.basicDetails?.hotelDescription));

  // Function to calculate word count
  function calculateWordCount(text) {
    return text?.trim().split(/\s+/).filter(word => word.length > 0).length;
  }

  const handleChange = (e) => {
    const { value } = e.target;
    const words = value?.trim().split(/\s+/); // Split and filter words
    const wordCount = words.length; // Count words
    setDescriptionCount(wordCount); // Update word count
    handleInputChange("hotelDescription", value);

  };




  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      overview: {
        ...prevData.overview,
        basicDetails: {
          ...prevData?.overview?.basicDetails,
          [field]: value,
        },
      },
    }));
  };

  const handleTagClick = (category, tag) => {
    setFormData((prevData) => ({
      ...prevData,
      overview: {
        ...prevData.overview,
        basicDetails: {
          ...prevData?.overview?.basicDetails,
          selectedTags: {
            ...prevData?.overview?.basicDetails?.selectedTags,
            [category]: prevData?.overview?.basicDetails?.selectedTags[
              category
            ]?.includes(tag)
              ? prevData.overview.basicDetails.selectedTags[category].filter(
                (t) => t !== tag
              )
              : [
                ...(prevData.overview.basicDetails.selectedTags[category] ||
                  []),
                tag,
              ],
          },
        },
      },
    }));
  };

  const handleStyleTagClick = (styleCategory, tag) => {
    setFormData((prevData) => ({
      ...prevData,
      overview: {
        ...prevData.overview,
        basicDetails: {
          ...prevData?.overview?.basicDetails,
          selectedTags: {
            ...prevData?.overview?.basicDetails?.selectedTags,
            styles: {
              ...prevData?.overview?.basicDetails?.selectedTags.styles,
              [styleCategory]:
                prevData?.overview?.basicDetails?.selectedTags.styles[
                  styleCategory
                ]?.includes(tag)
                  ? prevData.overview.basicDetails.selectedTags.styles[
                    styleCategory
                  ].filter((t) => t !== tag)
                  : [
                    ...(prevData.overview.basicDetails.selectedTags.styles[
                      styleCategory
                    ] || []),
                    tag,
                  ],
            },
          },
        },
      },
    }));
  };

  const basicDetails = formData.overview?.basicDetails || {};
  const selectedTags = basicDetails?.selectedTags || {};
  const styles = selectedTags.styles || {
    traditional: [],
    modern: [],
    eclectic: [],
    cultural: [],
  };
  const errors = basicDetails?.errors || {};


  return (
    <div>
      <h2 className="text-2xl lg:text-3xl font-semibold pb-2 text-neutralBlack font-outfitt">
        Basic Details
      </h2>
      <div className="w-full flex flex-wrap gap-4 justify-between mx-auto p-4 bg-white rounded-lg">
        <div className="lg:w-[47%] w-full">
          <Input
            label="Hotel Name"
            type="text"
            placeholder="Enter hotel name"
            value={basicDetails?.hotelName || ""}
            onChange={(e) => handleInputChange("hotelName", e.target.value)}
            error={errors?.hotelName}
          />
        </div>
        <div className="mb-3 w-full">
          <label className=" text-secondary flex justify-between items-center text-sm font-outfitt mb-2">
            Hotel Description
            <p
              className={`text-offGray text-sm font-outfitt ${descriptionCount > 299 ? "text-red-500" : "text-offGray"
                }`}
            >
              ({descriptionCount}/300 words)
            </p>
          </label>
          <textarea
            minLength={100}
            rows={8}
            placeholder="Write description of your hotel"
            value={basicDetails?.hotelDescription || ""}
            onChange={handleChange}
            // maxLength={300}
            className={`appearance-none border resize-none ${errors.hotelDescription ? "border-red-500" : "border-lightGray"
              } rounded-lg w-full py-3 px-3 text-secondary leading-tight focus:outline-none focus:shadow-outline`}
          />
          {errors.hotelDescription && (
            <p className="text-red-500 text-xs mt-1">
              {errors.hotelDescription}
            </p>
          )}
        </div>
        <div className="lg:w-[47%] w-full">
          <Input
            label="Hotel Website"
            type="text"
            placeholder="Link"
            value={basicDetails?.hotelWebsite || ""}
            onChange={(e) => handleInputChange("hotelWebsite", e.target.value)}
            error={errors.hotelWebsite}
          />
        </div>
        <div className="lg:w-[47%] w-full">
          <RatingDropdown
            label="Hotel Rating"
            value={basicDetails?.hotelRating || ""}
            onChange={(value) => handleInputChange("hotelRating", value)}
          />
          {errors.hotelRating && (
            <p className="text-red-500 text-sm">{errors.hotelRating}</p>
          )}
        </div>
        <div className="lg:w-[47%] w-full">
          <Dropdown
            label="Hotel Chain  (Optional)"
            options={hotelChains} // Add options for hotel chains
            value={basicDetails?.hotelChain || ""}
            onChange={(e) => handleInputChange("hotelChain", e.target.value)}
            error={errors.hotelChain}
          />
        </div>
        {/* <div className="lg:w-[47%] w-full">
          <Dropdown
            label="Brand (Optional)"
            options={hotelBrands} // Add options for brands
            value={basicDetails?.hotelBrand || ""}
            onChange={(e) => handleInputChange("hotelBrand", e.target.value)}
            error={errors.hotelBrand}
          />
        </div> */}
        <div className="w-full">
          <label className="flex items-center space-x-2 text-secondary font-outfitt">
            <p>Hotel type</p>
            <p className="text-[#C2C2C2] text-sm leading-5">
              (select up to top 5){" "}
            </p>{" "}
          </label>
          <p className="text-[#B0B0B0] mb-2 text-sm font-normal font-outfit tracking-[1%]">
            A sleeping space and common areas that may be shared with others
          </p>

          <SelectableTags
            label=""
            tags={hotelTypes}
            selectedTags={selectedTags?.types || []}
            onTagClick={(tag) => handleTagClick("types", tag)}
            max={5}
          />
          {errors?.hotelTypes && (
            <p className="text-red-500  mt-1">{errors.hotelTypes}</p>
          )}

        </div>
        <div className="w-full">
          <label className="block text-secondary py-2 text-sm">
            Hotel View{" "}
            <span className="text-[#C2C2C2] text-sm">
              (select up to top 5){" "}
            </span>{" "}
          </label>

          <SelectableTags
            label=""
            tags={hotelViews}
            max={5}
            selectedTags={selectedTags.views || []}
            onTagClick={(tag) => handleTagClick("views", tag)}
          />
          {errors.hotelViews && (
            <p className="text-red-500  mt-1">{errors.hotelViews}</p>
          )}
        </div>
        <div className="mb-4 w-full">
          <label className="block text-darkGray  font-medium text-base mb-2">
            Hotel Style{" "}
            <span className="text-[#C2C2C2] font-normal text-base">
              (select top 5)
            </span>
          </label>
          <p className="text-offGray font-normal text-sm leading-[21.84px]">
            Mentioning the hotel’s style is important to give potential guests a
            clear idea of what to expect and to effectively target the right
            clientele
          </p>
          {Object.keys(hotelStyles).map((styleCategory) => (
            <div key={styleCategory}>
              <h3 className="mt-4 mb-2 text-secondary text-sm capitalize">
                {styleCategory} style
              </h3>
              <SelectableTags
                max={5}
                tags={hotelStyles[styleCategory]}
                selectedTags={
                  styles
                    ? [
                      ...styles?.traditional,
                      ...styles?.modern,
                      ...styles?.eclectic,
                      ...styles?.cultural,
                    ]
                    : []
                }
                onTagClick={(tag) => handleStyleTagClick(styleCategory, tag)}
              />
            </div>
          ))}
        </div>
        {errors.hotelStyles && (
          <p className="text-red-500  mt-1">{errors.hotelStyles}</p>
        )}
      </div>
    </div>
  );
};

export default BasicForm;
