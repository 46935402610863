// UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchLoggedUser } from '../api/loggedUser';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                console.warn('Access token not found in localStorage');
                setLoading(false);
                return;
            }

            try {
                const response = await fetchLoggedUser();
                setUser(response.vendor);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const value = {
        user,
        loading,
        error,
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
