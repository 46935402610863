import React from "react";

const HotelIcon = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.95604 19.9586H7.16482V18.1878H16.8352V19.9586H18.044V15.2366C18.044 14.5873 17.8072 14.0315 17.3338 13.5692C16.8604 13.1068 16.2912 12.8756 15.6264 12.8756H11.3956V17.0073H7.16482V11.6951H5.95604V19.9586ZM9.28407 16.4171C9.70824 16.4171 10.0655 16.2741 10.3558 15.988C10.6461 15.702 10.7912 15.3519 10.7912 14.9377C10.7912 14.5235 10.6448 14.1746 10.3519 13.8911C10.059 13.6076 9.7005 13.4659 9.27636 13.4659C8.85219 13.4659 8.49496 13.6089 8.20468 13.8949C7.91437 14.1809 7.76921 14.531 7.76921 14.9453C7.76921 15.3595 7.91565 15.7083 8.20854 15.9918C8.50142 16.2753 8.85993 16.4171 9.28407 16.4171ZM2 23.5V8.85124L12 1.5L22 8.85124V23.5H2ZM3.42857 22.1049H20.5714V9.5488L12 3.27076L3.42857 9.5488V22.1049Z"
        fill={props.color}
      />
    </svg>
  );
};

export default HotelIcon;
