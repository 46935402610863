import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Typography,
	Button,
	useTheme,
} from '@mui/material';
import BillingInformationBox from './BillingInformationBox';
import BillingAddressBox from './BillingAddressBox';
import { toast } from 'react-toastify';

const BillingForm = ({ handleContinue, shouldHandlePayment }) => {
	const [trn, setTrn] = useState('');
	const theme = useTheme();
	const [isFormValid, setIsFormValid] = useState({
		billingInfo: false,
		billingAddress: false,
	});

	useEffect(() => {
		window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
	}, []);

	const handleFormValidation = (component, isValid) => {
		setIsFormValid((prev) => ({
			...prev,
			[component]: isValid,
		}));
	};

	function checkTrnAndContinue() {
		console.log(trn);
		if (trn.length === 10) {
			handleContinue(shouldHandlePayment);
			return;
		} else if (trn && trn.length !== 10) {
			toast.error('Trn Length should be 10 and only numbers');
			return;
		}
		handleContinue(shouldHandlePayment);
	}
	const isValidForm =
		isFormValid.billingInfo && isFormValid.billingAddress;

	return (
		<Container
			maxWidth='md'
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Box sx={{ mt: 4, textAlign: 'center', mb: 3 }}>
				<Typography
					variant='h4'
					component='h1'
					gutterBottom
					sx={{ fontWeight: '550' }}
				>
					Billing Information
				</Typography>
			</Box>
			<BillingInformationBox
				handleFormValidation={(isValid) =>
					handleFormValidation('billingInfo', isValid)
				}
				trn={trn}
				setTrn={setTrn}
			/>
			<BillingAddressBox
				handleFormValidation={(isValid) =>
					handleFormValidation('billingAddress', isValid)
				}
			/>
			<Box
				sx={{
					mt: 6,
					mb: 8,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Button
					variant='contained'
					color='primary'
					sx={{
						backgroundColor: isValidForm
							? '#fe4847'
							: theme.palette.grey.border,
						borderRadius: '10px',
						textTransform: 'none',
						width: { xs: '100%', md: '60%' },
						'&:hover': {
							backgroundColor: isValidForm
								? 'darkred'
								: theme.palette.grey.border,
						},
					}}
					onClick={checkTrnAndContinue}
					disabled={!isValidForm}
				>
					Continue
				</Button>
			</Box>
		</Container>
	);
};

export default BillingForm;
