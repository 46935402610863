'use client'
import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";

const ReviewCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    const data = [
        { review: '“Exploring this platform was a seamless experience. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs.”', name: 'Stillen Jenasen', type: 'Event Planner' },
        { review: '“Exploring this platform was a seamless experience. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs.”', name: 'Stillen Jenasen', type: 'Event Planner' },
        { review: '“Exploring this platform was a seamless experience. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs. The trustworthiness exhibited by the platform reassured me throughout the process, making it a reliable choice for my needs.”', name: 'Stillen Jenasen', type: 'Event Planner' },
    ];

   

    return (
        <div className='w-full py-8 '>
            <Slider {...settings}>
                {data.map((item, index) => (
                    <div key={index} className='px-4'>
                        <div className=" p-6 ">
                            <p className='text-[#7C7C7C]'>{item.review}</p>
                            <div className='flex items-center gap-2 mt-2'>
                                <IoIosStar className='text-primary' />
                                <IoIosStar className='text-primary' />
                                <IoIosStar className='text-primary' />
                                <IoIosStar className='text-primary' />
                                <IoIosStarOutline />
                            </div>
                            <h3 className='text-secondary font-medium mt-2'>{item.name}</h3>
                            <p className='text-[#7C7C7C] text-sm'>{item.type}</p>
                        </div>
                    </div>
                ))}
            </Slider>
           
        </div>
    )
};

export default ReviewCarousel;
