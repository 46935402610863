import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Input from "../../shared/common/customInput";
import IconGrid from "./icon-picker";

const AddFeatureModal = ({ isOpen, onRequestClose, onSave, featureToEdit, onUpdate }) => {
  const [featureName, setFeatureName] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    if (featureToEdit) {
      setFeatureName(featureToEdit.name);
      setSelectedIcon(featureToEdit.icon);
    } else {
      setFeatureName("");
      setSelectedIcon("");
    }
  }, [featureToEdit]);

  const handleSave = () => {
    if (featureName && selectedIcon) {
      if (featureToEdit) {
        onUpdate({
          name: featureName,
          icon: selectedIcon,
          index: featureToEdit.index,
        });
      } else {
        onSave({ name: featureName, icon: selectedIcon });
      }
      onRequestClose();
    } else {
      // Add validation message or handle empty values
      alert("Please provide both feature name and icon.");
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg w-11/12 max-w-lg relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">
          {featureToEdit ? "Edit Feature" : "Add Feature"}
        </h2>
        <FaTimes className="cursor-pointer" onClick={onRequestClose} />
      </div>
      <div className="flex gap-5 justify-between items-start">
        <div className="mb-4">
          <label className="block text-secondary text-sm mb-2">Icon</label>
          <IconGrid
            onIconSelect={setSelectedIcon}
            selectedIcon={selectedIcon}
          />
        </div>
        <div className="mb-4 w-3/4">
          <Input
            label="Name of the feature"
            type="text"
            placeholder="Enter feature name"
            value={featureName}
            onChange={(e) => setFeatureName(e.target.value)}
          />
        </div>
      </div>
      <button
        className="bg-primary w-full text-white px-4 py-2 rounded-md"
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default AddFeatureModal;
